import React from "react";

import { EditProfile } from "./EditProfile";

import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { dateTimeConstants } from "_constants";
import moment from "moment";
import { preventNegativeValueInDocs, formatDate } from "_helpers/helpers";
import { config } from "_helpers";

const QualificationsComponent = ({
  profileData,
  dropdownData,
  trainingData,
  refetchUser,
  isOpen,
  toggleAccordion,
  changeTab,
  permList,
  authUser,
}) => {
  return (
    <div
      id="qualifications"
      className={`profile-data__drops profile-data_comp ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#qualifications")}>
        <button type="button">
          <h3>Qualifications</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>Title</b>
            </span>
            <span>{profileData.user_type}</span>
            <EditProfile
              label="Title"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes("Edit Title")}
            >
              {(register, errors) => (
                <>
                  <label>Title</label>
                  <select
                    {...register("user_type_id", {
                      required: "Title is required",
                    })}
                    defaultValue={profileData.user_type_id}
                    className={
                      errors.user_type_id && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Title</option>
                    {dropdownData.Roles.map((role, index) => (
                      <option key={index} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>

                  {errors.user_type_id && (
                    <p className="error-message">
                      {errors.user_type_id.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Primary Crew</b>
            </span>
            <span>{profileData.primary_crew_name}</span>
            <EditProfile
              label="Primary Crew"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes("Assign Staff")}
            >
              {(register, errors) => (
                <>
                  <label>Primary Crew</label>
                  <select
                    {...register("primary_crew_identifier_id", {
                      required: "Primary Crew is required",
                    })}
                    defaultValue={profileData.primary_crew_identifier_id}
                    className={
                      errors.primary_crew_identifier_id &&
                      "profile-form-field-error"
                    }
                  >
                    <option value="">Select Primary Crew</option>
                    {dropdownData.FireFighter.map((crew, index) => (
                      <option key={index} value={crew.id}>
                        {crew.resource_name}
                      </option>
                    ))}
                  </select>
                  {errors.primary_crew_identifier_id && (
                    <p className="error-message">
                      {errors.primary_crew_identifier_id.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Fire Seasons</b>
            </span>
            <span>{profileData.fire_seasons ?? "-"}</span>
            <EditProfile
              label="Fire Seasons"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors) => (
                <>
                  <label>Fire Seasons</label>
                  <input
                    type="number"
                    {...register("fire_seasons", {
                      min: {
                        value: 0,
                        message: "Fire Seasons must be a valid value",
                      },
                      validate: (value) =>
                        value.toString().length <= 3 ||
                        "Fire Seasons must be at most 3 digits",
                      valueAsNumber: true,
                    })}
                    placeholder="Enter Fire Seasons"
                    defaultValue={profileData.fire_seasons}
                    className={
                      errors.fire_seasons && "profile-form-field-error"
                    }
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={preventNegativeValueInDocs}
                  />
                  {errors.fire_seasons && (
                    <p className="error-message">
                      {errors.fire_seasons.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Language</b>
            </span>
            <span>{profileData.language ?? "-"}</span>
            <EditProfile
              label="Language"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors) => (
                <>
                  <label>Language</label>
                  <input
                    {...register("language", {
                      maxLength: {
                        value: 100,
                        message:
                          "Length exceeds the maximum limit of 100 characters.",
                      },
                      pattern: {
                        value: /^[A-Za-z, ]+$/,
                        message: "Only alphabets and comma are allowed.",
                      },
                    })}
                    placeholder="Enter Language"
                    defaultValue={profileData.language}
                    className={errors.language && "profile-form-field-error"}
                  />
                  {errors.language && (
                    <p className="error-message">{errors.language.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Height</b>
            </span>
            <span>{profileData.height ?? "-"}</span>
            <EditProfile
              label="Height"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors) => (
                <>
                  <label>Height</label>
                  <input
                    {...register("height", {
                      maxLength: {
                        value: 10,
                        message:
                          "Length exceeds the maximum limit of 10 characters.",
                      },
                      pattern: {
                        value:
                          /^(?:(?:[4-7]' ?\d{1,2}")|(?:[4-7]-\d{1,2})|(?:[4-7] ft \d{1,2} in)|(?:[4-7]\.\d+ ft)|(?:\d{2,3}")|(?:\d{2,3} in))$/,
                        message:
                          'Invalid height format. Example: 6\'2", 6-2, 6 ft 2 in, 74"',
                      },
                    })}
                    placeholder={
                      'Enter Height. Sample formats: 6\'2", 6-2, 6 ft 2 in, 74"'
                    }
                    defaultValue={profileData.height}
                    className={errors.height && "profile-form-field-error"}
                  />
                  {errors.height && (
                    <p className="error-message">{errors.height.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Weight</b>
            </span>
            <span>{profileData.weight + " lbs"}</span>
            <EditProfile
              label="Weight"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors) => (
                <>
                  <label>Weight</label>
                  <input
                    type="number"
                    {...register("weight", {
                      required: "Weight is required",
                      min: {
                        value: 0,
                        message: "Weight must be a valid value",
                      },
                      validate: (value) =>
                        value.toString().length <= 4 ||
                        "Weight must be at most 4 digits",
                      valueAsNumber: true,
                    })}
                    placeholder="Enter Weight in lbs"
                    defaultValue={profileData.weight}
                    className={errors.weight && "profile-form-field-error"}
                    onWheel={(e) => e.target.blur()}
                    onKeyDown={preventNegativeValueInDocs}
                  />
                  {errors.weight && (
                    <p className="error-message">{errors.weight.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <a
                onClick={() => changeTab("qualifications")}
                className="link-green"
              >
                Qualifications
              </a>
            </span>
            <ul role="list">
              {profileData.qual_key.map((qual, index) => (
                <li role="listitem">{qual}</li>
              ))}
            </ul>
          </li>

          <li role="listitem">
            <span>
              <a onClick={() => changeTab("trainings")} className="link-green">
                Trainings
              </a>
            </span>
            {trainingData && (
              <ul role="list">
                <li role="listitem">
                  Physical Fitness:{" "}
                  {[
                    (trainingData.physical_fitness_pack_tes ||
                      trainingData.physical_fitness_pk_date) &&
                      "Pack Test",
                    (trainingData.physical_fitness_two_mile ||
                      trainingData.physical_fitness_2mi_date) &&
                      "2-Mile Run",
                  ]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </li>
                <li role="listitem">
                  Basic 32:{" "}
                  {[
                    trainingData.basic_32_s_130 && "S-130",
                    trainingData.basic_32_s_190 && "S-190",
                    trainingData.basic_32_l_180 && "L-180",
                    trainingData.basic_32_24_hr_cer && "24-hr Certificate",
                  ]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </li>
                <li role="listitem">
                  Fema Courses:{" "}
                  {[
                    trainingData.fema_courses_is_100 && "IS-100",
                    trainingData.fema_courses_is_200 && "IS-200",
                    trainingData.fema_courses_is_00700 && "IS-00700",
                    trainingData.fema_courses_is_00800 && "IS-00800",
                  ]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </li>
                <li role="listitem">
                  Firestorm Courses:{" "}
                  {[
                    trainingData.fs_courses_business_courses &&
                      "Business Ethics",
                    trainingData.fs_courses_driver_operator &&
                      "Driver/Operator",
                  ]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </li>
                <li role="listitem">
                  Instructor Training:{" "}
                  {[trainingData.instructor_training_m_410 && "M-410"]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </li>
                <li role="listitem">
                  Medical Training:{" "}
                  {[
                    trainingData.medical_training_survival_cpr &&
                      "Survival CPR/First Aid",
                  ]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </li>
                <li role="listitem">
                  Specialty Training:{" "}
                  {[
                    trainingData.specialty_training_hazwoper_40 &&
                      "HAZWOPER 40",
                    trainingData.specialty_training_hazwoper_8hr &&
                      "HAZWOPER 8HR",
                    trainingData.specialty_training_tcia_co &&
                      "TCIA Chipper Operator",
                    trainingData.specialty_training_fwfs_co &&
                      "FWFS Chipper Operator",
                    trainingData.specialty_training_certified_flagger &&
                      "Certified Flagger",
                    trainingData.specialty_training_hired_equipment &&
                      "Hire Equipment",
                    trainingData.specialty_training_atv && "ATV",
                    trainingData.specialty_training_co_st &&
                      "Chainsaw Operator and Safety Training",
                    trainingData.specialty_training_forklift_operator &&
                      "Forklift Operator",
                    trainingData.specialty_training_forklift_trainer &&
                      "Forklift Trainer",
                    trainingData.specialty_training_job_relations &&
                      "Job Relations",
                    trainingData.specialty_training_leader_success &&
                      "Comm. For Leader Success",
                    trainingData.specialty_training_colors_personality_training &&
                      "Colors Personality Training",
                    trainingData.specialty_training_inter_advanced &&
                      "Inter/Advanced Sawyer/Felling",
                    trainingData.specialty_training_fire_burn_boss &&
                      "State-Certified Prescribed-Fire Burn Boss",
                  ]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </li>
                <li role="listitem">
                  FFT1 Training:{" "}
                  {[
                    trainingData.fft1_training_s_131 && "S-131",
                    trainingData.fft1_training_s_133 && "S-133",
                    trainingData.fft1_training_s_134 && "S-134",
                    trainingData.fft1_training_s_211 && "S-211",
                    trainingData.fft1_training_s_212 && "S-212",
                    trainingData.fft1_training_s_212_rt && "S-212 RT",
                    trainingData.fft1_training_s_212_exp && "S-212 Exp",
                  ]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </li>
                <li role="listitem">
                  SRB Training:{" "}
                  {[
                    trainingData.srb_training_s_215 && "S-215",
                    trainingData.srb_training_s_234_219 && "S-234/219",
                    trainingData.srb_training_s_230 && "S-230",
                    trainingData.srb_training_s_231 && "S-231",
                    trainingData.srb_training_s_260 && "S-260",
                    trainingData.srb_training_s_270 && "S-270",
                    trainingData.srb_training_s_290 && "S-290",
                  ]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </li>
                <li role="listitem">
                  Skills Courses (I):{" "}
                  {[
                    trainingData.skills_courses_s_200 && "S-200",
                    trainingData.skills_courses_s_203 && "S-203",
                    trainingData.skills_courses_s_216 && "S-216",
                    trainingData.skills_courses_s_232 && "S-232",
                    trainingData.skills_courses_s_233 && "S-233r",
                    trainingData.skills_courses_s_235 && "S-235",
                    trainingData.skills_courses_s_244_245 && "S-244/245",
                    trainingData.skills_courses_s_248 && "S-248",
                    trainingData.skills_courses_s_258 && "S-258",
                    trainingData.skills_courses_s_259 && "S-259",
                    trainingData.skills_courses_s_271 && "S-271",
                    trainingData.skills_courses_s_273 && "S-273",
                    trainingData.skills_courses_s_300 && "S-300",
                    trainingData.skills_courses_s_301 && "S-301",
                    trainingData.skills_courses_s_330 && "S-330",
                    trainingData.skills_courses_s_331 && "S-331",
                    trainingData.skills_courses_s_336 && "S-336",
                    trainingData.skills_courses_s_339 && "S-339",
                    trainingData.skills_courses_s_346 && "S-346",
                    trainingData.skills_courses_s_348_347 && "S-348/347",
                    trainingData.skills_courses_s_354 && "S-354",
                    trainingData.skills_courses_s_355 && "S-355",
                    trainingData.skills_courses_s_356 && "S-356",
                  ]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </li>
                <li role="listitem">
                  Incident Courses:{" "}
                  {[
                    trainingData.incident_courses_i_220 && "I-220",
                    trainingData.incident_courses_i_300 && "I-300",
                    trainingData.incident_courses_i_400 && "I-400",
                    trainingData.incident_courses_i_401 && "I-401",
                    trainingData.incident_courses_i_402 && "I-402",
                  ]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </li>
                <li role="listitem">
                  Leadership Courses:{" "}
                  {[
                    trainingData.leadership_courses_l_280 && "L-280",
                    trainingData.leadership_courses_l_380 && "L-380",
                    trainingData.leadership_courses_l_381 && "L-381",
                    trainingData.leadership_courses_fire_mgmt &&
                      "Fire Mgmt Leadership",
                  ]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </li>
                <li role="listitem">
                  RX Burn Courses:{" "}
                  {[
                    trainingData.rx_burn_courses_rx_300 && "Rx-300",
                    trainingData.rx_burn_courses_rx_340_310 && "Rx-340/310",
                    trainingData.rx_burn_courses_rx_410 && "Rx-410",
                    trainingData.rx_burn_courses_rx_540 && "Rx-540",
                    trainingData.rx_burn_courses_currency && "Currency",
                  ]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </li>
                <li role="listitem">
                  Skills Courses (II):{" "}
                  {[
                    trainingData.skills_courses_s_357 && "S-357",
                    trainingData.skills_courses_s_360 && "S-360",
                    trainingData.skills_courses_s_370 && "S-370",
                    trainingData.skills_courses_s_371 && "S-371",
                    trainingData.skills_courses_s_378 && "S-378",
                    trainingData.skills_courses_s_390 && "S-390",
                    trainingData.skills_courses_s_400 && "S-400",
                    trainingData.skills_courses_s_402 && "S-402",
                    trainingData.skills_courses_s_403 && "S-403",
                    trainingData.skills_courses_s_404 && "S-404",
                    trainingData.skills_courses_s_420 && "S-420",
                    trainingData.skills_courses_s_430 && "S-430",
                    trainingData.skills_courses_s_440 && "S-440",
                    trainingData.skills_courses_s_443 && "S-443",
                    trainingData.skills_courses_s_445 && "S-445",
                    trainingData.skills_courses_s_450 && "S-450",
                    trainingData.skills_courses_s_460 && "S-460",
                    trainingData.skills_courses_s_470 && "S-470",
                    trainingData.skills_courses_s_490 && "S-490",
                    trainingData.skills_courses_s_491 && "S-491",
                    trainingData.skills_courses_s_492 && "S-492",
                    trainingData.skills_courses_s_493 && "S-493",
                  ]
                    .filter(Boolean)
                    .join(", ") || "-"}
                </li>
              </ul>
            )}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default QualificationsComponent;
