import React from "react";
import { connect } from "react-redux";
import { config } from "../_helpers";
import DatePicker from "react-datepicker";
import moment from "moment";
import { AlertPopup } from "../_components/AlertPopup";
import "../assets/styles/react-datepicker.css";
import "../assets/styles/application.css";
import { applicantsActions } from "../_actions";
import NumberFormat from "react-number-format";
import { toast } from "react-toastify";
import {
  checkCurrentUserIsSuperAdmin,
  preventNegativeValueInDocs,
} from "_helpers/helpers";

class EditApplicantion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      no_past_experience: null,
      no_past_reference: null,
      AnticipatedLocation: [],
      ApplyingPosition: [],
      CertificationStatus: [],
      GeneralQualification: [],
      PositionOn: [],
      Qualification: [],
      RaceList: [],
      SourceOfKnowledgeList: [],
      ReportingLocation: [],
      allData: [],
      qualification_list: [],

      sourceOfKnowledge: "",
      position_applying_for: "",
      fname: "",
      lname: "",
      sname: "",
      employee_name: "",
      tmpMailingAddress1: "",
      tmpMailingAddress2: "",
      tmpMailingCity: "",
      tmpMailingState: "",
      tmpMailingZip: "",
      mailingAddress1: "",
      mailingAddress2: "",
      city: "",
      state: "",
      zip: "",
      physicalStreetAddress1: "",
      physicalStreetAddress2: "",
      physicalCity: "",
      physicalState: "",
      physicalZip: "",
      email: "",
      main_phone: "",
      secondary_phone: "",
      anticipatedLocationList: "",
      chkSameAddress: false,
      maddress1: false,
      maddress2: false,
      mcity: false,
      mstate: false,
      mzip: false,

      reporting_location: "",
      chkPositionOn: "",
      chkCurrentQualification: [],
      applying_position: [],
      certification_status: "",
      certification_status_name: "",
      qualifications: "",
      agreement: "",
      fileTaskbook1: "",
      fileTaskbook2: "",
      fileTaskbook3: "",
      fileTaskbook4: "",
      fileTaskbook1file: "",
      fileTaskbook2file: "",
      fileTaskbook3file: "",
      fileTaskbook4file: "",
      txtDate: moment(),
      high_school: "",
      school_duration: "",
      school_taken: "",
      is_school_graduate: "",
      university: "",
      degree_duration: "",
      degree_taken: "",
      is_degree_graduate: "",
      university1: "",
      degree_duration1: "",
      degree_taken1: "",
      is_degree_graduate1: "",
      addition_info: "",
      firestorm_experience: "",
      seperation_reason: "",
      last_employer1: "",
      last_employer2: "",
      last_employer3: "",
      reference1StartDate: moment(),
      reference1Position: "",
      reference1EndDate: moment(),
      reference1Address: "",
      reference1City: "",
      reference1State: "",
      reference1Supervisor: "",
      reference1AltContact: "",
      reference1Telephone: "",
      reference1Fax: "",
      reference1Responsibilities: "",
      reference1ResonForLeaving: "",
      reference2StartDate: moment(),
      reference2Position: "",
      reference2EndDate: moment(),
      reference2Address: "",
      reference2City: "",
      reference2State: "",
      reference2Supervisor: "",
      reference2AltContact: "",
      reference2Telephone: "",
      reference2Fax: "",
      reference2Responsibilities: "",
      reference2ResonForLeaving: "",
      reference3StartDate: moment(),
      reference3Position: "",
      reference3EndDate: moment(),
      reference3Address: "",
      reference3City: "",
      reference3State: "",
      reference3Supervisor: "",
      reference3AltContact: "",
      reference3Telephone: "",
      reference3Fax: "",
      reference3Responsibilities: "",
      reference3ResonForLeaving: "",
      reference1name: "",
      reference1Relationship: "",
      reference1Phone: "",
      reference1Known: "",
      reference2name: "",
      reference2Relationship: "",
      reference2Phone: "",
      reference2Known: "",
      reference3name: "",
      reference3Relationship: "",
      reference3Phone: "",
      reference3Known: "",
      licenseState: "",
      licenseNumber: "",
      licenseExpDate: moment(),
      licenseClass: "",
      endorsement: "",
      DeniedLicense: "",
      LicenseRevoked: "",
      ReasonLicenseRevoked: "",
      Question1: "",
      Question2: "",
      Question3: "",
      gender: "",
      Race: "",
      Veteran: "",
      FullName: "",
      sourceError: "",
      empNameError: "",
      fnameError: "",
      lnameError: "",
      snameError: "",
      miError: "",
      address1Error: "",
      cityError: "",
      stateError: "",
      zipError: "",
      physicalStreetAddress1Error: "",
      physicalStreetAddress2Error: "",
      physicalCityError: "",
      physicalStateError: "",
      physicalZipError: "",
      emailError: "",
      phoneError: "",
      relocDateError: "",
      certStatusError: "",
      currentQualifyError: "",
      fExperienceError: "",
      Question1Error: "",
      Question2Error: "",
      Question3Error: "",
      AgreementError: "",
      FullNameError: "",
      AntiLocError: "",
      todayError: "",
      separationError: "",
      empClass: "hide",
      file_list: [],
      reloacationDate: moment(),
      reloacationdate: moment(),
      date: moment(),
      alertPop: false,

      reference1nameError: "",
      reference1RelationshipError: "",
      reference1PhoneError: "",
      reference1KnownError: "",

      reference2nameError: "",
      reference2RelationshipError: "",
      reference2PhoneError: "",
      reference2KnownError: "",

      last_employer1Error: "",
      reference1PositionError: "",
      reference1SupervisorError: "",
      reference1TelephoneError: "",

      last_employer2Error: "",
      reference2PositionError: "",
      reference2SupervisorError: "",
      reference2TelephoneError: "",
      // reference2StartDateError:"",
      // reference2EndDateError:"",
      // reference2AddressError:"",
      // reference2CityError:"",
      // reference2StateError:"",
      // reference2AltContactError:"",
      // reference2FaxError:""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
    this.toggleAddressCheckbox = this.toggleAddressCheckbox.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.clearFile = this.clearFile.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.handleCheckbox2 = this.handleCheckbox2.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.goBack = this.goBack.bind(this);
    this.closeAlert = this.closeAlert.bind(this);
    this.checkNull = this.checkNull.bind(this);
  }
  componentDidMount() {
    this.props.dispatch(applicantsActions.viewDetails(this.props.hash));
    this.props.dispatch(applicantsActions.getFieldAll());

    fetch(config.apiUrl + "/api/getlistall")
      .then((res) => {
        return res.json();
      })
      .then((data) => {})
      .catch((err) => console.log(err));
  }

  componentWillReceiveProps(nextProps) {
    // Dispalys success alert message
    if (nextProps.applicants.application?.success) {
      this.setState({
        alertPop: true,
        alertHead: "Success",
        alertMsg: nextProps.applicants.application.message,
        alertFunction: this.goBack.bind(this),
      });
    }
    const applicants = nextProps.applicants.list;
    if (applicants) {
      this.setState({
        hash: applicants.data.hash,
        email: applicants.data.email_address,
        applicants: applicants.data,
      });

      // Date format
      var txtDate;
      if (applicants.data.date !== "" && applicants.data.date !== undefined) {
        txtDate = moment(applicants.data.date_applied);
      } else {
        txtDate = moment();
      }
      const exp_date = applicants.data.license?.exp_date
        ? moment(applicants.data.license.exp_date)
        : undefined;
      const relocation_date = applicants.data.anticipated_relocation
        ? moment(applicants.data.anticipated_relocation)
        : moment();
      const reference1_start_date_Obj = applicants.data.reference1.start_date
        ? moment(applicants.data.reference1.start_date)
        : moment();
      const reference1_end_date_Obj = applicants.data.reference1.end_date
        ? moment(applicants.data.reference1.end_date)
        : moment();
      const reference2_start_date_Obj = applicants.data.reference2.start_date
        ? moment(applicants.data.reference2.start_date)
        : moment();
      const reference2_end_date_Obj = applicants.data.reference2.end_date
        ? moment(applicants.data.reference2.end_date)
        : moment();
      const reference3_start_date_Obj = applicants.data.reference3.start_date
        ? moment(applicants.data.reference3.start_date)
        : moment();
      const reference3_end_date_Obj = applicants.data.reference3.end_date
        ? moment(applicants.data.reference3.end_date)
        : moment();

      if (applicants.data.source_of_knowledge_id === 1) {
        this.setState({ empClass: "show" });
      }
      this.setState(
        {
          hash: applicants.data.hash,
          sourceOfKnowledge: applicants.data.source_of_knowledge_id,
          position_applying_for: applicants.data.position_applying_for,
          employee_name: applicants.data.firestorm_employee,
          fname: applicants.data.first_name,
          lname: applicants.data.last_name,
          sname: applicants.data.middle_initial,
          mailingAddress1: applicants.data.mailing_address1,
          mailingAddress2: applicants.data.mailing_address2,
          city: applicants.data.city_id,
          state: applicants.data.state_id,
          zip: applicants.data.zip_id,
          physicalStreetAddress1:
            applicants.data.physical_street_address1 ?? "",
          physicalStreetAddress2:
            applicants.data.physical_street_address2 ?? "",
          physicalCity: applicants.data.physical_city_id ?? "",
          physicalState: applicants.data.physical_state_id ?? "",
          physicalZip: applicants.data.physical_zip_id ?? "",
          email: applicants.data.email_address,
          main_phone: applicants.data.phone_number,
          secondary_phone: applicants.data.secondary_phone,
          anticipatedLocationList: applicants.data.anticipated_location_id,
          reloacationDate: relocation_date,
          applying_position: applicants.data.applying_position_id,
          reporting_location: applicants.data.reporting_location_id,
          chkPositionOn: applicants.data.position_on_id,
          chkCurrentQualification:
            applicants.data.qualification_id === "null"
              ? ""
              : applicants.data.qualification_id,
          certification_status: applicants.data.certification_status_id,
          certification_status_name: applicants.data.certification_status,
          qualifications: applicants.data.general_qualification_id,
          high_school: applicants.data.highschool_name,
          school_duration: applicants.data.highschool_duration,
          school_taken: applicants.data.highschool_course,
          is_school_graduate: applicants.data.highschool_gratuate_status,
          university: applicants.data.university1.name,
          degree_duration: applicants.data.university1.duration,
          degree_taken: applicants.data.university1.course,
          is_degree_graduate: applicants.data.university1.gratuate_status,
          university1: applicants.data.university2.name,
          degree_duration1: applicants.data.university2.duration,
          degree_taken1: applicants.data.university2.course,
          is_degree_graduate1: applicants.data.university2.gratuate_status,
          firestorm_experience: applicants.data.worked_for_firestorm,
          seperation_reason: applicants.data.reason_leave_firestorm,
          last_employer1: applicants.data.reference1.last_employee,
          addition_info: applicants.data.applicant_info,
          reference1Position: applicants.data.reference1.position,
          reference1StartDate: reference1_start_date_Obj,
          reference1EndDate: reference1_end_date_Obj,
          reference1Address: applicants.data.reference1.address,
          reference1City: applicants.data.reference1.city_id,
          reference1State: applicants.data.reference1.state_id,
          reference1Supervisor: applicants.data.reference1.supervisor_name,
          reference1AltContact: applicants.data.reference1.alt_contact_name,
          reference1Telephone: applicants.data.reference1.telephone,
          reference1Fax: applicants.data.reference1.fax,
          reference1Responsibilities:
            applicants.data.reference1.responsibilities,
          reference1ResonForLeaving:
            applicants.data.reference1.reason_for_leaving,
          last_employer2: applicants.data.reference2.last_employee,
          reference2Position: applicants.data.reference2.position,
          reference2StartDate: reference2_start_date_Obj,
          reference2EndDate: reference2_end_date_Obj,
          reference2Address: applicants.data.reference2.address,
          reference2City: applicants.data.reference2.city_id,
          reference2State: applicants.data.reference2.state_id,
          reference2Supervisor: applicants.data.reference2.supervisor_name,
          reference2AltContact: applicants.data.reference2.alt_contact_name,
          reference2Telephone: applicants.data.reference2.telephone,
          reference2Fax: applicants.data.reference2.fax,
          reference2Responsibilities:
            applicants.data.reference2.responsibilities,
          reference2ResonForLeaving:
            applicants.data.reference2.reason_for_leaving,
          last_employer3: applicants.data.reference3.last_employee,
          reference3Position: applicants.data.reference3.position,
          reference3StartDate: reference3_start_date_Obj,
          reference3EndDate: reference3_end_date_Obj,
          reference3Address: applicants.data.reference3.address,
          reference3City: applicants.data.reference3.city_id,
          reference3State: applicants.data.reference3.state_id,
          reference3Supervisor: applicants.data.reference3.supervisor_name,
          reference3AltContact: applicants.data.reference3.alt_contact_name,
          reference3Telephone: applicants.data.reference3.telephone,
          reference3Fax: applicants.data.reference3.fax,
          reference3Responsibilities:
            applicants.data.reference3.responsibilities,
          reference3ResonForLeaving:
            applicants.data.reference3.reason_for_leaving,
          reference1name: applicants.data.personalreference1.name,
          reference1Relationship:
            applicants.data.personalreference1.relationship,
          reference1Phone: applicants.data.personalreference1.phone,
          reference1Known: applicants.data.personalreference1.known,
          reference2name: applicants.data.personalreference2.name,
          reference2Relationship:
            applicants.data.personalreference2.relationship,
          reference2Phone: applicants.data.personalreference2.phone,
          reference2Known: applicants.data.personalreference2.known,
          reference3name: applicants.data.personalreference3.name,
          reference3Relationship:
            applicants.data.personalreference3.relationship,
          reference3Phone: applicants.data.personalreference3.phone,
          reference3Known: applicants.data.personalreference3.known,
          licenseState: applicants.data.license.state_id,
          licenseNumber: applicants.data.license.no,
          licenseClass: applicants.data.license.class,
          DeniedLicense: applicants.data.denied_license,
          LicenseRevoked: applicants.data.license_revoked,
          ReasonLicenseRevoked: applicants.data.reason_license_revoked,
          endorsement: applicants.data.license.endorsement,
          Question1: applicants.data.question1,
          Question2: applicants.data.question2,
          Question3: applicants.data.question3,
          gender: applicants.data.gender,
          Race: applicants.data.race_id,
          Veteran: applicants.data.veteran,
          agreement: applicants.data.agreement,
          FullName: applicants.data.full_name,
          txtDate: txtDate,
          licenseExpDate: exp_date,

          applyingPosition_temp: applicants.data.applying_position_id
            ? (applicants.data.applying_position_id || "").split(",")
            : [],
          CurrentQualification_temp: (applicants.data.qualification_id || "")
            .split(",")
            .filter((item) => {
              return item !== "";
            })
            ? (applicants.data.qualification_id || "").split(",")
            : applicants.data.qualification_id,
        },
        () => {
          if (
            this.state.CertificationStatus &&
            this.state.CertificationStatus.findIndex(
              (item) => item.id == this.state.certification_status,
            ) == -1
          ) {
            let { CertificationStatus } = this.state;
            CertificationStatus.push({
              id: this.state.certification_status,
              position: this.state.certification_status,
              value: this.state.certification_status_name,
            });
            this.setState({ CertificationStatus });
          }
        },
      );

      if (applicants.data.applying_position_id !== null) {
        this.setState({
          applyingPosition_temp:
            applicants.data.applying_position_id.split(","),
        });
      } else {
        this.setState({
          applyingPosition_temp: [],
        });
      }
      if (applicants.data.position_on_id !== null) {
        this.setState({
          positionOn_temp: applicants.data.position_on_id,
        });
      }
    }
    // Dropdown values
    if (nextProps.applicants.fields) {
      const { fields } = nextProps.applicants;
      if (
        this.state.certification_status &&
        fields.CertificationStatus.findIndex(
          (item) => item.id == this.state.certification_status,
        ) == -1
      ) {
        fields.CertificationStatus.push({
          id: this.state.certification_status,
          position: this.state.certification_status,
          value: this.state.certification_status_name,
        });
      }
      this.setState({
        fields: fields,
        AnticipatedLocation: fields.AnticipatedLocation,
        ApplyingPosition: fields.ApplyingPosition,
        CertificationStatus: fields.CertificationStatus,
        GeneralQualification: fields.GeneralQualification,
        PositionOn: fields.PositionOn,
        Qualification: fields.Qualification,
        RaceList: fields.Race,
        ReportingLocation: fields.ReportingLocation,
        SourceOfKnowledgeList: fields.SourceOfKnowledge,
      });
    }
  }

  closeAlert() {
    this.setState({
      alertPop: false,
    });
  }

  goBack() {
    this.props.goBack();
  }

  handleDateChange(name, date) {
    this.setState({
      [name]: date,
    });
  }
  clearFile(file) {
    this.setState({
      [file]: "",
    });
  }
  handleCheckbox2(e) {
    let name = e.target.name;
    let checked = e.target.checked;
    let value = e.target.value;

    if (!checked) {
      value = null;
    }
    this.setState({
      ...this.state,
      [name]: value,
      applicants: { ...this.state.applicants, [name]: value },
    });
  }

  handleCheckbox(e) {
    const value = e.target.value;
    const name = e.target.name;

    if (name === "chkCurrentQualification") {
      // check if this CurrentQualification_temp is needed in the state
      let CurrentQualification_temp = [...this.state.CurrentQualification_temp];
      if (e.target.checked) {
        CurrentQualification_temp.push(value);
      } else {
        CurrentQualification_temp = CurrentQualification_temp.filter(
          (qualification) => qualification !== value,
        );
      }
      this.setState({
        CurrentQualification_temp,
      });
    }

    if (name === "applying_position") {
      // check if this applyingPosition_temp is needed in the state
      let applyingPosition_temp = [...this.state.applyingPosition_temp];
      if (e.target.checked) {
        applyingPosition_temp.push(value);
      } else {
        applyingPosition_temp = applyingPosition_temp.filter(
          (position) => position !== value,
        );
      }
      this.setState({
        applyingPosition_temp,
      });
    }

    if (name === "chkPositionOn") {
      if (e.target.checked) {
        this.setState({
          positionOn_temp: value,
          chkPositionOn: value,
        });
      }
    }

    const chkStringQual = this.state.CurrentQualification_temp.toString();
    const chkString = this.state.applyingPosition_temp.toString();

    this.setState({
      applying_position: chkString,
      chkCurrentQualification:
        chkStringQual[0] === "," ? chkStringQual.slice(1) : chkStringQual,
    });
  }

  checkNull(value) {
    return [null, "", "null", undefined].includes(value);
  }

  handleChange(e) {
    var name = e.target.name,
      value = e.target.value;
    if (name === "sourceOfKnowledge" && value === "1") {
      this.setState({
        empClass: "show",
      });
    } else if (name === "sourceOfKnowledge" && value !== "1") {
      this.setState({
        empClass: "hide",
      });
    }

    this.setState(
      {
        [name]: value,
      },
      () => {
        var address = [
            "physicalStreetAddress1",
            "physicalStreetAddress2",
            "physicalCity",
            "physicalState",
            "physicalZip",
          ],
          ob = {};
        if (address.indexOf(name) > -1 && this.state.chkSameAddress) {
          ob = {
            mailingAddress1: this.state.physicalStreetAddress1,
            mailingAddress2: this.state.physicalStreetAddress2,
            city: this.state.physicalCity,
            state: this.state.physicalState,
            zip: this.state.physicalZip,
          };
        }
        this.setState(
          {
            ...this.state,
            ...ob,
          },
          () => {},
        );
      },
    );
    e.target.setAttribute("errors", "");
    // }
  }

  handleAddress(e) {
    if (e.target.checked) {
      this.setState({
        ...this.state,
        tmpMailingAddress1: this.state.mailingAddress1,
        tmpMailingAddress2: this.state.mailingAddress2,
        tmpMailingCity: this.state.city,
        tmpMailingState: this.state.state,
        tmpMailingZip: this.state.zip,
        mailingAddress1: this.state.physicalStreetAddress1,
        mailingAddress2: this.state.physicalStreetAddress2,
        city: this.state.physicalCity,
        state: this.state.physicalState,
        zip: this.state.physicalZip,
        chkSameAddress: true,
        maddress1: true,
        maddress2: true,
        mcity: true,
        mstate: true,
        mzip: true,
      });
      if (document.getElementById("mailingAddress1"))
        document.getElementById("mailingAddress1").setAttribute("errors", "");

      if (document.getElementById("mailingAddress2"))
        document.getElementById("mailingAddress2").setAttribute("errors", "");

      if (document.getElementById("city"))
        document.getElementById("city").setAttribute("errors", "");

      if (document.getElementById("state"))
        document.getElementById("state").setAttribute("errors", "");

      if (document.getElementById("zip"))
        document.getElementById("zip").setAttribute("errors", "");
    } else {
      this.setState({
        maddress1: false,
        maddress2: false,
        mcity: false,
        mstate: false,
        mzip: false,
        mailingAddress1: this.state.tmpMailingAddress1,
        mailingAddress2: this.state.tmpMailingAddress2,
        city: this.state.tmpMailingCity,
        state: this.state.tmpMailingState,
        zip: this.state.tmpMailingZip,
        chkSameAddress: false,
      });
    }
  }

  toggleAddressCheckbox = () => {
    const chkSameAddressCheckbox = document.getElementById("chkSameAddress");
    chkSameAddressCheckbox.checked = !chkSameAddressCheckbox.checked; // Toggle the checkbox
    this.handleAddress({ target: chkSameAddressCheckbox }); // Call handleAddress function with checkbox event
  };

  validate(e) {
    let isError = false;

    if (this.state.txtDate === "") {
      isError = true;
      this.setState({
        txtDateError: "error",
      });
      document.getElementById("txtDate").focus();
    }
    if (this.state.FullName.length < 1) {
      isError = true;
      this.setState({
        FullNameError: "error",
      });
      document.getElementById("FullName").focus();
    }
    if (this.state.agreement === "no" || this.state.agreement === "") {
      isError = true;
      this.setState({
        AgreementError: "error",
      });
      document.getElementById("agreement").focus();
    }
    if (this.state.Question3.length < 1) {
      isError = true;
      this.setState({
        Question3Error: "error",
      });
      document.getElementById("Question3").focus();
    }
    if (this.state.Question2.length < 1) {
      isError = true;
      this.setState({
        Question2Error: "error",
      });
      document.getElementById("Question2").focus();
    }
    if (this.state.Question1.length < 1) {
      isError = true;
      this.setState({
        Question1Error: "error",
      });
      document.getElementById("Question1").focus();
    }
    if (this.state.firestorm_experience === "") {
      isError = true;
      this.setState({
        fExperienceError: "error",
      });
      document.getElementById("firestorm_experience").focus();
    }
    if (this.state.firestorm_experience === "Yes") {
      if (
        this.state.seperation_reason === "" ||
        this.state.seperation_reason === "null"
      ) {
        isError = true;
        this.setState({
          separationError: "error",
        });
        document.getElementById("seperation_reason").focus();
      }
    }
    if (this.state.certification_status === "") {
      isError = true;
      this.setState({
        certStatusError: "error",
      });
      document.getElementById("certification_status").focus();
    }

    if (this.state.reloacationDate === "") {
      isError = true;
      this.setState({
        relocDateError: "error",
      });
      document.getElementById("reloacationDate").focus();
    }

    if (this.state.anticipatedLocationList === "") {
      isError = true;
      this.setState({
        AntiLocError: "error",
      });
      document.getElementById("anticipatedLocationList").focus();
    }
    if (this.state.main_phone.length < 1) {
      isError = true;
      this.setState({
        phoneError: "error",
      });
      document.getElementById("main_phone").focus();
    }
    if (this.state.email.length < 1 || !this.IsValidEmail(this.state.email)) {
      isError = true;
      this.setState({
        emailError: "error",
      });
      document.getElementById("email").focus();
    }
    // if(this.state.email.length > 1 || this.IsValidEmail(this.state.email)){
    //     isError = false;
    //     this.setState({
    //       emailError: ""
    //     })
    // }

    if (this.state.zip === "") {
      isError = true;
      this.setState({
        zipError: "error",
      });
      document.getElementById("zip").focus();
    }
    if (this.state.state === "") {
      isError = true;
      this.setState({
        stateError: "error",
      });
      document.getElementById("state").focus();
    }
    if (this.state.city === "") {
      isError = true;
      this.setState({
        cityError: "error",
      });
      document.getElementById("city").focus();
    }
    if (this.state.mailingAddress1 === "") {
      isError = true;
      this.setState({
        address1Error: "error",
      });
      document.getElementById("mailingAddress1").focus();
    }
    if (this.state.physicalStreetAddress1 === "") {
      isError = true;
      this.setState({
        physicalStreetAddress1Error: "error",
      });
      document.getElementById("physicalStreetAddress1").focus();
    }
    if (this.state.physicalCity === "") {
      isError = true;
      this.setState({
        physicalCityError: "error",
      });
      document.getElementById("physicalCity").focus();
    }
    if (this.state.physicalState === "") {
      isError = true;
      this.setState({
        physicalStateError: "error",
      });
      document.getElementById("physicalState").focus();
    }
    if (this.state.physicalZip === "") {
      isError = true;
      this.setState({
        physicalZipError: "error",
      });
      document.getElementById("physicalZip").focus();
    }

    /*if (this.state.sname === "") {
      isError = true;
      this.setState({
        snameError: "error"
      });
      document.getElementById("sname").focus();
    }*/
    if (this.state.lname === "") {
      isError = true;
      this.setState({
        lnameError: "error",
      });
      document.getElementById("lname").focus();
    }
    if (this.state.fname === "") {
      isError = true;
      this.setState({
        fnameError: "error",
      });
      document.getElementById("fname").focus();
    }
    if (this.state.sourceOfKnowledge === "") {
      isError = true;
      this.setState({
        sourceError: "error",
      });
      document.getElementById("sourceOfKnowledge").focus();
    }
    if (this.state.sourceOfKnowledge === 1) {
      if (
        this.state.employee_name === "" ||
        this.state.employee_name === null
      ) {
        isError = true;
        this.setState({
          empNameError: "error",
        });
        document.getElementById("employee_name").focus();
      }
    }

    //----------------NEW ERRORS--------------------//

    if (this.state.reference1name === "") {
      isError = true;
      this.setState({ reference1nameError: "error" });
    }
    if (this.state.reference1Relationship === "") {
      isError = true;
      this.setState({ reference1RelationshipError: "error" });
    }
    if (this.state.reference1Phone === "") {
      isError = true;
      this.setState({ reference1PhoneError: "error" });
    }
    if (this.state.reference1Known === "") {
      isError = true;
      this.setState({ reference1KnownError: "error" });
    }

    if (this.state.reference2name === "") {
      isError = true;
      this.setState({ reference2nameError: "error" });
    }
    if (this.state.reference2Relationship === "") {
      isError = true;
      this.setState({ reference2RelationshipError: "error" });
    }
    if (this.state.reference2Phone === "") {
      isError = true;
      this.setState({ reference2PhoneError: "error" });
    }
    if (this.state.reference2Known === "") {
      isError = true;
      this.setState({ reference2KnownError: "error" });
    }

    if (this.state.last_employer1 === "") {
      isError = true;
      this.setState({ last_employer1Error: "error" });
    }
    if (this.state.reference1Position === "") {
      isError = true;
      this.setState({ reference1PositionError: "error" });
    }
    if (this.state.reference1Supervisor === "") {
      isError = true;
      this.setState({ reference1SupervisorError: "error" });
    }
    if (this.state.reference1Telephone === "") {
      isError = true;
      this.setState({ reference1TelephoneError: "error" });
    }

    if (this.state.last_employer2 === "") {
      isError = true;
      this.setState({ last_employer2Error: "error" });
    }
    if (this.state.reference2Position === "") {
      isError = true;
      this.setState({ reference2PositionError: "error" });
    }
    if (this.state.reference2Supervisor === "") {
      isError = true;
      this.setState({ reference2SupervisorError: "error" });
    }
    if (this.state.reference2Telephone === "") {
      isError = true;
      this.setState({ reference2TelephoneError: "error" });
    }
    // if(this.state.reference2StartDate === ""){
    //   isError = true;
    //   this.setState({reference2StartDateError:"error"})
    // }
    // if(this.state.reference2EndDate === ""){
    //   isError = true;
    //   this.setState({reference2EndDateError:"error"})
    // }

    // if (this.state.reference2Address === "") {
    //   isError = true;
    //   this.setState({ reference2AddressError: "error" });
    // }
    // if (this.state.reference2City === "") {
    //   isError = true;
    //   this.setState({ reference2CityError: "error" });
    // }
    // if (this.state.reference2State === "") {
    //   isError = true;
    //   this.setState({ reference2StateError: "error" });
    // }

    // if (this.state.reference2AltContact === "") {
    //   isError = true;
    //   this.setState({ reference2AltContactError: "error" });
    // }

    // if (this.state.reference2Fax === "") {
    //   isError = true;
    //   this.setState({ reference2FaxError: "error" });
    // }

    return isError;
  }
  IsValidEmail(email) {
    var expr = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return expr.test(email);
  }
  onSubmit(e) {
    e.preventDefault();
    const err = this.validate();
    this.setState({
      file_list: [],
    });
    if (this.state.fileTaskbook1file !== "") {
      this.state.file_list.push(this.state.fileTaskbook1file);
    }
    if (this.state.fileTaskbook2file !== "") {
      this.state.file_list.push(this.state.fileTaskbook2file);
    }
    if (this.state.fileTaskbook3file !== "") {
      this.state.file_list.push(this.state.fileTaskbook3file);
    }
    if (this.state.fileTaskbook4file !== "") {
      this.state.file_list.push(this.state.fileTaskbook4file);
    }

    // To check current certification status is filled or not
    const certArray = [];
    for (const status in this.state.CertificationStatus) {
      certArray.push(this.state.CertificationStatus[status].id);
    }
    const cert = certArray.indexOf(parseInt(this.state.certification_status));
    let error = false;
    if (cert === -1) {
      error = true;
      this.setState({
        certStatusError: "error",
      });
      document.getElementById("certification_status").focus();
    }

    if (!err && !error) {
      const data = new FormData();
      data.append("hash", this.state.hash);
      data.append("sourceOfKnowledge", this.state.sourceOfKnowledge);
      data.append("position_applying_for", this.state.position_applying_for);
      data.append("fname", this.state.fname);
      data.append("lname", this.state.lname);
      data.append("sname", this.state.sname);
      data.append("employee_name", this.state.employee_name);
      data.append("mailingAddress1", this.state.mailingAddress1);
      data.append("mailingAddress2", this.state.mailingAddress2);
      data.append("city", this.state.city);
      data.append("state", this.state.state);
      data.append("zip", this.state.zip);
      data.append("physicalStreetAddress1", this.state.physicalStreetAddress1);
      data.append("physicalStreetAddress2", this.state.physicalStreetAddress2);
      data.append("physicalCity", this.state.physicalCity);
      data.append("physicalState", this.state.physicalState);
      data.append("physicalZip", this.state.physicalZip);
      data.append("email", this.state.email);
      data.append("main_phone", this.state.main_phone);
      data.append("secondary_phone", this.state.secondary_phone);
      data.append(
        "anticipatedLocationList",
        this.state.anticipatedLocationList,
      );
      data.append(
        "reloacationDate",
        moment(this.state.reloacationDate).format("MM/DD/YY"),
      );
      data.append("applying_position", this.state.applying_position);
      data.append("reporting_location", this.state.reporting_location);
      data.append(
        "chkCurrentQualification",
        this.state.chkCurrentQualification,
      );
      data.append("certification_status", this.state.certification_status);
      data.append("qualifications", this.state.qualifications);
      data.append("Question1", this.state.Question1);
      data.append("Question2", this.state.Question2);
      data.append("Question3", this.state.Question3);
      data.append("FullName", this.state.FullName);
      data.append("txtDate", moment(this.state.txtDate).format("MM/DD/YY"));
      data.append("high_school", this.state.high_school);
      data.append("school_duration", this.state.school_duration);
      data.append("school_taken", this.state.school_taken);
      data.append("is_school_graduate", this.state.is_school_graduate);
      data.append("university", this.state.university);
      data.append("degree_duration", this.state.degree_duration);
      data.append("degree_taken", this.state.degree_taken);
      data.append("is_degree_graduate", this.state.is_degree_graduate);
      data.append("university1", this.state.university1);
      data.append("degree_duration1", this.state.degree_duration1);
      data.append("degree_taken1", this.state.degree_taken1);
      data.append("is_degree_graduate1", this.state.is_degree_graduate1);
      data.append("addition_info", this.state.addition_info);
      data.append("firestorm_experience", this.state.firestorm_experience);
      data.append("seperation_reason", this.state.seperation_reason);
      data.append("last_employer1", this.state.last_employer1);
      data.append("last_employer2", this.state.last_employer2);
      data.append("last_employer3", this.state.last_employer3);
      data.append(
        "reference1StartDate",
        moment(this.state.reference1StartDate).format("MM/DD/YY"),
      );
      data.append("reference1Position", this.state.reference1Position);
      data.append(
        "reference1EndDate",
        moment(this.state.reference1EndDate).format("MM/DD/YY"),
      );
      data.append("reference1Address", this.state.reference1Address);
      data.append("reference1City", this.state.reference1City);
      data.append("reference1State", this.state.reference1State);
      data.append("reference1Supervisor", this.state.reference1Supervisor);
      data.append("reference1AltContact", this.state.reference1AltContact);
      data.append("reference1Telephone", this.state.reference1Telephone);
      data.append("reference1Fax", this.state.reference1Fax);
      data.append(
        "reference1Responsibilities",
        this.state.reference1Responsibilities,
      );
      data.append(
        "reference1ResonForLeaving",
        this.state.reference1ResonForLeaving,
      );
      data.append(
        "reference2StartDate",
        moment(this.state.reference2StartDate).format("MM/DD/YY"),
      );
      data.append("reference2Position", this.state.reference2Position);
      data.append(
        "reference2EndDate",
        moment(this.state.reference2EndDate).format("MM/DD/YY"),
      );
      data.append("reference2Address", this.state.reference2Address);
      data.append("reference2City", this.state.reference2City);
      data.append("reference2State", this.state.reference2State);
      data.append("reference2Supervisor", this.state.reference2Supervisor);
      data.append("reference2AltContact", this.state.reference2AltContact);
      data.append("reference2Telephone", this.state.reference2Telephone);
      data.append("reference2Fax", this.state.reference2Fax);
      data.append(
        "reference2Responsibilities",
        this.state.reference2Responsibilities,
      );
      data.append(
        "reference2ResonForLeaving",
        this.state.reference2ResonForLeaving,
      );
      data.append(
        "reference3StartDate",
        moment(this.state.reference3StartDate).format("MM/DD/YY"),
      );
      data.append("reference3Position", this.state.reference3Position);
      data.append(
        "reference3EndDate",
        moment(this.state.reference3EndDate).format("MM/DD/YY"),
      );
      data.append("reference3Address", this.state.reference3Address);
      data.append("reference3City", this.state.reference3City);
      data.append("reference3State", this.state.reference3State);
      data.append("reference3Supervisor", this.state.reference3Supervisor);
      data.append("reference3AltContact", this.state.reference3AltContact);
      data.append("reference3Telephone", this.state.reference3Telephone);
      data.append("reference3Fax", this.state.reference3Fax);
      data.append(
        "reference3Responsibilities",
        this.state.reference3Responsibilities,
      );
      data.append(
        "reference3ResonForLeaving",
        this.state.reference3ResonForLeaving,
      );
      data.append("reference1name", this.state.reference1name);
      data.append("reference1Relationship", this.state.reference1Relationship);
      data.append("reference1Phone", this.state.reference1Phone);
      data.append("reference1Known", this.state.reference1Known);
      data.append("reference2name", this.state.reference2name);
      data.append("reference2Relationship", this.state.reference2Relationship);
      data.append("reference2Phone", this.state.reference2Phone);
      data.append("reference2Known", this.state.reference2Known);
      data.append("reference3name", this.state.reference3name);
      data.append("reference3Relationship", this.state.reference3Relationship);
      data.append("reference3Phone", this.state.reference3Phone);
      data.append("reference3Known", this.state.reference3Known);
      data.append("licenseState", this.state.licenseState);
      data.append("licenseNumber", this.state.licenseNumber);
      data.append(
        "licenseExpDate",
        (function (date) {
          if (!date) return "";
          var _date = typeof date === "string" ? new Date(date) : date;
          if (!moment(_date).isValid()) return "";
          return _date.format("MM/DD/YY");
        })(this.state.licenseExpDate),
      );
      data.append("licenseClass", this.state.licenseClass);
      data.append("licenseEndorsement", this.state.endorsement);
      data.append("DeniedLicense", this.state.DeniedLicense);
      data.append("LicenseRevoked", this.state.LicenseRevoked);
      data.append("ReasonLicenseRevoked", this.state.ReasonLicenseRevoked);
      data.append("gender", this.state.gender);
      data.append("Race", this.state.Race);
      data.append("Veteran", this.state.Veteran);
      data.append("agreement", this.state.agreement);
      data.append("chkPositionOn", this.state.chkPositionOn);
      data.append("no_past_experience", this.state.no_past_experience);
      data.append("no_past_reference", this.state.no_past_reference);

      this.state.file_list.forEach((file, index) => {
        data.append("qualification_file[]", file);
      });

      this.props.dispatch(applicantsActions.editApplication(data));
    } else {
      toast.error("Please fill the required fields!");
    }
  }
  render() {
    var qualification = [],
      applying_position_list = [],
      position_on_list = [];
    if (this.state.Qualification) {
      if (this.state.applicants) {
        this.state.Qualification.forEach((item, index) => {
          var checked;

          if (this.state.applicants.qualification_id) {
            var qual = this.state.applicants.qualification_id.split(",");
            if (qual.indexOf(item.id.toString()) !== -1) {
              checked = "checked";
            } else {
              checked = "";
            }
          }
          qualification.push(
            <div
              className="current_qualification_item"
              key={"chkCurrentQualification" + item.id}
            >
              <input
                type="checkbox"
                id={"chkCurrentQualification" + item.id}
                name="chkCurrentQualification"
                defaultValue={item.id}
                onChange={this.handleCheckbox.bind(this)}
                defaultChecked={checked}
              />
              <label htmlFor={"chkCurrentQualification" + item.id}>
                <span />
              </label>
              <span className="wpcf7-list-item-label">{item.value}</span>
            </div>,
          );
        });
      }
    }
    if (this.state.ApplyingPosition) {
      if (this.state.applicants) {
        this.state.ApplyingPosition.forEach((item, index) => {
          var pos_checked;
          if (this.state.applicants.applying_position_id) {
            var pos = this.state.applicants.applying_position_id.split(",");
            if (pos.indexOf(item.id.toString()) !== -1) {
              pos_checked = "checked";
            } else {
              pos_checked = "";
            }
          }
          applying_position_list.push(
            <div className="checkbox_div" key={"applying_position" + item.id}>
              <input
                type="checkbox"
                id={"applying_position" + item.id}
                defaultChecked={pos_checked}
                name="applying_position"
                defaultValue={item.id}
                onChange={this.handleCheckbox.bind(this)}
              />
              <label
                className="chk_label"
                htmlFor={"applying_position" + item.id}
              >
                <span />
              </label>
              <span className="wpcf7-list-item-label">{item.value}</span>
            </div>,
          );
        });
      }
    }

    if (this.state.PositionOn) {
      if (this.state.applicants) {
        this.state.PositionOn.forEach((item) => {
          position_on_list.push(
            <div className="checkbox_div" key={"chkPositionOn" + item.id}>
              <input
                className="application-radio"
                style={{ width: "35px", cursor: "pointer" }}
                type="radio"
                id={"chkPositionOn" + item.id}
                key={"chkPositionOn" + item.id}
                defaultChecked={item.id == this.state.applicants.position_on_id}
                name="chkPositionOn"
                defaultValue={item.id}
                onChange={this.handleCheckbox.bind(this)}
              />
              <label className="chk_label" htmlFor={"chkPositionOn" + item.id}>
                <span />
              </label>
              <span className="wpcf7-list-item-label">{item.value}</span>
            </div>,
          );
        });
      }
    }

    return (
      <div className="application_container edit_app_container">
        <div className="breadcrumbs">
          <span onClick={this.goBack.bind(this)} className="green">
            Applicants
          </span>{" "}
          / Employment Application
        </div>
        <h1 className="page_title">Employment Application</h1>
        <div className="applicants_inner_head inner_head" />
        <div className="inner_container">
          <div className="formField">
            <p>
              Please fill out all of the necessary fields in this form.
              Incomplete applications will not be accepted. For job application
              inquiries, Please ONLY contact us Tuesdays and Thursdays between
              10:00am & 2:00pm.
            </p>
          </div>
          <div className="formField">
            <label>How did you hear about applying to Firestorm?*</label>
            <select
              name="sourceOfKnowledge"
              id="sourceOfKnowledge"
              value={this.state.sourceOfKnowledge}
              errors={this.state.sourceError}
              onChange={this.handleChange}
            >
              <option value="">---</option>
              {this.state.SourceOfKnowledgeList.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.value}
                </option>
              ))}
            </select>

            <input
              id="employee_name"
              type="text"
              name="employee_name"
              value={this.state.employee_name ? this.state.employee_name : ""}
              size="40"
              className={"employee_name " + this.state.empClass}
              errors={this.state.empNameError}
              placeholder="Name of Firestorm Employee*"
              onChange={this.handleChange}
            />
          </div>

          <hr />
          <div className="formField">
            <label>Full Legal Name*</label>
            <div className="legal_name app_input">
              <input
                type="text"
                name="fname"
                id="fname"
                value={
                  this.state.fname && this.state.fname !== "null"
                    ? this.state.fname
                    : ""
                }
                placeholder="First Name*"
                onChange={this.handleChange}
                errors={this.state.fnameError}
              />
              <input
                type="text"
                name="lname"
                id="lname"
                value={
                  this.state.lname && this.state.lname !== "null"
                    ? this.state.lname
                    : ""
                }
                placeholder="Last Name*"
                onChange={this.handleChange}
                errors={this.state.lnameError}
              />
              <input
                type="text"
                name="sname"
                id="sname"
                className="small_input"
                value={
                  this.state.sname && this.state.sname !== "null"
                    ? this.state.sname
                    : ""
                }
                placeholder="MI"
                errors={this.state.snameError}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="formField">
            <label>Physical Address*</label>
            <div className="mailing_address2 app_input">
              <input
                type="text"
                name="physicalStreetAddress1"
                id="physicalStreetAddress1"
                errors={this.state.physicalStreetAddress1Error}
                value={
                  this.state.physicalStreetAddress1 &&
                  this.state.physicalStreetAddress1 !== "null"
                    ? this.state.physicalStreetAddress1
                    : ""
                }
                placeholder="Physical Address*"
                onChange={this.handleChange}
              />
              <input
                type="text"
                name="physicalStreetAddress2"
                id="physicalStreetAddress2"
                errors={this.state.physicalStreetAddress2Error}
                value={
                  this.state.physicalStreetAddress2 &&
                  this.state.physicalStreetAddress2 !== "null"
                    ? this.state.physicalStreetAddress2
                    : ""
                }
                placeholder="Physical Address Line 2"
                onChange={this.handleChange}
              />
            </div>
            <div className="mailing_address2 app_input">
              <input
                type="text"
                name="physicalCity"
                id="physicalCity"
                value={
                  this.state.physicalCity && this.state.physicalCity !== "null"
                    ? this.state.physicalCity
                    : ""
                }
                placeholder="City*"
                errors={this.state.physicalCityError}
                onChange={this.handleChange}
              />
              <input
                type="text"
                name="physicalState"
                id="physicalState"
                value={
                  this.state.physicalState &&
                  this.state.physicalState !== "null"
                    ? this.state.physicalState
                    : ""
                }
                placeholder="State*"
                errors={this.state.physicalStateError}
                onChange={this.handleChange}
              />
              <input
                type="number"
                ref={(input) => {
                  this.physicalZipInput = input;
                }}
                name="physicalZip"
                id="physicalZip"
                value={
                  this.state.physicalZip && this.state.physicalZip !== "null"
                    ? this.state.physicalZip
                    : ""
                }
                className="small_input"
                errors={this.state.physicalZipError}
                placeholder="Zip*"
                onChange={this.handleChange}
                onKeyDown={preventNegativeValueInDocs}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>

          <div className="formField">
            <label>Current Mailing Address*</label>
            <input
              id="chkSameAddress"
              type="checkbox"
              name="chkSameAddress"
              defaultValue={this.state.chkSameAddress}
              onChange={this.handleAddress}
              style={{
                cursor: "pointer",
                marginRight: "0px",
                display: "inline-block",
                width: "20px",
                marginBottom: "20px",
                marginTop: "10px",
              }}
            />
            <span
              className="wpcf7-list-item-label"
              onClick={this.toggleAddressCheckbox} // Added onClick event to the label
              style={{ cursor: "pointer" }}
            >
              <b>&nbsp;&nbsp;Same as Physical Address</b>
            </span>
            <br />
            <div className="mailing_address app_input">
              <input
                type="text"
                name="mailingAddress1"
                id="mailingAddress1"
                errors={this.state.address1Error}
                value={
                  this.state.mailingAddress1 &&
                  this.state.mailingAddress1 !== "null"
                    ? this.state.mailingAddress1
                    : ""
                }
                placeholder="Mailing Address*"
                onChange={this.handleChange}
                readOnly={this.state.maddress1}
                style={{
                  pointerEvents: this.state.maddress1 ? "none" : "auto",
                }}
              />
              <input
                type="text"
                name="mailingAddress2"
                id="mailingAddress2"
                value={
                  this.state.mailingAddress2 &&
                  this.state.mailingAddress2 !== "null"
                    ? this.state.mailingAddress2
                    : ""
                }
                placeholder="Address Line 2"
                onChange={this.handleChange}
                readOnly={this.state.maddress2}
                style={{
                  pointerEvents: this.state.maddress2 ? "none" : "auto",
                }}
              />
            </div>
            <div className="mailing_address2 app_input">
              <input
                type="text"
                name="city"
                id="city"
                value={
                  this.state.city && this.state.city !== "null"
                    ? this.state.city
                    : ""
                }
                placeholder="City*"
                errors={this.state.cityError}
                onChange={this.handleChange}
                readOnly={this.state.mcity}
                style={{ pointerEvents: this.state.mcity ? "none" : "auto" }}
              />
              <input
                type="text"
                name="state"
                id="state"
                value={
                  this.state.state && this.state.state !== "null"
                    ? this.state.state
                    : ""
                }
                placeholder="State*"
                errors={this.state.stateError}
                onChange={this.handleChange}
                readOnly={this.state.mstate}
                style={{ pointerEvents: this.state.mstate ? "none" : "auto" }}
              />
              <input
                type="number"
                ref={(input) => {
                  this.zipInput = input;
                }}
                name="zip"
                id="zip"
                value={
                  this.state.zip && this.state.zip !== "null"
                    ? this.state.zip
                    : ""
                }
                className="small_input"
                errors={this.state.zipError}
                placeholder="Zip*"
                onChange={this.handleChange}
                readOnly={this.state.mzip}
                style={{ pointerEvents: this.state.mzip ? "none" : "auto" }}
                onKeyDown={preventNegativeValueInDocs}
                onWheel={(e) => e.target.blur()}
              />
            </div>
          </div>

          <div className="formField">
            <label>Contact Information*</label>
            <div className="contact_info app_input">
              <input
                type="text"
                name="email"
                id="email"
                value={
                  this.state.email && this.state.email !== "null"
                    ? this.state.email
                    : ""
                }
                placeholder="Email Address*"
                onChange={this.handleChange}
                errors={this.state.emailError}
              />
              <NumberFormat
                format="###-###-####"
                mask="_"
                onChange={this.handleChange}
                name="main_phone"
                id="main_phone"
                value={
                  this.state.main_phone && this.state.main_phone !== "null"
                    ? this.state.main_phone
                    : ""
                }
                placeholder="Cell Phone (Main Contact)*"
                errors={this.state.phoneError}
              />
              {/*<input type="tel" name="secondary_phone" value={this.state.secondary_phone && this.state.secondary_phone !== "null" ? this.state.secondary_phone : ""} placeholder="Secondary Phone Number" onChange={this.handleChange} maxLength="10" />*/}
              <NumberFormat
                format="###-###-####"
                mask="_"
                onChange={this.handleChange}
                name="secondary_phone"
                value={
                  this.state.secondary_phone &&
                  this.state.secondary_phone !== "null"
                    ? this.state.secondary_phone
                    : ""
                }
                placeholder="Secondary Phone"
              />
            </div>
          </div>

          <div className="formField">
            <label>Select Anticipated Living Location for Fire Season*</label>
            <select
              name="anticipatedLocationList"
              id="anticipatedLocationList"
              value={
                this.state.anticipatedLocationList &&
                this.state.anticipatedLocationList !== "null"
                  ? this.state.anticipatedLocationList
                  : ""
              }
              onChange={this.handleChange}
              errors={this.state.AntiLocError}
            >
              <option value="">---</option>
              {this.state.AnticipatedLocation.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.value}
                </option>
              ))}
            </select>

            <DatePicker
              className={"anticipated_date " + this.state.error}
              name="reloacationDate"
              id="reloacationDate"
              placeholderText="Anticipated relocation date"
              selected={this.state.reloacationDate}
              // value={this.state.reloacationDate}
              onChange={this.handleDateChange.bind(this, "reloacationDate")}
              errors={this.state.relocDateError}
              // peekNextMonth
              dateFormat="MM/DD/YYYY"
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          </div>

          <div className="formField">
            <label>Position Applying For</label>
            <p className="sub_label">
              Applying Position (Check all that apply)
            </p>

            {applying_position_list}

            <p className="sub_label">Desired Reporting Location (select one)</p>
            <select
              name="reporting_location"
              id="reporting_location"
              value={this.state.reporting_location}
              onChange={this.handleChange}
            >
              <option value="">---</option>
              {this.state.ReportingLocation.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.value}
                </option>
              ))}
            </select>

            <p className="sub_label">
              I would like to be considered for a position on
            </p>

            {position_on_list}
          </div>

          <div className="formField">
            <label>
              Current Fire Qualifications: as specified in PMS 310-1 NWCG
              guidelines
            </label>
            <p className="sub_label">Check all that apply</p>

            {qualification}

            <p className="sub_label">Current Certification Status*</p>
            <select
              name="certification_status"
              id="certification_status"
              value={this.state.certification_status}
              onChange={this.handleChange}
              errors={this.state.certStatusError}
            >
              <option value="">---</option>
              {this.state.CertificationStatus.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.value}
                </option>
              ))}
            </select>
          </div>

          <div className="formField">
            <label>Education</label>
            <div className="education_div">
              <p className="sub_label">High School:</p>
              <input
                type="text"
                name="high_school"
                placeholder="High School Name & Location"
                value={
                  this.state.high_school && this.state.high_school !== "null"
                    ? this.state.high_school
                    : ""
                }
                onChange={this.handleChange}
              />
              <input
                type="text"
                name="school_duration"
                placeholder="No. Years/Units Completed"
                value={
                  this.state.school_duration &&
                  this.state.school_duration !== "null"
                    ? this.state.school_duration
                    : ""
                }
                onChange={this.handleChange}
              />
              <input
                type="text"
                name="school_taken"
                placeholder="Degree/Courses Taken"
                value={
                  this.state.school_taken && this.state.school_taken !== "null"
                    ? this.state.school_taken
                    : ""
                }
                onChange={this.handleChange}
              />
              <input
                type="text"
                name="is_school_graduate"
                placeholder="Did you graduate? (yes/no)"
                value={
                  this.state.is_school_graduate &&
                  this.state.is_school_graduate !== "null"
                    ? this.state.is_school_graduate
                    : ""
                }
                onChange={this.handleChange}
              />
            </div>

            <div className="education_div">
              <p className="sub_label">University / Tech School:</p>
              <input
                type="text"
                name="university"
                value={
                  this.state.university && this.state.university !== "null"
                    ? this.state.university
                    : ""
                }
                placeholder="University/Tech Name & Location"
                onChange={this.handleChange}
              />
              <input
                type="text"
                name="degree_duration"
                value={
                  this.state.degree_duration &&
                  this.state.degree_duration !== "null"
                    ? this.state.degree_duration
                    : ""
                }
                placeholder="No. Years/Units Completed"
                onChange={this.handleChange}
              />
              <input
                type="text"
                name="degree_taken"
                value={
                  this.state.degree_taken && this.state.degree_taken !== "null"
                    ? this.state.degree_taken
                    : ""
                }
                placeholder="Degree/Courses Taken"
                onChange={this.handleChange}
              />
              <input
                type="text"
                name="is_degree_graduate"
                value={
                  this.state.is_degree_graduate &&
                  this.state.is_degree_graduate !== "null"
                    ? this.state.is_degree_graduate
                    : ""
                }
                placeholder="Did you graduate? (yes/no)"
                onChange={this.handleChange}
              />
            </div>

            <div className="education_div">
              <p className="sub_label">University / Tech School:</p>
              <input
                type="text"
                name="university1"
                value={
                  this.state.university1 && this.state.university1 !== "null"
                    ? this.state.university1
                    : ""
                }
                placeholder="University/Tech Name & Location"
                onChange={this.handleChange}
              />
              <input
                type="text"
                name="degree_duration1"
                value={
                  this.state.degree_duration1 &&
                  this.state.degree_duration1 !== "null"
                    ? this.state.degree_duration1
                    : ""
                }
                placeholder="No. Years/Units Completed"
                onChange={this.handleChange}
              />
              <input
                type="text"
                name="degree_taken1"
                value={
                  this.state.degree_taken1 &&
                  this.state.degree_taken1 !== "null"
                    ? this.state.degree_taken1
                    : ""
                }
                placeholder="Degree/Courses Taken"
                onChange={this.handleChange}
              />
              <input
                type="text"
                name="is_degree_graduate1"
                value={
                  this.state.is_degree_graduate1 &&
                  this.state.is_degree_graduate1 !== "null"
                    ? this.state.is_degree_graduate1
                    : ""
                }
                placeholder="Did you graduate? (yes/no)"
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="formField">
            <label>Additional Information</label>
            <p>
              Present below any other information which relates to your ability
              to perform the job for which you are applying such as licenses,
              memberships, language skills, academic and athletic achievements,
              community involvement, and volunteer experiences. Leave out
              information indicating religion, gender or any other protected
              category.
            </p>
            <textarea
              name="addition_info"
              id="addition_info"
              value={
                this.state.addition_info && this.state.addition_info !== "null"
                  ? this.state.addition_info
                  : ""
              }
              placeholder="Please enter additional information here..."
              onChange={this.handleChange}
            />
          </div>

          {/* Experiences */}
          {this.state.applicants && (
            <div className="formField">
              <label>Professional Referencess</label>
              {/* No Past Experiences */}

              {/* <div className="checkbox_div m-b-2">
                <input
                  value="yes"
                  type="checkbox"
                  id="no_past_experience"
                  onChange={this.handleCheckbox2.bind(this)}
                  name="no_past_experience"
                  defaultChecked={!this.checkNull(this.state.applicants.no_past_experience)}
                />
                <label className="chk_label" htmlFor="no_past_experience">
                  <span></span>
                </label>
                <span className="wpcf7-list-item-label">No Past Experience</span>
              </div> */}

              <p className="sub_label">
                Have you worked for Firestorm in the past?*
              </p>
              <select
                required={this.state.applicants.no_past_experience === null}
                disabled={this.state.applicants.no_past_experience === "yes"}
                name="firestorm_experience"
                className="small_dropdown"
                id="firestorm_experience"
                value={this.state.firestorm_experience}
                onChange={this.handleChange}
                errors={this.state.fExperienceError}
              >
                <option value="">---</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>

              <p className="sub_label">
                If Yes, what was the reason for separation? Who was your
                supervisor?
              </p>
              <input
                type="text"
                required={this.state.applicants.no_past_experience === null}
                disabled={this.state.applicants.no_past_experience === "yes"}
                name="seperation_reason"
                id="seperation_reason"
                placeholder="If Yes, please explain..."
                value={
                  this.state.seperation_reason &&
                  this.state.seperation_reason !== "null"
                    ? this.state.seperation_reason
                    : ""
                }
                onChange={this.handleChange}
                errors={this.state.separationError}
              />

              <p className="sub_label">
                Past Experiences / Employment Record / References - #1
              </p>

              <div className="referance_div">
                <input
                  required={this.state.applicants.no_past_experience === null}
                  disabled={this.state.applicants.no_past_experience === "yes"}
                  type="text"
                  name="last_employer1"
                  errors={this.state.last_employer1Error}
                  placeholder="Last Employer"
                  value={
                    this.state.last_employer1 &&
                    this.state.last_employer1 !== "null"
                      ? this.state.last_employer1
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  disabled={this.state.applicants.no_past_experience === "yes"}
                  type="text"
                  name="reference1Position"
                  errors={this.state.reference1PositionError}
                  placeholder="Position Held"
                  value={
                    this.state.reference1Position &&
                    this.state.reference1Position !== "null"
                      ? this.state.reference1Position
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <DatePicker
                  required={this.state.applicants.no_past_experience === null}
                  disabled={this.state.applicants.no_past_experience === "yes"}
                  selected={this.state.reference1StartDate}
                  name="reference1StartDate"
                  // value={this.state.reference1StartDate}
                  placeholderText="Start Date (mm/dd/yyyy)"
                  onChange={this.handleDateChange.bind(
                    this,
                    "reference1StartDate",
                  )}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="MM/DD/YYYY"
                />

                <DatePicker
                  required={this.state.applicants.no_past_experience === null}
                  disabled={this.state.applicants.no_past_experience === "yes"}
                  selected={this.state.reference1EndDate}
                  name="reference1EndDate"
                  // value={this.state.reference1EndDate}
                  placeholderText="End Date (mm/dd/yyyy)"
                  onChange={this.handleDateChange.bind(
                    this,
                    "reference1EndDate",
                  )}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="MM/DD/YYYY"
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  disabled={this.state.applicants.no_past_experience === "yes"}
                  type="text"
                  name="reference1Address"
                  placeholder="Address"
                  value={
                    this.state.reference1Address &&
                    this.state.reference1Address !== "null"
                      ? this.state.reference1Address
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  disabled={this.state.applicants.no_past_experience === "yes"}
                  type="text"
                  name="reference1City"
                  placeholder="City"
                  value={
                    this.state.reference1City &&
                    this.state.reference1City !== "null"
                      ? this.state.reference1City
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  disabled={this.state.applicants.no_past_experience === "yes"}
                  type="text"
                  name="reference1State"
                  placeholder="State"
                  value={
                    this.state.reference1State &&
                    this.state.reference1State !== "null"
                      ? this.state.reference1State
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  disabled={this.state.applicants.no_past_experience === "yes"}
                  type="text"
                  name="reference1Supervisor"
                  errors={this.state.reference1SupervisorError}
                  placeholder="Supervisors Name"
                  value={
                    this.state.reference1Supervisor &&
                    this.state.reference1Supervisor !== "null"
                      ? this.state.reference1Supervisor
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  disabled={this.state.applicants.no_past_experience === "yes"}
                  type="text"
                  name="reference1AltContact"
                  placeholder="Alternate Contact Name"
                  value={
                    this.state.reference1AltContact &&
                    this.state.reference1AltContact !== "null"
                      ? this.state.reference1AltContact
                      : ""
                  }
                  onChange={this.handleChange}
                />
                {/*<input type="text" name="reference1Telephone" placeholder="Cell Phone" value={this.state.reference1Telephone && this.state.reference1Telephone !== "null" ? this.state.reference1Telephone : ""} onChange={this.handleChange}  />*/}
                <NumberFormat
                  required={this.state.applicants.no_past_experience === null}
                  disabled={this.state.applicants.no_past_experience === "yes"}
                  format="###-###-####"
                  mask="_"
                  onChange={this.handleChange}
                  name="reference1Telephone"
                  errors={this.state.reference1TelephoneError}
                  placeholder="Cell Phone"
                  value={
                    this.state.reference1Telephone &&
                    this.state.reference1Telephone !== "null"
                      ? this.state.reference1Telephone
                      : ""
                  }
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  disabled={this.state.applicants.no_past_experience === "yes"}
                  type="text"
                  name="reference1Fax"
                  placeholder="Fax"
                  value={
                    this.state.reference1Fax &&
                    this.state.reference1Fax !== "null"
                      ? this.state.reference1Fax
                      : ""
                  }
                  onChange={this.handleChange}
                />

                <p>Responsibilities</p>
                <textarea
                  required={this.state.applicants.no_past_experience === null}
                  disabled={this.state.applicants.no_past_experience === "yes"}
                  name="reference1Responsibilities"
                  placeholder="Please fill out your responsibilities for this position..."
                  value={
                    this.state.reference1Responsibilities &&
                    this.state.reference1Responsibilities !== "null"
                      ? this.state.reference1Responsibilities
                      : ""
                  }
                  onChange={this.handleChange}
                />

                <p>Reason For Leaving</p>
                <textarea
                  required={this.state.applicants.no_past_experience === null}
                  disabled={this.state.applicants.no_past_experience === "yes"}
                  name="reference1ResonForLeaving"
                  placeholder="Please describe your reason for leaving..."
                  value={
                    this.state.reference1ResonForLeaving &&
                    this.state.reference1ResonForLeaving !== "null"
                      ? this.state.reference1ResonForLeaving
                      : ""
                  }
                  onChange={this.handleChange}
                />
              </div>

              <div className="referance_div">
                <p className="sub_label">
                  Past Experiences / Employment Record / References - #2
                </p>
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="last_employer2"
                  placeholder="Last Employer"
                  errors={this.state.last_employer2Error}
                  value={
                    this.state.last_employer2 &&
                    this.state.last_employer2 !== "null"
                      ? this.state.last_employer2
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="reference2Position"
                  placeholder="Position Held"
                  errors={this.state.reference2PositionError}
                  value={
                    this.state.reference2Position &&
                    this.state.reference2Position !== "null"
                      ? this.state.reference2Position
                      : ""
                  }
                  onChange={this.handleChange}
                />

                <DatePicker
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  selected={this.state.reference2StartDate}
                  name="reference2StartDate"
                  // value={this.state.reference2StartDate}
                  placeholderText="Start Date (mm/dd/yyyy)"
                  errors={this.state.reference2StartDateError}
                  onChange={this.handleDateChange.bind(
                    this,
                    "reference2StartDate",
                  )}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="MM/DD/YYYY"
                />

                <DatePicker
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  selected={this.state.reference2EndDate}
                  errors={this.state.reference2EndDateError}
                  name="reference2EndDate"
                  // value={this.state.reference2EndDate}
                  placeholderText="End Date (mm/dd/yyyy)"
                  onChange={this.handleDateChange.bind(
                    this,
                    "reference2EndDate",
                  )}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="MM/DD/YYYY"
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="reference2Address"
                  placeholder="Address"
                  errors={this.state.reference2AddressError}
                  value={
                    this.state.reference2Address &&
                    this.state.reference2Address !== "null"
                      ? this.state.reference2Address
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="reference2City"
                  placeholder="City"
                  errors={this.state.reference2CityError}
                  value={
                    this.state.reference2City &&
                    this.state.reference2City !== "null"
                      ? this.state.reference2City
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="reference2State"
                  errors={this.state.reference2StateError}
                  placeholder="State"
                  value={
                    this.state.reference2State &&
                    this.state.reference2State !== "null"
                      ? this.state.reference2State
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="reference2Supervisor"
                  errors={this.state.reference2SupervisorError}
                  placeholder="Supervisors Name"
                  value={
                    this.state.reference2Supervisor &&
                    this.state.reference2Supervisor !== "null"
                      ? this.state.reference2Supervisor
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="reference2AltContact"
                  placeholder="Alternate Contact Name"
                  errors={this.state.reference2AltContactError}
                  value={
                    this.state.reference2AltContact &&
                    this.state.reference2AltContact !== "null"
                      ? this.state.reference2AltContact
                      : ""
                  }
                  onChange={this.handleChange}
                />
                {/*<input type="text" name="reference2Telephone" placeholder="Cell Phone" value={this.state.reference2Telephone && this.state.reference2Telephone !== "null" ? this.state.reference2Telephone : ""}  onChange={this.handleChange}/>*/}
                <NumberFormat
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  format="###-###-####"
                  mask="_"
                  onChange={this.handleChange}
                  name="reference2Telephone"
                  placeholder="Cell Phone"
                  errors={this.state.reference2TelephoneError}
                  value={
                    this.state.reference2Telephone &&
                    this.state.reference2Telephone !== "null"
                      ? this.state.reference2Telephone
                      : ""
                  }
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  errors={this.state.reference2FaxError}
                  name="reference2Fax"
                  placeholder="Fax"
                  value={
                    this.state.reference2Fax &&
                    this.state.reference2Fax !== "null"
                      ? this.state.reference2Fax
                      : ""
                  }
                  onChange={this.handleChange}
                />

                <p>Responsibilities</p>
                <textarea
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  name="reference2Responsibilities"
                  placeholder="Please fill out your responsibilities for this position..."
                  value={
                    this.state.reference2Responsibilities &&
                    this.state.reference2Responsibilities !== "null"
                      ? this.state.reference2Responsibilities
                      : ""
                  }
                  onChange={this.handleChange}
                />

                <p>Reason For Leaving</p>
                <textarea
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  name="reference2ResonForLeaving"
                  placeholder="Please describe your reason for leaving..."
                  value={
                    this.state.reference2ResonForLeaving &&
                    this.state.reference2ResonForLeaving !== "null"
                      ? this.state.reference2ResonForLeaving
                      : ""
                  }
                  onChange={this.handleChange}
                />
              </div>

              <div className="referance_div">
                <p className="sub_label">
                  Past Experiences / Employment Record / References - #3 (if
                  applicable)
                </p>
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="last_employer3"
                  placeholder="Last Employer"
                  value={
                    this.state.last_employer3 &&
                    this.state.last_employer3 !== "null"
                      ? this.state.last_employer3
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="reference3Position"
                  placeholder="Position Held"
                  value={
                    this.state.reference3Position &&
                    this.state.reference3Position !== "null"
                      ? this.state.reference3Position
                      : ""
                  }
                  onChange={this.handleChange}
                />

                <DatePicker
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  selected={this.state.reference3StartDate}
                  // value={this.state.reference3StartDate}
                  name="reference3StartDate"
                  placeholderText="Start Date (mm/dd/yyyy)"
                  onChange={this.handleDateChange.bind(
                    this,
                    "reference3StartDate",
                  )}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  dateFormat="MM/DD/YYYY"
                />
                <DatePicker
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  selected={this.state.reference3EndDate}
                  // value={this.state.reference3EndDate}
                  name="reference3EndDate"
                  placeholderText="End Date (mm/dd/yyyy)"
                  onChange={this.handleDateChange.bind(
                    this,
                    "reference3EndDate",
                  )}
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="MM/DD/YYYY"
                  dropdownMode="select"
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="reference3Address"
                  placeholder="Address"
                  value={
                    this.state.reference3Address &&
                    this.state.reference3Address !== "null"
                      ? this.state.reference3Address
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="reference3City"
                  placeholder="City"
                  value={
                    this.state.reference3City &&
                    this.state.reference3City !== "null"
                      ? this.state.reference3City
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="reference3State"
                  placeholder="State"
                  value={
                    this.state.reference3State &&
                    this.state.reference3State !== "null"
                      ? this.state.reference3State
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="reference3Supervisor"
                  placeholder="Supervisors Name"
                  value={
                    this.state.reference3Supervisor &&
                    this.state.reference3Supervisor !== "null"
                      ? this.state.reference3Supervisor
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="reference3AltContact"
                  placeholder="Alternate Contact Name"
                  value={
                    this.state.reference3AltContact &&
                    this.state.reference3AltContact !== "null"
                      ? this.state.reference3AltContact
                      : ""
                  }
                  onChange={this.handleChange}
                />
                {/*<input type="text" name="reference3Telephone" placeholder="Telephone Number" value={this.state.reference3Telephone && this.state.reference3Telephone !=="null" ? this.state.reference3Telephone : ""} onChange={this.handleChange} />*/}
                <NumberFormat
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  format="###-###-####"
                  mask="_"
                  onChange={this.handleChange}
                  name="reference3Telephone"
                  placeholder="Cell Phone"
                  value={
                    this.state.reference3Telephone &&
                    this.state.reference3Telephone !== "null"
                      ? this.state.reference3Telephone
                      : ""
                  }
                />
                <input
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  type="text"
                  name="reference3Fax"
                  placeholder="Fax"
                  value={
                    this.state.reference3Fax &&
                    this.state.reference3Fax !== "null"
                      ? this.state.reference3Fax
                      : ""
                  }
                  onChange={this.handleChange}
                />

                <p>Responsibilities</p>
                <textarea
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  name="reference3Responsibilities"
                  placeholder="Please fill out your responsibilities for this position..."
                  value={
                    this.state.reference3Responsibilities &&
                    this.state.reference3Responsibilities !== "null"
                      ? this.state.reference3Responsibilities
                      : ""
                  }
                  onChange={this.handleChange}
                />

                <p>Reason For Leaving</p>
                <textarea
                  required={this.state.applicants.no_past_experience === null}
                  // disabled={this.state.applicants.no_past_experience === 'yes'}
                  name="reference3ResonForLeaving"
                  placeholder="Please describe your reason for leaving..."
                  value={
                    this.state.reference3ResonForLeaving &&
                    this.state.reference3ResonForLeaving !== "null"
                      ? this.state.reference3ResonForLeaving
                      : ""
                  }
                  onChange={this.handleChange}
                />
              </div>
            </div>
          )}

          {/* References */}
          {this.state.applicants && (
            <div className="formField">
              <label>Professional References</label>
              {/* No Past References */}

              {/* <div className="checkbox_div m-b-2">
                <input
                  value="yes"
                  type="checkbox"
                  id="no_past_reference"
                  onChange={this.handleCheckbox2.bind(this)}
                  name="no_past_reference"
                  defaultChecked={!this.checkNull(this.state.applicants.no_past_reference)}
                />
                <label className="chk_label" htmlFor="no_past_reference">
                  <span></span>
                </label>
                <span className="wpcf7-list-item-label">No Past References</span>
              </div> */}

              <div className="personal_referance_div">
                <p>Reference - #1</p>
                <input
                  type="text"
                  required={this.state.applicants.no_past_reference === null}
                  // disabled={this.state.applicants.no_past_reference === 'yes'}
                  name="reference1name"
                  errors={this.state.reference1nameError}
                  placeholder="Name"
                  value={
                    this.state.reference1name &&
                    this.state.reference1name !== "null"
                      ? this.state.reference1name
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_reference === null}
                  // disabled={this.state.applicants.no_past_reference === 'yes'}
                  type="text"
                  name="reference1Relationship"
                  errors={this.state.reference1RelationshipError}
                  placeholder="Relationship"
                  value={
                    this.state.reference1Relationship &&
                    this.state.reference1Relationship !== "null"
                      ? this.state.reference1Relationship
                      : ""
                  }
                  onChange={this.handleChange}
                />
                {/*<input type="text" name="reference1Phone" placeholder="Cell Phone" value={this.state.reference1Phone && this.state.reference1Phone !== "null" ? this.state.reference1Phone : ""} onChange={this.handleChange} /> */}
                <NumberFormat
                  required={this.state.applicants.no_past_reference === null}
                  // disabled={this.state.applicants.no_past_reference === 'yes'}
                  format="###-###-####"
                  mask="_"
                  onChange={this.handleChange}
                  name="reference1Phone"
                  errors={this.state.reference1PhoneError}
                  placeholder="Cell Phone"
                  value={
                    this.state.reference1Phone &&
                    this.state.reference1Phone !== "null"
                      ? this.state.reference1Phone
                      : ""
                  }
                />
                <input
                  required={this.state.applicants.no_past_reference === null}
                  // disabled={this.state.applicants.no_past_reference === 'yes'}
                  type="text"
                  name="reference1Known"
                  errors={this.state.reference1KnownError}
                  placeholder="How long have you known them?"
                  value={
                    this.state.reference1Known &&
                    this.state.reference1Known !== "null"
                      ? this.state.reference1Known
                      : ""
                  }
                  onChange={this.handleChange}
                />
              </div>

              <div className="personal_referance_div">
                <p>Reference - #2</p>
                <input
                  required={this.state.applicants.no_past_reference === null}
                  // disabled={this.state.applicants.no_past_reference === 'yes'}
                  type="text"
                  name="reference2name"
                  errors={this.state.reference2nameError}
                  placeholder="Name"
                  value={
                    this.state.reference2name &&
                    this.state.reference2name !== "null"
                      ? this.state.reference2name
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_reference === null}
                  // disabled={this.state.applicants.no_past_reference === 'yes'}
                  type="text"
                  name="reference2Relationship"
                  errors={this.state.reference2RelationshipError}
                  placeholder="Relationship"
                  value={
                    this.state.reference2Relationship &&
                    this.state.reference2Relationship !== "null"
                      ? this.state.reference2Relationship
                      : ""
                  }
                  onChange={this.handleChange}
                />
                {/*<input type="text" name="reference2Phone" placeholder="Cell Phone" value={this.state.reference2Phone && this.state.reference2Phone !=="null" ? this.state.reference2Phone : ""} onChange={this.handleChange} />   */}
                <NumberFormat
                  required={this.state.applicants.no_past_reference === null}
                  // disabled={this.state.applicants.no_past_reference === 'yes'}
                  format="###-###-####"
                  mask="_"
                  onChange={this.handleChange}
                  name="reference2Phone"
                  errors={this.state.reference2PhoneError}
                  placeholder="Cell Phone"
                  value={
                    this.state.reference2Phone &&
                    this.state.reference2Phone !== "null"
                      ? this.state.reference2Phone
                      : ""
                  }
                />
                <input
                  required={this.state.applicants.no_past_reference === null}
                  // disabled={this.state.applicants.no_past_reference === 'yes'}
                  type="text"
                  name="reference2Known"
                  errors={this.state.reference2KnownError}
                  placeholder="How long have you known them?"
                  value={
                    this.state.reference2Known &&
                    this.state.reference2Known !== "null"
                      ? this.state.reference2Known
                      : ""
                  }
                  onChange={this.handleChange}
                />
              </div>

              <div className="personal_referance_div">
                <p>Reference - #3</p>
                <input
                  required={this.state.applicants.no_past_reference === null}
                  // disabled={this.state.applicants.no_past_reference === 'yes'}
                  type="text"
                  name="reference3name"
                  placeholder="Name"
                  value={
                    this.state.reference3name &&
                    this.state.reference3name !== "null"
                      ? this.state.reference3name
                      : ""
                  }
                  onChange={this.handleChange}
                />
                <input
                  required={this.state.applicants.no_past_reference === null}
                  // disabled={this.state.applicants.no_past_reference === 'yes'}
                  type="text"
                  name="reference3Relationship"
                  placeholder="Relationship"
                  value={
                    this.state.reference3Relationship &&
                    this.state.reference3Relationship !== "null"
                      ? this.state.reference3Relationship
                      : ""
                  }
                  onChange={this.handleChange}
                />
                {/*<input type="text" name="reference3Phone" placeholder="Cell Phone" value={this.state.reference3Phone && this.state.reference3Phone !=="null" ? this.state.reference3Phone : ""} onChange={this.handleChange} /> */}
                <NumberFormat
                  required={this.state.applicants.no_past_reference === null}
                  // disabled={this.state.applicants.no_past_reference === 'yes'}
                  format="###-###-####"
                  mask="_"
                  onChange={this.handleChange}
                  name="reference3Phone"
                  placeholder="Cell Phone"
                  value={
                    this.state.reference3Phone &&
                    this.state.reference3Phone !== "null"
                      ? this.state.reference3Phone
                      : ""
                  }
                />
                <input
                  required={this.state.applicants.no_past_reference === null}
                  // disabled={this.state.applicants.no_past_reference === 'yes'}
                  type="text"
                  name="reference3Known"
                  placeholder="How long have you known them?"
                  value={
                    this.state.reference3Known &&
                    this.state.reference3Known !== "null"
                      ? this.state.reference3Known
                      : ""
                  }
                  onChange={this.handleChange}
                />
              </div>
            </div>
          )}

          <div className="formField driver_qualification">
            <label>Driver Qualifications</label>
            <p>
              If you would like to be considered for a driving position during
              your employment and you have a valid driver's license. You need to
              complete the section below to be trained in and operate a
              commercial vehicle.
            </p>

            <p className="sub_label">
              If you do not have a valid or current driver's license you can
              skip this section.
            </p>
            <p className="sub_label">Driver License(s) past 3 years: </p>
            <p>License - #1 (if applicable)</p>
            <div className="license_div">
              <input
                type="text"
                name="licenseState"
                placeholder="State"
                value={
                  this.state.licenseState && this.state.licenseState !== "null"
                    ? this.state.licenseState
                    : ""
                }
                onChange={this.handleChange}
              />
              <input
                type="text"
                name="licenseNumber"
                placeholder="License#"
                value={
                  this.state.licenseNumber &&
                  this.state.licenseNumber !== "null"
                    ? this.state.licenseNumber
                    : ""
                }
                onChange={this.handleChange}
              />

              <DatePicker
                selected={this.state.licenseExpDate}
                name="licenseExpDate"
                placeholderText="Exp Date"
                onChange={this.handleDateChange.bind(this, "licenseExpDate")}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                dateFormat="MM/DD/YYYY"
              />
              <input
                type="text"
                name="licenseClass"
                placeholder="Class A, B, C"
                value={
                  this.state.licenseClass && this.state.licenseClass !== "null"
                    ? this.state.licenseClass
                    : ""
                }
                onChange={this.handleChange}
              />
              <input
                type="text"
                name="endorsement"
                placeholder="Endorsements"
                value={
                  this.state.endorsement && this.state.endorsement !== "null"
                    ? this.state.endorsement
                    : ""
                }
                onChange={this.handleChange}
              />
            </div>

            <p className="sub_label">
              Have you ever been denied a license, permit or privilege to
              operate a motor vehicle?
            </p>
            <select
              name="DeniedLicense"
              id="DeniedLicense"
              onChange={this.handleChange}
              className="small_dropdown"
              value={this.state.DeniedLicense ? this.state.DeniedLicense : ""}
            >
              <option value="">---</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>

            <p className="sub_label">
              Has any license, permit or privilege ever been revoked?
            </p>
            <select
              name="LicenseRevoked"
              id="LicenseRevoked"
              value={this.state.LicenseRevoked || ""}
              className="small_dropdown"
              onChange={this.handleChange}
            >
              <option value="">---</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
            <input
              type="text"
              value={
                this.state.ReasonLicenseRevoked &&
                this.state.ReasonLicenseRevoked !== "null"
                  ? this.state.ReasonLicenseRevoked
                  : ""
              }
              name="ReasonLicenseRevoked"
              placeholder="If Yes, please explain..."
              onChange={this.handleChange}
            />
          </div>

          <div className="formField">
            <label>Please Answer the Following Questions</label>
            <p>
              I have read and understand the physical and mental requirements
              needed in order to be a wildland firefighter that are explained in
              the link below. I am qualified and fit to perform these tasks.
              (For more information on fitness for this job go here:{" "}
              <a
                target="_blank"
                href="https://www.nifc.gov/about-us/our-partners/blm/training/fitness-challenge"
                rel="noreferrer"
              >
                https://www.nifc.gov/about-us/our-partners/blm/training/fitness-challenge
              </a>
              )*
            </p>
            <select
              name="Question2"
              id="Question2"
              className="small_dropdown"
              value={this.state.Question2}
              onChange={this.handleChange}
              errors={this.state.Question2Error}
            >
              <option value="">---</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>

            <p>
              Firestorm W.F.S. Inc. is a drug free and smoke-free work place. If
              hired, will you comply to our company drug and smoking policies?*
            </p>
            <select
              name="Question3"
              id="Question3"
              className="small_dropdown"
              value={this.state.Question3}
              onChange={this.handleChange}
              errors={this.state.Question3Error}
            >
              <option value="">---</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          <div className="formField">
            <label>Legal and Final Remarks</label>
            <p className="sub_label">
              Firestorm W.F.S. Inc. is an equal opportunity employer. All
              candidates will be considered for employment without regard to
              race, color, sex, religion, national origin and age.
            </p>

            <p>
              Firestorm is a Federal Contractor and is required to meet certain
              guidelines for our Affirmative Action Plan; therefore, we request
              that you submit the following information. This information is
              voluntary and will not affect your hiring eligibility. This
              information will remain confidential and hiring supervisors will
              not see this information.
            </p>

            {checkCurrentUserIsSuperAdmin(
              this.props?.user?.profile?.data?.is_admin,
            ) && (
              <>
                <p className="sub_label">Select Gender</p>
                <select
                  name="gender"
                  id="gender"
                  className="small_dropdown"
                  value={this.state.gender}
                  onChange={this.handleChange}
                >
                  <option value="">---</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>

                <p className="sub_label">Select Race</p>
                <select
                  name="Race"
                  id="race"
                  value={this.state.Race}
                  onChange={this.handleChange}
                >
                  <option value="">---</option>
                  {this.state.RaceList.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.value}
                    </option>
                  ))}
                </select>

                <p className="sub_label">Are you a Veteran</p>
                <select
                  name="Veteran"
                  id="veteran"
                  className="small_dropdown"
                  value={this.state.Veteran || ""}
                  onChange={this.handleChange}
                >
                  <option value="">---</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </select>
              </>
            )}

            <div className="paragragh">
              <p>
                Submission of false information is grounds for employment and
                candidacy termination.
              </p>

              <p>
                I hereby authorize Firestorm Wildland Fire Suppression, Inc. to
                thoroughly investigate my references, work record, education and
                other matters related to my suitability for employment
                (excluding criminal background information) unless otherwise
                specified above. I further, authorize the references I have
                listed to disclose to the company any and all letters, reports
                and other information related to my work records, without giving
                me prior notice of such disclosure. In addition, I hereby
                release the Company, my former employers and all other persons,
                corporations, partnerships and associations from any and all
                claims, demands or liabilities arising out of or in any way
                related to such investigation or disclosure.
              </p>

              <p>
                I verify that all information provided on this application is in
                fact true to the best of my knowledge and understand that if
                information is not true, my candidacy and/or employment will be
                terminated. By filling out your name and dating below is a legal
                agreement between you (the applicant) and Firestorm Inc.
              </p>
            </div>

            <p className="sub_label">Do you agree to the above statement?*</p>

            <select
              name="agreement"
              id="agreement"
              value={this.state.agreement}
              className="small_dropdown"
              onChange={this.handleChange}
              errors={this.state.AgreementError}
            >
              <option value="">---</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>

          <div className="formField">
            <p className="sub_label">Full Name*</p>
            <input
              type="text"
              name="FullName"
              id="FullName"
              className="FullName"
              placeholder="Type Legal Full Name"
              value={
                this.state.FullName && this.state.FullName !== "null"
                  ? this.state.FullName
                  : ""
              }
              onChange={this.handleChange}
              errors={this.state.FullNameError}
            />

            <p className="sub_label">Todays Date*</p>

            <DatePicker
              className={"txtDate " + this.state.error}
              disabled
              selected={this.state.txtDate}
              // value={this.state.txtDate}
              name="txtDate"
              id="txtDate"
              placeholderText="mm/dd/yyyy"
              onChange={this.handleDateChange.bind(this, "txtDate")}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="MM/DD/YYYY"
            />
          </div>

          <div className="formField">
            <input
              type="submit"
              name="submit"
              value="Update application"
              className="submit_appln"
              onClick={this.onSubmit}
            />
          </div>
        </div>
        {this.state.alertPop && (
          <AlertPopup
            head={this.state.alertHead}
            message={this.state.alertMsg}
            viewOpen="true"
            alertFunction={this.state.alertFunction}
            close={this.closeAlert.bind(this)}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { applicants, user } = state;
  return {
    applicants,
    user,
  };
}

const connectedApplication = connect(mapStateToProps)(EditApplicantion);
export { connectedApplication as EditApplicantion };
