import React from "react";
import { withRouter } from "react-router";
import ReactSelect from "react-select";

import "../assets/styles/crew-assignment-status.css";

export const VEHICLE_REPORT_OPTIONS = [
  { label: "All", value: "All" },
  { label: "Assigned", value: "Assigned" },
  { label: "Unassigned", value: "Unassigned" },
];

const VehicleReportWidget = ({ toggle, widgetid, visibility, history }) => {
  const [currentFilters, setCurrentFilters] = React.useState(null);

  const handleOnChange = (newSelectedOptions) => {
    setCurrentFilters(newSelectedOptions);
  };

  const handleRunReport = () => {
    history.push("/vehicle-report", {
      currentFilters,
    });
  };

  return (
    <div className="dashboard_expiration_date_outer">
      <div className="dashboard_expiration_date inner_head">
        <h2>
          Vehicle Report<span className="drag-icon-controller"></span>
        </h2>
        <span
          className="toggle-controller"
          aria-owns="vehicle-report-filter"
          onClick={(event) => {
            toggle(event, widgetid);
          }}
        ></span>
      </div>
      <div
        className="employee_stats_details assignment_stats-details"
        aria-expanded={visibility == 1 ? "true" : "false"}
        id="vehicle-report-filter"
      >
        <h4>Filters</h4>
        <div style={{ marginTop: 20 }}>
          <ReactSelect
            value={currentFilters}
            onChange={handleOnChange}
            options={VEHICLE_REPORT_OPTIONS}
          />
        </div>
        <div className="assignment-status-outer-form">
          <div className="run-report" onClick={() => handleRunReport()}>
            Run Report
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(VehicleReportWidget);
