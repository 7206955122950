import React, { useState } from "react";

import { EditProfile } from "./EditProfile";

const AffirmativeActionComponent = ({
  profileData,
  dropdownData,
  refetchUser,
  isOpen,
  toggleAccordion,
  permList,
  authUser,
}) => {
  return (
    <div
      id="affirmative-action"
      className={`profile-data__drops profile-data_comp ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#affirmative-action")}>
        <button type="button">
          <h3>Affirmative Action</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>Race</b>
            </span>
            <span>{profileData.race ? profileData.race : "-"}</span>
            <EditProfile
              label="Race"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Affirmative Action Section",
              )}
            >
              {(register, errors) => (
                <>
                  <label>Race</label>
                  <select
                    {...register("race_id", {
                      required: "Race is required",
                    })}
                    defaultValue={profileData.userattribute?.race_id}
                    className={errors.race_id && "profile-form-field-error"}
                  >
                    <option value="">Select Race</option>
                    {dropdownData.Race.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                  {errors.race_id && (
                    <p className="error-message">{errors.race_id.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>EEO Classification</b>
            </span>
            <span>
              {profileData.eeo_classification
                ? profileData.eeo_classification?.title
                : "-"}
            </span>
            <EditProfile
              label="EEO Classification"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Affirmative Action Section",
              )}
            >
              {(register, errors) => (
                <>
                  <label>EEO Classification</label>
                  <select
                    {...register("eeo_classification_id")}
                    defaultValue={profileData.eeo_classification_id}
                    className={
                      errors.eeo_classification_id && "profile-form-field-error"
                    }
                  >
                    <option value="">Select EEO Classification</option>
                    {dropdownData.ListEeoClassification.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                  {errors.eeo_classification_id && (
                    <p className="error-message">
                      {errors.eeo_classification_id.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Veteran Status</b>
            </span>
            <span>
              {profileData.veteran === 1
                ? "Veteran"
                : profileData.veteran === 0
                  ? "Not a Veteran"
                  : "-"}
            </span>
            <EditProfile
              label="Veteran Status"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Affirmative Action Section",
              )}
            >
              {(register, errors) => (
                <>
                  <label>Veteran Status</label>
                  <select
                    {...register("veteran", {
                      required: "Veteran Status is required",
                    })}
                    defaultValue={profileData.veteran}
                    className={errors.veteran && "profile-form-field-error"}
                  >
                    <option value="">Select Veteran Status</option>
                    <option value="1">Veteran</option>
                    <option value="0">Not a Veteran</option>
                  </select>
                  {errors.veteran && (
                    <p className="error-message">{errors.veteran.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AffirmativeActionComponent;
