import React, { useEffect, useState } from "react";
import "./Profile.scss";
import { config } from "_helpers";
import { EditProfile } from "./EditProfile";
import OverviewComponent from "./OverviewComponent";
import MedicalInformationComponent from "./MedicalInformationComponent";
import ContactInformationComponent from "./ContactInformationComponent";
import AffirmativeActionComponent from "./AffirmativeActionComponent";
import MerchandiseInformationComponent from "./MerchandiseInformationComponent";
import WorkersCompensationComponent from "./WorkersCompensationComponent";
import NotesComponent from "./NotesComponent";
import { userStatus } from "_constants";
import { useQuery } from "react-query";
import { profileService } from "_services/profile.service";
import DriverQualificationsComponent from "./DriverQualificationsComponent";
import AdministrationComponent from "./AdministrationComponent";
import { profile } from "_reducers/profile.reducer";
import QualificationsComponent from "./QualificationsComponent";
import IfcaContractComponent from "./IfcaContractComponent";
import NationalCrewComponent from "./NationalCrewComponent";
import InventoryAssignedComponent from "./InventoryAssignedComponent";
import AssignedDatesComponent from "./AssignedDatesComponent";
import CompletedDatesComponent from "./CompletedDatesComponent";
import ExpirationDatesComponent from "./ExpirationDatesComponent";
import VerifiedDocumentsComponent from "./VerifiedDocumentsComponent";
import EmploymentHistoryComponent from "./EmploymentHistoryComponent";

const ViewProfile = ({
  userHash,
  authUser,
  permList,
  changeTab,
  reloadKey,
}) => {
  // Fetch dropdown data
  const { data: dropdownData } = useQuery(
    "dropdownData",
    profileService.fetchDropdownData,
  );

  useEffect(() => {
    // Fetch data or trigger update whenever reloadKey changes
    refetchUser();
    refetchHistory();
  }, [reloadKey]);

  useEffect(() => {
    console.log('yi');
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setActiveDivision(`#${entry.target.id}`);
        }
      });
    };
  
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "-80px 0px -80% 0px",
    });
  
    const querySections = () => {
      const sections = document.querySelectorAll(".profile-data_comp");
      if (sections.length > 0) {
        sections.forEach((section) => observer.observe(section));
      } else {
        console.log("Sections not found, retrying...");
        setTimeout(querySections, 500); // Retry every 500ms
      }
    };
  
    // Call the querySections function to check for sections
    querySections();
  
    // Cleanup observer on component unmount
    return () => observer.disconnect();
  }, []);

  const [activeDivision, setActiveDivision] = useState("#overview");
  const [expandedComponents, setExpandedComponents] = useState([]);

  const divisions = [
    { href: "#overview", title: "Overview", text: "Overview" },
    {
      href: "#contact-information",
      title: "Contact Information",
      text: "Contact Information",
    },
    {
      href: "#notes",
      title: "Notes",
      text: "Notes",
      permission: "Employee Notes",
      ownProfile: false,
    },
    {
      href: "#workers-compensation",
      title: "Workers' Compensation",
      text: "Workers' Compensation",
      permission: "Workers Compensation",
      ownProfile: false,
    },
    {
      href: "#medical-information",
      title: "Medical Information",
      text: "Medical Information",
      permission: "Manage Medical Information",
      ownProfile: true,
    },
    {
      href: "#affirmative-action",
      title: "Affirmative Action",
      text: "Affirmative Action",
      permission: "Manage Employee Affirmative Action Section",
      ownProfile: false,
    },
    {
      href: "#merchandise-information",
      title: "Merchandise Information",
      text: "Merchandise Information",
      permission: "Manage Employee Profile Fields",
      ownProfile: true,
    },
    {
      href: "#driver-qualifications",
      title: "Driver Qualifications",
      text: "Driver Qualifications",
      permission: "Manage Expiry/Assigned Dates",
      ownProfile: true,
    },
    {
      href: "#administration",
      title: "Administration",
      text: "Administration",
      permission: "Manage Administration Information",
      ownProfile: false,
    },
    {
      href: "#qualifications",
      title: "Qualifications",
      text: "Qualifications",
      permission: "Manage Expiry/Assigned Dates",
      ownProfile: true,
    },
    {
      href: "#ifca-contract",
      title: "IFCA Contract",
      text: "IFCA Contract",
      permission: "Manage Expiry/Assigned Dates",
      ownProfile: true,
    },
    {
      href: "#national-crew",
      title: "National Crew",
      text: "National Crew",
      permission: "Manage Expiry/Assigned Dates",
      ownProfile: true,
    },
    {
      href: "#inventory-assigned",
      title: "Inventory Assigned",
      text: "Inventory Assigned",
      permission: "Inventory",
      ownProfile: true,
    },
    {
      href: "#assigned-dates",
      title: "Assigned Dates",
      text: "Assigned Dates",
      permission: "Manage Expiry/Assigned Dates",
      ownProfile: true,
    },
    {
      href: "#completed-dates",
      title: "Completed Dates",
      text: "Completed Dates",
      permission: "Manage Expiry/Assigned Dates",
      ownProfile: true,
    },
    {
      href: "#expiration-dates",
      title: "Expiration Dates",
      text: "Expiration Dates",
      permission: "Manage Expiry/Assigned Dates",
      ownProfile: true,
    },
    {
      href: "#verified-documents",
      title: "Verified Documents",
      text: "Verified Documents",
      permission: "Manage Expiry/Assigned Dates",
      ownProfile: true,
    },
    {
      href: "#employment-history",
      title: "Employment History",
      text: "Employment History",
      permission: "Manage Expiry/Assigned Dates",
      ownProfile: true,
    },
  ];

  const changeActiveDivision = (divisionHref) => {
    // Scroll to the corresponding section with smooth scrolling and offset
    const targetSection = document.querySelector(divisionHref);
    const offset = 86; // Height of the top bar or header

    if (targetSection) {
      const sectionPosition =
        targetSection.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({
        top: sectionPosition,
        behavior: "smooth",
      });
    }
    setExpandedComponents((prev) =>
      prev.includes(divisionHref) ? prev : [...prev, divisionHref],
    );
  };

  const collapseAllComponents = () => {
    setActiveDivision("#overview");
    setExpandedComponents([]);
  };

  const expandAllComponents = () => {
    setActiveDivision("#overview");
    setExpandedComponents(divisions.map((division) => division.href));
  };

  const toggleAccordion = (divisionHref) => {
    setExpandedComponents((prev) =>
      prev.includes(divisionHref)
        ? prev.filter((item) => item !== divisionHref)
        : [...prev, divisionHref],
    );
  };

  // Fetch profile data, passing the function reference, not the result of the function call
  const {
    data: profileData,
    refetch: refetchUser,
    isSuccess: isProfileDataFetched,
  } = useQuery(
    ["userData", userHash], // Unique key including userHash to prevent conflicts
    () => profileService.getUser(userHash), // Pass function reference, not the result
    {
      enabled: !!userHash, // Ensure query only runs when userHash is available
    },
  );

  let profileUserName = "";
  let profilePic = "";
  const { data: trainingData } = useQuery(
    ["trainingUserData", profileData?.id],
    () => profileService.getTrainingData(profileData?.usertraining?.id),
    {
      enabled: isProfileDataFetched && !!profileData?.usertraining?.id,
    },
  );

  const ownProfile = profileData?.id == authUser?.id;

  const { data: inventoryAssignedData } = useQuery(
    ["inventoryAssignedUserData", profileData?.hash],
    () => profileService.getInventoryAssignedData(profileData?.hash),
    {
      enabled: isProfileDataFetched && !!profileData?.hash,
    },
  );

  const { data: historyData, refetch: refetchHistory } = useQuery(
    ["historyUserData", profileData?.id], // Use `id` instead of `hash` for uniqueness
    async () => {
      if (!profileData?.id) return Promise.reject("Profile ID is missing"); // Prevent API call
      return profileService.getEmploymentHistoryData(profileData.id);
    },
    {
      enabled: isProfileDataFetched && !!profileData?.id, // Ensure `id` exists before enabling query
    },
  );

  if (profileData) {
    // Image
    profilePic =
      config.apiUrl + "/" + profileData.profile_photo ?? config.altImg;

    // User name
    profileUserName =
      profileData.first_name &&
      profileData.first_name +
        " " +
        (profileData.middle_name ? profileData.middle_name + " " : "") +
        profileData.last_name;
  }

  const onImageError = (e) => {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  };

  const visibleComponents = [
    "Employee Notes",
    "Workers Compensation",
    "Manage Medical Information",
    "Manage Employee Affirmative Action Section",
    "Manage Employee Profile Fields",
    "Manage Expiry/Assigned Dates",
    "Manage Administration Information",
    "Inventory",
  ].filter((perm) => permList.includes(perm));

  const ownProfileComponents = [
    "Manage Medical Information",
    "Manage Employee Profile Fields",
    "Manage Expiry/Assigned Dates",
    "Inventory",
  ].filter((perm) => ownProfile && !permList.includes(perm));

  const totalVisibleComponents =
    visibleComponents.length + ownProfileComponents.length;

  return (
    <div className="ft profile-data">
      <nav className="profile-data__nav">
        <ul role="list" className="profile-divisions">
          {permList &&
            profileData &&
            divisions.map((division, index) =>
              !division.permission ||
              (division.permission && permList.includes(division.permission)) ||
              (division.ownProfile && ownProfile) ? (
                <li
                  key={index}
                  className={division.href === activeDivision ? "active" : ""}
                >
                  <a
                    onClick={() => changeActiveDivision(division.href)}
                    title={division.title}
                    className="division-button"
                  >
                    {division.text}
                  </a>
                </li>
              ) : null,
            )}
        </ul>
      </nav>
      <div className="profile-data__cnt">
        {!profileData && (
          <div className="dashboard_expiration_date_no_data">
            <img
              className="loader"
              alt="Loader"
              src={require("../../assets/images/loader.gif").default}
            />
          </div>
        )}
        {profileData && dropdownData && (
          <>
            {/* Overview Division */}
            <OverviewComponent
              profileData={profileData}
              profileUserName={profileUserName}
              dropdownData={dropdownData}
              refetchUser={refetchUser}
              permList={permList}
              authUser={authUser}
            />
            <div className="profile-data__col">
              <header>
                <button
                  type="button"
                  onClick={
                    expandedComponents.length
                      ? collapseAllComponents
                      : expandAllComponents
                  }
                >
                  {expandedComponents.length ? "Collapse" : "Expand"} All
                </button>
              </header>
              <ContactInformationComponent
                profileData={profileData}
                refetchUser={refetchUser}
                isOpen={expandedComponents.includes("#contact-information")}
                toggleAccordion={toggleAccordion}
                permList={permList}
                authUser={authUser}
              />
              {permList.includes("Employee Notes") && (
                <NotesComponent
                  profileData={profileData}
                  refetchUser={refetchUser}
                  isOpen={expandedComponents.includes("#notes")}
                  toggleAccordion={toggleAccordion}
                />
              )}
              {permList.includes("Workers Compensation") && (
                <WorkersCompensationComponent
                  profileData={profileData}
                  refetchUser={refetchUser}
                  isOpen={expandedComponents.includes("#workers-compensation")}
                  toggleAccordion={toggleAccordion}
                />
              )}
              {(permList.includes("Manage Medical Information") ||
                ownProfile) && (
                <MedicalInformationComponent
                  profileData={profileData}
                  refetchUser={refetchUser}
                  isOpen={expandedComponents.includes("#medical-information")}
                  toggleAccordion={toggleAccordion}
                  permList={permList}
                  authUser={authUser}
                />
              )}
              {permList.includes(
                "Manage Employee Affirmative Action Section",
              ) && (
                <AffirmativeActionComponent
                  profileData={profileData}
                  dropdownData={dropdownData}
                  refetchUser={refetchUser}
                  isOpen={expandedComponents.includes("#affirmative-action")}
                  toggleAccordion={toggleAccordion}
                  permList={permList}
                  authUser={authUser}
                />
              )}
              {(permList.includes("Manage Employee Profile Fields") ||
                ownProfile) && (
                <MerchandiseInformationComponent
                  profileData={profileData}
                  dropdownData={dropdownData}
                  refetchUser={refetchUser}
                  isOpen={expandedComponents.includes(
                    "#merchandise-information",
                  )}
                  toggleAccordion={toggleAccordion}
                  permList={permList}
                  authUser={authUser}
                />
              )}
              {(permList.includes("Manage Expiry/Assigned Dates") ||
                ownProfile) && (
                <DriverQualificationsComponent
                  profileData={profileData}
                  refetchUser={refetchUser}
                  isOpen={expandedComponents.includes("#driver-qualifications")}
                  toggleAccordion={toggleAccordion}
                  permList={permList}
                  authUser={authUser}
                />
              )}
              {permList.includes("Manage Administration Information") && (
                <AdministrationComponent
                  profileData={profileData}
                  refetchUser={refetchUser}
                  isOpen={expandedComponents.includes("#administration")}
                  toggleAccordion={toggleAccordion}
                  permList={permList}
                  authUser={authUser}
                />
              )}
              {(permList.includes("Manage Expiry/Assigned Dates") ||
                ownProfile) && (
                <QualificationsComponent
                  profileData={profileData}
                  dropdownData={dropdownData}
                  trainingData={trainingData?.data?.form}
                  isOpen={expandedComponents.includes("#qualifications")}
                  refetchUser={refetchUser}
                  toggleAccordion={toggleAccordion}
                  changeTab={changeTab}
                  permList={permList}
                  authUser={authUser}
                />
              )}
              {(permList.includes("Manage Expiry/Assigned Dates") ||
                ownProfile) && (
                <IfcaContractComponent
                  profileData={profileData}
                  dropdownData={dropdownData}
                  refetchUser={refetchUser}
                  isOpen={expandedComponents.includes("#ifca-contract")}
                  toggleAccordion={toggleAccordion}
                  permList={permList}
                  authUser={authUser}
                />
              )}
              {(permList.includes("Manage Expiry/Assigned Dates") ||
                ownProfile) && (
                <NationalCrewComponent
                  profileData={profileData}
                  refetchUser={refetchUser}
                  isOpen={expandedComponents.includes("#national-crew")}
                  toggleAccordion={toggleAccordion}
                  permList={permList}
                  authUser={authUser}
                />
              )}
              {(permList.includes("Inventory") || ownProfile) && (
                <InventoryAssignedComponent
                  profileData={profileData}
                  inventoryData={inventoryAssignedData?.data}
                  isOpen={expandedComponents.includes("#inventory-assigned")}
                  toggleAccordion={toggleAccordion}
                  permList={permList}
                  authUser={authUser}
                />
              )}

              {(permList.includes("Manage Expiry/Assigned Dates") ||
                ownProfile) && (
                <>
                  <h3>Important Dates</h3>
                  <AssignedDatesComponent
                    profileData={profileData}
                    refetchUser={refetchUser}
                    isOpen={expandedComponents.includes("#assigned-dates")}
                    toggleAccordion={toggleAccordion}
                    permList={permList}
                    authUser={authUser}
                  />
                  <CompletedDatesComponent
                    profileData={profileData}
                    refetchUser={refetchUser}
                    isOpen={expandedComponents.includes("#completed-dates")}
                    toggleAccordion={toggleAccordion}
                    permList={permList}
                    authUser={authUser}
                  />
                  <ExpirationDatesComponent
                    profileData={profileData}
                    refetchUser={refetchUser}
                    isOpen={expandedComponents.includes("#expiration-dates")}
                    toggleAccordion={toggleAccordion}
                    permList={permList}
                    authUser={authUser}
                  />
                  <VerifiedDocumentsComponent
                    profileData={profileData}
                    refetchUser={refetchUser}
                    isOpen={expandedComponents.includes("#verified-documents")}
                    toggleAccordion={toggleAccordion}
                    permList={permList}
                    authUser={authUser}
                  />
                  <EmploymentHistoryComponent
                    profileData={profileData}
                    refetchUser={refetchUser}
                    refetchHistory={refetchHistory}
                    historyData={historyData?.data}
                    isOpen={expandedComponents.includes("#employment-history")}
                    toggleAccordion={toggleAccordion}
                    permList={permList}
                    authUser={authUser}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>

      {/* Profile Data Card */}
      <div className="profile-data__card-wrap">
        {totalVisibleComponents > 3 && profileData && (
          <div className="profile-data__card">
            <figure
              className={
                profileData.is_locked === 1
                  ? "Locked"
                  : profileData.status === userStatus.Guest
                    ? "Guest"
                    : profileData.active
              }
            >
              <img
                alt={
                  profileData.first_name &&
                  profileData.first_name + " " + profileData.last_name
                }
                src={profilePic}
                onError={onImageError}
              />
            </figure>
            <h3>{profileUserName}</h3>
            <ul role="list">
              <li role="listitem">{profileData.user_type}</li>
              <li role="listitem">{profileData.crew_name}</li>
              <li role="listitem">
                Firestorm ID #: <span>{profileData.fire_id ?? "-"}</span>
              </li>
              <li role="listitem">
                ICA #: <span> {profileData.ica_number ?? "-"}</span>
              </li>
              <li role="listitem">
                Primary Crew:{" "}
                <span>{profileData.primary_crew_name ?? "-"}</span>
              </li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewProfile;
