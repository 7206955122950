import React, { Component } from "react";
import { config } from "_helpers/config";
import { connect } from "react-redux";
import { docsActions } from "_actions/docs.actions";
import { Link } from "react-router-dom";
import { Tab, Row, Col, NavItem, Nav } from "react-bootstrap";
import { MerchandiseOrder } from "Docs/Forms/MerchandiseOrderForm";
import Payroll from "Docs/Forms/Payroll";
import TimeOffRequest from "Docs/Forms/TimeOffRequest";
import { PaycheckDistribution } from "Docs/Forms/PaycheckDistribution";
import { CandidateInfoPage } from "Docs/Forms/CandidateInfoPage";
import { W4 } from "Docs/Forms/W4";
import InterviewForm from "InterviewForm";
import ErrorBoundary from "_components/ErrorBoundary";
import { selectCurrentUserPermissions } from "_helpers/reduxSelector";
import EquipmentResponsibilityForm from "Docs/Forms/EquipmentResponsibilityForm";
import DocStatusTag from "_components/utils/DocStatusTag";
import VerbalWarningAck from "Docs/Forms/VerbalWarningAck/VerbalWarningAck";
import BeneficiaryForm from "Docs/Forms/BeneficiaryForm";
import { formatDate } from "_helpers/helpers";

const DocumentTypes = {
  Onboarding: 1,
  Rehire: 2,
  Other: 3,
  GISS_Onboarding: 4,
  GISS_Rehire: 5,
};

class UserDocsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order_doc: "asc",
      order_at: "asc",
      documents: [],
      view_doc: false,
      adminMode: true,
      tab: "list",
      doc: {
        new: null,
        id: null,
        slug: "merchandise-order-form",
      },
    };
    // Bindings
    this.sortHandler = this.sortHandler.bind(this);
    this.tabChange = this.tabChange.bind(this);
    this.generateDocList = this.generateDocList.bind(this);
    this.viewDoc = this.viewDoc.bind(this);
    this.viewList = this.viewList.bind(this);
    this._compareFn = this._compareFn.bind(this);
    this.sortHandler = this.sortHandler.bind(this);
  }
  // Compare function
  _compareFn(a, b, type, by) {
    if (type === "docs_name") {
      if (by === "asc") {
        let name_1 = a.doc_type_name,
          name_2 = b.doc_type_name;
        if (name_1 < name_2) {
          return -1;
        } else if (name_1 > name_2) {
          return 1;
        } else return 0;
      } else {
        let name_1 = a.doc_type_name,
          name_2 = b.doc_type_name;
        if (name_1 < name_2) {
          return 1;
        } else if (name_1 > name_2) {
          return -1;
        } else return 0;
      }
    } else if (type === "updated_at") {
      if (by === "asc") {
        let date_1 = new Date(a.updated_at),
          date_2 = new Date(b.updated_at);
        if (date_1 < date_2) {
          return -1;
        } else if (date_1 > date_2) {
          return 1;
        } else return 0;
      } else {
        let date_1 = new Date(a.updated_at),
          date_2 = new Date(b.updated_at);
        if (date_1 < date_2) {
          return 1;
        } else if (date_1 > date_2) {
          return -1;
        } else return 0;
      }
    } else if (type === "created_at") {
      if (by === "asc") {
        let date_1 = new Date(a.created_at),
          date_2 = new Date(b.created_at);
        if (date_1 < date_2) {
          return -1;
        } else if (date_1 > date_2) {
          return 1;
        } else return 0;
      } else {
        let date_1 = new Date(a.created_at),
          date_2 = new Date(b.created_at);
        if (date_1 < date_2) {
          return 1;
        } else if (date_1 > date_2) {
          return -1;
        } else return 0;
      }
    }
    return 0;
  }
  // Handle sorting in table
  sortHandler(type) {
    let documents = this.state.documents;
    documents.sort();
    switch (type) {
      case "docs_name":
        if (this.state.order_doc === "asc") {
          documents.sort((a, b) => this._compareFn(a, b, "docs_name", "asc"));
          this.setState({ documents, order_doc: "desc" });
        } else {
          documents.sort((a, b) => this._compareFn(a, b, "docs_name", "desc"));
          this.setState({ documents, order_doc: "asc" });
        }
        break;
      case "updated_at":
        if (this.state.order_at === "asc") {
          documents.sort((a, b) => this._compareFn(a, b, "updated_at", "asc"));
          this.setState({ documents, order_at: "desc" });
        } else {
          documents.sort((a, b) => this._compareFn(a, b, "updated_at", "desc"));
          this.setState({ documents, order_at: "asc" });
        }
        break;
      case "created_at":
        if (this.state.created_at === "asc") {
          documents.sort((a, b) => this._compareFn(a, b, "created_at", "asc"));
          this.setState({ documents, created_at: "desc" });
        } else {
          documents.sort((a, b) => this._compareFn(a, b, "created_at", "desc"));
          this.setState({ documents, created_at: "asc" });
        }
        break;
      default:
        return this.state.documents;
    }
  }

  tabChange(tab) {
    this.setState({
      tab,
    });
  }

  viewDoc(doc) {
    this.setState({
      doc: {
        ...this.state.doc,
        id: doc.id,
        slug: doc?.slug,
        docs_type_id: doc?.docs_type_id,
        tor_docs_status: doc?.tor_docs_status,
      },
      view_doc: true,
      tab: "document",
    });
  }

  viewList() {
    this.setState({
      doc: { ...this.state.doc, id: null },
      view_doc: false,
      tab: "list",
    });
  }

  generateDocList(list, type, other, rehire, giss) {
    // let signature = this.props.chat.settings.signature;
    // let loggedInUser = this.props.user.profile.data.hash;
    let signature;
    this.props.chat &&
      this.props.chat.settings &&
      (signature = this.props.chat.settings.signature);
    let loggedInUser;
    this.props.user &&
      this.props.user.profile &&
      (loggedInUser = this.props.user.profile.data.hash);
    let selectedUser = (() => this.props.user && this.props.user.items.data)();
    let empty_list, docs_list;
    let filteredList = list;
    if (type === DocumentTypes.Rehire || type === DocumentTypes.GISS_Rehire) {
      filteredList = list.filter(
        (doc) => doc.docs_categories_id == 2 || doc.docs_categories_id == 5,
      );
    } else if (
      type === DocumentTypes.Onboarding ||
      type === DocumentTypes.GISS_Onboarding
    ) {
      filteredList = list.filter(
        (doc) => doc.docs_categories_id == 1 || doc.docs_categories_id == 4,
      );
    } else {
      filteredList = list.filter((doc) => doc.docs_categories_id == type);
    }

    (filteredList.length === 0 && type === 1) || type === 4
      ? (empty_list = (
          <p className="doc-list-empty">
            <strong>{selectedUser.full_name}</strong> haven't submitted any
            Onboarding Documents
          </p>
        ))
      : type === 3 || type === 6
        ? (empty_list = (
            <p className="doc-list-empty">
              <strong>{selectedUser.full_name}</strong> haven't added any Other
              Documents
            </p>
          ))
        : (empty_list = (
            <p className="doc-list-empty">
              <strong>{selectedUser.full_name}</strong> haven't added any Rehire
              Documents
            </p>
          ));
    docs_list = empty_list;

    filteredList.length > 0 &&
      (docs_list = filteredList.map((doc, index) => {
        let docStatus = "";
        const docStatusColor =
          doc.tor_docs_status === 4 ? "#3f7e39" : "#c9c188";

        switch (doc?.tor_docs_status) {
          case 1:
            docStatus = "Not Approved";
            break;
          case 2:
            docStatus = "Submitted";
            break;
          case 3:
            docStatus = "In review";
            break;
          case 4:
            docStatus = "Approved";
            break;
          case 5:
            docStatus = "Not filled";
            break;
          default:
            docStatus = "Not approved";
            break;
        }

        return (
          <div className="applicant_item list_item">
            <div className="homeEmp_list">
              <div className="basic_info unavailable_employe">
                <div className="item_cell">
                  <img
                    className="doc-list-img"
                    src={`${config.assetUrl}icons/doc-check-circle.svg`}
                    alt=""
                  />
                  <span>
                    {doc.doc_type_name}
                    {!!(doc.docs_type_id === 23) && (
                      <DocStatusTag
                        status={docStatus}
                        bgColor={docStatusColor}
                        fontColor={doc.tor_docs_status === 4 ? "#fff" : ""}
                      />
                    )}
                  </span>
                </div>
                <div className="item_cell">
                  <span>{formatDate(doc.created_at)}</span>
                </div>
                <div className="item_cell">
                  <span>
                    {" "}
                    {doc.docs_categories_id === 3
                      ? doc.docs_type_id === 23 && doc.tor_docs_status === 4
                        ? formatDate(doc.approved_date)
                        : "-"
                      : formatDate(doc.updated_at)}
                  </span>
                </div>
                {
                  <div className="item_cell  doc_download">
                    <span>
                      {doc.docs_type.id !== 29 && (
                        <a
                          href={`${config.apiUrl}/docs/pdf/${doc.slug}?id=${doc.id}&signature=${signature}&uid=${loggedInUser}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={`${config.assetUrl}icons/doc-admin-download.svg`}
                            alt=""
                          />
                        </a>
                      )}
                    </span>
                  </div>
                }
                <div className="item_cell  doc_download">
                  {other ? (
                    <span>
                      <a onClick={() => this.viewDoc(doc)}>
                        <img
                          src={`${config.assetUrl}icons/doc-admin-view.svg`}
                          alt=""
                        />
                      </a>
                    </span>
                  ) : rehire ? (
                    <span>
                      <Link
                        to={`/docs/${this.props.userHash}/${doc.slug}?rehire=true`}
                      >
                        <img
                          src={`${config.assetUrl}icons/doc-admin-view.svg`}
                          alt=""
                        />
                      </Link>
                    </span>
                  ) : (
                    <span>
                      <Link
                        to={`/docs/${this.props.userHash}/${doc.slug}?rehire=false`}
                      >
                        <img
                          src={`${config.assetUrl}icons/doc-admin-view.svg`}
                          alt=""
                        />
                      </Link>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      }));

    return docs_list;
  }

  componentDidMount() {
    let hash = this.props.userHash;
    this.props.dispatch(docsActions.getUserDocs(hash)).then((res) => {
      this.setState({ documents: this.props.docs.userDocs });
    });
  }
  render() {
    // let signature; this.props.chat && this.props.chat.settings && (signature = this.props.chat.settings.signature);
    // let loggedInUser; this.props.user && this.props.user.profile && (loggedInUser= this.props.user.profile.data.hash);
    let selectedUser = null;
    this.props.user &&
      this.props.user.items &&
      (selectedUser = this.props.user.items.data);

    console.log(this.state.doc);

    return (
      <div>
        <Tab.Container id="tabs-with-dropdown" defaultActiveKey="first">
          <Row className="clearfix">
            <Col sm={12}>
              <div className="custom-tabs m-0">
                <Nav bsStyle="tabs">
                  <NavItem eventKey="first" className="custom-tab">
                    <i className="fa fa-file"></i>
                    Onboarding Documents
                  </NavItem>
                  <NavItem eventKey="second" className="custom-tab">
                    <i className="fa fa-file-download"></i>
                    Rehire Documents
                  </NavItem>
                  <NavItem eventKey="third" className="custom-tab">
                    <i className="fa fa-file-alt"></i>
                    Other Documents
                  </NavItem>
                  {this?.props?.currentUserPermissions?.includes(
                    "Manage Interview Forms",
                  ) && (
                    <NavItem eventKey="fourth" className="custom-tab">
                      <i className="fa fa-clipboard-list"></i>
                      Interview Forms
                    </NavItem>
                  )}
                </Nav>
              </div>
            </Col>
            <Col sm={12}>
              <div className="custom-tab-content">
                <Tab.Content animation>
                  {/* Onboarding DOCUMENTS */}
                  <Tab.Pane eventKey="first">
                    {/* <!--enable this tab contents corresponding to the tab clicked--> */}
                    <div className="appln_list_header">
                      <div
                        className="list_item_head"
                        onClick={() => this.sortHandler("docs_name")}
                      >
                        Name
                        <span
                          className={`${
                            this.state.order_doc === "asc" ? "asc" : "desc"
                          } sort-arrow`}
                        ></span>
                      </div>
                      <div
                        className="list_item_head appln_date"
                        onClick={() => this.sortHandler("updated_at")}
                      >
                        Date Updated
                        <span
                          className={`${
                            this.state.order_at === "asc" ? "asc" : "desc"
                          } sort-arrow`}
                        ></span>
                      </div>
                      <div
                        className="list_item_head appln_date"
                        onClick={() => this.sortHandler("created_at")}
                      >
                        Date Created
                        <span
                          className={`${
                            this.state.created_at === "asc" ? "asc" : "desc"
                          } sort-arrow`}
                        ></span>
                      </div>
                      <div className="list_item_head"></div>
                      <div className="list_item_head"></div>
                    </div>
                    {selectedUser &&
                      this.generateDocList(
                        this.state.documents,
                        selectedUser.user_type_id === 11 ||
                          selectedUser.user_type_id === 12
                          ? 4
                          : 1,
                        false,
                        false,
                        selectedUser.user_type_id === 11 ||
                          selectedUser.user_type_id === 12,
                      )}
                  </Tab.Pane>

                  {/* Rehire DOCUMENTS */}
                  <Tab.Pane eventKey="second">
                    {/* <!--enable this tab contents corresponding to the tab clicked--> */}
                    <div className="appln_list_header">
                      <div
                        className="list_item_head"
                        onClick={() => this.sortHandler("docs_name")}
                      >
                        Name
                        <span
                          className={`${
                            this.state.order_doc === "asc" ? "asc" : "desc"
                          } sort-arrow`}
                        ></span>
                      </div>
                      <div
                        className="list_item_head appln_date"
                        onClick={() => this.sortHandler("updated_at")}
                      >
                        Date Updated
                        <span
                          className={`${
                            this.state.order_at === "asc" ? "asc" : "desc"
                          } sort-arrow`}
                        ></span>
                      </div>
                      <div
                        className="list_item_head appln_date"
                        onClick={() => this.sortHandler("created_at")}
                      >
                        Date Created
                        <span
                          className={`${
                            this.state.created_at === "asc" ? "asc" : "desc"
                          } sort-arrow`}
                        ></span>
                      </div>
                      <div className="list_item_head"></div>
                      <div className="list_item_head"></div>
                    </div>
                    {selectedUser &&
                      this.generateDocList(
                        this.state.documents,
                        selectedUser.user_type_id === 11 ||
                          selectedUser.user_type_id === 12
                          ? 5
                          : 2,
                        false,
                        true,
                        selectedUser.user_type_id === 11 ||
                          selectedUser.user_type_id === 12,
                      )}
                  </Tab.Pane>

                  {/* Other Documents */}
                  <Tab.Pane eventKey="third">
                    <section className="content onboarding-docs">
                      {/* LIST */}
                      {this.state.tab === "list" && (
                        <section className="list">
                          {/* <!--enable this tab contents corresponding to the tab clicked--> */}
                          <div className="appln_list_header">
                            <div
                              className="list_item_head"
                              onClick={() => this.sortHandler("docs_name")}
                            >
                              Name
                              <span
                                className={`${
                                  this.state.order_doc === "asc"
                                    ? "asc"
                                    : "desc"
                                } sort-arrow`}
                              ></span>
                            </div>
                            <div
                              className="list_item_head appln_date"
                              onClick={() => this.sortHandler("updated_at")}
                            >
                              Date Submitted
                              <span
                                className={`${
                                  this.state.order_at === "asc" ? "asc" : "desc"
                                } sort-arrow`}
                              ></span>
                            </div>
                            <div
                              className="list_item_head appln_date"
                              onClick={() => this.sortHandler("created_at")}
                            >
                              Date Approved
                              <span
                                className={`${
                                  this.state.created_at === "asc"
                                    ? "asc"
                                    : "desc"
                                } sort-arrow`}
                              ></span>
                            </div>
                            <div className="list_item_head"></div>
                            <div className="list_item_head"></div>
                          </div>
                          {selectedUser &&
                            this.generateDocList(
                              this.state.documents,
                              3,
                              true,
                              false,
                              selectedUser.user_type_id === 11 ||
                                selectedUser.user_type_id === 12,
                            )}
                        </section>
                      )}
                      {/* DOCUMENT */}
                      {this.state.tab === "document" && (
                        <section className="document">
                          <p className="buttonWrapper">
                            <button
                              id="back-button"
                              className="button"
                              onClick={this.viewList}
                            >
                              Back
                            </button>
                          </p>
                          <div className="container doc_info full-width_form">
                            {this.state?.doc?.slug ===
                              "merchandise-order-form" && (
                              <MerchandiseOrder
                                viewList={this.viewList}
                                view_doc={this.state.view_doc}
                                new_doc_id={this.state.doc.id}
                                single_doc={true}
                                tabChange={this.tabChange}
                                slug="merchandise-order-form"
                                adminMode={this.state.adminMode}
                                lastPage={true}
                              />
                            )}
                            {this.state?.doc?.slug === "payroll-deduction" && (
                              <Payroll
                                goBack={() => this.viewList()}
                                isSingleDoc={true}
                                adminMode={this.state.adminMode}
                                docDetails={this.state?.doc}
                              />
                            )}
                            {this.state?.doc?.slug === "time-off-request" && (
                              <TimeOffRequest
                                goBack={() => this.viewList()}
                                isSingleDoc={true}
                                adminMode={this.state.adminMode}
                                docDetails={this.state?.doc}
                                status={this.state?.doc?.tor_docs_status}
                              />
                            )}
                            {this.state?.doc?.slug === "warning-management" && (
                              <VerbalWarningAck
                                goBack={() => this.viewList()}
                                isSingleDoc={true}
                                adminMode={this.state.adminMode}
                                docDetails={this.state?.doc}
                              />
                            )}
                            {this.state?.doc?.slug ===
                              "paycheck-distribution-form" && (
                              <PaycheckDistribution
                                goBack={() => this.viewList()}
                                single_doc={true}
                                adminMode={this.state.adminMode}
                                new_doc_id={this.state.doc.id}
                                slug={this.state.doc.slug}
                                lastPage={true}
                              />
                            )}
                            {this.state?.doc?.slug ===
                              "employee-information-page" && (
                              <CandidateInfoPage
                                goBack={() => this.viewList()}
                                single_doc={true}
                                adminMode={this.state.adminMode}
                                new_doc_id={this.state.doc.id}
                                slug={this.state.doc.slug}
                                lastPage={true}
                              />
                            )}
                            {this.state?.doc?.slug === "beneficiary-form" && (
                              <BeneficiaryForm
                                goBack={() => this.viewList()}
                                single_doc={true}
                                adminMode={this.state.adminMode}
                                new_doc_id={this.state.doc.id}
                                slug={this.state.doc.slug}
                                lastPage={true}
                                doc={this.state.doc}
                                docs={this.props.docs}
                              />
                            )}
                            {this.state?.doc?.slug === "w-4" && (
                              <ErrorBoundary>
                                <W4
                                  goBack={() => this.viewList()}
                                  single_doc={true}
                                  adminMode={this.state.adminMode}
                                  new_doc_id={this.state.doc.id}
                                  slug={this.state.doc.slug}
                                  lastPage={true}
                                />
                              </ErrorBoundary>
                            )}
                            {this.state?.doc?.slug ===
                              "equipment-responsibility-form" && (
                              <ErrorBoundary>
                                <EquipmentResponsibilityForm
                                  goBack={() => this.viewList()}
                                  isSingleDoc={true}
                                  adminMode={this.state.adminMode}
                                  docDetails={this.state?.doc}
                                />
                              </ErrorBoundary>
                            )}
                          </div>
                        </section>
                      )}
                    </section>
                  </Tab.Pane>
                  <Tab.Pane eventKey="fourth">
                    <InterviewForm hash={this.props.userHash} listOnly />
                  </Tab.Pane>
                </Tab.Content>
              </div>
            </Col>
          </Row>
        </Tab.Container>

        {/* <!--enable this tab contents corresponding to the tab clicked--> */}
        {/* <div className="appln_list_header">
          <div className="list_item_head" onClick={() => this.sortHandler("docs_name")}>
            Name
            <span className={`${this.state.order_doc === "asc" ? "asc" : "desc"} sort-arrow`}></span>
          </div>
          <div className="list_item_head appln_date" onClick={() => this.sortHandler("updated_at")}>
            Date Updated
            <span className={`${this.state.order_at === "asc" ? "asc" : "desc"} sort-arrow`}></span>
          </div>
          <div className="list_item_head"></div>
          <div className="list_item_head"></div>
        </div> */}
        {/* {this.state.documents.map((doc) => {
          return (
            <div className="applicant_item list_item">
              <div className="homeEmp_list">
                <div className="basic_info unavailable_employe">
                  <div className="item_cell">
                    <img className="doc-list-img" src={`${config.assetUrl}icons/doc-check-circle.svg`} alt="" />
                    <span>{doc.doc_type_name}</span>
                  </div>
                  <div className="item_cell">
                    <span>{moment(doc.updated_at).format("MM/DD/YY")}</span>
                  </div>
                  <div className="item_cell  doc_download">
                    <span>
                      <a href={`${config.apiUrl}/docs/pdf/${doc.slug}?id=${doc.id}&signature=${signature}&uid=${loggedInUser}`} target="_blank">
                        <img src={`${config.assetUrl}icons/doc-admin-download.svg`} alt="" />
                      </a>
                    </span>
                  </div>
                  <div className="item_cell  doc_download">
                    <span>
                      <Link to={`/docs/${this.props.userHash}/${doc.slug}`}>
                        <img src={`${config.assetUrl}icons/doc-admin-view.svg`} alt="" />
                      </Link>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })} */}
      </div>
    );
  }
}

function mapStateToProps(state) {
  let { docs, chat, user } = state;
  return {
    docs,
    chat,
    user,
    currentUserPermissions: selectCurrentUserPermissions(state),
  };
}

let connectedUserDocsList = connect(mapStateToProps)(UserDocsList);
export { connectedUserDocsList as UserDocsList };
