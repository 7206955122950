/* eslint-disable no-unreachable */
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { authHeader, config } from "../_helpers";
import {
  applicantsActions,
  createProfileActions,
  permissionActions,
  resourceActions,
} from "../_actions";
import "../assets/styles/applicants.css";
import ReactModal from "react-modal";
import ReactPaginate from "react-paginate";
import { getApplicantStatus } from "_utils/utils";
import { toast } from "react-toastify";
import NoInterviewFormAlert from "_components/NoInterviewFormAlert/NoInterviewFormAlert";
import Modal from "_components/utils/Modal/Modal";
import DateAndTimePicker from "_components/utils/DateAndTimePicker/DateAndTimePicker";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import moment from "moment";
import { AddEditNote } from "_components/utils/AddEditNote/AddEditNote";
import { ApplicantNoteCard } from "_components/utils/ApplicantNoteCard/ApplicantNoteCard";
import { ReactComponent as NoteIcon } from "assets/icons/applicant-note.svg";
import { ReactComponent as Warning } from "assets/icons/applicant-warning-icon.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { handleErrorResponse } from "_helpers/helpers";
import { formatDate } from "_helpers/helpers";
import { dateTimeConstants, contactEmailConstants } from "_constants";

ReactModal.setAppElement("#app");

// To format the phone number
function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}

const ApplicationStatuses = {
  NOT_SELECTED_ARCHIVE: 0,
  RECEIVED: 1,
  WAS_HIRED: 2,
  IN_REVIEW: 3,
  OFFERED_POSITION: 4,
  DO_NOT_HIRE_ARCHIVE: 10,
};

const ISO_DATE_FORMAT = dateTimeConstants.ISO_DATE_FORMAT;

class ApplicantList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statusUpdating: false,
      statusOpen: false,
      flag: true,
      applicationItem: [],
      removeApplicant: "",
      removeAction: "",
      selected: "",
      _page: 0,
      searchStatus: false,
      clearFilter: false,
      applicant_info: [],
      resultData: [],
      filterResult: false,
      order: true,
      sort: "date",
      nameOrder: false,
      emailOrder: false,
      phoneOrder: false,
      dateOrder: true,
      areaOrder: false,
      certOrder: false,
      orderAll: "asc",
      dateSort: "desc",
      loader: "",
      keySearch: "",
      validMsg: "",
      borderError: "",
      filterOption: "",
      currentStatusUpdate: null,
      paginationLoading: false,
      isNotesOpen: false,
      selectedApplicantNote: "",
      editableNote: { note: "", id: "" },
      noteEditMode: false,
      isNoteLoading: false,
      results_per_page: localStorage.getItem("results_per_page")
        ? parseInt(localStorage.getItem("results_per_page"))
        : 30,

      applicantHash: "",
      crews: [],
      applicant_details: null,
      applicantStatusList: [],
      applicantStatus: "",
      crew: "",
      whoSelected: "",
      whoRejected: "",
      rejectionReason: "",
      date: moment(),
      time: moment(),
      isConfirmUpdateModalOpen: false,
      applicantCurrentStatus: {},
      applicantHasAnyInterviewForms: false,
    };
    this.lastCreatedNoteRef = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
    this.viewDetails = this.viewDetails.bind(this);
    this.sendMail = this.sendMail.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.chooseAction = this.chooseAction.bind(this);
    this.validate = this.validate.bind(this);
    this.updateApplicantStatus = this.updateApplicantStatus.bind(this);
    this.archived = this.archived.bind(this);
    this.hired = this.hired.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.emailFilter = this.emailFilter.bind(this);
    this.filter = this.filter.bind(this);
    this.sorting = this.sorting.bind(this);

    this.removeStatusCloseModal = this.removeStatusCloseModal.bind(this);
    this.onChangeUpdateApplicantStatus =
      this.onChangeUpdateApplicantStatus.bind(this);
    this.onChangeUpdateApplicantDate =
      this.onChangeUpdateApplicantDate.bind(this);
    this.onChangeUpdateApplicantTime =
      this.onChangeUpdateApplicantTime.bind(this);
    this.handleSearchCrewOrEmployee =
      this.handleSearchCrewOrEmployee.bind(this);
    this.onChangeSelect = this.onChangeSelect.bind(this);
    this.toggleNotes = this.toggleNotes.bind(this);
    this.onEditNoteClick = this.onEditNoteClick.bind(this);
    this.onCancelAddEditNote = this.onCancelAddEditNote.bind(this);
    this.onChangeEditNote = this.onChangeEditNote.bind(this);
    this.deleteApplicantNote = this.deleteApplicantNote.bind(this);
  }

  onChangeUpdateApplicantStatus(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  onChangeUpdateApplicantDate(date) {
    this.setState({ date });
  }

  onChangeUpdateApplicantTime(time) {
    this.setState({ time });
  }

  removeStatusCloseModal() {
    this.setState({ statusOpen: false, applicantStatus: "" });
    this.setState({
      whoRejected: "",
      rejectionReason: "",
      whoSelected: "",
      crew: "",
    });
  }

  onChangeSelect(selected, type) {
    this.setState({ [type]: selected.value });
    console.log(this.state.crew);
    if (type === "whoSelected") {
      this.setState({ whoRejected: "", rejectionReason: "" });
    } else if (type === "whoRejected") {
      this.setState({ whoSelected: "", crew: "" });
    } else if (
      (type === "applicantStatus" && selected.value === 1) ||
      selected.value === 3 ||
      selected.value === 4
    ) {
      this.setState({
        whoRejected: "",
        rejectionReason: "",
        whoSelected: "",
        crew: "",
      });
    }
  }

  async handleSearchCrewOrEmployee(value, type) {
    let data = new FormData();
    data.append("keyword", value);
    let response = [];
    switch (type) {
      case "crew":
        const crews = await this.props.dispatch(
          resourceActions.searchResource(data),
        );
        response = crews;
        break;
      case "who selected":
      case "who rejected":
        const employees = await this.props.dispatch(
          resourceActions.employeeList(data),
        );
        response = employees?.data;
        break;
      default:
        break;
    }

    const items = response?.data;
    let res = [];
    let options = [];

    if (type === "who selected" || type === "who rejected") {
      options = items.map((emp) => ({
        ...emp,
        value: emp.userid,
        label: `${emp.last_name} ${emp.first_name}`,
      }));
      return options;
    }

    for (let key in items) {
      let arr = items[key];
      if (arr.length) {
        res = res.concat(arr);
      }
    }

    options = res.map((item) => ({
      label: item.resource_name,
      value: item.id,
    }));

    return options;
  }

  loadOptions = (value, callback, type) => {
    setTimeout(async () => {
      const res = await this.handleSearchCrewOrEmployee(value, type);
      callback(res);
    }, 500);
  };

  updateApplicantStatus() {
    const {
      applicantHash,
      applicantStatus,
      crew,
      whoRejected,
      whoSelected,
      rejectionReason,
      date,
      time,
      isConfirmUpdateModalOpen,
    } = this.state;

    const isConfirmNeeded = [
      ApplicationStatuses.WAS_HIRED,
      ApplicationStatuses.NOT_SELECTED_ARCHIVE,
      ApplicationStatuses.DO_NOT_HIRE_ARCHIVE,
    ].includes(applicantStatus);

    const hasUserChangedOption =
      this.state.applicantCurrentStatus.value !== applicantStatus;

    if (
      (applicantStatus === ApplicationStatuses.NOT_SELECTED_ARCHIVE ||
        applicantStatus === ApplicationStatuses.DO_NOT_HIRE_ARCHIVE) &&
      !rejectionReason?.length
    ) {
      return toast.error("The  notes on reason for rejection can't be empty.");
    }

    if (isConfirmNeeded && hasUserChangedOption && !isConfirmUpdateModalOpen) {
      return this.setState({ isConfirmUpdateModalOpen: true });
    } else if (
      (applicantStatus === ApplicationStatuses.OFFERED_POSITION ||
        applicantStatus === ApplicationStatuses.WAS_HIRED) &&
      this?.state?.selectedApplicantNoInterviewForms
    ) {
      return toast.error(
        " The applicant hasn't filled out the interview form.",
      );
    }

    this.setState({ statusUpdating: true });

    let data = new FormData();
    data.append("hash", applicantHash);
    data.append("action", applicantStatus);

    if (isConfirmNeeded) {
      if (applicantStatus === ApplicationStatuses.WAS_HIRED) {
        data.append(
          "crew_id",
          crew ? crew : this.props?.user?.profile?.data?.crew_identifier_id,
        );
      } else if (
        applicantStatus === ApplicationStatuses.NOT_SELECTED_ARCHIVE ||
        applicantStatus === ApplicationStatuses.DO_NOT_HIRE_ARCHIVE
      ) {
        data.append("rejected_reason", rejectionReason);
      }
      data.append("performed_by", this.props?.user?.profile?.data?.id);
      data.append(
        "status_change_date",
        date && time
          ? `${moment(date).format(ISO_DATE_FORMAT)} ${moment(time).format(
              "hh:mm:ss",
            )}`
          : null,
      );
    }

    this.props
      .dispatch(applicantsActions.removeApplicant(data))
      ?.then((res) => {
        if (res?.success) {
          this.removeStatusCloseModal();
          this.setState({
            isConfirmUpdateModalOpen: false,
            statusUpdating: false,
          });
          this.componentDidMount();
          this.setState({ statusUpdating: false });
        } else {
          handleErrorResponse(res);
          this.setState({ statusUpdating: false });
        }
      });
  }

  componentDidMount() {
    if (this.props.param !== "" && this.props.from === "filter") {
      var startDate, endDate;
      if (this.props.param.start_date === undefined) {
        startDate = "";
      } else {
        startDate = this.props.param.start_date;
      }
      if (this.props.param.end_date === undefined) {
        endDate = "";
      } else {
        endDate = this.props.param.end_date;
      }
      this.setState({
        startDate,
        endDate,
        variable1: this.props.param.var1,
        variable2: this.props.param.var2,
      });
      let data = new FormData();
      data.append("startDate", startDate);
      data.append("endDate", endDate);
      data.append("variable1", this.props.param.var1);
      data.append("variable2", this.props.param.var2);
      this.props.dispatch(applicantsActions.filterApplicants(data));
    }
    // All applicants
    else {
      this.props.dispatch(
        applicantsActions.getAll(this.state.results_per_page),
      );
    }
    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(createProfileActions.getAll());
  }

  componentWillReceiveProps(nextProps) {
    window.addEventListener("scroll", this.handleScroll);
    const { applicants, user } = nextProps;

    const statuses = nextProps.listAll?.listAll?.ApplicantStatus?.map(
      (item) => ({ ...item, label: item.name }),
    );

    this.setState({
      applicantStatusList: statuses,
    });

    // Fetching Applicant list
    if (applicants.items) {
      let dataResult;
      var appInf = [];
      // for filter results
      if (
        this.props.param !== "" &&
        this.props.from === "filter" &&
        this.state.clearFilter === false
      ) {
        dataResult = applicants.items.data;
      } else {
        dataResult = applicants.items.data.data;
      }
      if (dataResult && dataResult.length) {
        if (dataResult.length > 0) {
          dataResult.map((item, index) => {
            appInf[item.hash] = item.applicant_info;
            this.setState((state) => ({
              applicant_info: { ...state.applicant_info, ...appInf },
            }));
          });
        }
      } else {
        applicants.items.data = [];
      }
      this.setState({
        resultData: Array.isArray(applicants.items.data)
          ? { data: applicants.items.data }
          : applicants.items.data,
      });
      if (
        Array.isArray(applicants.items.data) &&
        applicants.items.data.length
      ) {
        // Filters
        this.setState({
          filterResult: true,
        });
      } else {
        this.setState({
          filterResult: false,
        });
      }
    }
  }

  componentDidUpdate() {
    if (this.lastCreatedNoteRef.current) {
      this.lastCreatedNoteRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }

  canShowRecentNotes = (item) => {
    if (
      item?.notes?.length &&
      item?.hash !== this.state.selectedApplicantNote?.hash
    ) {
      return true;
    }
    return false;
  };

  openNoteEditorFromRecentNote = (note) => {
    // debugger
    this.toggleNotes(note);
  };

  // Default image function
  onImageError(e) {
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  async updateNotes(noteDetails) {
    this.setState({ isNoteLoading: true });
    const userPermissions = this.props.permission.permissions.data;

    if (!userPermissions.includes("Update Applicant Note"))
      return toast.error("You don't have permission to add/edit notes!");

    const applicantHash = this.state.noteEditMode
      ? noteDetails.applicant_hash
      : noteDetails.hash;
    const { id, note } = this.state.editableNote;

    let data = new FormData();
    data.append("applicant_hash", applicantHash);
    data.append("notes", note);
    data.append("note_id", this.state.noteEditMode ? id : "");

    let sortedApplicantsFormData = new FormData();
    if (this.state.hasSorted || this.state.filterOption) {
      sortedApplicantsFormData.append("sort", this.state.sort);
      sortedApplicantsFormData.append(
        "order",
        this.state.order ? "desc" : "asc",
      );
      sortedApplicantsFormData.append("type", this.state.filterOption);
    }

    let filteredApplicantsReqData = new FormData();
    filteredApplicantsReqData.append("startDate", this.state.startDate || "");
    filteredApplicantsReqData.append("endDate", this.state.endDate || "");
    filteredApplicantsReqData.append("variable1", this.state.variable1 || "");
    filteredApplicantsReqData.append("variable2", this.state.variable2 || "");

    const res = await this.props.dispatch(applicantsActions.updateNote(data));

    if (res?.data?.success) {
      toast.success(res.data.message);
      this.setState({
        noteEditMode: false,
        editableNote: {
          ...this.state.editableNote,
          id: "",
          note: "",
        },
      });

      if (this.props.from === "filter") {
        this.props.dispatch(
          applicantsActions.sortFilterApplicant(
            data,
            this.state.sort,
            this.state.order ? "desc" : "asc",
          ),
        );
        this.setState({ isNoteLoading: false });
        return;
      }

      if (this.state.hasSorted || this.state.filterOption) {
        this.props.dispatch(
          await applicantsActions.searchApplicants(sortedApplicantsFormData),
        );
      } else {
        await this.props.dispatch(
          applicantsActions.getAll(this.state.results_per_page),
        );
      }

      this.setState({ isNoteLoading: false });
    } else {
      const parsedRes = JSON.parse(res?.error);
      if (parsedRes.message) {
        Object.keys(parsedRes.message).forEach((key) => {
          parsedRes.message[key].forEach((message) => {
            toast.error(message);
          });
        });
      }
    }
  }

  async deleteApplicantNote(note) {
    this.setState({ isNoteLoading: true });
    const { id } = note;
    const data = new FormData();
    data.append("note_id", id);
    const res = await this.props.dispatch(
      applicantsActions.deleteNote(data, id),
    );
    if (res?.data?.success) {
      toast.success(res.data.message);
      this.setState({ isNoteLoading: false });

      let sortedApplicantsFormData = new FormData();
      if (this.state.hasSorted || this.state.filterOption) {
        sortedApplicantsFormData.append("sort", this.state.sort);
        sortedApplicantsFormData.append(
          "order",
          this.state.order ? "desc" : "asc",
        );
        sortedApplicantsFormData.append("type", this.state.filterOption);
      }

      if (this.props.from === "filter") {
        this.props.dispatch(
          applicantsActions.sortFilterApplicant(
            data,
            this.state.sort,
            this.state.order ? "desc" : "asc",
          ),
        );
        this.setState({ isNoteLoading: false });
        return;
      }

      if (this.state.hasSorted || this.state.filterOption) {
        this.props.dispatch(
          await applicantsActions.searchApplicants(sortedApplicantsFormData),
        );
      } else {
        await this.props.dispatch(
          applicantsActions.getAll(this.state.results_per_page),
        );
      }
    } else {
      this.setState({ isNoteLoading: false });
      const parsedRes = JSON.parse(res?.error);
      if (parsedRes.message) {
        Object.keys(parsedRes.message).forEach((key) => {
          parsedRes.message[key].forEach((message) => {
            toast.error(message);
          });
        });
      }
    }
  }
  // Opens model for removing applicant
  statusOpenModal(item, selectedApplicantNoInterviewForms) {
    const applicantStatus = this.props.listAll?.listAll?.ApplicantStatus?.find(
      (option) => option.value === item?.status,
    );

    this.setState({
      applicantHash: item.hash,
      applicantCurrentStatus: {
        ...applicantStatus,
        label: applicantStatus?.name,
      },
      removeApplicant: item?.hash,
      currentStatusUpdate: item?.status > -1 ? item?.status : "",
      selectedApplicantNoInterviewForms,
      statusOpen: true,
    });
  }

  // Close the popup
  statusCloseModal() {
    this.setState({
      statusOpen: false,
      statusUpdating: false,
      selectedApplicantNoInterviewForms: false,
    });
  }

  // Selecting remove option
  chooseAction(action) {
    var status = {
      denied: 0,
      received: 1,
      hire: 2,
      inreview: 3,
      offered: 4,
      donthire: 10,
      remove: 0,
    };
    this.setState({
      removeAction: action,
      selected: action,
      currentStatusUpdate: status[action],
      validMsg: "",
      borderError: "",
    });
  }

  // set active class for selected option
  isActive(value) {
    var status = {
      0: "remove",
      1: "received",
      2: "hire",
      3: "inreview",
      4: "offered",
      10: "donthire",
    };
    if (
      this.state.currentStatusUpdate > -1 &&
      status.hasOwnProperty(this.state.currentStatusUpdate)
    ) {
      if (status[this.state.currentStatusUpdate] === value) {
        return "active";
      }
    }
    return "";
    // return value === this.state.selected ? "active" : "default";
  }

  // To Archieved applicants page
  archived() {
    this.props.archived();
  }

  // To Hired applicants page
  hired() {
    this.props.hired();
  }

  // To detail page of applicant
  viewDetails(hash) {
    this.props.viewDetails(hash);
  }

  // To email applicant page
  emailFilter() {
    this.props.emailFilter();
  }

  // To filter page
  filter() {
    this.props.filter();
  }

  toggleNotes(note) {
    if (
      this.state.selectedApplicantNote?.hash &&
      this.state.selectedApplicantNote?.hash === note?.hash
    ) {
      return this.setState({ isNotesOpen: false, selectedApplicantNote: null });
    }
    this.setState({
      isNotesOpen: !this.state.isNotesOpen,
      selectedApplicantNote: note,
    });
  }

  onEditNoteClick(note) {
    this.setState({
      noteEditMode: true,
      editableNote: {
        ...this.state.editableNote,
        id: note.id,
        note: note.notes,
      },
    });
  }

  onCancelAddEditNote() {
    this.setState({ editableNote: { note: "", id: "" }, noteEditMode: false });
  }

  onChangeEditNote(value) {
    this.setState({
      editableNote: { ...this.state.editableNote, note: value },
    });
  }

  sorting(sort, e) {
    this.setState({
      order: !this.state.order,
      sort,
      loader: "",
      applicationItem: [],
      hasSorted: true,
    });
    if (sort === "name") {
      this.setState({
        nameOrder: !this.state.nameOrder,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: false,
        certOrder: false,
        jobStatusOrder: false,
        dateOrder: false,
      });
    }
    if (sort === "email") {
      this.setState({
        nameOrder: false,
        emailOrder: !this.state.emailOrder,
        phoneOrder: false,
        areaOrder: false,
        certOrder: false,
        jobStatusOrder: false,
        dateOrder: false,
      });
    }
    if (sort === "phone") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: !this.state.phoneOrder,
        areaOrder: false,
        certOrder: false,
        jobStatusOrder: false,
        dateOrder: false,
      });
    }
    if (sort === "area") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: !this.state.areaOrder,
        certOrder: false,
        jobStatusOrder: false,
        dateOrder: false,
      });
    }
    if (sort === "cert") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: false,
        certOrder: !this.state.certOrder,
        jobStatusOrder: false,
        dateOrder: false,
      });
    }
    if (sort === "date") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: false,
        certOrder: false,
        jobStatusOrder: false,
        dateOrder: !this.state.dateOrder,
      });
    }
    if (sort === "job_status") {
      this.setState({
        nameOrder: false,
        emailOrder: false,
        phoneOrder: false,
        areaOrder: false,
        certOrder: false,
        dateOrder: false,
        jobStatusOrder: !this.state.jobStatusOrder,
      });
    }
    var order;
    if (this.state.order) {
      order = "asc";
    } else {
      order = "desc";
    }
    this.setState({ orderAll: order, dateSort: order });
    if (this.props.param !== "" && this.props.from === "filter") {
      let data = new FormData();
      data.append("startDate", this.state.startDate);
      data.append("endDate", this.state.endDate);
      data.append("variable1", this.state.variable1);
      data.append("variable2", this.state.variable2);
      this.props.dispatch(
        applicantsActions.sortFilterApplicant(data, sort, order),
      );
    } else {
      let data = new FormData();
      if (this.state.keySearch) {
        data.append("keyword", this.state.keySearch);
      }
      data.append("sort", sort);
      data.append("order", order);
      data.append("results_per_page", this.state.results_per_page);
      this.props.dispatch(applicantsActions.searchApplicants(data));
    }
  }

  validate() {
    var err = false;
    if (this.state.removeAction === "") {
      return true;
    }
  }

  updateApplicantStatusErrorToast(message) {
    toast?.error(message);
    this.setState({ statusUpdating: false });
  }

  handleSearch() {
    this.setState({
      searchStatus: true,
      applicationItem: [],
    });
    let { filterOption } = this.state;
    let data = new FormData();
    data.append("type", filterOption);
    data.append("keyword", this?.state?.keySearch);
    data.append("results_per_page", this.state.results_per_page);

    // data.append("status", text);
    if (
      this.props.param !== "" &&
      this.props.from === "filter" &&
      this?.state?.keySearch !== ""
    ) {
      data.append("startDate", this.props.param.start_date);
      data.append("endDate", this.props.param.end_date);
      data.append("variable1", this.props.param.var1);
      data.append("variable2", this.props.param.var2);
      this.props.dispatch(applicantsActions.filterApplicants(data));
    } else {
      data.append("sort", this.state.sort);
      if (this.state.sort === "date") {
        data.append("order", this.state.dateSort);
      } else {
        data.append("order", this.state.orderAll);
      }
      this.props.dispatch(applicantsActions.searchApplicants(data));
      this.setState({ clearFilter: true });
    }
  }

  sendMail(email) {
    this.props.sendMail(email);
  }

  handleScroll(event) {
    return;
    var method;
    var wrap = document.getElementById("right_sidebar"),
      contentHeight = wrap.offsetHeight,
      yOffset = window.pageYOffset,
      y = yOffset + window.innerHeight,
      applicationItem = [],
      sortVariable = "",
      order;
    // Sortable field
    if (this.state.sort !== "" && this.state.sort !== "date") {
      sortVariable =
        "&sort=" + this.state.sort + "&order=" + this.state.orderAll;
    } else if (this.state.sort !== "" && this.state.sort === "date") {
      sortVariable =
        "&sort=" + this.state.sort + "&order=" + this.state.dateSort;
    }
    let { filterOption } = this.state;
    // data.append("type", filterOption);
    if (filterOption) {
      sortVariable = `${sortVariable}&type=${filterOption}`;
    }

    if (y >= contentHeight) {
      let data = this.state.resultData;

      const { permission } = this.props;
      var permList = [];
      if (permission.permissions) {
        permList = permission.permissions.data;
      }

      if (data.current_page < data.last_page && this.state.flag !== false) {
        var requestOptions = {};
        if (data.next_page_url.indexOf("search") !== -1) {
          method = "POST";
          let bdata = new FormData();
          bdata.append("keyword", this.state.keySearch);
          requestOptions = {
            method: method,
            headers: authHeader(),
            body: bdata,
          };
        } else {
          method = "GET";
          requestOptions = {
            method: method,
            headers: authHeader(),
          };
        }

        fetch(data.next_page_url + sortVariable, requestOptions)
          .then((res) => {
            return res.json();
          })
          .then((data) => {
            var appInf = [];
            data.data.data.map((item, index) => {
              appInf[item.hash] = item.applicant_info;
              this.setState((state) => ({
                applicant_info: { ...state.applicant_info, ...appInf },
              }));
            });
            this.setState({ resultData: data.data, applicant_info: appInf });
            // this.setState({
            //   applicationItem: [
            //     ...this.state.applicationItem,
            //     ...applicationItem
            //   ],
            // });
          })
          .catch((err) => console.log(err));
      }
    }
  }
  handlePageChange(page) {
    if (!page || this.state.filterResult) return;
    var current = page.selected + 1;
    var method,
      sortVariable = "";
    if (this.state.sort !== "" && this.state.sort !== "date") {
      sortVariable =
        "&sort=" + this.state.sort + "&order=" + this.state.orderAll;
    } else if (this.state.sort !== "" && this.state.sort === "date") {
      sortVariable =
        "&sort=" + this.state.sort + "&order=" + this.state.dateSort;
    }
    let { filterOption } = this.state;
    if (filterOption) {
      sortVariable = `${sortVariable}&type=${filterOption}`;
    }

    let data = this.state.resultData;

    const { permission } = this.props;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }

    var requestOptions = {};
    if (
      (data.next_page_url && data.next_page_url.indexOf("search") !== -1) ||
      (data.last_page_url && data.last_page_url.indexOf("search") !== -1)
    ) {
      method = "POST";
      let bdata = new FormData();
      bdata.append("keyword", this.state.keySearch);
      requestOptions = {
        method: method,
        headers: authHeader(),
        body: bdata,
      };
    } else {
      method = "GET";
      requestOptions = {
        method: method,
        headers: authHeader(),
      };
    }
    var _url =
      (data.next_page_url || data.last_page_url) &&
      (data.next_page_url || data.last_page_url).replace(
        /(page=)[0-9]{1,}/,
        "page=" + current,
      );

    this.setState({
      paginationLoading: true,
      _page: page.selected,
    });
    fetch(
      _url + "&results_per_page=" + this.state.results_per_page + sortVariable,
      requestOptions,
    )
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        var appInf = {};
        if (data && data.data && data.data.data.length > 0) {
          var dataResult = data.data.data || [];
          dataResult.map((item, index) => {
            appInf[item.hash] =
              item.applicant_info && item.applicant_info !== "null"
                ? item.applicant_info
                : "";
            // this.setState(state => ({
            //   applicant_info: appInf
            // }));
          });
        }
        this.setState(
          {
            applicant_info: appInf,
          },
          () => {
            this.setState({ resultData: data.data, paginationLoading: false });
          },
        );
      })
      .catch((err) => {
        this.setState({
          paginationLoading: false,
        });
      });
  }
  render() {
    const { applicants, permission } = this.props;
    const reslts_per = [5, 10, 15, 20, 30, 50, 100];
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }
    var applicationItem = [];
    const style = {
      content: {
        width: "40%",
      },
    };

    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }
    const canUserAddNotes = permList.includes("Update Applicant Note");
    const loggedInUser = this.props?.user?.profile?.data;
    const isSupAdmin = loggedInUser?.is_admin === 2;

    const tooltip = (
      <Tooltip id="tooltip">
        <strong style={{ color: "#fff" }}>No interview forms added.</strong>
      </Tooltip>
    );

    if (applicants.items) {
      let dataResult = [];
      if (
        this.props.param !== "" &&
        this.props.from === "filter" &&
        this.state.clearFilter === false
      ) {
        dataResult = applicants.items.data;
      } else {
        if (applicants.items.data.length === 0) {
          dataResult = applicants.items.data;
        } else {
          dataResult = applicants.items.data.data;
        }
      }
      var nameClass,
        emailClass,
        phoneClass,
        dateClass,
        areaClass,
        certClass,
        jobStatusClass;
      if (this.state.sort === "name") {
        if (this.state.nameOrder && this.state.sort === "name") {
          nameClass = "sort-arrow desc";
        } else {
          nameClass = "sort-arrow asc";
        }
        emailClass =
          phoneClass =
          dateClass =
          areaClass =
          certClass =
          jobStatusClass =
            "sort-arrow";
      }
      if (this.state.sort === "email") {
        if (this.state.emailOrder && this.state.sort === "email") {
          emailClass = "sort-arrow desc";
        } else {
          emailClass = "sort-arrow asc";
        }
        nameClass =
          phoneClass =
          dateClass =
          areaClass =
          certClass =
          jobStatusClass =
            "sort-arrow";
      }
      if (this.state.sort === "phone") {
        if (this.state.phoneOrder && this.state.sort === "phone") {
          phoneClass = "sort-arrow desc";
        } else {
          phoneClass = "sort-arrow asc";
        }
        nameClass =
          emailClass =
          dateClass =
          areaClass =
          certClass =
          jobStatusClass =
            "sort-arrow";
      }
      if (this.state.sort === "date") {
        if (this.state.dateOrder && this.state.sort === "date") {
          dateClass = "sort-arrow desc";
        } else {
          dateClass = "sort-arrow asc";
        }
        nameClass =
          emailClass =
          areaClass =
          phoneClass =
          certClass =
          jobStatusClass =
            "sort-arrow";
      }
      if (this.state.sort === "area") {
        if (this.state.areaOrder && this.state.sort === "area") {
          areaClass = "sort-arrow desc";
        } else {
          areaClass = "sort-arrow asc";
        }
        nameClass =
          emailClass =
          dateClass =
          phoneClass =
          certClass =
          jobStatusClass =
            "sort-arrow";
      }
      if (this.state.sort === "cert") {
        if (this.state.certOrder && this.state.sort === "cert") {
          certClass = "sort-arrow desc";
        } else {
          certClass = "sort-arrow asc";
        }
        nameClass =
          emailClass =
          dateClass =
          phoneClass =
          areaClass =
          jobStatusClass =
            "sort-arrow";
      }
      if (this.state.sort === "job_status") {
        if (this.state.jobStatusOrder && this.state.sort === "job_status") {
          jobStatusClass = "sort-arrow desc";
        } else {
          jobStatusClass = "sort-arrow asc";
        }
        nameClass =
          emailClass =
          areaClass =
          phoneClass =
          certClass =
            "sort-arrow";
      }

      if (dataResult) {
        const applicantsList = this.state.resultData?.data;

        return (
          <div className="applicants_container applicant_listing">
            <div className="page_title">
              <h1>Applicants</h1>
              <div className="top_btn_group flex-group">
                {/* {permList.includes("Manage Applicants") &&
                  permList.includes("Add New Applicant") && (
                    <Link to={"/application"} className="new_applicants button">
                      Add New Applicant
                    </Link>
                  )} */}

                {permList.includes("Manage Applicants") &&
                  permList.includes("Email Applicants") && (
                    <button
                      className="button"
                      onClick={this.emailFilter.bind(this)}
                    >
                      Email Applicants
                    </button>
                  )}

                <button className="button" onClick={this.filter.bind(this)}>
                  Filter
                </button>

                {[
                  contactEmailConstants.HR,
                  contactEmailConstants.JIMMY_DO,
                  contactEmailConstants.TEST_ADMIN,
                ].includes(loggedInUser.email) ? (
                  <Link
                    to={"/former-employed-applicants"}
                    className="former_applicants"
                  >
                    <button className="button">
                      Former Employed Applicants
                    </button>
                  </Link>
                ) : (
                  <></>
                )}

                {permList.includes("Manage Applicants") &&
                  permList.includes("View Hired Applicants") && (
                    <button
                      className="button"
                      onClick={this.archived.bind(this)}
                    >
                      Archived Applicants
                    </button>
                  )}
              </div>
            </div>

            <div
              className={
                "applicants_inner_head inner_head inner_head_mob " +
                (this.state.filterResult ? "hide-filters" : "")
              }
            >
              <div className="col-xs-12 col-sm-12 col-md-6">
                <select
                  name="filters"
                  className="applicant_filter"
                  value={this.state.filterOption}
                  onChange={(event) => {
                    this.setState(
                      {
                        filterOption: event.target.value,
                      },
                      () => {
                        this.handleSearch("");
                      },
                    );
                  }}
                >
                  <option value="">All</option>
                  <option value="received">Received</option>
                  <option value="inreview">In Review</option>
                  <option value="offered">Offered Position</option>
                </select>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-6">
                <div className="right_inputs_wrapper">
                  <div className="results_per_page_wrapper">
                    Results per page
                    <select
                      value={this.state.results_per_page}
                      onChange={($event) => {
                        var value = $event.target.value;
                        this.setState(
                          {
                            results_per_page: value,
                          },
                          () => {
                            this.handlePageChange.call(this, {
                              selected: this.state._page,
                            });
                          },
                        );

                        localStorage.setItem("results_per_page", value);
                      }}
                    >
                      {reslts_per &&
                        reslts_per.map((item, index) => {
                          return (
                            <option
                              //selected={item === this.state.results_per_page}
                              key={"results_per_page" + index}
                              value={item}
                            >
                              {item}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  <div className="applicant_search_wrapper">
                    <input
                      type="text"
                      className="applicant_search"
                      value={this.state.keySearch}
                      name="keyword"
                      placeholder="Search..."
                      onChange={(e) =>
                        this.setState({ keySearch: e.target.value })
                      }
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          this.handleSearch(); // Trigger search on Enter key press
                        }
                      }}
                      autoComplete="off"
                    />

                    <button
                      style={{ height: "38px", marginLeft: "5px" }}
                      onClick={this.handleSearch}
                      className="button"
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="inner_container col-lg-12 applicants_list_page"
              id="applicants_inner"
            >
              <div
                className="applicants_list"
                id="applicants_list"
                onScroll={this.handleScroll}
              >
                <div className="appln_list_header">
                  <div
                    className="list_item_head appln_name"
                    onClick={this.sorting.bind(this, "name")}
                  >
                    Name
                    <span className={nameClass} />
                  </div>
                  <div
                    className="list_item_head appln_email"
                    onClick={this.sorting.bind(this, "email")}
                  >
                    Email
                    <span className={emailClass} />
                  </div>
                  <div
                    className="list_item_head appln_phone"
                    onClick={this.sorting.bind(this, "phone")}
                  >
                    Cell Phone
                    <span className={phoneClass} />
                  </div>
                  <div
                    className="list_item_head appln_date"
                    onClick={this.sorting.bind(this, "date")}
                  >
                    Date
                    <span className={dateClass} />
                  </div>
                  <div
                    className="list_item_head appln_area"
                    // onClick={this.sorting.bind(this, "area")}
                  >
                    Area
                    <span className={areaClass} />
                  </div>
                  <div
                    className="list_item_head"
                    // onClick={this.sorting.bind(this, "cert")}
                  >
                    Cert. Status
                    <span className={certClass} />
                  </div>
                  <div
                    className="list_item_head appln_status"
                    onClick={this.sorting.bind(this, "job_status")}
                  >
                    Status
                    <span className={jobStatusClass} />
                  </div>
                  <div className="list_item_head appln_status">
                    Archived Status
                  </div>
                </div>
                <div className="items-wrapper">
                  {this.state.paginationLoading && (
                    <div className="items-overlay" />
                  )}
                  {applicantsList?.map((item, index) => {
                    return (
                      <div
                        className="applicant_item"
                        key={index + 25}
                        data-test="df"
                      >
                        {item.status === 3 && (
                          <span className="aplication-status-indicator">
                            <img
                              src={
                                require("../assets/images/review.png").default
                              }
                              className="in-review-ico"
                              alt="review"
                            />
                          </span>
                        )}
                        {item.status === 1 && (
                          <span className="aplication-status-indicator">
                            <img
                              src={
                                require("../assets/images/tick_round.png")
                                  .default
                              }
                              alt="tick"
                            />
                          </span>
                        )}
                        {item.status === 4 && (
                          <span className="offer-posted aplication-status-indicator">
                            <img
                              src={
                                require("../assets/images/offeredposition.svg")
                                  .default
                              }
                              alt="Offered Position"
                            />
                          </span>
                        )}

                        <div className="basic_info">
                          <div className="appln_name">
                            {item.first_name + " " + item.last_name}
                            {item.reapplied_status == 1 && item.status == 1 && (
                              <span className="badge">NEW</span>
                            )}
                            {item.interview_form_count === 0 && (
                              <span className="no-interview-form-icon">
                                <OverlayTrigger
                                  placement="top"
                                  overlay={tooltip}
                                >
                                  <Warning />
                                </OverlayTrigger>
                              </span>
                            )}
                          </div>
                          <div className="appln_email">
                            {item.email_address ? (
                              <a
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                                className="phone_email_link"
                                href={`mailto:${item.email_address}`}
                                target="_top"
                              >
                                {item.email_address}
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="appln_phone">
                            {item.phone_number ? (
                              <a
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                                className="phone_email_link"
                                href={`tel:${item.phone_number}`}
                                target="_top"
                              >
                                {formatPhoneNumber(item.phone_number)}
                              </a>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="appln_date">
                            {formatDate(item.date)}
                          </div>
                          <div className="appln_area">{item.area}</div>
                          <div className="appln_cert_status">
                            {item.cert_status}
                          </div>
                          <div className="appln_status">
                            {getApplicantStatus[item.status]}
                          </div>
                          <div className="appln_status">
                            {item.do_not_hire && (
                              <span class="fs-badge fs-badge-red">
                                Do Not Hire
                              </span>
                            )}
                            {item.not_selected && (
                              <span class="fs-badge fs-badge-orange">
                                Not Selected
                              </span>
                            )}
                            {!(item.do_not_hire || item.not_selected) && (
                              <>NA</>
                            )}
                          </div>
                        </div>

                        <div className="applicant-card-footer">
                          <div className="applicant-notes-list">
                            <span className="applicant-note-icon">
                              <NoteIcon />
                            </span>
                            <span
                              className={`applicants-notes-toggle ${
                                item.hash ===
                                this.state.selectedApplicantNote?.hash
                                  ? "applicant-notes-open"
                                  : ""
                              }`}
                              onClick={() => this.toggleNotes(item)}
                            >
                              Notes ({item?.notes?.length})
                            </span>

                            {item.hash ===
                              this.state.selectedApplicantNote?.hash && (
                              <div className="note-editor">
                                <div className="add-note-editor">
                                  {(canUserAddNotes || isSupAdmin) &&
                                    !this.state.noteEditMode && (
                                      <AddEditNote
                                        note={item}
                                        value={this.state.editableNote?.note}
                                        onChange={this.onChangeEditNote}
                                        onCancel={this.onCancelAddEditNote}
                                        disabled={
                                          !this.state.editableNote?.note
                                        }
                                        onSaveNote={this.updateNotes}
                                        loading={this.state.isNoteLoading}
                                      />
                                    )}
                                </div>
                                <div className="applicant-notes">
                                  {item?.notes?.map((note, index) =>
                                    note.id === this.state.editableNote?.id ? (
                                      <AddEditNote
                                        disabled={
                                          !this.state.editableNote?.note
                                        }
                                        key={note.id}
                                        note={note}
                                        value={this.state.editableNote?.note}
                                        onChange={this.onChangeEditNote}
                                        onCancel={this.onCancelAddEditNote}
                                        onSaveNote={this.updateNotes}
                                        loading={this.state.isNoteLoading}
                                      />
                                    ) : (
                                      <div
                                        ref={
                                          index === item?.notes.length - 1
                                            ? this.lastCreatedNoteRef
                                            : null
                                        }
                                      >
                                        <ApplicantNoteCard
                                          key={note.id}
                                          note={note}
                                          onEditNoteClick={this.onEditNoteClick}
                                          onDeleteNoteClick={
                                            this.deleteApplicantNote
                                          }
                                          loading={this.state.isNoteLoading}
                                          canEditOrDelete={
                                            note.author.id ===
                                              loggedInUser?.id || isSupAdmin
                                          }
                                        />
                                      </div>
                                    ),
                                  )}
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="applicant-actions">
                            {permList.includes("Manage Applicants") &&
                              permList.includes("Email Applicants") && (
                                <>
                                  <img
                                    alt="mail"
                                    src={
                                      require("../assets/images/mail.png")
                                        .default
                                    }
                                    onClick={this.sendMail.bind(
                                      this,
                                      item.email_address,
                                    )}
                                    className="applicant-card-action-button"
                                  />
                                </>
                              )}
                            <>
                              <Link
                                to={"/applicant-view/" + item.hash}
                                target="_blank"
                              >
                                <img
                                  alt="view"
                                  src={
                                    require("../assets/images/view.png").default
                                  }
                                  className="applicant-card-action-button"
                                />
                              </Link>
                            </>
                            <img
                              alt="Update Applicant"
                              src={
                                require("../assets/images/update_applicant.png")
                                  .default
                              }
                              className="applicant-card-action-button"
                              onClick={this.statusOpenModal.bind(
                                this,
                                item,
                                !item?.interview_form_count,
                              )}
                            />
                          </div>
                          {this.canShowRecentNotes(item) && (
                            <div className="recent-applicant-note">
                              <ApplicantNoteCard
                                key={item?.notes[0]?.id}
                                note={item?.notes[0]}
                                onEditNoteClick={this.onEditNoteClick}
                                onDeleteNoteClick={this.deleteApplicantNote}
                                loading={this.state.isNoteLoading}
                                canEditOrDelete={
                                  item?.notes[0]?.author?.id ===
                                    loggedInUser?.id || isSupAdmin
                                }
                                toggleEditNoteFromRecent={() =>
                                  this.openNoteEditorFromRecentNote(item)
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    );
                  })}

                  {!this.state.filterResult &&
                    this.state.resultData.data &&
                    this.state.resultData.data.length > 0 && (
                      <div className="customised-pagination">
                        <ReactPaginate
                          previousLabel={""}
                          nextLabel={""}
                          breakLabel={"..."}
                          breakClassName={"break-me"}
                          pageCount={this.state.resultData.last_page}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          onPageChange={this.handlePageChange.bind(this)}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                        />
                      </div>
                    )}
                  {(!this.state.resultData.data ||
                    !this.state.resultData.data.length) && (
                    <div key="noResult" className="applicant_item light-box">
                      <div className="basic_info">
                        <p className="noResult">No search results were found</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <Modal
              isOpen={this.state.statusOpen}
              className="update-applicant-status-modal"
            >
              <div className="modal-head">
                <h4>UPDATE APPLICANT STATUS</h4>
              </div>

              <div className="modal-body applicant-status-modal-body">
                {this?.state?.selectedApplicantNoInterviewForms && (
                  <NoInterviewFormAlert />
                )}

                {this.state?.applicantStatusList && (
                  <div className="status-select input-holder 1">
                    <span>Status</span>
                    <Select
                      options={this.state.applicantStatusList}
                      defaultValue={this.state.applicantCurrentStatus}
                      onChange={(selection) =>
                        this.onChangeSelect(selection, "applicantStatus")
                      }
                      classNamePrefix="select"
                    />
                  </div>
                )}

                {this.state.applicantStatus ===
                  ApplicationStatuses.WAS_HIRED && (
                  <>
                    <div className="crew-select input-holder">
                      <span>Crew</span>
                      <AsyncSelect
                        loadOptions={(value, callback) =>
                          this.loadOptions(value, callback, "crew")
                        }
                        onChange={(selection) =>
                          this.onChangeSelect(selection, "crew")
                        }
                        placeholder="Search"
                        classNamePrefix="select"
                        // isDisabled={true} //toggling disable on client request
                        defaultValue={{
                          label: this.props?.user?.profile?.data?.crew_name,
                          value:
                            this.props?.user?.profile?.data?.crew_identifier_id,
                        }}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                    <div className="who-select input-holder">
                      <span>Who selected them?</span>
                      <AsyncSelect
                        loadOptions={(value, callback) =>
                          this.loadOptions(value, callback, "who selected")
                        }
                        onChange={(selection) =>
                          this.onChangeSelect(selection, "whoSelected")
                        }
                        placeholder="Search"
                        classNamePrefix="select"
                        isDisabled={true}
                        defaultValue={{
                          label:
                            this.props?.user?.profile?.data
                              ?.full_name_last_first,
                          value: this.props?.user?.profile?.data?.id,
                        }}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                  </>
                )}

                {(this.state.applicantStatus ===
                  ApplicationStatuses.DO_NOT_HIRE_ARCHIVE ||
                  this.state.applicantStatus ===
                    ApplicationStatuses.NOT_SELECTED_ARCHIVE) && (
                  <>
                    <div className="who-select input-holder">
                      <span>Who rejected them?</span>
                      <AsyncSelect
                        loadOptions={(value, callback) =>
                          this.loadOptions(value, callback, "who rejected")
                        }
                        onChange={(selection) =>
                          this.onChangeSelect(selection, "whoRejected")
                        }
                        placeholder="Search"
                        classNamePrefix="select"
                        isDisabled={true}
                        defaultValue={{
                          label:
                            this.props?.user?.profile?.data
                              ?.full_name_last_first,
                          value: this.props?.user?.profile?.data?.id,
                        }}
                        components={{
                          DropdownIndicator: () => null,
                          IndicatorSeparator: () => null,
                        }}
                      />
                    </div>
                    <div className="rejection-notes">
                      <span>Notes on why the application was rejected</span>
                      <textarea
                        rows="5"
                        value={this.state.rejectionReason}
                        onChange={(e) =>
                          this.setState({ rejectionReason: e.target.value })
                        }
                      />
                    </div>
                  </>
                )}

                {(this.state.applicantStatus ===
                  ApplicationStatuses.WAS_HIRED ||
                  this.state.applicantStatus ===
                    ApplicationStatuses.DO_NOT_HIRE_ARCHIVE ||
                  this.state.applicantStatus ===
                    ApplicationStatuses.NOT_SELECTED_ARCHIVE) && (
                  <div className="date-time input-holder">
                    <span>Date and time</span>
                    <DateAndTimePicker
                      date={this.state.date}
                      onChangeDate={this.onChangeUpdateApplicantDate}
                      time={this.state.time}
                      onChangeTime={this.onChangeUpdateApplicantTime}
                      disabled={true}
                    />
                  </div>
                )}

                <>
                  <div className="button-holder button-holder--right">
                    <button
                      disabled={false}
                      className="button grey"
                      onClick={this.removeStatusCloseModal.bind(this)}
                    >
                      Cancel
                    </button>
                    <button
                      disabled={
                        this?.state?.statusUpdating ||
                        this.state.applicantStatus === ""
                      }
                      className="button"
                      onClick={this.updateApplicantStatus.bind(this)}
                    >
                      Update
                    </button>
                  </div>
                </>
              </div>
            </Modal>

            <Modal
              isOpen={this.state.isConfirmUpdateModalOpen}
              className="update-applicant-status-modal update-applicant-status-modal--sec"
            >
              <div className="modal-head">
                <h4 className="text-left">Confirm Status Update</h4>
              </div>
              <div className="modal-body applicant-status-modal-body">
                <p>
                  {" "}
                  An email notification will be sent to both the applicant and
                  the respective authority.
                </p>
                <>
                  <div className="button-holder button-holder--right">
                    <button
                      disabled={false}
                      className="button grey"
                      onClick={() =>
                        this.setState({ isConfirmUpdateModalOpen: false })
                      }
                    >
                      Cancel
                    </button>
                    <button
                      disabled={this?.state?.statusUpdating}
                      className="button"
                      onClick={this.updateApplicantStatus.bind(this)}
                    >
                      Confirm
                    </button>
                  </div>
                </>
              </div>
            </Modal>
          </div>
        );
      }
    }

    // Showing loader
    return (
      <div className="applicants_container applicant_listing">
        <div className="page_title">
          <h1>Applicants</h1>
          <div className="top_btn_group flex-group">
            {/* {permList.includes("Manage Applicants") &&
              permList.includes("View Hired Applicants") && (
                <button className="button" onClick={this.hired.bind(this)}>
                  Hired/Saved Applicants
                </button>
              )} */}

            {/* {permList.includes("Manage Applicants") &&
              permList.includes("Add New Applicant") && (
                <Link to={"/application"} className="new_applicants button">
                  Add New Applicant
                </Link>
              )} */}

            {permList.includes("Manage Applicants") &&
              permList.includes("Email Applicants") && (
                <button
                  className="button"
                  onClick={this.emailFilter.bind(this)}
                >
                  Email Applicants
                </button>
              )}

            <button className="button" onClick={this.filter.bind(this)}>
              Filter
            </button>

            <button className="button" onClick={this.archived.bind(this)}>
              Archived Applicants
            </button>
          </div>
        </div>

        <div className="inner_container col-lg-12" id="applicants_inner">
          <center
            id="feedLoader"
            className={this.state.loader ? this.state.loader : ""}
          >
            <img
              alt="Loader"
              src={require("../assets/images/loader.gif").default}
            />
          </center>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { applicants, permission, user, createProfile: listAll } = state;
  return {
    applicants,
    permission,
    listAll,
    user,
  };
}

const connectedHomePage = connect(mapStateToProps)(ApplicantList);
export { connectedHomePage as ApplicantList };
