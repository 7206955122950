import React, { Component, Fragment } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { config } from "_helpers/config";
import _ from "lodash";
import DatePicker from "react-datepicker";
import { docsActions } from "_actions/docs.actions";
import { toast } from "react-toastify";
import { SignPad } from "Docs/Components";
import moment from "moment";
import { FormNav } from "./FormNav";
import { navigate, validate } from "./formHandlers";
import logo from "assets/images/logo-with-tagline.png";
import { dateTimeConstants } from "_constants";

class OnDutyMealPeriodAgreement extends Component {
  constructor(props) {
    super(props);
    const options = {
      timeZone: dateTimeConstants.PST_TIMEZONE,
      month: dateTimeConstants.TWO_DIGIT_FORMAT,
      day: dateTimeConstants.TWO_DIGIT_FORMAT,
      year: dateTimeConstants.NUMERIC_FORMAT,
    };
    const formattedDate = new Date().toLocaleDateString(
      dateTimeConstants.LOCALE_DATE_STRING_FORMAT,
      options,
    );
    this.handleDateChange = this.handleDateChange.bind(this);
    this.state = {
      rehire_doc: false,
      doc: {},
      form: {
        allow_submit: false,
        // Dates
        on_duty_meal_period_agreement_date: formattedDate,
        on_duty_meal_period_agreement_revocation_date: formattedDate,

        // Employee Signatures
        on_duty_meal_period_agreement_employee_signature: null,
        on_duty_meal_period_agreement_revocation_employee_signature: null,

        // Employee names
        on_duty_meal_period_agreement_employee_name: null,
        on_duty_meal_period_agreement_revocation_employee_name: null,
      },
      errors: {},
      form_edited: false,
    };
    // Handlers
    this.handleFormChange = this.handleFormChange.bind(this);
    this.validate = validate.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleSignature = this.handleSignature.bind(this);
    this.navigate = navigate.bind(this);
    this.goBack = this.goBack.bind(this);
    // Refs
    this.form = React.createRef();
    this.signContainer = React.createRef();
  }
  allowFormSubmission() {
    let allow_submit = false;
    let meal_period_agreement =
      this.state.form.on_duty_meal_period_agreement_date &&
      this.state.form.on_duty_meal_period_agreement_employee_signature &&
      this.state.form.on_duty_meal_period_agreement_employee_name;
    let meal_period_agreement_revocation =
      this.state.form.on_duty_meal_period_agreement_revocation_date &&
      this.state.form
        .on_duty_meal_period_agreement_revocation_employee_signature &&
      this.state.form.on_duty_meal_period_agreement_revocation_employee_name;

    if (meal_period_agreement && meal_period_agreement_revocation) {
      allow_submit = false;
    } else if (meal_period_agreement || meal_period_agreement_revocation) {
      allow_submit = true;
    }

    this.setState({
      form: {
        ...this.state.form,
        allow_submit,
      },
    });
    return {
      meal_period_agreement,
      meal_period_agreement_revocation,
    };
  }
  // Handle Datepicker changes
  handleDateChange(field, date) {
    let nextState = this.state;
    if (date) {
      _.set(nextState, field, date.format("MM/DD/YYYY"));
    }
    this.setState({
      ...nextState,
      errors: {
        [field]: "",
      },
    });
    this.allowFormSubmission();
  }
  // Handle Form Change
  handleFormChange(e) {
    // safety check for datepicker
    if (e.target && e.target.attributes["field"]) {
      let field = e.target.attributes["field"].value;
      let nextState = this.state;
      let { name, value, checked, type } = e.target;
      if (type == "checkbox") {
        _.set(nextState, field, checked);
      } else {
        _.set(nextState, field, value);
      }
      this.setState({
        ...nextState,
        errors: {
          ...this.state.errors,
          [field]: "",
        },
      });
      this.allowFormSubmission();
    }
    !this.state.form_edited && this.setState({ form_edited: true });
  }
  submitForm(e) {
    e.preventDefault();
    // Check if required set of fields are filled
    const agreementOrRevocationResponse = this.allowFormSubmission();
    if (!this.state.form.allow_submit) {
      toast.error(
        "Please fill out either the Agreement or Revocation section for On-Duty Meal Period Form.",
      );
      return;
    }
    let err = this.validate();
    if (err) {
      return;
    }
    let { form, doc } = this.state;
    let data = {
      id: doc.id,
      doc_type: doc.docs_type_id,
      form,
    };
    if (agreementOrRevocationResponse.meal_period_agreement) {
      // If meal waiver has been filled, make withdrawal fields null
      data.form = {
        ...data.form,
        on_duty_meal_period_agreement_revocation_date: null,
        on_duty_meal_period_agreement_revocation_employee_signature: null,
        on_duty_meal_period_agreement_revocation_employee_name: null,
      };
    } else if (agreementOrRevocationResponse.meal_period_agreement_revocation) {
      // If meal waiver withdrawal has been filled, make acceptance fields null
      data.form = {
        ...data.form,
        on_duty_meal_period_agreement_date: null,
        on_duty_meal_period_agreement_employee_signature: null,
        on_duty_meal_period_agreement_employee_name: null,
      };
    }
    if (this.props.adminMode) {
      data.admin_mode = this.props.adminMode;
    }
    this.props.single_doc
      ? (data.single_doc = true)
      : (data.single_doc = false);
    this.props.rehire ? (data.rehire = true) : (data.rehire = false);
    this.props.dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (this.props.mobileMode && !this.props.lastPage) {
          this.props.setScreen("sidebar");
          this.navigate("next", this.props.rehire);
        } else if (this.props.lastPage) {
          if (this.props.adminMode) {
            this.props.history.push(`/docs`);
          } else {
            this.props.history.push(`/dashboard`);
          }
        } else {
          this.navigate("next", this.props.rehire);
        }
      } else {
        if (this.props.mobileMode) this.props.toggleErrorPopup(res.message);
        else toast.error(res.message);
      }
    });
  }
  handleSignature(field, employee_signature) {
    let nextState = this.state;
    _.set(nextState, field, employee_signature);
    this.setState({
      ...nextState,
      errors: {
        ...this.state.errors,
        [field]: "",
      },
    });
    this.allowFormSubmission();
  }
  // GET FORM data from api with id passed in props
  getFormData(id) {
    this.props.dispatch(docsActions.getForm(id)).then((res) => {
      if (res.success) {
        const currentState = this.state;
        let { form, user } = res.data;
        // date needs to a moment object or Datepicker breaks.
        form.on_duty_meal_period_agreement_date =
          form.on_duty_meal_period_agreement_date ||
          currentState.form.on_duty_meal_period_agreement_date;
        form.on_duty_meal_period_agreement_revocation_date =
          form.on_duty_meal_period_agreement_revocation_date ||
          currentState.form.on_duty_meal_period_agreement_revocation_date;

        if (!form?.on_duty_meal_period_agreement_employee_name) {
          form.on_duty_meal_period_agreement_employee_name =
            user?.full_name ?? "";
        }
        if (!form?.on_duty_meal_period_agreement_revocation_employee_name) {
          form.on_duty_meal_period_agreement_revocation_employee_name =
            user?.full_name ?? "";
        }

        // spread the data coming from API into state
        this.setState({
          form,
        });
        this.allowFormSubmission();
      }
    });
  }
  goBack() {
    this.props.resetForm();
    if (this.state.form_edited) {
      this.props.toggleCloseConfirmation(true);
    } else {
      this.props.setScreen("sidebar");
    }
  }
  componentDidMount() {
    let docSlug = this.props.match.params.doc;
    let doc;
    let { activeForm } = this.props.docs;
    // if (this.props.rehire) {
    //   doc = this.props.docs.userDocs.find(
    //     (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
    //   );
    // } else {
    //   doc = this.props.docs.userDocs.find((doc) => doc.slug === docSlug && doc.docs_categories_id == 1);
    // }

    if (this.props.rehire) {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id === 2,
      );
    } else {
      doc = this.props.docs.userDocs.find(
        (doc) => doc.slug === docSlug && doc.docs_categories_id == 1,
      );
    }
    const height = this.signContainer.clientHeight;
    const width = this.signContainer.clientWidth;
    this.setState({
      doc,
      sign: {
        height: height,
        width: width,
      },
    });
    this.getFormData(doc.id);
  }
  render() {
    let { form, errors } = this.state;
    let { adminMode } = this.props;
    return (
      <div className="doc_info-rh">
        {/* Title */}
        <div className="doc-title meal-title">
          <img src={logo} className="doc-title-img" />
          <span className="close-button" onClick={this.goBack}>
            <img src={`${config.assetUrl}icons/back-btn.svg`} />
          </span>
          <h3>On-Duty Meal Period Agreement</h3>
        </div>
        <div className="doc_info-form">
          <form
            className="info-form mpn"
            ref={this.form}
            onChange={this.handleFormChange}
            onSubmit={this.submitForm}
            name="mpn"
            noValidate
          >
            <div className="row">
              <div className="col-md-12">
                <p>
                  I understand that under California law I am entitled to a
                  30-minute duty-free meal break if I work five or more hours in
                  a work day.
                </p>
                <p>
                  I understand and agree that the nature of my work as a
                  Firestorm Wildland Fire Suppression, Inc. employee prevents me
                  from being relieved of all duties and requires me to remain
                  on-duty during meal periods. I voluntarily agree to work an
                  on-duty meal period. I understand that I will be given the
                  opportunity to eat a meal while on duty and that I will be
                  paid for this time. Finally, I understand that, by signing
                  this on-duty meal period agreement, I will not be entitled to
                  any additional pay associated with the meal period.
                </p>
                <p>
                  I understand that I may revoke this agreement at any time by
                  providing written notice in advance to Human Resources. As an
                  option, I further understand that I may revoke this agreement
                  by signing the revocation section below and returning this
                  Agreement to Human Resources. I also understand that I may
                  work on-duty meal periods after revoking this agreement by
                  signing a new on-duty meal period agreement.
                </p>
              </div>
            </div>
            {/*row ends*/}
            <div
              className="row form-row no_border"
              style={{ paddingBottom: 0 }}
            >
              <div className="col" style={{ marginBottom: 0 }}>
                <div className="inputs-col">
                  <div className="inputs-head">Employee's Signature</div>
                  <div className="inputs-items">
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className="sign_here"
                    >
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              "form.on_duty_meal_period_agreement_employee_signature",
                            )}
                            signature={
                              form.on_duty_meal_period_agreement_employee_signature
                            }
                            disabled={adminMode}
                          />
                          <input
                            type="hidden"
                            value={
                              form.on_duty_meal_period_agreement_employee_signature
                            }
                            name="on_duty_meal_period_agreement_employee_signature"
                            field="form.on_duty_meal_period_agreement_employee_signature"
                          ></input>
                          {errors[
                            "form.on_duty_meal_period_agreement_employee_signature"
                          ] && (
                            <label
                              htmlFor="on_duty_meal_period_agreement_employee_signature"
                              className="error_label"
                            >
                              {
                                errors[
                                  "form.on_duty_meal_period_agreement_employee_signature"
                                ]
                              }
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">Date</div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors["form.on_duty_meal_period_agreement_date"] ? "error" : ""} w-100`}
                      name="on_duty_meal_period_agreement_date"
                      id="on_duty_meal_period_agreement_date"
                      selected={
                        form.on_duty_meal_period_agreement_date
                          ? moment(form.on_duty_meal_period_agreement_date)
                          : null
                      }
                      field="form.on_duty_meal_period_agreement_date"
                      placeholder="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(
                        this,
                        "form.on_duty_meal_period_agreement_date",
                      )}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    />
                    {errors["form.on_duty_meal_period_agreement_date"] && (
                      <label
                        htmlFor="on_duty_meal_period_agreement_date"
                        className="error_label"
                      >
                        {errors["form.on_duty_meal_period_agreement_date"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row no_border" style={{ paddingTop: 0 }}>
              <div className="col">
                <div className="inputs-col" style={{ width: "50%" }}>
                  {/*single input */}
                  <div className="inputs-head">Employee’s Printed Name</div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors["form.on_duty_meal_period_agreement_employee_name"] ? "error" : ""}`}
                      name="on_duty_meal_period_agreement_employee_name"
                      id="on_duty_meal_period_agreement_employee_name"
                      value={form.on_duty_meal_period_agreement_employee_name}
                      field="form.on_duty_meal_period_agreement_employee_name"
                      placeholder="- Enter here -"
                    />
                    {errors[
                      "form.on_duty_meal_period_agreement_employee_name"
                    ] && (
                      <label
                        htmlFor="on_duty_meal_period_agreement_employee_name"
                        className="error_label"
                      >
                        {
                          errors[
                            "form.on_duty_meal_period_agreement_employee_name"
                          ]
                        }
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "160px",
                borderTop: "#dadada 1px solid",
                borderBottom: "#dadada 1px solid",
              }}
            >
              <p>-- OR --</p>
            </div>
            <div
              class="row"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <div
                class="col-md-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <h3 style={{ fontWeight: "600" }}>
                  On-Duty Meal Period Agreement Revocation
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>
                  I revoke my On-Duty Meal Period Agreement. I acknowledge that
                  this revocation is not complete until I sign below and return
                  this form to Human Resources. I also further understand that
                  this revocation only applies to meal periods to which I
                  otherwise would be entitled after I submit this signed
                  revocation to Human Resources.
                </p>
              </div>
            </div>
            {/*row ends*/}
            <div
              className="row form-row no_border"
              style={{ paddingBottom: 0 }}
            >
              <div className="col" style={{ marginBottom: 0 }}>
                <div className="inputs-col">
                  <div className="inputs-head">Employee's Signature</div>
                  <div className="inputs-items">
                    <div
                      ref={(element) => {
                        this.signContainer = element;
                      }}
                      className="sign_here"
                    >
                      {this.state.sign && (
                        <Fragment>
                          <SignPad
                            height={this.state.sign.height}
                            width={this.state.sign.width}
                            handleSignature={this.handleSignature.bind(
                              this,
                              "form.on_duty_meal_period_agreement_revocation_employee_signature",
                            )}
                            signature={
                              form.on_duty_meal_period_agreement_revocation_employee_signature
                            }
                            disabled={adminMode}
                          />
                          <input
                            type="hidden"
                            value={
                              form.on_duty_meal_period_agreement_revocation_employee_signature
                            }
                            name="on_duty_meal_period_agreement_revocation_employee_signature"
                            field="form.on_duty_meal_period_agreement_revocation_employee_signature"
                          ></input>
                          {errors[
                            "form.on_duty_meal_period_agreement_revocation_employee_signature"
                          ] && (
                            <label
                              htmlFor="on_duty_meal_period_agreement_revocation_employee_signature"
                              className="error_label"
                            >
                              {
                                errors[
                                  "form.on_duty_meal_period_agreement_revocation_employee_signature"
                                ]
                              }
                            </label>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">Date</div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${errors["form.on_duty_meal_period_agreement_revocation_date"] ? "error" : ""} w-100`}
                      name="on_duty_meal_period_agreement_revocation_date"
                      id="on_duty_meal_period_agreement_revocation_date"
                      selected={
                        form.on_duty_meal_period_agreement_revocation_date
                          ? moment(
                              form.on_duty_meal_period_agreement_revocation_date,
                            )
                          : null
                      }
                      field="form.on_duty_meal_period_agreement_revocation_date"
                      placeholder="mm/dd/yyyy"
                      onChange={this.handleDateChange.bind(
                        this,
                        "form.on_duty_meal_period_agreement_revocation_date",
                      )}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    />
                    {errors[
                      "form.on_duty_meal_period_agreement_revocation_date"
                    ] && (
                      <label
                        htmlFor="on_duty_meal_period_agreement_revocation_date"
                        className="error_label"
                      >
                        {
                          errors[
                            "form.on_duty_meal_period_agreement_revocation_date"
                          ]
                        }
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/*row ends*/}
            <div className="row form-row no_border" style={{ paddingTop: 0 }}>
              <div className="col">
                <div className="inputs-col" style={{ width: "50%" }}>
                  {/*single input */}
                  <div className="inputs-head">Employee’s Printed Name</div>
                  <div className="inputs-items">
                    <input
                      type="text"
                      className={`${errors["form.on_duty_meal_period_agreement_revocation_employee_name"] ? "error" : ""}`}
                      name="on_duty_meal_period_agreement_revocation_employee_name"
                      id="on_duty_meal_period_agreement_revocation_employee_name"
                      value={
                        form.on_duty_meal_period_agreement_revocation_employee_name
                      }
                      field="form.on_duty_meal_period_agreement_revocation_employee_name"
                      placeholder="- Enter here -"
                    />
                    {errors[
                      "form.on_duty_meal_period_agreement_revocation_employee_name"
                    ] && (
                      <label
                        htmlFor="on_duty_meal_period_agreement_revocation_employee_name"
                        className="error_label"
                      >
                        {
                          errors[
                            "form.on_duty_meal_period_agreement_revocation_employee_name"
                          ]
                        }
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <FormNav
              adminMode={adminMode}
              navigate={this.navigate.bind(this)}
              disclaimer_check={form.allow_submit}
              mobileMode={this.props.mobileMode}
            />
          </form>
        </div>
      </div>
    );
  }
}
function mapStateToProps(state) {
  let { docs } = state;
  return { docs };
}
let connectedOnDutyMealPeriodAgreement = compose(
  withRouter,
  connect(mapStateToProps),
)(OnDutyMealPeriodAgreement);
export { connectedOnDutyMealPeriodAgreement as OnDutyMealPeriodAgreement };
