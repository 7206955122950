import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import Messages from "./Messages";
import { chatActions, permissionActions } from "../_actions";
import ConversationHeader from "./ConversationHeader";
import Conversation from "./Conversation";
import MessageBox from "./MessageBox";
import Info from "./Info";
import { SocketContext } from "../_context/socket";
import { config, authHeader } from "../_helpers";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      infoActive: false,
      adminMode: false,
    };

    this.toggleInfo = this.toggleInfo.bind(this);
    this.forceScrollBottom = this.forceScrollBottom.bind(this);
    this.toggleAdmin = this.toggleAdmin.bind(this);
    this.markRead = this.markRead.bind(this);
  }

  refreshTimer = null;
  ConversationArea = null;

  toggleInfo() {
    this.setState({
      infoActive: !this.state.infoActive,
    });
  }

  toggleAdmin(e) {
    let infoActive = this.state.infoActive;

    if (!e.target.checked && infoActive) {
      infoActive = false;
    }

    this.setState(
      {
        adminMode: e.target.checked,
        infoActive,
      },
      () => {
        this.props.dispatch(
          chatActions.toggleAdminMode({ adminMode: this.state.adminMode }),
        );
      },
    );
  }

  forceScrollBottom() {
    if (this.ConversationArea) {
      this.ConversationArea.scrollToBottom(true);
    }
  }

  markRead() {
    const _requestOptions = {
      method: "GET",
      headers: authHeader(),
    };
    const _url = `${config.apiUrl}/api/chat/conversations/markallasread`;
    fetch(_url, _requestOptions).then((response) =>
      response.json().then(
        (res) => {
          //console.log("Unavailable Reasons", res);
          this.props.dispatch(chatActions.getConversations());
        },
        (err) => console.error(err),
      ),
    );
  }

  componentWillUnmount() {
    clearInterval(this.refreshTimer);
  }

  componentDidMount() {
    let { params } = this.props.match;

    this.props.dispatch(permissionActions.getPermission());
    this.props.dispatch(chatActions.getConversations()).then((res) => {
      let convList = res.data.data;
      if (!convList.some((conv) => conv.id == params.conversation_id)) {
        if (params.conversation_id) {
          this.props.dispatch(
            chatActions.getConversation({ id: params.conversation_id }),
          );
        }
      }
    });
    this.props.dispatch(chatActions.getConversationSettings());

    // this.refreshTimer = setInterval(
    //   function() {
    //     let chat = this.props.chat;
    //     if (!chat.conversations.loading) {
    //       this.props.dispatch(chatActions.getConversations());
    //     }
    //   }.bind(this),
    //   5000
    // );
  }

  componentDidUpdate(oldProps) {
    let { admin, conversations, conversation } = this.props.chat;

    let { params } = this.props.match;

    if (admin !== oldProps.chat.admin) {
      this.props.dispatch(chatActions.getConversations()).then((res) => {
        let convList = res.data.data;
        if (!convList.some((conv) => conv.id == params.conversation_id)) {
          if (params.conversation_id) {
            this.props.dispatch(
              chatActions.getConversation({ id: params.conversation_id }),
            );
          }
        }
      });
    }

    let conversationExist = conversations.data.find(
      (conv) => conv.id == params.conversation_id,
    );

    if (
      conversationExist &&
      conversations.data &&
      oldProps.chat.conversations.data !== conversations.data &&
      conversations.data.length &&
      params.conversation_id &&
      conversation.id != params.conversation_id
    ) {
      let conversation = {
        id: params.conversation_id,
      };
      conversation.admin_mode = admin.active;
      this.props.dispatch(chatActions.getMessages(conversation));
      this.props.dispatch(chatActions.readAllMessages(conversation));
    } else if (
      conversationExist &&
      conversations.data &&
      oldProps.match.params.conversation_id != params.conversation_id &&
      conversations.data.length &&
      params.conversation_id &&
      conversation.id != params.conversation_id
    ) {
      let conversation = {
        id: params.conversation_id,
      };
      conversation.admin_mode = admin.active;
      this.props.dispatch(chatActions.getMessages(conversation));
      this.props.dispatch(chatActions.readAllMessages(conversation));
    }
  }

  render() {
    let path = this.props.match.path;
    let { admin, conversation } = this.props.chat;
    let { profile } = this.props.user;
    let { infoActive } = this.state;
    let { permissions } = this.props.permission;

    return (
      <div className="container-fluid">
        <div className="row msg msg-header dashb-header">
          <div className="col-sm-4">
            <h1
              className={`msg-heading ${permissions && permissions.data.includes("Manage Chat") ? "is-admin" : ""}`}
            >
              Messages
            </h1>
            {permissions && permissions.data.includes("Manage Chat") ? (
              <div className="is-admin-header">
                Admin Mode
                <div className="pretty p-switch p-fill">
                  <input
                    type="checkbox"
                    id="admin-toggle"
                    checked={admin.active}
                    onChange={this.toggleAdmin}
                  />
                  <div className="state">
                    <label htmlFor="admin-toggle"> </label>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <button className="btn-mark-as-read btn" onClick={this.markRead}>
              Mark all as read
            </button>
          </div>
          {!admin.active && (
            <div className="col-sm-8">
              <Link
                to={`/chat/new-message`}
                className="pull-right btn msg-new-btn msg-new-message"
              >
                <i className="far fa-envelope"></i> New Message
              </Link>
              {permissions && permissions.data.includes("Create Group") ? (
                <Link
                  to={`/chat/new-group`}
                  className="pull-right btn msg-new-btn msg-new-group"
                >
                  <i className="fas fa-users"></i> New Group
                </Link>
              ) : (
                ""
              )}
            </div>
          )}
        </div>
        <div className="row msg">
          <SocketContext.Consumer>
            {(socket) => (
              <React.Fragment>
                <Messages />
                <div
                  className={`msg-col msg-messages${infoActive ? " group-info-open" : ""}${conversation.id ? " showmb" : " hidemb"}${
                    admin.active ? " is-admin" : ""
                  }`}
                >
                  {conversation.id ? (
                    <React.Fragment>
                      <ConversationHeader
                        toggleInfo={this.toggleInfo.bind(this)}
                      />
                      <Conversation
                        ref={(instance) => {
                          this.ConversationArea = instance;
                        }}
                        pusher={socket}
                      />
                      {!admin.active &&
                        profile &&
                        conversation.users &&
                        conversation.users.some(
                          (user) => user.hash == profile.data.hash,
                        ) && (
                          <MessageBox
                            forceScrollBottom={this.forceScrollBottom}
                          />
                        )}
                    </React.Fragment>
                  ) : (
                    <div className="msg-landing">
                      <div className="mgs-landing_content">
                        <img
                          className="landing-img"
                          src={`${config.assetUrl}icons/new-msg-landing.svg`}
                          alt=""
                        />
                        <h3>Hi, Welcome to Firestorm Messaging.</h3>
                        <p>
                          Send messages to others by selecting 'New Message' or
                          'Search for People or Group'.
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {conversation.id ? (
                  <Info
                    infoActive={infoActive}
                    toggleInfo={this.toggleInfo.bind(this)}
                  />
                ) : (
                  ""
                )}
              </React.Fragment>
            )}
          </SocketContext.Consumer>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { chat, permission, user } = state;
  return { chat, permission, user };
}

const connectedDashboard = connect(mapStateToProps)(Dashboard);

export default connectedDashboard;
