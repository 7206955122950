import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { permissionActions, userActions } from "../_actions";
import ViewProfile from "_components/Profile/ViewProfile";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hash: this.props?.hash?.split("/")[2],
    };
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(permissionActions.getPermission());
    var hash = "?hash=" + this.state.hash;
    this.props.dispatch(userActions.getProfile(hash));
  }

  componentWillReceiveProps(nextProps) {
    const { user, inventory, permission } = nextProps;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;

      this.setState({
        permList,
      });
    }
    if (user.items) {
      this.setState({
        profile: user.items.data,
      });
    }
  }

  goBack() {
    this.props.goBack(this.props.resourceDetails);
  }

  render() {
    const { permission } = this.props;
    var permList = [];
    if (permission.permissions) {
      permList = permission.permissions.data;
    }

    return (
      // details

      <div className="resource_container profile_container">
        <div className="breadcrumbs">
          <span className="green" onClick={this.goBack.bind(this)}>
            Your Crew
          </span>{" "}
          / Profile
        </div>
        <div className="page_title float">
          <h1>
            {this.state.profile
              ? this.state.profile.first_name +
                " " +
                this.state.profile.last_name
              : ""}
          </h1>
          <div className="btn_list">
            <button
              style={{ fontSize: "11px", padding: "6px 16px" }}
              className="button remove_resource"
              onClick={this.goBack.bind(this)}
            >
              Back
            </button>
            <Link to={`/fire-history/${this.state?.hash}`}>
              <button
                style={{ fontSize: "11px", padding: "6px 16px" }}
                className="button print"
              >
                Fire History
              </button>
            </Link>
            <Link to={`/fire-history/${this.state?.hash}/legacy`}>
              <button
                style={{ fontSize: "11px", padding: "6px 16px" }}
                className="button print"
              >
                Legacy Fire History
              </button>
            </Link>
          </div>
        </div>
        {console.log(this.state.hash)}
        {console.log(this.props?.user?.profile?.data?.hash)}
        <ViewProfile
          userHash={this.state.hash}
          authUser={this.props?.user?.profile?.data?.hash}
          permList={permList}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, permission } = state;
  return {
    user,
    permission,
  };
}

const connectedRegisterPage = connect(mapStateToProps)(Profile);
export { connectedRegisterPage as Profile };
