import fetchIntercept from "fetch-intercept";
import { toast } from "react-toastify";

let count = 1;

const interval = setInterval(() => {
  if (count !== 1) {
    count = 1;
  }
}, 10000);

const unregister = fetchIntercept.register({
  request: function (url, config) {
    return [url, config];
  },

  requestError: function (error) {
    if (error.message) {
      toast.error(error.message);
    }
    return Promise.reject(error);
  },

  response: function (response) {
    return response;
  },

  responseError: function (error) {
    if (error.message === "Failed to fetch") {
      if (count === 1) {
        toast.error("Failed to connect server", {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        count++;
      }
    }
    return Promise.reject(error);
  },
});

export { unregister };
