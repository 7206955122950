import React from "react";

import { EditProfile } from "./EditProfile";

import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { checkPastDate, formatDate } from "_helpers/helpers";
import { dateTimeConstants } from "_constants";
import moment from "moment";

const ExpirationDatesComponent = ({
  profileData,
  refetchUser,
  isOpen,
  toggleAccordion,
  permList,
  authUser,
}) => {
  return (
    <div
      id="expiration-dates"
      className={`profile-data__drops profile-data_comp ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#expiration-dates")}>
        <button type="button">
          <h3>Expiration Dates</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>Drivers License</b>
            </span>
            <span
              className={checkPastDate(profileData.dl_expiry) ? "expired" : ""}
            >
              {formatDate(profileData.dl_expiry)}
            </span>
            <EditProfile
              label="Drivers License"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Drivers License</label>
                  <Controller
                    name="dl_expiry"
                    control={control}
                    defaultValue={
                      profileData.dl_expiry
                        ? moment(profileData.dl_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Drivers License Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.dl_expiry && (
                    <p className="error-message">{errors.dl_expiry.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Commercial Drivers License</b>
            </span>
            <span
              className={
                checkPastDate(profileData.commercial_dl_expiry) ? "expired" : ""
              }
            >
              {formatDate(profileData.commercial_dl_expiry)}
            </span>
            <EditProfile
              label="Commercial Drivers License"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Commercial Drivers License</label>
                  <Controller
                    name="commercial_dl_expiry"
                    control={control}
                    defaultValue={
                      profileData.commercial_dl_expiry
                        ? moment(profileData.commercial_dl_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Commercial Drivers License Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.commercial_dl_expiry && (
                    <p className="error-message">
                      {errors.commercial_dl_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>DOL Blue Card/MSPA</b>
            </span>
            <span
              className={
                checkPastDate(profileData.dol_blue_card_expiry) ? "expired" : ""
              }
            >
              {formatDate(profileData.dol_blue_card_expiry)}
            </span>
            <EditProfile
              label="DOL Blue Card/MSPA"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>DOL Blue Card/MSPA</label>
                  <Controller
                    name="dol_blue_card_expiry"
                    control={control}
                    defaultValue={
                      profileData.dol_blue_card_expiry
                        ? moment(profileData.dol_blue_card_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select DOL Blue Card/MSPA Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.dol_blue_card_expiry && (
                    <p className="error-message">
                      {errors.dol_blue_card_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>DOL - Finger Print</b>
            </span>
            <span
              className={
                checkPastDate(profileData.finger_print_expiry) ? "expired" : ""
              }
            >
              {formatDate(profileData.finger_print_expiry)}
            </span>
            <EditProfile
              label="DOL - Finger Print"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>DOL - Finger Print</label>
                  <Controller
                    name="finger_print_expiry"
                    control={control}
                    defaultValue={
                      profileData.finger_print_expiry
                        ? moment(profileData.finger_print_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Finger Print Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.finger_print_expiry && (
                    <p className="error-message">
                      {errors.finger_print_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>DOL - Physical</b>
            </span>
            <span
              className={
                checkPastDate(profileData.physical_expiry) ? "expired" : ""
              }
            >
              {formatDate(profileData.physical_expiry)}
            </span>
            <EditProfile
              label="DOL - Physical"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>DOL - Physical</label>
                  <Controller
                    name="physical_expiry"
                    control={control}
                    defaultValue={
                      profileData.physical_expiry
                        ? moment(profileData.physical_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Physical Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.physical_expiry && (
                    <p className="error-message">
                      {errors.physical_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>First Aid/CPR</b>
            </span>
            <span
              className={
                checkPastDate(profileData.first_aid_expiry) ? "expired" : ""
              }
            >
              {formatDate(profileData.first_aid_expiry)}
            </span>
            <EditProfile
              label="First Aid/CPR"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>First Aid/CPR</label>
                  <Controller
                    name="first_aid_expiry"
                    control={control}
                    defaultValue={
                      profileData.first_aid_expiry
                        ? moment(profileData.first_aid_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select First Aid/CPR Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.first_aid_expiry && (
                    <p className="error-message">
                      {errors.first_aid_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Sexual Harassment Certification</b>
            </span>
            <span
              className={
                checkPastDate(profileData.sexual_harassment_expiry)
                  ? "expired"
                  : ""
              }
            >
              {formatDate(profileData.sexual_harassment_expiry)}
            </span>
            <EditProfile
              label="Sexual Harassment Certification"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Sexual Harassment Certification</label>
                  <Controller
                    name="sexual_harassment_expiry"
                    control={control}
                    defaultValue={
                      profileData.sexual_harassment_expiry
                        ? moment(profileData.sexual_harassment_expiry).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Sexual Harassment Cert. Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.sexual_harassment_expiry && (
                    <p className="error-message">
                      {errors.sexual_harassment_expiry.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Faller Qualification</b>
            </span>
            <span
              className={
                checkPastDate(profileData.faller_quals) ? "expired" : ""
              }
            >
              {formatDate(profileData.faller_quals)}
            </span>
            <EditProfile
              label="Faller Qualification"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Faller Qualification</label>
                  <Controller
                    name="faller_quals"
                    control={control}
                    defaultValue={
                      profileData.faller_quals
                        ? moment(profileData.faller_quals).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date
                                .startOf("day")
                                .isBefore(moment().startOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        minDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Faller Qualification Expiry Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.faller_quals && (
                    <p className="error-message">
                      {errors.faller_quals.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ExpirationDatesComponent;
