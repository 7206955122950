import { authHeader, config } from "../_helpers";

export const applicantsService = {
  getAll,
  sortApplicant,
  viewDetails,
  removeApplicant,
  getArchived,
  sortArchived,
  getHired,
  sortHired,
  updateNote,
  searchApplicants,
  getFilters,
  filterApplicants,
  sortFilterApplicant,
  updateNoteSingle,
  setSort,
  getFieldAll,
  editApplication,
  getApplicantDetails,
  deleteNote,
  getFormerEmployedApplicants,
  changeFormerEmployeeApprovalStatus,
  saveAdminReasonForLeaving,
};

function getAll(perpage) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl +
      "/api/getquery/applicants?page=1&results_per_page=" +
      perpage,
    requestOptions,
  ).then(handleResponse, handleError);
}

function getFormerEmployedApplicants(perpage) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl +
      "/api/getquery/former-applicants?page=1&results_per_page=" +
      perpage,
    requestOptions,
  ).then(handleResponse, handleError);
}

function sortApplicant(sort, order) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/getquery/applicants?sort=" + sort + "&order=" + order,
    requestOptions,
  ).then(handleResponse, handleError);
}

function getFieldAll() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(config.apiUrl + "/api/getlistall", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function viewDetails(hash) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/applicantdetails/" + hash,
    requestOptions,
  ).then(handleResponse, handleError);
}

function removeApplicant(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + "/api/removeapplicant", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function changeFormerEmployeeApprovalStatus(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/change-former-employee-approval-status",
    requestOptions,
  ).then(handleResponse, handleError);
}

function saveAdminReasonForLeaving(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/save-admin-reason-for-leaving",
    requestOptions,
  ).then(handleResponse, handleError);
}

function getArchived() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/getquery/archived_applicants",
    requestOptions,
  ).then(handleResponse, handleError);
}

function sortArchived(sort, order) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl +
      "/api/getquery/archived_applicants?sort=" +
      sort +
      "&order=" +
      order,
    requestOptions,
  ).then(handleResponse, handleError);
}

function getHired() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/getquery/hired_applicants",
    requestOptions,
  ).then(handleResponse, handleError);
}

function sortHired(sort, order) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl +
      "/api/getquery/hired_applicants?sort=" +
      sort +
      "&order=" +
      order,
    requestOptions,
  ).then(handleResponse, handleError);
}

function updateNote(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/applicant-notes/update",
    requestOptions,
  ).then(handleResponse, handleError);
}

function deleteNote(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/applicant-notes/delete",
    requestOptions,
  ).then(handleResponse, handleError);
}

function updateNoteSingle(data, hash) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + "/api/updateapplicantnote", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function searchApplicants(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + "/api/search/applicants", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function getFilters() {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(config.apiUrl + "/api/getlist/filters", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function filterApplicants(data) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(config.apiUrl + "/api/filterapplicants", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function sortFilterApplicant(data, sort, order) {
  const requestOptions = {
    method: "POST",
    headers: authHeader(),
    body: data,
  };

  return fetch(
    config.apiUrl + "/api/filterapplicants?sort=" + sort + "&order=" + order,
    requestOptions,
  ).then(handleResponse, handleError);
}

function setSort(sort, order) {
  const requestOptions = {
    method: "GET",
    headers: authHeader(),
  };

  return fetch(
    config.apiUrl + "/api/getquery/applicants?sort=" + sort + "&order=" + order,
    requestOptions,
  ).then(handleResponse, handleError);
}

function editApplication(data) {
  const requestOptions = {
    method: "POST",
    headers: { ...authHeader() },
    body: data,
  };

  return fetch(config.apiUrl + "/api/applicationupdate", requestOptions).then(
    handleResponse,
    handleError,
  );
}

function getApplicantDetails(jobApplicantHash) {
  const requestOptions = {
    headers: { ...authHeader() },
  };

  return fetch(
    config.apiUrl + "/api/applicant/applicantdetails/" + jobApplicantHash,
    requestOptions,
  ).then(handleResponse, handleError);
}

function handleResponse(response) {
  return new Promise((resolve, reject) => {
    if (response.ok) {
      // return json if it was returned in the response
      var contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        response.json().then((json) => resolve(json));
      } else {
        resolve();
      }
    } else {
      // return error message from response body
      response.text().then((text) => reject(text));
    }
  });
}

function handleError(error) {
  return Promise.reject(error && error.message);
}
