import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import _, { deburr, fromPairs } from "lodash";
import cx from "classnames";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Row, Col, Table } from "react-bootstrap";
import Modal from "react-responsive-modal";

import { applicantsActions, interviewActions } from "_actions";
import { validateForm } from "./formHandler.js";
import {
  getInterviewForm,
  isInterviewFomLoading,
  selectJobApplicant,
} from "_helpers/reduxSelector";
import styles from "./interview.module.scss";
import CustomCheckbox from "Docs/Components/Checkbox/index.jsx";

const DATE_FORMAT = "MM/DD/YYYY";
const INP_DATE_FORMAT = "MM/DD/YYYY";
const TIME_FORMAT = "h:mm A";

const References = ({ data, onClick, checked }) => (
  <div className={styles.references_container}>
    <div>
      <table class="table table-sm">
        <tbody>
          <tr>
            <th scope="row">Name</th>
            <td>{data?.name}</td>
          </tr>
          <tr>
            <th scope="row">Relationship</th>
            <td>{data?.relationship}</td>
          </tr>
          <tr>
            <th scope="row">Cell Phone</th>
            <td>{data?.phone}</td>
          </tr>
          <tr>
            <th scope="row">Verified</th>
            <td className={styles.reference_checkBoxWrap}>
              <CustomCheckbox
                checked={checked}
                onChange={(event) => onClick?.(event, data)}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
);

const initialState = (applicant, user) => ({
  full_name: `${applicant?.first_name} ${applicant?.middle_initial} ${applicant?.last_name}`,
  interview_date: moment(),
  scheduled_interview_time: moment()?.startOf("hour"),
  candidate_arrival_time: moment()?.startOf("hour"),
  //overall_rating: "",
  interview_recap: "",
  why_this_company: "",
  why_we_hire: "",
  interested_in_firefighter: "",
  personal_quality: "",
  expectation: "",
  skills_required: "",
  things_to_do: "",
  areas_to_improve: "",
  team_experience: "",
  worked_successfully: "",
  availability: "",
  deploy_assignment: "",
  extreme_work: "",
  prepare_for_job: "",
  family_support: "",
  deal_multiple_change: "",
  left_without_notice: "",
  mistake_and_fix: "",
  expectation_thoughts: "",
  handle_situations: "",
  respond_situation: "",
  action_to_crude_story: "",
  biggest_prof_challenge: "",
  respond_to_vocal_tone: "",
  handle_conflict: "",
  respond_to_alcohol: "",
  inspire_others: "",
  where_five_years: "",
  your_goals: "",
  open_task_book: "",
  last_active: "",
  biggest_assign_challenge: "",
  notes: "",
  reference_check_name_1: "",
  reference_check_number_1: "",
  reference_one_question_1: "",
  reference_one_question_2: "",
  reference_one_question_3: "",
  reference_one_question_4: "",
  reference_check_name_2: "",
  reference_check_number_2: "",
  reference_two_question_1: "",
  reference_two_question_2: "",
  reference_two_question_3: "",
  reference_two_question_4: "",
  facebook_link: "",
  instagram_link: "",
  social_media_notes: "",
  candidate_information_sent_to_hr: "",
  candidate_information_sent_date: "",
  class_date_one: "",
  other: "",
  interviewer: user?.id,
  interviewer_date: moment(),
  candidate_training_status: "",
  class_date_two: "",
  student_status: "",
  spring_sem_completion_date: "",
  fall_sem_start_date: "",
  living_status: "",
  ca_move_date: "",
  out_of_state_move_date: "",
  candidate_training_status_sub: "",
  living_status_sub: "",
  reference_1_verified: 0,
  reference_2_verified: 0,
  reference_3_verified: 0,
});

export const InterviewForm = ({
  setKey,
  hash,
  applicant,
  setCurrentView,
  user,
  editMode,
  formId,
  getInterviewFormList,
  isNew,
  setIsNew,
  newFormId,
  isSubmittable,
}) => {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const [
    {
      full_name,
      interview_date,
      scheduled_interview_time,
      candidate_arrival_time,
      //overall_rating,
      interview_recap,
      why_this_company,
      why_we_hire,
      interested_in_firefighter,
      personal_quality,
      expectation,
      skills_required,
      things_to_do,
      areas_to_improve,
      team_experience,
      worked_successfully,
      availability,
      deploy_assignment,
      extreme_work,
      prepare_for_job,
      family_support,
      deal_multiple_change,
      left_without_notice,
      mistake_and_fix,
      expectation_thoughts,
      handle_situations,
      respond_situation,
      action_to_crude_story,
      biggest_prof_challenge,
      respond_to_vocal_tone,
      handle_conflict,
      respond_to_alcohol,
      inspire_others,
      where_five_years,
      your_goals,
      open_task_book,
      last_active,
      biggest_assign_challenge,
      notes,
      reference_check_name_1,
      reference_check_number_1,
      reference_one_question_1,
      reference_one_question_2,
      reference_one_question_3,
      reference_one_question_4,
      reference_check_name_2,
      reference_check_number_2,
      reference_two_question_1,
      reference_two_question_2,
      reference_two_question_3,
      reference_two_question_4,
      facebook_link,
      instagram_link,
      social_media_notes,
      candidate_information_sent_to_hr,
      candidate_information_sent_date,
      class_date_one,
      other,
      interviewer,
      interviewer_date,
      candidate_training_status,
      class_date_two,
      student_status,
      spring_sem_completion_date,
      fall_sem_start_date,
      living_status,
      ca_move_date,
      out_of_state_move_date,
      candidate_training_status_sub,
      living_status_sub,
    },
    setFormState,
  ] = useState(initialState(applicant, user));

  const [references, setReferences] = React.useState();
  const [confirmSubmissionOpen, setConfirmSubmissionOpen] =
    React.useState(false);
  const interviewForm = useSelector((state) => getInterviewForm(state));

  const loading = useSelector((state) => isInterviewFomLoading(state));
  const jobApplicationDetails = useSelector(selectJobApplicant);

  useEffect(() => {
    const formData = interviewForm?.form;
    if (isNew) {
      setFormState(initialState(applicant, user));
      return;
    }
    if (editMode && formData) {
      Object.keys(formData).forEach((key) => {
        const value = formData[key];
        if (key.includes("_date") && value) {
          let dt = moment(new Date(value)).format(INP_DATE_FORMAT);
          setFormState((prevState) => ({ ...prevState, [key]: moment(dt) }));
        } else if (key.includes("_time") && value) {
          let tm = moment(value, TIME_FORMAT).toDate();
          setFormState((prevState) => ({ ...prevState, [key]: moment(tm) }));
        } else if (value || value === 0) {
          setFormState((prevState) => ({ ...prevState, [key]: String(value) }));
        }
      });
      let _references = { ...references };
      _references.reference_1_verified = formData?.reference_1_verified;
      _references.reference_2_verified = formData?.reference_2_verified;
      _references.reference_3_verified = formData?.reference_3_verified;
      setReferences(_references);
    }
  }, [interviewForm]);

  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDateChange = (name, value) => {
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const deleteIntForm = () => {
    const data = new FormData();
    data.append("interview_form_id", formId);
    dispatch(interviewActions.deleteInterviewForm(data)).then((res) => {
      if (res.success) {
        getInterviewFormList();
        setCurrentView("listView");
      } else {
        toast.error(res.message);
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    /**Reset error object */
    const submitter = event?.nativeEvent?.submitter?.name;

    if (!isSubmittable && submitter !== "draft") {
      return toast.error(
        "Please fill out the reference form before proceeding",
      );
    }
    const submittedForm = event.target;
    if (submitter !== "draft") {
      let err = validateForm(submittedForm, {});
      if (err && !_.isEmpty(err)) {
        setErrors(err);
        return;
      } else {
        setErrors({});
      }
    } else {
      let err = validateForm(submittedForm, {});
      const mandatoryDraftFields = [
        "interview_date",
        "scheduled_interview_time",
        "candidate_arrival_time",
      ];
      let mandatoryDraftFieldErrors = {};
      mandatoryDraftFields?.forEach((key) => {
        if (err?.[key]) {
          mandatoryDraftFieldErrors[key] = err[key];
        }
      });
      if (mandatoryDraftFieldErrors && !_.isEmpty(mandatoryDraftFieldErrors)) {
        setErrors(mandatoryDraftFieldErrors);
        return;
      } else {
        setErrors({});
      }
    }

    if (submitter == "draft") {
      submitForm(submitter);
    } else {
      setConfirmSubmissionOpen(true);
    }
  };

  const closeConfirmSubmissionModal = () => {
    setConfirmSubmissionOpen(false);
  };

  const submitForm = (submitter) => {
    const submittedForm = formRef.current;

    closeConfirmSubmissionModal();
    const form = {
      interviewer: interviewer,
      ...references,
    };

    if (submittedForm) {
      Object.keys(submittedForm).forEach((index) => {
        let el = submittedForm[index];
        if (el.name) {
          if (el.type === "radio" && el.checked) {
            form[el.name] = el.value;
          } else if (el.type === "date" && el.value) {
            form[el.name] = moment(el.value).format(DATE_FORMAT);
          } else if (el.type !== "radio") {
            form[el.name] = el.value;
          }
        }
      });

      const data = {
        applicant_id: hash,
        status: submitter === "draft" ? 2 : 1,
        interviewer: interviewer,
        date_of_interview: moment(interview_date)?.isValid()
          ? moment(interview_date).format(DATE_FORMAT)
          : null,
        date_of_submission: moment().format(DATE_FORMAT),
        form,
        form_id: editMode && formId ? formId : newFormId,
        // form_id:formId.length? formId : newFormId
      };

      dispatch(interviewActions.saveInterviewForm(data)).then((res) => {
        if (res.success) {
          setCurrentView(
            submitter === "draft" ? "draftComplete" : "submitComplete",
          );
          if (submitter !== "draft") {
            dispatch(applicantsActions?.viewDetails(hash));
          }
        } else {
          toast.error(res.message);
        }
      });

      setIsNew(false);
    }
  };

  const handleReferenceChange = (payload, referenceNumb) => {
    let _references = { ...references };
    _references[`reference_${referenceNumb}_verified`] = _references?.[
      `reference_${referenceNumb}_verified`
    ]
      ? 0
      : 1;
    _references[`reference_${referenceNumb}_name`] = payload?.name;
    _references[`reference_${referenceNumb}_phone`] = payload?.phone;
    _references[`reference_${referenceNumb}_relation`] = payload?.relationship;
    setReferences(_references);
  };

  const leastOneReference = React.useMemo(() => {
    return (
      references?.reference_1_verified ||
      references?.reference_2_verified ||
      references?.reference_3_verified
    );
  }, [references]);

  React.useEffect(() => {
    if (jobApplicationDetails) {
      const noOfReferences = 3;
      let _references = { ...references };
      Array(noOfReferences)
        .fill(0)
        ?.forEach((_, index) => {
          let currentReference = index + 1;
          _references[`reference_${currentReference}_name`] =
            jobApplicationDetails?.[
              `personalreference${currentReference}`
            ]?.name;
          _references[`reference_${currentReference}_phone`] =
            jobApplicationDetails?.[
              `personalreference${currentReference}`
            ]?.phone;
          _references[`reference_${currentReference}_relation`] =
            jobApplicationDetails?.[
              `personalreference${currentReference}`
            ]?.relationship;
        });
      setReferences(_references);
    }
  }, [jobApplicationDetails]);

  const hasReferences = React.useMemo(() => {
    return (
      jobApplicationDetails?.reference_1_verified ||
      jobApplicationDetails?.reference_2_verified ||
      jobApplicationDetails?.reference_3_verified
    );
  }, [jobApplicationDetails]);

  const fetchInterviewForm = () => {
    dispatch(
      interviewActions.getInterviewForm(
        editMode && formId ? formId : newFormId,
      ),
    );
  };

  useEffect(() => {
    if (!isNew) fetchInterviewForm();
  }, []);

  return (
    <>
      {/* {((editMode && !_.isEmpty(interviewForm)) || !editMode) && ( */}
      {editMode && (
        <div className={styles.interview_form_wrapper}>
          <form onSubmit={handleSubmit} noValidate ref={formRef}>
            <div className={styles.interview_form_body}>
              {/* <h3>{editMode ? "Edit Interview Form" : "New Interview Form"}</h3> */}
              {/* <div className={styles.professional_reference}>
                <h4>Professional References:</h4>
                {!hasReferences && <p>No references were added, you are not able to fill the interview form without any professional references.</p>}
                <Row className="form-row">
                  <Col sm={12} md={4}>
                    {jobApplicationDetails?.personalreference1?.name && (
                      <References data={jobApplicationDetails?.personalreference1} checked={!!references?.reference_1_verified} onClick={(event, value) => handleReferenceChange(value, "1")} />
                    )}
                  </Col>
                  <Col sm={12} md={4}>
                    {jobApplicationDetails?.personalreference2?.name && (
                      <References data={jobApplicationDetails?.personalreference2} checked={!!references?.reference_2_verified} onClick={(event, value) => handleReferenceChange(value, "2")} />
                    )}
                  </Col>
                  <Col sm={12} md={4}>
                    {jobApplicationDetails?.personalreference3?.name && (
                      <References data={jobApplicationDetails?.personalreference3} checked={!!references?.reference_3_verified} onClick={(event, value) => handleReferenceChange(value, "3")} />
                    )}
                  </Col>
                </Row>
              </div> */}
              <div className={styles.form__wrapper}>
                <div className={styles.form__wrapper}>
                  <div className={styles.form_group_row}>
                    <div className={styles.form_group}>
                      <label htmlFor="full_name">Name of Candidate</label>
                      <input
                        type="text"
                        required
                        id="full_name"
                        name="full_name"
                        value={full_name}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                    <div className={cx(styles.form_group, "full_width_date")}>
                      <label htmlFor="interview_date">Date of Interview</label>
                      <DatePicker
                        type="date"
                        className={`${
                          errors["interview_date"] ? styles.error : ""
                        }`}
                        id="interview_date"
                        name="interview_date"
                        selected={
                          moment(interview_date)?.isValid()
                            ? moment(interview_date)
                            : null
                        }
                        onChange={(date) =>
                          handleDateChange("interview_date", date)
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        required
                        maxDate={moment()}
                      />
                    </div>
                  </div>

                  <div className={styles.form_group_row}>
                    <div className={cx(styles.form_group, "full_width_date")}>
                      <label htmlFor="scheduled_interview_time">
                        Scheduled Interview Time
                      </label>
                      <DatePicker
                        id="scheduled_interview_time"
                        className={`${
                          errors["scheduled_interview_time"] ? styles.error : ""
                        }`}
                        name="scheduled_interview_time"
                        selected={
                          moment(scheduled_interview_time)?.isValid()
                            ? scheduled_interview_time
                            : null
                        }
                        onChange={(time) =>
                          handleDateChange("scheduled_interview_time", time)
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Select Time"
                        dateFormat="h:mm A"
                        required
                      />
                    </div>
                    <div className={cx(styles.form_group, "full_width_date")}>
                      <label htmlFor="candidate_arrival_time">
                        Candidate Arrival Time
                      </label>
                      <DatePicker
                        id="candidate_arrival_time"
                        className={`${
                          errors["candidate_arrival_time"] ? styles.error : ""
                        }`}
                        name="candidate_arrival_time"
                        selected={
                          moment(candidate_arrival_time)?.isValid()
                            ? moment(candidate_arrival_time)
                            : null
                        }
                        onChange={(time) =>
                          handleDateChange("candidate_arrival_time", time)
                        }
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={15}
                        timeCaption="Select Time"
                        dateFormat="h:mm A"
                        required
                      />
                    </div>
                  </div>

                  {/* <div className={`${styles.form_group} ${styles.overall_rating}`}>
                <label>Overall Rating</label>
                <div
                  className={`${
                    errors["overall_rating"]
                      ? cx(styles.error, styles.overall_rating_wrapper)
                      : styles.overall_rating_wrapper
                  }`}
                >
                  <p>Bad</p>
                  <div className={styles.radio_btn_wrapper}>
                    <div className={`${styles.radio_group}`}>
                      <label className={styles.container_radio}>
                        1
                        <input
                          type="radio"
                          required
                          id="one"
                          name="overall_rating"
                          value="1"
                          checked={overall_rating === "1"}
                          onChange={handleChange}
                        />
                        <span className={styles.radiobtn}></span>
                      </label>
                    </div>
                    <div className={`${styles.radio_group}`}>
                      <label className={styles.container_radio}>
                        2
                        <input
                          type="radio"
                          required
                          name="overall_rating"
                          id="two"
                          value="2"
                          checked={overall_rating === "2"}
                          onChange={handleChange}
                        />
                        <span className={styles.radiobtn}></span>
                      </label>
                    </div>
                    <div className={`${styles.radio_group}`}>
                      <label className={styles.container_radio}>
                        3
                        <input
                          type="radio"
                          required
                          name="overall_rating"
                          id="three"
                          value="3"
                          checked={overall_rating === "3"}
                          onChange={handleChange}
                        />
                        <span className={styles.radiobtn}></span>
                      </label>
                    </div>
                    <div className={`${styles.radio_group}`}>
                      <label className={styles.container_radio}>
                        4
                        <input
                          type="radio"
                          required
                          name="overall_rating"
                          id="four"
                          value="4"
                          checked={overall_rating === "4"}
                          onChange={handleChange}
                        />
                        <span className={styles.radiobtn}></span>
                      </label>
                    </div>
                    <div className={`${styles.radio_group}`}>
                      <label className={styles.container_radio}>
                        5
                        <input
                          type="radio"
                          required
                          name="overall_rating"
                          id="five"
                          value="5"
                          checked={overall_rating === "5"}
                          onChange={handleChange}
                        />
                        <span className={styles.radiobtn}></span>
                      </label>
                    </div>
                  </div>
                  <p>(Good)</p>
                </div>
              </div> */}

                  {/* <div className={`${styles.form_group}`}>
                    <label htmlFor="interview_recap">Interview Recap</label>
                    <textarea
                      name="interview_recap"
                      id="interview_recap"
                      className={`${errors["interview_recap"] ? styles.error : ""}`}
                      rows="4"
                      value={interview_recap}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div> */}

                  <div className={`${styles.form_group}`}>
                    <div className={`${styles.table_wrapper}`}>
                      <table>
                        <thead>
                          <th colSpan="7">Questions</th>
                          <th>
                            1 <span>(Bad)</span>
                          </th>
                          <th>2</th>
                          <th>3</th>
                          <th>4</th>
                          <th>
                            5 <span>(Good)</span>
                          </th>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan="7">
                              Why do you want to work for this company?
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="why_this_company"
                                    value={1}
                                    onChange={handleChange}
                                    checked={why_this_company === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_this_company"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="why_this_company"
                                    value={2}
                                    onChange={handleChange}
                                    checked={why_this_company === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_this_company"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="why_this_company"
                                    value={3}
                                    onChange={handleChange}
                                    checked={why_this_company === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_this_company"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="why_this_company"
                                    value={4}
                                    onChange={handleChange}
                                    checked={why_this_company === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_this_company"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="why_this_company"
                                    value={5}
                                    onChange={handleChange}
                                    checked={why_this_company === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_this_company"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">Why should we hire you?</td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    name="why_we_hire"
                                    value={1}
                                    onChange={handleChange}
                                    checked={why_we_hire === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_we_hire"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    name="why_we_hire"
                                    value={2}
                                    onChange={handleChange}
                                    checked={why_we_hire === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_we_hire"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    name="why_we_hire"
                                    value={3}
                                    onChange={handleChange}
                                    checked={why_we_hire === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_we_hire"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    name="why_we_hire"
                                    value={4}
                                    onChange={handleChange}
                                    checked={why_we_hire === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_we_hire"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="why_we_hire"
                                    value={5}
                                    onChange={handleChange}
                                    checked={why_we_hire === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["why_we_hire"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              What made you interested in wildland firefighting?
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="interested_in_firefighter"
                                    value={1}
                                    onChange={handleChange}
                                    checked={interested_in_firefighter === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["interested_in_firefighter"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="interested_in_firefighter"
                                    value={2}
                                    onChange={handleChange}
                                    checked={interested_in_firefighter === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["interested_in_firefighter"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="interested_in_firefighter"
                                    value={3}
                                    onChange={handleChange}
                                    checked={interested_in_firefighter === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["interested_in_firefighter"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="interested_in_firefighter"
                                    value={4}
                                    onChange={handleChange}
                                    checked={interested_in_firefighter === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["interested_in_firefighter"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="interested_in_firefighter"
                                    value={5}
                                    onChange={handleChange}
                                    checked={interested_in_firefighter === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["interested_in_firefighter"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              {" "}
                              <b>
                                What personal qualities or characteristics do
                                you value most?
                              </b>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="personal_quality"
                                    value={1}
                                    onChange={handleChange}
                                    checked={personal_quality === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["personal_quality"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="personal_quality"
                                    value={2}
                                    onChange={handleChange}
                                    checked={personal_quality === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["personal_quality"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="personal_quality"
                                    value={3}
                                    onChange={handleChange}
                                    checked={personal_quality === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["personal_quality"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="personal_quality"
                                    value={4}
                                    onChange={handleChange}
                                    checked={personal_quality === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["personal_quality"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="personal_quality"
                                    value={5}
                                    onChange={handleChange}
                                    checked={personal_quality === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["personal_quality"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          {/* <tr>
                            <td colSpan="7">What do you expect from this job?</td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation"
                                    value={1}
                                    onChange={handleChange}
                                    checked={expectation === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation"
                                    value={2}
                                    onChange={handleChange}
                                    checked={expectation === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation"
                                    value={3}
                                    onChange={handleChange}
                                    checked={expectation === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation"
                                    value={4}
                                    onChange={handleChange}
                                    checked={expectation === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation"
                                    value={5}
                                    onChange={handleChange}
                                    checked={expectation === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr> */}

                          {/* <tr>
                            <td colSpan="7"> What skills do you think are required to perform this job effectively?</td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="skills_required"
                                    value={1}
                                    onChange={handleChange}
                                    checked={skills_required === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["skills_required"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="skills_required"
                                    value={2}
                                    onChange={handleChange}
                                    checked={skills_required === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["skills_required"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="skills_required"
                                    value={3}
                                    onChange={handleChange}
                                    checked={skills_required === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["skills_required"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="skills_required"
                                    value={4}
                                    onChange={handleChange}
                                    checked={skills_required === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["skills_required"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="skills_required"
                                    value={5}
                                    onChange={handleChange}
                                    checked={skills_required === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["skills_required"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr> */}

                          {/* <tr>
                            <td colSpan="7">
                              {" "}
                              <b>
                                If we were to offer you a job today, what would be the first three things you do next?{" "}
                              </b>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="things_to_do"
                                    value={1}
                                    onChange={handleChange}
                                    checked={things_to_do === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["things_to_do"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="things_to_do"
                                    value={2}
                                    onChange={handleChange}
                                    checked={things_to_do === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["things_to_do"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="things_to_do"
                                    value={3}
                                    onChange={handleChange}
                                    checked={things_to_do === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["things_to_do"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="things_to_do"
                                    value={4}
                                    onChange={handleChange}
                                    checked={things_to_do === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["things_to_do"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="things_to_do"
                                    value={5}
                                    onChange={handleChange}
                                    checked={things_to_do === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["things_to_do"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr> */}

                          <tr>
                            <td colSpan="7">
                              What are areas you know need improvement?
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="areas_to_improve"
                                    value={1}
                                    onChange={handleChange}
                                    checked={areas_to_improve === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["areas_to_improve"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="areas_to_improve"
                                    value={2}
                                    onChange={handleChange}
                                    checked={areas_to_improve === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["areas_to_improve"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="areas_to_improve"
                                    value={3}
                                    onChange={handleChange}
                                    checked={areas_to_improve === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["areas_to_improve"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="areas_to_improve"
                                    value={4}
                                    onChange={handleChange}
                                    checked={areas_to_improve === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["areas_to_improve"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="areas_to_improve"
                                    value={5}
                                    onChange={handleChange}
                                    checked={areas_to_improve === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["areas_to_improve"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              Describe a team experience you found
                              rewarding.{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="team_experience"
                                    value={1}
                                    onChange={handleChange}
                                    checked={team_experience === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["team_experience"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="team_experience"
                                    value={2}
                                    onChange={handleChange}
                                    checked={team_experience === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["team_experience"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="team_experience"
                                    value={3}
                                    onChange={handleChange}
                                    checked={team_experience === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["team_experience"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="team_experience"
                                    value={4}
                                    onChange={handleChange}
                                    checked={team_experience === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["team_experience"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="team_experience"
                                    value={5}
                                    onChange={handleChange}
                                    checked={team_experience === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["team_experience"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              Can you tell me about a time when you worked
                              successfully as a part of a team?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="worked_successfully"
                                    value={1}
                                    onChange={handleChange}
                                    checked={worked_successfully === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["worked_successfully"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="worked_successfully"
                                    value={2}
                                    onChange={handleChange}
                                    checked={worked_successfully === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["worked_successfully"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="worked_successfully"
                                    value={3}
                                    onChange={handleChange}
                                    checked={worked_successfully === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["worked_successfully"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="worked_successfully"
                                    value={4}
                                    onChange={handleChange}
                                    checked={worked_successfully === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["worked_successfully"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="worked_successfully"
                                    value={5}
                                    onChange={handleChange}
                                    checked={worked_successfully === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["worked_successfully"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          {/* <tr>
                            <td colSpan="7">
                              We may not get busy till July will you be available between now and then within a 1-2 hour
                              time frame? How are you occupy your time while waiting for a fire call?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="availability"
                                    value={1}
                                    onChange={handleChange}
                                    checked={availability === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["availability"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="availability"
                                    value={2}
                                    onChange={handleChange}
                                    checked={availability === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["availability"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="availability"
                                    value={3}
                                    onChange={handleChange}
                                    checked={availability === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["availability"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="availability"
                                    value={4}
                                    onChange={handleChange}
                                    checked={availability === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["availability"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="availability"
                                    value={5}
                                    onChange={handleChange}
                                    checked={availability === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["availability"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr> */}

                          {/* <tr>
                            <td colSpan="7">
                              You have a fire call and need to be at the office in an hour to deploy to your assignment.
                              Your car doesn’t start or your ride doesn’t show up, what do you do?
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deploy_assignment"
                                    value={1}
                                    onChange={handleChange}
                                    checked={deploy_assignment === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["deploy_assignment"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deploy_assignment"
                                    value={2}
                                    onChange={handleChange}
                                    checked={deploy_assignment === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["deploy_assignment"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deploy_assignment"
                                    value={3}
                                    onChange={handleChange}
                                    checked={deploy_assignment === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["deploy_assignment"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deploy_assignment"
                                    value={4}
                                    onChange={handleChange}
                                    checked={deploy_assignment === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["deploy_assignment"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deploy_assignment"
                                    value={5}
                                    onChange={handleChange}
                                    checked={deploy_assignment === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["deploy_assignment"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr> */}

                          <tr>
                            <td colSpan="7">
                              Tell me how you are prepared for extreme physical
                              work, long hours, and tough conditions while being
                              away from your family and use of cell phones?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="extreme_work"
                                    value={1}
                                    onChange={handleChange}
                                    checked={extreme_work === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["extreme_work"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="extreme_work"
                                    value={2}
                                    onChange={handleChange}
                                    checked={extreme_work === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["extreme_work"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="extreme_work"
                                    value={3}
                                    onChange={handleChange}
                                    checked={extreme_work === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["extreme_work"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="extreme_work"
                                    value={4}
                                    onChange={handleChange}
                                    checked={extreme_work === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["extreme_work"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="extreme_work"
                                    value={5}
                                    onChange={handleChange}
                                    checked={extreme_work === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["extreme_work"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>
                          {/* 
                          <tr>
                            <td colSpan="7">
                              What have you done to prepare for this job? (Acquired necessary gear like boots, sleeping
                              bag, headlamp? PT hikes?){" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="prepare_for_job"
                                    value={1}
                                    onChange={handleChange}
                                    checked={prepare_for_job === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["prepare_for_job"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="prepare_for_job"
                                    value={2}
                                    onChange={handleChange}
                                    checked={prepare_for_job === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["prepare_for_job"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="prepare_for_job"
                                    value={3}
                                    onChange={handleChange}
                                    checked={prepare_for_job === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["prepare_for_job"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="prepare_for_job"
                                    value={4}
                                    onChange={handleChange}
                                    checked={prepare_for_job === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["prepare_for_job"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="prepare_for_job"
                                    value={5}
                                    onChange={handleChange}
                                    checked={prepare_for_job === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["prepare_for_job"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr> */}

                          {/* <tr>
                            <td colSpan="7">
                              You may only be home for only a few days during the month for the entire season, have you
                              discussed that with your spouse? Do they support your decision to continue with this
                              position?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="family_support"
                                    value={1}
                                    onChange={handleChange}
                                    checked={family_support === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["family_support"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="family_support"
                                    value={2}
                                    onChange={handleChange}
                                    checked={family_support === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["family_support"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="family_support"
                                    value={3}
                                    onChange={handleChange}
                                    checked={family_support === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["family_support"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="family_support"
                                    value={4}
                                    onChange={handleChange}
                                    checked={family_support === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["family_support"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="family_support"
                                    value={5}
                                    onChange={handleChange}
                                    checked={family_support === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["family_support"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr> */}

                          {/* <tr>
                            <td colSpan="7">
                              How do you deal with multiple changes while at work? Would you consider yourself a
                              flexible person?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deal_multiple_change"
                                    value={1}
                                    onChange={handleChange}
                                    checked={deal_multiple_change === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["deal_multiple_change"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deal_multiple_change"
                                    value={2}
                                    onChange={handleChange}
                                    checked={deal_multiple_change === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["deal_multiple_change"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deal_multiple_change"
                                    value={3}
                                    onChange={handleChange}
                                    checked={deal_multiple_change === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["deal_multiple_change"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deal_multiple_change"
                                    value={4}
                                    onChange={handleChange}
                                    checked={deal_multiple_change === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["deal_multiple_change"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="deal_multiple_change"
                                    value={5}
                                    onChange={handleChange}
                                    checked={deal_multiple_change === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["deal_multiple_change"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr> */}

                          {/* <tr>
                            <td colSpan="7">
                              <b>
                                Have you ever left a job without giving two weeks notice, if so why? If you found out in
                                the middle of an assignment that this job wasn’t for you would you complete the
                                assignment term?
                              </b>{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="left_without_notice"
                                    value={1}
                                    onChange={handleChange}
                                    checked={left_without_notice === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["left_without_notice"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="left_without_notice"
                                    value={2}
                                    onChange={handleChange}
                                    checked={left_without_notice === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["left_without_notice"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="left_without_notice"
                                    value={3}
                                    onChange={handleChange}
                                    checked={left_without_notice === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["left_without_notice"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="left_without_notice"
                                    value={4}
                                    onChange={handleChange}
                                    checked={left_without_notice === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["left_without_notice"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="left_without_notice"
                                    value={5}
                                    onChange={handleChange}
                                    checked={left_without_notice === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["left_without_notice"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr> */}

                          <tr>
                            <td colSpan="7">
                              Tell me a time where you made a mistake and how
                              did you fix it? What have you learned from making
                              mistakes in the workplace?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="mistake_and_fix"
                                    value={1}
                                    onChange={handleChange}
                                    checked={mistake_and_fix === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["mistake_and_fix"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="mistake_and_fix"
                                    value={2}
                                    onChange={handleChange}
                                    checked={mistake_and_fix === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["mistake_and_fix"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="mistake_and_fix"
                                    value={3}
                                    onChange={handleChange}
                                    checked={mistake_and_fix === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["mistake_and_fix"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="mistake_and_fix"
                                    value={4}
                                    onChange={handleChange}
                                    checked={mistake_and_fix === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["mistake_and_fix"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="mistake_and_fix"
                                    value={5}
                                    onChange={handleChange}
                                    checked={mistake_and_fix === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["mistake_and_fix"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          {/* <tr>
                            <td colSpan="7">
                              <b>What do you think we expect from you? </b>{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation_thoughts"
                                    value={1}
                                    onChange={handleChange}
                                    checked={expectation_thoughts === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation_thoughts"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation_thoughts"
                                    value={2}
                                    onChange={handleChange}
                                    checked={expectation_thoughts === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation_thoughts"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation_thoughts"
                                    value={3}
                                    onChange={handleChange}
                                    checked={expectation_thoughts === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation_thoughts"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation_thoughts"
                                    value={4}
                                    onChange={handleChange}
                                    checked={expectation_thoughts === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation_thoughts"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="expectation_thoughts"
                                    value={5}
                                    onChange={handleChange}
                                    checked={expectation_thoughts === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["expectation_thoughts"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr> */}

                          <tr>
                            <td colSpan="7">
                              We spent a lot of time in close quarters for many
                              days under stressful and difficult situations how
                              will you handle a crew member that has a bad
                              attitude and is bringing the moral of the crew
                              down?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_situations"
                                    value={1}
                                    onChange={handleChange}
                                    checked={handle_situations === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_situations"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_situations"
                                    value={2}
                                    onChange={handleChange}
                                    checked={handle_situations === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_situations"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_situations"
                                    value={3}
                                    onChange={handleChange}
                                    checked={handle_situations === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_situations"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_situations"
                                    value={4}
                                    onChange={handleChange}
                                    checked={handle_situations === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_situations"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_situations"
                                    value={5}
                                    onChange={handleChange}
                                    checked={handle_situations === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_situations"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              While on assignment someone in the buggy turns
                              around rudely disrespects you. How do you respond
                              to that situation?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_situation"
                                    value={1}
                                    onChange={handleChange}
                                    checked={respond_situation === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_situation"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_situation"
                                    value={2}
                                    onChange={handleChange}
                                    checked={respond_situation === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_situation"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_situation"
                                    value={3}
                                    onChange={handleChange}
                                    checked={respond_situation === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_situation"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_situation"
                                    value={4}
                                    onChange={handleChange}
                                    checked={respond_situation === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_situation"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_situation"
                                    value={5}
                                    onChange={handleChange}
                                    checked={respond_situation === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_situation"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              You and the crew are working on the fire line and
                              you overhear another member of the crew telling a
                              crude story, how would that make you feel? What
                              actions might you take?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="action_to_crude_story"
                                    value={1}
                                    onChange={handleChange}
                                    checked={action_to_crude_story === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["action_to_crude_story"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="action_to_crude_story"
                                    value={2}
                                    onChange={handleChange}
                                    checked={action_to_crude_story === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["action_to_crude_story"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="action_to_crude_story"
                                    value={3}
                                    onChange={handleChange}
                                    checked={action_to_crude_story === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["action_to_crude_story"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="action_to_crude_story"
                                    value={4}
                                    onChange={handleChange}
                                    checked={action_to_crude_story === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["action_to_crude_story"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="action_to_crude_story"
                                    value={5}
                                    onChange={handleChange}
                                    checked={action_to_crude_story === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["action_to_crude_story"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              What was has been the biggest challenge
                              professionally and how did you handle it?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_prof_challenge"
                                    value={1}
                                    onChange={handleChange}
                                    checked={biggest_prof_challenge === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_prof_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_prof_challenge"
                                    value={2}
                                    onChange={handleChange}
                                    checked={biggest_prof_challenge === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_prof_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_prof_challenge"
                                    value={3}
                                    onChange={handleChange}
                                    checked={biggest_prof_challenge === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_prof_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_prof_challenge"
                                    value={4}
                                    onChange={handleChange}
                                    checked={biggest_prof_challenge === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_prof_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_prof_challenge"
                                    value={5}
                                    onChange={handleChange}
                                    checked={biggest_prof_challenge === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_prof_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              Your crew boss was out ahead scouting the fire and
                              on his way back he sees you are doing something he
                              doesn’t like. He speaks to you in an assertive
                              direct manner, how do you respond to his direction
                              and vocal tone?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_vocal_tone"
                                    value={1}
                                    onChange={handleChange}
                                    checked={respond_to_vocal_tone === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_vocal_tone"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_vocal_tone"
                                    value={2}
                                    onChange={handleChange}
                                    checked={respond_to_vocal_tone === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_vocal_tone"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_vocal_tone"
                                    value={3}
                                    onChange={handleChange}
                                    checked={respond_to_vocal_tone === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_vocal_tone"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_vocal_tone"
                                    value={4}
                                    onChange={handleChange}
                                    checked={respond_to_vocal_tone === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_vocal_tone"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_vocal_tone"
                                    value={5}
                                    onChange={handleChange}
                                    checked={respond_to_vocal_tone === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_vocal_tone"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              Have you resolved a conflict with a colleague or
                              superior? How did you handle it?
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_conflict"
                                    value={1}
                                    onChange={handleChange}
                                    checked={handle_conflict === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_conflict"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_conflict"
                                    value={2}
                                    onChange={handleChange}
                                    checked={handle_conflict === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_conflict"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_conflict"
                                    value={3}
                                    onChange={handleChange}
                                    checked={handle_conflict === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_conflict"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_conflict"
                                    value={4}
                                    onChange={handleChange}
                                    checked={handle_conflict === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_conflict"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="handle_conflict"
                                    value={5}
                                    onChange={handleChange}
                                    checked={handle_conflict === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["handle_conflict"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              <b>
                                You see a coworker with a bag of weed or a
                                bottle of alcohol while out on assignment, this
                                may include when you’re “off the clock” what do
                                you do?
                              </b>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_alcohol"
                                    value={1}
                                    onChange={handleChange}
                                    checked={respond_to_alcohol === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_alcohol"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_alcohol"
                                    value={2}
                                    onChange={handleChange}
                                    checked={respond_to_alcohol === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_alcohol"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_alcohol"
                                    value={3}
                                    onChange={handleChange}
                                    checked={respond_to_alcohol === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_alcohol"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_alcohol"
                                    value={4}
                                    onChange={handleChange}
                                    checked={respond_to_alcohol === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_alcohol"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="respond_to_alcohol"
                                    value={5}
                                    onChange={handleChange}
                                    checked={respond_to_alcohol === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["respond_to_alcohol"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              When have you had to take the lead and inspire
                              others? How did you do this?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="inspire_others"
                                    value={1}
                                    onChange={handleChange}
                                    checked={inspire_others === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["inspire_others"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="inspire_others"
                                    value={2}
                                    onChange={handleChange}
                                    checked={inspire_others === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["inspire_others"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="inspire_others"
                                    value={3}
                                    onChange={handleChange}
                                    checked={inspire_others === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["inspire_others"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="inspire_others"
                                    value={4}
                                    onChange={handleChange}
                                    checked={inspire_others === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["inspire_others"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="inspire_others"
                                    value={5}
                                    onChange={handleChange}
                                    checked={inspire_others === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["inspire_others"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              <b>Where do you want to be in five years? </b>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="where_five_years"
                                    value={1}
                                    onChange={handleChange}
                                    checked={where_five_years === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["where_five_years"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="where_five_years"
                                    value={2}
                                    onChange={handleChange}
                                    checked={where_five_years === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["where_five_years"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="where_five_years"
                                    value={3}
                                    onChange={handleChange}
                                    checked={where_five_years === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["where_five_years"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="where_five_years"
                                    value={4}
                                    onChange={handleChange}
                                    checked={where_five_years === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["where_five_years"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="where_five_years"
                                    value={5}
                                    onChange={handleChange}
                                    checked={where_five_years === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["where_five_years"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          <tr>
                            <td colSpan="7">
                              Tell me about some of your goals?{" "}
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="your_goals"
                                    value={1}
                                    onChange={handleChange}
                                    checked={your_goals === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["your_goals"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="your_goals"
                                    value={2}
                                    onChange={handleChange}
                                    checked={your_goals === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["your_goals"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="your_goals"
                                    value={3}
                                    onChange={handleChange}
                                    checked={your_goals === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["your_goals"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="your_goals"
                                    value={4}
                                    onChange={handleChange}
                                    checked={your_goals === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["your_goals"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="your_goals"
                                    value={5}
                                    onChange={handleChange}
                                    checked={your_goals === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["your_goals"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr>

                          {/* <tr>
                            <td colSpan="7">Do you currently have any Taskbooks open? Complete? </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="open_task_book"
                                    value={1}
                                    onChange={handleChange}
                                    checked={open_task_book === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["open_task_book"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="open_task_book"
                                    value={2}
                                    onChange={handleChange}
                                    checked={open_task_book === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["open_task_book"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="open_task_book"
                                    value={3}
                                    onChange={handleChange}
                                    checked={open_task_book === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["open_task_book"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="open_task_book"
                                    value={4}
                                    onChange={handleChange}
                                    checked={open_task_book === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["open_task_book"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="open_task_book"
                                    value={5}
                                    onChange={handleChange}
                                    checked={open_task_book === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["open_task_book"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr> */}

                          {/* <tr>
                            <td colSpan="7">When was the last season you were active in fire on a rx or wildfire?</td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="last_active"
                                    value={1}
                                    onChange={handleChange}
                                    checked={last_active === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["last_active"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="last_active"
                                    value={2}
                                    onChange={handleChange}
                                    checked={last_active === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["last_active"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="last_active"
                                    value={3}
                                    onChange={handleChange}
                                    checked={last_active === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["last_active"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="last_active"
                                    value={4}
                                    onChange={handleChange}
                                    checked={last_active === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["last_active"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="last_active"
                                    value={5}
                                    onChange={handleChange}
                                    checked={last_active === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["last_active"] ? cx(styles.error, styles.radiobtn) : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr> */}

                          {/* <tr>
                            <td colSpan="7">
                              What was the biggest challenge on a fire assignment and how did you handle it?
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_assign_challenge"
                                    value={1}
                                    onChange={handleChange}
                                    checked={biggest_assign_challenge === "1"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_assign_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_assign_challenge"
                                    value={2}
                                    onChange={handleChange}
                                    checked={biggest_assign_challenge === "2"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_assign_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_assign_challenge"
                                    value={3}
                                    onChange={handleChange}
                                    checked={biggest_assign_challenge === "3"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_assign_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_assign_challenge"
                                    value={4}
                                    onChange={handleChange}
                                    checked={biggest_assign_challenge === "4"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_assign_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                            <td>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="biggest_assign_challenge"
                                    value={5}
                                    onChange={handleChange}
                                    checked={biggest_assign_challenge === "5"}
                                  />
                                  <span
                                    className={`${
                                      errors["biggest_assign_challenge"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                </label>
                              </div>
                            </td>
                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className={`${styles.form_group} ${styles.notes}`}>
                    <label htmlFor="notes">Notes</label>
                    <textarea
                      name="notes"
                      id="notes"
                      rows="4"
                      value={notes}
                      onChange={handleChange}
                      required
                      className={`${errors["notes"] ? styles.error : ""}`}
                    ></textarea>
                  </div>

                  {/* Reference check #1 and Reference check #2 was here, removed cos of new design */}

                  {/* Social media check div was here*/}

                  {/* <Table
                    striped
                    bordered
                    condensed
                    className={styles.second_table}
                  >
                    <tbody>
                      <tr>
                        <td className={styles.single_col}>
                          Candidate information sent to HR for drug screen?
                          <div
                            className={`${styles.form_group_row} ${styles.vertical_group}`}
                          >
                            <div className={`${styles.radio_btn_wrapper}`}>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="candidate_information_sent_to_hr"
                                    value={2}
                                    id="one"
                                    checked={
                                      candidate_information_sent_to_hr === "2"
                                    }
                                    onChange={handleChange}
                                  />
                                  <span
                                    className={`${
                                      errors["candidate_information_sent_to_hr"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                  <p>Yes</p>
                                  {candidate_information_sent_to_hr === "2" && (
                                    <div className={styles.form_group}>
                                      <label
                                        className={`${styles.hidden_label}`}
                                        htmlFor="ds-date"
                                      >
                                        Date on Interview
                                      </label>
                                      <DatePicker
                                        type="text"
                                        required={
                                          candidate_information_sent_to_hr ===
                                          "2"
                                        }
                                        id="ds-date"
                                        placeholderText="Date"
                                        className={`${
                                          errors[
                                            "candidate_information_sent_date"
                                          ]
                                            ? styles.error
                                            : ""
                                        }`}
                                        name="candidate_information_sent_date"
                                        selected={
                                          candidate_information_sent_date &&
                                          moment(
                                            candidate_information_sent_date
                                          )
                                        }
                                        onChange={(date) =>
                                          handleDateChange(
                                            "candidate_information_sent_date",
                                            date
                                          )
                                        }
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                        autoComplete="off"
                                      />
                                    </div>
                                  )}
                                </label>
                              </div>
                              <div className={`${styles.radio_group}`}>
                                <label className={styles.container_radio}>
                                  <input
                                    className={`${styles.radio_inp}`}
                                    type="radio"
                                    required
                                    name="candidate_information_sent_to_hr"
                                    value={1}
                                    id="one"
                                    checked={
                                      candidate_information_sent_to_hr === "1"
                                    }
                                    onChange={handleChange}
                                  />
                                  <span
                                    className={`${
                                      errors["candidate_information_sent_to_hr"]
                                        ? cx(styles.error, styles.radiobtn)
                                        : styles.radiobtn
                                    }`}
                                  ></span>
                                  <p>No</p>
                                </label>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Student status</td>
                        <td>Student status</td>
                        <td>Student status</td>
                      </tr>
                      <tr>
                        <td>3</td>
                      </tr>
                    </tbody>
                  </Table> */}

                  <div className={styles.continer_table_students}>
                    <div className={styles.table_outerdiv}>
                      <table className={styles.second_table}>
                        <tbody>
                          <tr>
                            <td
                              colSpan={4}
                              className={styles.second_table__head}
                            >
                              <div
                                className={`${styles.grouped_inputs} ${styles.grouped_inputs} `}
                              >
                                <p>
                                  <strong>
                                    Candidate information sent to HR for drug
                                    screen?
                                  </strong>
                                </p>
                                <div
                                  className={`${styles.form_group_row} ${styles.vertical_group} ver_grp`}
                                >
                                  <div
                                    className={`${styles.radio_btn_wrapper} ${styles.width__auto} radio_btns`}
                                  >
                                    <div
                                      className={`${styles.radio_group} first_radio_input`}
                                    >
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp} `}
                                          type="radio"
                                          required
                                          name="candidate_information_sent_to_hr"
                                          value={1}
                                          id="one"
                                          checked={
                                            candidate_information_sent_to_hr ===
                                            "1"
                                          }
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors[
                                              "candidate_information_sent_to_hr"
                                            ]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn,
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>No</p>
                                      </label>
                                    </div>
                                    <div className={`${styles.radio_group}`}>
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp}`}
                                          type="radio"
                                          required
                                          name="candidate_information_sent_to_hr"
                                          value={2}
                                          id="one"
                                          checked={
                                            candidate_information_sent_to_hr ===
                                            "2"
                                          }
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors[
                                              "candidate_information_sent_to_hr"
                                            ]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn,
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>Yes</p>
                                        {candidate_information_sent_to_hr ===
                                          "2" && (
                                          <div
                                            className={`${styles.form_group} ${styles.form__row}`}
                                          >
                                            <label
                                              className={`${styles.hidden_label}`}
                                              htmlFor="ds-date"
                                            >
                                              Date on Interview
                                            </label>
                                            <DatePicker
                                              type="text"
                                              required={
                                                candidate_information_sent_to_hr ===
                                                "2"
                                              }
                                              id="ds-date"
                                              placeholderText="Date"
                                              className={`${
                                                errors[
                                                  "candidate_information_sent_date"
                                                ]
                                                  ? styles.error
                                                  : ""
                                              }`}
                                              name="candidate_information_sent_date"
                                              selected={
                                                candidate_information_sent_date &&
                                                moment(
                                                  candidate_information_sent_date,
                                                )
                                              }
                                              onChange={(date) =>
                                                handleDateChange(
                                                  "candidate_information_sent_date",
                                                  date,
                                                )
                                              }
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              autoComplete="off"
                                            />
                                          </div>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td width="16%">
                              <p>
                                <strong>Student status</strong>
                              </p>
                            </td>
                            <td width="28%">
                              <div
                                className={`${styles.grouped_inputs} ${styles.grouped_inputs} second_row`}
                              >
                                <div
                                  className={`${styles.form_group_row} ${styles.vertical_group} ver_grp`}
                                >
                                  <div
                                    className={`${styles.radio_btn_wrapper}`}
                                  >
                                    <div className={`${styles.radio_group}`}>
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp}`}
                                          type="radio"
                                          required
                                          name="student_status"
                                          id="one"
                                          value={3}
                                          checked={student_status === "3"}
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors["student_status"]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn,
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>
                                          <strong>Not a student</strong>
                                        </p>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td width="28%">
                              <div
                                className={`${styles.grouped_inputs} ${styles.grouped_inputs} second_row`}
                              >
                                <div
                                  className={`${styles.form_group_row} ${styles.vertical_group} ver_grp`}
                                >
                                  <div
                                    className={`${styles.radio_btn_wrapper}`}
                                  >
                                    <div className={`${styles.radio_group}`}>
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp}`}
                                          type="radio"
                                          required
                                          name="student_status"
                                          id="one"
                                          value={1}
                                          checked={student_status === "1"}
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors["student_status"]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn,
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>
                                          <strong>Spring Semester</strong>
                                        </p>
                                        {student_status === "1" && (
                                          <div className={styles.form_group}>
                                            <label
                                              className={`${styles.hidden_label}`}
                                              htmlFor="ss-comple-date"
                                            >
                                              Completion Date
                                            </label>
                                            <DatePicker
                                              type="date"
                                              required={student_status === "1"}
                                              className={`${
                                                errors[
                                                  "spring_sem_completion_date"
                                                ]
                                                  ? styles.error
                                                  : ""
                                              }`}
                                              id="spring_sem_completion_date"
                                              placeholderText="Completion Date"
                                              name="spring_sem_completion_date"
                                              selected={
                                                spring_sem_completion_date &&
                                                moment(
                                                  spring_sem_completion_date,
                                                )
                                              }
                                              onChange={(date) =>
                                                handleDateChange(
                                                  "spring_sem_completion_date",
                                                  date,
                                                )
                                              }
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              autoComplete="off"
                                            />
                                          </div>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td width="28%">
                              <div
                                className={`${styles.grouped_inputs} ${styles.grouped_inputs} second_row`}
                              >
                                <div
                                  className={`${styles.form_group_row} ${styles.vertical_group} ver_grp`}
                                >
                                  <div
                                    className={`${styles.radio_btn_wrapper}`}
                                  >
                                    <div className={`${styles.radio_group}`}>
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp}`}
                                          type="radio"
                                          required
                                          name="student_status"
                                          id="one"
                                          value={2}
                                          checked={student_status === "2"}
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors["student_status"]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn,
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>
                                          <strong>Fall Semester</strong>
                                        </p>
                                        {student_status === "2" && (
                                          <div className={styles.form_group}>
                                            <label
                                              className={`${styles.hidden_label}`}
                                              htmlFor="ss-start-date"
                                            >
                                              Start Date
                                            </label>
                                            <DatePicker
                                              type="date"
                                              required={student_status === "2"}
                                              id="fall_sem_start_date"
                                              placeholderText="Start Date"
                                              className={`${
                                                errors["fall_sem_start_date"]
                                                  ? styles.error
                                                  : ""
                                              }`}
                                              name="fall_sem_start_date"
                                              selected={
                                                fall_sem_start_date &&
                                                moment(fall_sem_start_date)
                                              }
                                              onChange={(date) =>
                                                handleDateChange(
                                                  "fall_sem_start_date",
                                                  date,
                                                )
                                              }
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              autoComplete="off"
                                            />
                                          </div>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td width="16%">
                              <p>
                                <strong>Living status</strong>
                              </p>
                            </td>
                            <td width="28%">
                              <div
                                className={`${styles.grouped_inputs} ${styles.grouped_inputs} second_row`}
                              >
                                <div
                                  className={`${styles.form_group_row} ${styles.vertical_group} ver_grp`}
                                >
                                  <div
                                    className={`${styles.radio_btn_wrapper}`}
                                  >
                                    <div className={`${styles.radio_group}`}>
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp}`}
                                          type="radio"
                                          required
                                          name="living_status"
                                          id="one"
                                          value={3}
                                          checked={living_status === "3"}
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors["living_status"]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn,
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>
                                          <strong>
                                            Currently within 2 hours of
                                          </strong>
                                        </p>
                                        {living_status === "3" && (
                                          <div
                                            className={`${styles.form_group_row} ${styles.vertical_group}`}
                                          >
                                            <div
                                              className={`${styles.radio_btn_wrapper}`}
                                            >
                                              <div
                                                className={`${styles.radio_group}`}
                                              >
                                                <label
                                                  className={
                                                    styles.container_radio
                                                  }
                                                >
                                                  <input
                                                    className={`${styles.radio_inp}`}
                                                    type="radio"
                                                    required={
                                                      living_status === "3"
                                                    }
                                                    id="ls-02"
                                                    name="living_status_sub"
                                                    value={2}
                                                    checked={
                                                      living_status_sub === "2"
                                                    }
                                                    onChange={handleChange}
                                                  />
                                                  <span
                                                    className={`${
                                                      errors[
                                                        "living_status_sub"
                                                      ]
                                                        ? cx(
                                                            styles.error,
                                                            styles.radiobtn,
                                                          )
                                                        : styles.radiobtn
                                                    }`}
                                                  ></span>
                                                  <p className={styles.bold}>
                                                    Chico
                                                  </p>
                                                </label>
                                              </div>
                                              <div
                                                className={`${styles.radio_group}`}
                                              >
                                                <label
                                                  className={
                                                    styles.container_radio
                                                  }
                                                >
                                                  <input
                                                    className={`${styles.radio_inp}`}
                                                    type="radio"
                                                    required={
                                                      living_status === "3"
                                                    }
                                                    id="ls-03"
                                                    name="living_status_sub"
                                                    value={1}
                                                    checked={
                                                      living_status_sub === "1"
                                                    }
                                                    onChange={handleChange}
                                                  />
                                                  <span
                                                    className={`${
                                                      errors[
                                                        "living_status_sub"
                                                      ]
                                                        ? cx(
                                                            styles.error,
                                                            styles.radiobtn,
                                                          )
                                                        : styles.radiobtn
                                                    }`}
                                                  ></span>
                                                  <p>Anderson</p>
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td width="28%">
                              <div
                                className={`${styles.grouped_inputs} ${styles.grouped_inputs} second_row`}
                              >
                                <div
                                  className={`${styles.form_group_row} ${styles.vertical_group} ver_grp`}
                                >
                                  <div
                                    className={`${styles.radio_btn_wrapper}`}
                                  >
                                    <div className={`${styles.radio_group}`}>
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp}`}
                                          type="radio"
                                          required
                                          name="living_status"
                                          id="one"
                                          value={1}
                                          checked={living_status === "1"}
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors["living_status"]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn,
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>
                                          <strong>Out of Area In CA</strong>
                                        </p>
                                        {living_status === "1" && (
                                          <div className={styles.form_group}>
                                            <label
                                              className={`${styles.hidden_label}`}
                                              htmlFor="ss-comple-date"
                                            >
                                              Move Date
                                            </label>
                                            <DatePicker
                                              type="date"
                                              required={living_status === "1"}
                                              className={`${
                                                errors["ca_move_date"]
                                                  ? styles.error
                                                  : ""
                                              }`}
                                              id="ca_move_date"
                                              placeholderText="Move Date"
                                              name="ca_move_date"
                                              selected={
                                                ca_move_date &&
                                                moment(ca_move_date)
                                              }
                                              onChange={(date) =>
                                                handleDateChange(
                                                  "ca_move_date",
                                                  date,
                                                )
                                              }
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              autoComplete="off"
                                            />
                                          </div>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td width="28%">
                              <div
                                className={`${styles.grouped_inputs} ${styles.grouped_inputs} second_row`}
                              >
                                <div
                                  className={`${styles.form_group_row} ${styles.vertical_group} ver_grp`}
                                >
                                  <div
                                    className={`${styles.radio_btn_wrapper}`}
                                  >
                                    <div className={`${styles.radio_group}`}>
                                      <label className={styles.container_radio}>
                                        <input
                                          className={`${styles.radio_inp}`}
                                          type="radio"
                                          required
                                          name="living_status"
                                          id="one"
                                          value={2}
                                          checked={living_status === "2"}
                                          onChange={handleChange}
                                        />
                                        <span
                                          className={`${
                                            errors["living_status"]
                                              ? cx(
                                                  styles.error,
                                                  styles.radiobtn,
                                                )
                                              : styles.radiobtn
                                          }`}
                                        ></span>
                                        <p>
                                          <strong>Out Of State</strong>
                                        </p>
                                        {living_status === "2" && (
                                          <div className={styles.form_group}>
                                            <label
                                              className={`${styles.hidden_label}`}
                                              htmlFor="ss-start-date"
                                            >
                                              Date on Interview
                                            </label>
                                            <DatePicker
                                              type="date"
                                              required={living_status === "2"}
                                              className={`${
                                                errors["out_of_state_move_date"]
                                                  ? styles.error
                                                  : ""
                                              }`}
                                              id="out_of_state_move_date"
                                              placeholderText="Move Date"
                                              name="out_of_state_move_date"
                                              selected={
                                                out_of_state_move_date &&
                                                moment(out_of_state_move_date)
                                              }
                                              onChange={(date) =>
                                                handleDateChange(
                                                  "out_of_state_move_date",
                                                  date,
                                                )
                                              }
                                              showMonthDropdown
                                              showYearDropdown
                                              dropdownMode="select"
                                              autoComplete="off"
                                            />
                                          </div>
                                        )}
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className={styles.form_group_row}>
                    <div className={styles.form_group}>
                      <label htmlFor="interviewer_name">
                        Name of Interviewer
                      </label>
                      <input
                        type="text"
                        id=""
                        name=""
                        value={user?.full_name}
                        disabled
                      />
                    </div>
                    <div className={cx(styles.form_group, "full_width_date")}>
                      <label htmlFor="interviewer_date">
                        Date on Form Submission
                      </label>
                      <DatePicker
                        type="date"
                        className={styles.date_picker}
                        popperClassName={styles.date_picker}
                        wrapperClassName={styles.date_picker}
                        id="interviewer_date"
                        name="interviewer_date"
                        selected={interviewer_date}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        autoComplete="off"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.submit_action__wrapper}>
              <div
                className={cx(styles.input__wrapper, styles.no_margin_input)}
              >
                <input
                  type="button"
                  name="draft"
                  className={styles.button}
                  value="Back"
                  onClick={(e) => {
                    e.preventDefault();
                    // setCurrentView("listView");
                    setKey("referenceForm");
                  }}
                />
                {/* {editMode && ( */}
                {formId && (
                  <input
                    type="button"
                    name="draft"
                    className={styles.button}
                    value="Delete"
                    onClick={(e) => {
                      e.preventDefault();
                      deleteIntForm();
                    }}
                    disabled={loading}
                  />
                )}
              </div>
              {/* {leastOneReference && ( */}
              {
                <div className={styles.input__wrapper}>
                  <input
                    type="submit"
                    name="draft"
                    className={styles.button}
                    value="Save as Draft"
                    disabled={loading}
                  />
                  <input
                    type="submit"
                    name="submit"
                    className={styles.button}
                    value="Submit"
                    disabled={loading}
                  />
                </div>
              }
            </div>
          </form>
          <Modal
            open={confirmSubmissionOpen}
            onClose={closeConfirmSubmissionModal}
            little
          >
            <div id="deleteBullPopup">
              <div className="modal-head">
                <h4 className="modal-title">
                  Confirm Interview Form Submission
                </h4>
              </div>
              <div className="modal-body">
                <p>
                  By submitting this form, your responses will be finalized and
                  the form will become uneditable. Are you sure you want to
                  proceed with submitting the interview form for this applicant?
                </p>
                <div className="btn_list">
                  <button
                    className="button grey"
                    onClick={closeConfirmSubmissionModal}
                  >
                    Cancel
                  </button>
                  <button
                    className="button"
                    onClick={() => submitForm("submit")}
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};
