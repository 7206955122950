/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Col, Row, Tab, Tabs } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";
import DatePicker from "react-datepicker";
import { InterviewForm } from "InterviewForm/InterviewForm";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { interviewActions } from "_actions";
import { getInterviewForm } from "_helpers/reduxSelector";

const DATE_FORMAT = "MM/DD/YYYY";

const refInterviewSchema = yup.object().shape({
  work_1: yup.boolean(),
  work_2: yup.boolean(),
  personal_1: yup.boolean(),
  personal_2: yup.boolean(),
  facebook_check: yup.boolean(),
  instagram_check: yup.boolean(),
  work_1_notes: yup.string().max(350, "Must be less than 350 characters"),
  work_2_notes: yup.string().max(350, "Must be less than 350 characters"),
  personal_1_notes: yup.string().max(350, "Must be less than 350 characters"),
  personal_2_notes: yup.string().max(350, "Must be less than 350 characters"),
  hire_1: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  work_ethic_1: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  consistency_1: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  hire_2: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  work_ethic_2: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  consistency_2: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  hire_3: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  work_ethic_3: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  consistency_3: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  hire_4: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  work_ethic_4: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  consistency_4: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
  facebook_date: yup.string().nullable(),
  instagram_date: yup.string().nullable(),
  desc: yup
    .string()
    .matches(/^\s*\S.*$|^$/, "* This field cannot contain only blankspaces"),
});

const SubInterviewTabs = (props) => {
  const {
    hash,
    formId,
    user,
    applicant,
    setCurrentView,
    isNew,
    setIsNew,
    newFormId,
    setNewFormId,
    setIsSubmittable,
    isSubmittable,
  } = props;
  const interviewForm = useSelector((state) => getInterviewForm(state));

  const dispatch = useDispatch();

  const [key, setKey] = useState("referenceForm");
  const [isEnabled, setIsEnabled] = useState(false);
  const [isRefsOk, setIsRefsOk] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    reset,
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(refInterviewSchema),
    defaultValues: {
      work_1: false,
      work_1_notes: "",
      work_1_date: "",
      work_2: false,
      work_2_notes: "",
      work_2_date: "",
      personal_1: false,
      personal_1_notes: "",
      personal_1_date: "",
      personal_2: false,
      personal_2_notes: "",
      personal_2_date: "",
      // --------------------------------//
      hire_1: "",
      work_ethic_1: "",
      consistency_1: "",
      hire_2: "",
      work_ethic_2: "",
      consistency_2: "",
      hire_3: "",
      work_ethic_3: "",
      consistency_3: "",
      hire_4: "",
      work_ethic_4: "",
      consistency_4: "",
      // --------------------------------//
      facebook: false,
      facebook_date: "",
      instagram: false,
      instagram_date: "",
      desc: "",
    },
  });

  const fetchInterviewForm = () => {
    dispatch(interviewActions.getInterviewForm(formId));
  };

  useEffect(() => {
    if (!isNew) {
      fetchInterviewForm();
    }
  }, []);

  const onSubmit = (data) => {
    setLoading(true);
    const form = {
      work_reference_1_employer: applicant?.reference1?.last_employee ?? "",
      work_reference_1_position: applicant?.reference1?.position ?? "",
      work_reference_1_supervisor: applicant?.reference1?.supervisor_name ?? "",
      work_reference_1_phone: applicant?.reference1?.telephone ?? "",
      work_reference_1_verified: work_1 ? 1 : 0,
      work_reference_1_notes: data.work_1_notes ?? "",
      work_reference_1_date_verified: data.work_1_date?._d
        ? moment(data.work_1_date._d).format(DATE_FORMAT)
        : "",
      work_reference_2_employer: applicant?.reference2?.last_employee ?? "",
      work_reference_2_position: applicant?.reference2?.position ?? "",
      work_reference_2_supervisor: applicant?.reference2?.supervisor_name ?? "",
      work_reference_2_phone: applicant?.reference2?.telephone ?? "",
      work_reference_2_verified: work_2 ? 1 : 0,
      work_reference_2_notes: data.work_2_notes ?? "",
      work_reference_2_date_verified: data.work_2_date?._d
        ? moment(data.work_2_date._d).format(DATE_FORMAT)
        : "",
      reference_1_name: applicant?.personalreference1?.name ?? "",
      reference_1_relation: applicant?.personalreference1?.relationship ?? "",
      reference_1_phone: applicant?.personalreference1?.phone ?? "",
      reference_1_verified: personal_1 ? 1 : 0,
      reference_1_notes: data.personal_1_notes ?? "",
      reference_1_date_verified: data.personal_1_date?._d
        ? moment(data.personal_1_date._d).format(DATE_FORMAT)
        : "",
      reference_2_name: applicant?.personalreference2?.name ?? "",
      reference_2_relation: applicant?.personalreference2?.relationship ?? "",
      reference_2_phone: applicant?.personalreference2?.phone ?? "",
      reference_2_verified: personal_2 ? 1 : 0,
      reference_2_notes: data.personal_2_notes ?? "",
      reference_2_date_verified: data.personal_2_date?._d
        ? moment(data.personal_2_date._d).format(DATE_FORMAT)
        : "",
      work_reference_one_question_1: data.hire_1 ?? "",
      work_reference_one_question_2: data.work_ethic_1 ?? "",
      work_reference_one_question_3: data.consistency_1 ?? "",
      work_reference_two_question_1: data.hire_2 ?? "",
      work_reference_two_question_2: data.work_ethic_2 ?? "",
      work_reference_two_question_3: data.consistency_2 ?? "",
      professional_reference_one_question_1: data.hire_3 ?? "",
      professional_reference_one_question_2: data.work_ethic_3 ?? "",
      professional_reference_one_question_3: data.consistency_3 ?? "",
      professional_reference_two_question_1: data.hire_4 ?? "",
      professional_reference_two_question_2: data.work_ethic_4 ?? "",
      professional_reference_two_question_3: data.consistency_4 ?? "",
      facebook_check: data.facebook_check ? 1 : 0,
      facebook_date: data.facebook_date
        ? moment(data.facebook_date).format(DATE_FORMAT)
        : "",
      instagram_check: data.instagram_check ? 1 : 0,
      instagram_date: data.instagram_date
        ? moment(data.instagram_date).format(DATE_FORMAT)
        : "",
      social_media_notes: data.desc ?? "",
    };

    const requestData = {
      applicant_id: hash,
      date_of_interview:
        applicant?.date_of_interview || moment().format(DATE_FORMAT),
      date_of_submission: moment().format(DATE_FORMAT),
      interviewer: user?.id,
      status: 2,
      form_id: formId,
      form: form,
    };

    dispatch(interviewActions.saveInterviewForm(requestData)).then((res) => {
      if (res.success) {
        setNewFormId(res.data?.form_id);
        setKey("interviewForm");
        setIsNew(false);
        setIsSubmittable(isFormFilled(form));
        return;
      } else {
        toast.error(res.message);
      }
    });
    // }
    setLoading(false);
  };

  const onCancelClick = () => {
    setCurrentView("listView");
    setIsNew(false);
  };

  const checkReferences = () => {
    const references = [
      applicant?.reference1.last_employee,
      applicant?.reference1.position,
      applicant?.reference1.supervisor_name,
      applicant?.reference1.telephone,
      applicant?.reference2.last_employee,
      applicant?.reference2.position,
      applicant?.reference2.supervisor_name,
      applicant?.reference2.telephone,
      applicant?.personalreference1.name,
      applicant?.personalreference1.relationship,
      applicant?.personalreference1.phone,
      applicant?.personalreference2.name,
      applicant?.personalreference2.relationship,
      applicant?.personalreference2.phone,
    ];
    const isAllRefsAvailable = references.every((item) => item.length);
    if (!isAllRefsAvailable) {
      setIsRefsOk(false);
      reset({
        work_1: false,
        work_2: false,
        personal_1: false,
        personal_2: false,
      });
      return;
    }
    setIsRefsOk(true);
  };

  const work_1 = watch("work_1");
  const work_2 = watch("work_2");
  const personal_1 = watch("personal_1");
  const personal_2 = watch("personal_2");

  useEffect(() => {
    if (applicant && interviewForm) {
      const form = {
        work_reference_1_employer: applicant?.reference1?.last_employee ?? "",
        work_reference_1_position: applicant?.reference1?.position ?? "",
        work_reference_1_supervisor:
          applicant?.reference1?.supervisor_name ?? "",
        work_reference_1_phone: applicant?.reference1?.telephone ?? "",
        work_reference_1_verified: work_1 ? 1 : 0,
        work_reference_1_notes:
          interviewForm?.form?.work_reference_1_notes ?? "",
        work_reference_1_date_verified: interviewForm?.form
          ?.work_reference_1_date_verified
          ? moment(interviewForm?.form?.work_1_date).format(DATE_FORMAT)
          : "",
        work_reference_2_employer: applicant?.reference2?.last_employee ?? "",
        work_reference_2_position: applicant?.reference2?.position ?? "",
        work_reference_2_supervisor:
          applicant?.reference2?.supervisor_name ?? "",
        work_reference_2_phone: applicant?.reference2?.telephone ?? "",
        work_reference_2_verified: work_2 ? 1 : 0,
        work_reference_2_notes:
          interviewForm?.form?.work_reference_2_notes ?? "",
        work_reference_2_date_verified: interviewForm?.form
          ?.work_reference_2_date_verified
          ? moment(interviewForm?.form?.work_reference_2_date_verified).format(
              DATE_FORMAT,
            )
          : "",
        reference_1_name: applicant?.personalreference1?.name ?? "",
        reference_1_relation: applicant?.personalreference1?.relationship ?? "",
        reference_1_phone: applicant?.personalreference1?.phone ?? "",
        reference_1_verified: personal_1 ? 1 : 0,
        reference_1_notes: interviewForm?.form?.reference_1_notes ?? "",
        reference_1_date_verified: interviewForm?.form
          ?.reference_1_date_verified
          ? moment(interviewForm?.form?.reference_1_date_verified).format(
              DATE_FORMAT,
            )
          : "",
        reference_2_name: applicant?.personalreference2?.name ?? "",
        reference_2_relation: applicant?.personalreference2?.relationship ?? "",
        reference_2_phone: applicant?.personalreference2?.phone ?? "",
        reference_2_verified: personal_2 ? 1 : 0,
        reference_2_notes: interviewForm?.form?.reference_2_notes ?? "",
        reference_2_date_verified: interviewForm?.form
          ?.reference_2_date_verified
          ? moment(interviewForm?.form?.reference_2_date_verified).format(
              DATE_FORMAT,
            )
          : "",
        work_reference_one_question_1:
          interviewForm?.form?.work_reference_one_question_1 ?? "",
        work_reference_one_question_2:
          interviewForm?.form?.work_reference_one_question_2 ?? "",
        work_reference_one_question_3:
          interviewForm?.form?.work_reference_one_question_3 ?? "",
        work_reference_two_question_1:
          interviewForm?.form?.work_reference_two_question_1 ?? "",
        work_reference_two_question_2:
          interviewForm?.form?.work_reference_two_question_2 ?? "",
        work_reference_two_question_3:
          interviewForm?.form?.work_reference_two_question_3 ?? "",
        professional_reference_one_question_1:
          interviewForm?.form?.professional_reference_one_question_1 ?? "",
        professional_reference_one_question_2:
          interviewForm?.form?.professional_reference_one_question_2 ?? "",
        professional_reference_one_question_3:
          interviewForm?.form?.professional_reference_one_question_3 ?? "",
        professional_reference_two_question_1:
          interviewForm?.form?.professional_reference_two_question_1 ?? "",
        professional_reference_two_question_2:
          interviewForm?.form?.professional_reference_two_question_2 ?? "",
        professional_reference_two_question_3:
          interviewForm?.form?.professional_reference_two_question_3 ?? "",
        facebook_check: interviewForm?.form?.facebook_check ? 1 : 0,
        facebook_date: interviewForm?.form?.facebook_date
          ? moment(interviewForm?.form?.facebook_date).format(DATE_FORMAT)
          : "",
        instagram_check: interviewForm?.form?.instagram_check ? 1 : 0,
        instagram_date: interviewForm?.form?.instagram_date
          ? moment(interviewForm?.form?.instagram_date).format(DATE_FORMAT)
          : "",
        social_media_notes: interviewForm?.form?.social_media_notes ?? "",
      };
      setIsSubmittable(isFormFilled(form));
    }
  }, [interviewForm]);

  useEffect(() => {
    // if isNew, user don't have access to only referenceForm first.
    if (isNew) {
      setIsEnabled(true);
      return;
    }
    setIsEnabled(false);
  }, [isNew]);

  useEffect(() => {
    if (interviewForm?.form && !isNew) {
      reset({
        work_1: interviewForm?.form?.work_reference_1_verified ?? "",
        work_1_notes: interviewForm?.form?.work_reference_1_notes ?? "",
        work_1_date: interviewForm?.form?.work_reference_1_date_verified
          ? moment(interviewForm.form.work_reference_1_date_verified)
          : "",
        work_2: interviewForm?.form?.work_reference_2_verified ?? "",
        work_2_notes: interviewForm?.form?.work_reference_2_notes ?? "",
        work_2_date: interviewForm?.form?.work_reference_2_date_verified
          ? moment(interviewForm.form.work_reference_2_date_verified)
          : "",
        personal_1: interviewForm?.form?.reference_1_verified ?? "",
        personal_1_notes: interviewForm?.form?.reference_1_notes ?? "",
        personal_1_date: interviewForm?.form?.reference_1_date_verified
          ? moment(interviewForm.form.reference_1_date_verified)
          : "",
        personal_2: interviewForm?.form?.reference_2_verified ?? "",
        personal_2_notes: interviewForm?.form?.reference_2_notes ?? "",
        personal_2_date: interviewForm?.form?.reference_2_date_verified
          ? moment(interviewForm.form.reference_2_date_verified)
          : "",
        hire_1: interviewForm?.form?.work_reference_one_question_1 ?? "",
        work_ethic_1: interviewForm?.form?.work_reference_one_question_2 ?? "",
        consistency_1: interviewForm?.form?.work_reference_one_question_3 ?? "",
        hire_2: interviewForm?.form?.work_reference_two_question_1 ?? "",
        work_ethic_2: interviewForm?.form?.work_reference_two_question_2 ?? "",
        consistency_2: interviewForm?.form?.work_reference_two_question_3 ?? "",
        hire_3:
          interviewForm?.form?.professional_reference_one_question_1 ?? "",
        work_ethic_3:
          interviewForm?.form?.professional_reference_one_question_2 ?? "",
        consistency_3:
          interviewForm?.form?.professional_reference_one_question_3 ?? "",
        hire_4:
          interviewForm?.form?.professional_reference_two_question_1 ?? "",
        work_ethic_4:
          interviewForm?.form?.professional_reference_two_question_2 ?? "",
        consistency_4:
          interviewForm?.form?.professional_reference_two_question_3 ?? "",
        facebook_check: interviewForm?.form?.facebook_check ?? "",
        facebook_date: interviewForm?.form?.facebook_date
          ? moment(interviewForm.form.facebook_date)
          : "",
        instagram_check: interviewForm?.form?.instagram_check ?? "",
        instagram_date: interviewForm?.form?.instagram_date
          ? moment(interviewForm.form.instagram_date)
          : "",
        desc: interviewForm?.form?.social_media_notes ?? "",
      });
    }
  }, [interviewForm]);

  const isFormFilled = (obj) => {
    for (const key in obj) {
      if (
        (obj.hasOwnProperty(key) &&
          (obj[key] === null || obj[key] === undefined || obj[key] === "")) ||
        obj[key] === 0
      ) {
        return false; // If any key has a null, undefined, or empty string value, return false
      }
    }
    return true; // If all keys have a value, return true
  };

  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 interview_form_wrapper"
      >
        <Tab eventKey="referenceForm" title="Reference Form">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="ref_Form_wrapper">
              <h3>Work/Personal References</h3>
              <p className="note_para">
                <b>Note:</b> It is mandatory to call and verify all the
                references.
                <div>
                  {!isRefsOk && (
                    <p className="refs_not_ok">
                      Please fill all the reference details for submitting the
                      interview form. Please edit the profile OR edit the job
                      application for entering the reference details.{" "}
                    </p>
                  )}
                </div>
              </p>{" "}
              <div></div>
              {/* -----------------1---------------- */}{" "}
              <Row className="show-grid interview__grid">
                <div className="interview__head">
                  <h1>Reference #1 (Past Work History)</h1>
                </div>
                <Col className="col_table">
                  <div className="interview__wrap">
                    <table className="ref_table">
                      <thead>
                        {/* <tr>
                        <th colSpan={2}>Reference #1 (Past Work History)</th>
                      </tr> */}
                      </thead>
                      <tbody>
                        <tr>
                          <td>Employer</td>
                          <td>
                            {applicant?.reference1?.last_employee || "-" || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>Position Held</td>
                          <td>
                            {applicant?.reference1?.position || "-" || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>Supervisor</td>
                          <td>
                            {applicant?.reference1?.supervisor_name ||
                              "-" ||
                              "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>Cell Phone</td>
                          <td>{applicant?.reference1?.telephone || "-"}</td>
                        </tr>
                        <tr>
                          <td>Verified</td>
                          <td>
                            <div className="checkbox_wrap">
                              <input
                                type="checkbox"
                                {...register("work_1")}
                                className={`checkbox_input ${
                                  errors?.work_1?.message ? "error" : ""
                                }`}
                              />{" "}
                              <label className="checkbox_label"></label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Verified date</td>
                          <td>
                            <div className="verified_date">
                              <Controller
                                control={control}
                                name="work_1_date"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <DatePicker
                                    className="datepicker_ref"
                                    placeholderText="Date"
                                    ref={ref}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={moment(new Date())}
                                  />
                                )}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>

                <div className="referenceCheckSection">
                  <Row className="show-grid ">
                    <Col>
                      <p>Would you hire them again?</p>
                      <input
                        type="text"
                        {...register("hire_1")}
                        className={`${errors.hire_1?.message ? "error" : ""}`}
                      />
                    </Col>
                    <Col>
                      <p>How would you describe their work ethic?</p>
                      <input
                        type="text"
                        {...register("work_ethic_1")}
                        className={`${
                          errors.work_ethic_1?.message ? "error" : ""
                        }`}
                      />
                    </Col>
                    <Col>
                      <p>Did they show up on time consistently?</p>
                      <input
                        type="text"
                        {...register("consistency_1")}
                        className={`${
                          errors.consistency_1?.message ? "error" : ""
                        }`}
                      />
                    </Col>
                  </Row>
                </div>

                <Col className="col_note">
                  <p>Notes</p>
                  <textarea
                    id=""
                    name=""
                    {...register("work_1_notes")}
                    className={`${errors.work_1_notes?.message ? "error" : ""}`}
                  ></textarea>
                  <span className="text-area-error-msg">
                    {errors?.work_1_notes?.message}
                  </span>
                </Col>
              </Row>{" "}
              {/* -----------------2---------------- */}{" "}
              <Row className="show-grid interview__grid">
                <div className="interview__head">
                  <h1>Reference #2 (Past Work History)</h1>
                </div>
                <Col className="col_table">
                  <div className="interview__wrap">
                    <table className="ref_table">
                      <thead>
                        {/* <tr>
                        <th colSpan={2}>Reference #2 (Work)</th>
                      </tr> */}
                      </thead>
                      <tbody>
                        <tr>
                          <td>Employer</td>
                          <td>{applicant?.reference2?.last_employee || "-"}</td>
                        </tr>
                        <tr>
                          <td>Position Held</td>
                          <td>{applicant?.reference2?.position || "-"}</td>
                        </tr>
                        <tr>
                          <td>Supervisor</td>
                          <td>
                            {applicant?.reference2?.supervisor_name || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>Cell Phone</td>
                          <td>{applicant?.reference2?.telephone || "-"}</td>
                        </tr>
                        <tr>
                          <td>Verified</td>
                          <td>
                            <div className="checkbox_wrap">
                              <input
                                type="checkbox"
                                {...register("work_2")}
                                className={`checkbox_input ${
                                  errors?.work_2?.message ? "error" : ""
                                }`}
                              />
                              <label className="checkbox_label"></label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Verified date</td>
                          <td>
                            <div className="verified_date">
                              <Controller
                                control={control}
                                name="work_2_date"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <DatePicker
                                    ref={ref}
                                    placeholderText="Date"
                                    onChange={onChange}
                                    className="datepicker_ref"
                                    onBlur={onBlur}
                                    selected={value}
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={moment(new Date())}
                                  />
                                )}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>

                <div className="referenceCheckSection">
                  <Row className="show-grid">
                    <Col>
                      <p>Would you hire them again?</p>
                      <input
                        type="text"
                        {...register("hire_2")}
                        className={`${errors.hire_2?.message ? "error" : ""}`}
                      />
                    </Col>
                    <Col>
                      <p>How would you describe their work ethic?</p>
                      <input
                        type="text"
                        {...register("work_ethic_2")}
                        className={`${
                          errors.work_ethic_2?.message ? "error" : ""
                        }`}
                      />
                    </Col>
                    <Col>
                      <p>Did they show up on time consistently?</p>
                      <input
                        type="text"
                        {...register("consistency_2")}
                        className={`${
                          errors.consistency_2?.message ? "error" : ""
                        }`}
                      />
                    </Col>
                  </Row>
                </div>

                <Col className="col_note">
                  <p>Notes</p>
                  <textarea
                    id=""
                    className={`${errors.work_2_notes?.message ? "error" : ""}`}
                    name=""
                    {...register("work_2_notes")}
                  ></textarea>
                  <span className="text-area-error-msg">
                    {errors?.work_2_notes?.message}
                  </span>
                </Col>
              </Row>{" "}
              {/* -----------------3---------------- */}{" "}
              <Row className="show-grid interview__grid">
                <div className="interview__head">
                  <h1>Reference #3 (Professional)</h1>
                </div>
                <Col className="col_table">
                  <div className="interview__wrap">
                    <table className="ref_table">
                      <thead>
                        {/* <tr>
                        <th colSpan={2}>Reference #3 (Personal)</th>
                      </tr> */}
                      </thead>
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{applicant?.personalreference1.name || "-"}</td>
                        </tr>
                        <tr>
                          <td>Relationship</td>
                          <td>
                            {applicant?.personalreference1.relationship || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>Cell Phone</td>
                          <td>{applicant?.personalreference1.phone || "-"}</td>
                        </tr>
                        <tr>
                          <td>Verified</td>
                          <td>
                            <div className="checkbox_wrap">
                              <input
                                type="checkbox"
                                {...register("personal_1")}
                                className={`checkbox_input ${
                                  errors?.personal_1?.message ? "error" : ""
                                }`}
                              />
                              <label className="checkbox_label"></label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Verified date</td>
                          <td>
                            <div className="verified_date">
                              <Controller
                                control={control}
                                name="personal_1_date"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <DatePicker
                                    ref={ref}
                                    placeholderText="Date"
                                    onChange={onChange}
                                    className="datepicker_ref"
                                    onBlur={onBlur}
                                    selected={value}
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={moment(new Date())}
                                  />
                                )}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>
                <div className="referenceCheckSection">
                  <Row className="show-grid">
                    <Col>
                      <p>Would you hire them again?</p>
                      <input
                        type="text"
                        {...register("hire_3")}
                        className={`${errors.hire_3?.message ? "error" : ""}`}
                      />
                    </Col>
                    <Col>
                      <p>How would you describe their work ethic?</p>
                      <input
                        type="text"
                        {...register("work_ethic_3")}
                        className={`${
                          errors.work_ethic_3?.message ? "error" : ""
                        }`}
                      />
                    </Col>
                    <Col>
                      <p>Did they show up on time consistently?</p>
                      <input
                        type="text"
                        {...register("consistency_3")}
                        className={`${
                          errors.consistency_3?.message ? "error" : ""
                        }`}
                      />
                    </Col>
                  </Row>
                </div>
                <Col className="col_note">
                  <p>Notes</p>
                  <textarea
                    id=""
                    name=""
                    rows="6"
                    cols="33"
                    {...register("personal_1_notes")}
                    className={`${
                      errors.personal_1_notes?.message ? "error" : ""
                    }`}
                  ></textarea>
                  <span className="text-area-error-msg">
                    {errors?.personal_1_notes?.message}
                  </span>
                </Col>
              </Row>{" "}
              {/* ----------------4----------------- */}
              <Row className="show-grid last_item interview__grid">
                <div className="interview__head">
                  <h1>Reference #4 (Professional)</h1>
                </div>
                <Col className="col_table">
                  <div className="interview__wrap">
                    <table className="ref_table">
                      <thead>
                        {/* <tr>
                        <th colSpan={2}>Reference #4 (Personal)</th>
                      </tr> */}
                      </thead>
                      <tbody>
                        <tr>
                          <td>Name</td>
                          <td>{applicant?.personalreference2.name || "-"}</td>
                        </tr>
                        <tr>
                          <td>Relationship</td>
                          <td>
                            {applicant?.personalreference2.relationship || "-"}
                          </td>
                        </tr>
                        <tr>
                          <td>Cell Phone</td>
                          <td>{applicant?.personalreference2.phone || "-"}</td>
                        </tr>
                        <tr>
                          <td>Verified</td>
                          <td>
                            <div className="checkbox_wrap">
                              <input
                                type="checkbox"
                                {...register("personal_2")}
                                className={`checkbox_input ${
                                  errors?.personal_2?.message ? "error" : ""
                                }`}
                              />
                              <label className="checkbox_label"></label>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Verified date</td>
                          <td>
                            <div className="verified_date">
                              <Controller
                                control={control}
                                name="personal_2_date"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <DatePicker
                                    ref={ref}
                                    placeholderText="Date"
                                    className="datepicker_ref"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    showMonthDropdown
                                    showYearDropdown
                                    maxDate={moment(new Date())}
                                  />
                                )}
                              />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Col>

                <div className="referenceCheckSection">
                  <Row className="show-grid">
                    <Col>
                      <p>Would you hire them again?</p>
                      <input
                        type="text"
                        {...register("hire_4")}
                        className={`${errors.hire_4?.message ? "error" : ""}`}
                      />
                    </Col>
                    <Col>
                      <p>How would you describe their work ethic?</p>
                      <input
                        type="text"
                        {...register("work_ethic_4")}
                        className={`${
                          errors.work_ethic_4?.message ? "error" : ""
                        }`}
                      />
                    </Col>
                    <Col>
                      <p>Did they show up on time consistently?</p>
                      <input
                        type="text"
                        {...register("consistency_4")}
                        className={`${
                          errors.consistency_4?.message ? "error" : ""
                        }`}
                      />
                    </Col>
                  </Row>
                </div>

                <Col className="col_note">
                  <p>Notes</p>
                  <textarea
                    id=""
                    name=""
                    rows="6"
                    cols="33"
                    {...register("personal_2_notes")}
                    className={`${
                      errors.personal_2_notes?.message ? "error" : ""
                    }`}
                  ></textarea>
                  <span className="text-area-error-msg">
                    {errors?.personal_2_notes?.message}
                  </span>
                </Col>
              </Row>
              <div className="referenceCheckSection social_media">
                <h4>Social Media Check</h4>
                <Row className="show-grid">
                  <Col>
                    <div className="checkbox_wrap">
                      <p>Facebook</p>
                      <input
                        type="checkbox"
                        {...register("facebook_check")}
                        className={`checkbox_input ${
                          errors?.facebook_check?.message ? "error" : ""
                        }`}
                      />{" "}
                      <label className="checkbox_label"></label>
                    </div>
                    <div className="facebook_date">
                      <Controller
                        control={control}
                        name="facebook_date"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <DatePicker
                            // className="datepicker_ref"
                            {...register("facebook_date")}
                            className={`${
                              errors.facebook_date?.message ? "error" : ""
                            }`}
                            ref={ref}
                            placeholderText="Date"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            showMonthDropdown
                            showYearDropdown
                            maxDate={moment(new Date())}
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div className="checkbox_wrap">
                      <p>Instagram</p>
                      <input
                        type="checkbox"
                        {...register("instagram_check")}
                        className={`checkbox_input ${
                          errors?.instagram_check?.message ? "error" : ""
                        }`}
                      />{" "}
                      <label className="checkbox_label"></label>
                    </div>
                    <div className="facebook_date">
                      <Controller
                        control={control}
                        name="instagram_date"
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <DatePicker
                            className={`${
                              errors.instagram_date?.message ? "error" : ""
                            }`}
                            {...register("instagram_date")}
                            ref={ref}
                            placeholderText="Date"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            showMonthDropdown
                            showYearDropdown
                            maxDate={moment(new Date())}
                          />
                        )}
                      />
                    </div>
                  </Col>
                  <Col xs={12} md={0}>
                    <p>Notes</p>
                    <textarea
                      className={`${errors.desc?.message ? "error" : ""}`}
                      id=""
                      name=""
                      rows="5"
                      cols="33"
                      {...register("desc")}
                    />
                  </Col>
                </Row>
              </div>
              <div className="ref_form_btn">
                <div className="interview_btn">
                  <button className="button" onClick={onCancelClick}>
                    {" "}
                    Cancel{" "}
                  </button>
                  <button className="button" type="submit" disabled={loading}>
                    {" "}
                    Next{" "}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Tab>
        <Tab
          eventKey="interviewForm"
          title="Interview Form"
          disabled={isEnabled}
        >
          <InterviewForm
            hash={props.hash}
            applicant={applicant}
            setCurrentView={setCurrentView}
            editMode={props.editMode}
            user={user}
            formId={props.formId}
            isNew={isNew}
            setIsNew={setIsNew}
            setKey={setKey}
            getInterviewFormList={props.getInterviewFormList}
            newFormId={newFormId}
            isSubmittable={isSubmittable}
          />
        </Tab>
      </Tabs>
    </>
  );
};

export default SubInterviewTabs;
