import React from "react";

import { convertDateTimeToPST } from "_helpers/helpers";

import { useUpdateProfile } from "./EditProfile";

const NotesComponent = ({
  profileData,
  refetchUser,
  isOpen,
  toggleAccordion,
}) => {
  const [note, setNote] = React.useState("");
  const [displayAllNotes, setDisplayAllNotes] = React.useState(false);

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const mutation = useUpdateProfile(refetchUser, () => {
    setNote("");
  });

  const toggleAllNotesDisplay = () => {
    setDisplayAllNotes(!displayAllNotes);
  };

  const saveNote = () => {
    let data = new FormData();
    data.append("note", note.trim());
    mutation.mutate({ userId: profileData.id, formData: data });
  };

  const cancelNote = () => {
    setNote("");
  };

  return (
    <div
      id="notes"
      className={`profile-data__drops profile-data_comp profile-data__drops--notes ${
        isOpen ? "is-active" : ""
      }`}
    >
      <header onClick={() => toggleAccordion("#notes")}>
        <button type="button">
          <h3>Notes</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <div className="profile-data__form-wrap is-active">
          <textarea
            maxLength="1200"
            type="text"
            autoComplete="off"
            placeholder="Add your note here"
            value={note}
            onChange={handleNoteChange}
          ></textarea>
          {note.trim().length ? (
            <footer>
              <button
                onClick={cancelNote}
                type="button"
                className="l-btn l-btn--close"
              >
                Cancel
              </button>
              <button onClick={saveNote} type="button" className="l-btn">
                Save
              </button>
            </footer>
          ) : (
            <></>
          )}
        </div>
        <ul role="list">
          {profileData.notes.length && profileData.notes[0] ? (
            <li role="listitem">
              <div className="profile-data__note">
                <header>
                  <h4>
                    Note
                    {profileData.notes[0].author_user ? (
                      " by " + profileData.notes[0].author_user.full_name
                    ) : (
                      <></>
                    )}
                  </h4>
                  <div className="profile-data__note-dates">
                    <span>
                      {profileData.notes[0].author_user
                        ? convertDateTimeToPST(profileData.notes[0].created_at)
                        : ""}
                    </span>
                  </div>
                </header>
                <p>{profileData.notes[0].note}</p>
              </div>
            </li>
          ) : (
            <></>
          )}
          {displayAllNotes &&
            profileData.notes.length > 1 &&
            profileData.notes.slice(1).map((noteItem, index) => (
              <li role="listitem">
                <div className="profile-data__note">
                  <header>
                    <h4>
                      Note
                      {noteItem.author_user ? (
                        " by " + noteItem.author_user.full_name
                      ) : (
                        <></>
                      )}
                    </h4>
                    <div className="profile-data__note-dates">
                      <span>
                        {noteItem.author_user
                          ? convertDateTimeToPST(noteItem.created_at)
                          : ""}
                      </span>
                    </div>
                  </header>
                  <p>{noteItem.note}</p>
                </div>
              </li>
            ))}
        </ul>
      </div>
      <footer className={profileData.notes.length > 1 ? "footer-border" : ""}>
        {profileData.notes.length > 1 && (
          <button onClick={toggleAllNotesDisplay} type="button">
            Show {displayAllNotes ? "Less" : "All"}
          </button>
        )}
      </footer>
    </div>
  );
};

export default NotesComponent;
