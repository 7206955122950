import React from "react";

import { EditProfile } from "./EditProfile";

import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";

import { checkPastDate, formatDate } from "_helpers/helpers";
import { dateTimeConstants } from "_constants";
import moment from "moment";

const VerifiedDocumentsComponent = ({
  profileData,
  refetchUser,
  isOpen,
  toggleAccordion,
  permList,
  authUser,
}) => {
  return (
    <div
      id="verified-documents"
      className={`profile-data__drops profile-data_comp ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#verified-documents")}>
        <button type="button">
          <h3>Verified Documents</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>Drug Screening</b>
            </span>
            <span className={!profileData.drug_screening_doc ? "expired" : ""}>
              {(profileData.drug_screening_doc ? "Verified" : "Not Verified") +
                (profileData.drug_screening_doc &&
                profileData.drug_screening_doc_date
                  ? " - " + formatDate(profileData.drug_screening_doc_date)
                  : "")}
            </span>
            <EditProfile
              label="Drug Screening"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Drug Screening Verification Status</label>
                  <select
                    {...register("drug_screening_doc", {
                      required:
                        "Drug screening verification status is required",
                    })}
                    defaultValue={profileData.drug_screening_doc}
                    className={
                      errors.drug_screening_doc && "profile-form-field-error"
                    }
                  >
                    <option value="0">Not Verified</option>
                    <option value="1">Verified</option>
                  </select>
                  {errors.drug_screening_doc && (
                    <p className="error-message">
                      {errors.drug_screening_doc.message}
                    </p>
                  )}

                  <label>Drug Screening Verification Date</label>
                  <Controller
                    name="drug_screening_doc_date"
                    control={control}
                    defaultValue={
                      profileData.drug_screening_doc_date
                        ? moment(profileData.drug_screening_doc_date).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Drug Screening Verification Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.drug_screening_doc_date && (
                    <p className="error-message">
                      {errors.drug_screening_doc_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>ID/I9</b>
            </span>
            <span className={!profileData.i9_doc ? "expired" : ""}>
              {(profileData.i9_doc ? "Verified" : "Not Verified") +
                (profileData.i9_doc && profileData.i9_doc_date
                  ? " - " + formatDate(profileData.i9_doc_date)
                  : "")}
            </span>
            <EditProfile
              label="ID/I9 Screening"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>ID/I9 Verification Status</label>
                  <select
                    {...register("i9_doc", {
                      required: "ID/I9 verification status is required",
                    })}
                    defaultValue={profileData.i9_doc}
                    className={errors.i9_doc && "profile-form-field-error"}
                  >
                    <option value="0">Not Verified</option>
                    <option value="1">Verified</option>
                  </select>
                  {errors.i9_doc && (
                    <p className="error-message">{errors.i9_doc.message}</p>
                  )}

                  <label>ID/I9 Verification Date</label>
                  <Controller
                    name="i9_doc_date"
                    control={control}
                    defaultValue={
                      profileData.i9_doc_date
                        ? moment(profileData.i9_doc_date).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select ID/I9 Verification Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.i9_doc_date && (
                    <p className="error-message">
                      {errors.i9_doc_date.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Onboarding Documents</b>
            </span>
            <span className={!profileData.onboarding_doc ? "expired" : ""}>
              {(profileData.onboarding_doc ? "Verified" : "Not Verified") +
                (profileData.onboarding_doc && profileData.docs_approved_at
                  ? " - " + formatDate(profileData.docs_approved_at)
                  : "")}
            </span>
            <EditProfile
              label="Onboarding Documents"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={false}
              hasPermissionToEditOthers={permList.includes(
                "Manage Expiry/Assigned Dates",
              )}
            >
              {(register, errors, setValue, control) => (
                <>
                  <label>Onboarding Documents Verification Status</label>
                  <select
                    {...register("onboarding_doc", {
                      required:
                        "Onboarding documents verification status is required",
                    })}
                    defaultValue={profileData.onboarding_doc}
                    className={
                      errors.onboarding_doc && "profile-form-field-error"
                    }
                  >
                    <option value="0">Not Verified</option>
                    <option value="1">Verified</option>
                  </select>
                  {errors.onboarding_doc && (
                    <p className="error-message">
                      {errors.onboarding_doc.message}
                    </p>
                  )}

                  <label>Onboarding Documents Verification Date</label>
                  <Controller
                    name="docs_approved_at"
                    control={control}
                    defaultValue={
                      profileData.docs_approved_at
                        ? moment(profileData.docs_approved_at).format(
                            dateTimeConstants.DATE_FORMAT,
                          )
                        : null
                    }
                    render={({
                      field: { onChange, value },
                      fieldState: { error },
                    }) => (
                      <DatePicker
                        selected={value ? moment(value) : ""}
                        onChange={(date) =>
                          onChange(
                            date &&
                              !date.endOf("day").isAfter(moment().endOf("day"))
                              ? date.format(dateTimeConstants.DATE_FORMAT)
                              : "",
                          )
                        }
                        customInput={<input inputMode="none" />}
                        maxDate={moment()}
                        showMonthDropdown
                        showYearDropdown
                        placeholderText="Select Onboarding Documents Verification Date"
                        className={error ? "profile-form-field-error" : ""}
                        dateFormat={dateTimeConstants.DATE_FORMAT}
                        showPopperArrow={false}
                      />
                    )}
                  />
                  {errors.docs_approved_at && (
                    <p className="error-message">
                      {errors.docs_approved_at.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default VerifiedDocumentsComponent;
