import React, { Fragment, useEffect, useState } from "react";
import { config } from "_helpers/config";
import "../../assets/styles/docs.scss";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { docsActions, createProfileActions } from "_actions";
import "../../assets/styles/timeOfRequest.css";
import { FormNav } from "./FormNav";
import { SignPad } from "Docs/Components";
import DatePicker from "react-datepicker";
import moment from "moment";
import { handleNavigate, navigate } from "./formHandlers";
import { usPhone } from "_utils/formatter";
import { withRouter } from "react-router-dom";
import checkIfDocsShouldBeLocked from "_helpers/lockDocs";
import _, { deburr, fromPairs } from "lodash";

const DATE_FORMAT = "MM/DD/YYYY";
const errors = {};

const filters = {
  "Primary Beneficiary": false,
  "Contingent Beneficiary": false,
  Both: false,
};

const BeneficiaryForm = ({
  adminMode,
  mobileMode,
  lastPage,
  rehire,
  setScreen,
  isSingleDoc,
  doc,
  docs,
  resetForm,
  ...props
}) => {
  const dispatch = useDispatch();
  const [listFilters, setListFilters] = useState(filters);
  const [selectedFilterCheckbox, setSelectedFilterCheckbox] = useState("");
  const [formOwner, setFormOwner] = useState("");
  const [isDocumentLocked, setDocumentLocked] = useState(false);
  const [errors, setErrors] = useState({});

  const [formValues, setFormValues] = useState({
    from: "",
    number_of_pages: "",
    primary_beneficiary: "",
    contingent_beneficiary: "",
    both: "",
    first_name: "test",
    middle_initial: "",
    last_name: "",
    ssn: "",
    dob: "",
    telephone: "",
    email: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    policy_number: "",

    pb_first_name: "",
    pb_middle_initial: "",
    pb_last_name: "",
    pb_ssn: "",
    pb_dob: "",
    pb_telephone: "",
    pb_email: "",
    pb_address: "",
    pb_city: "",
    pb_state: "",
    pb_zip: "",
    pb_percentage: "",

    pb1_first_name: "",
    pb1_middle_initial: "",
    pb1_last_name: "",
    pb1_ssn: "",
    pb1_dob: "",
    pb1_telephone: "",
    pb1_email: "",
    pb1_address: "",
    pb1_city: "",
    pb1_state: "",
    pb1_zip: "",
    pb1_percentage: "",

    pb2_first_name: "",
    pb2_middle_initial: "",
    pb2_last_name: "",
    pb2_ssn: "",
    pb2_dob: "",
    pb2_telephone: "",
    pb2_email: "",
    pb2_address: "",
    pb2_city: "",
    pb2_state: "",
    pb2_zip: "",
    pb2_percentage: "",

    cb_first_name: "",
    cb_middle_initial: "",
    cb_last_name: "",
    cb_ssn: "",
    cb_dob: "",
    cb_telephone: "",
    cb_email: "",
    cb_address: "",
    cb_city: "",
    cb_state: "",
    cb_zip: "",
    cb_percentage: "",

    cb1_first_name: "",
    cb1_middle_initial: "",
    cb1_last_name: "",
    cb1_ssn: "",
    cb1_dob: "",
    cb1_telephone: "",
    cb1_email: "",
    cb1_address: "",
    cb1_city: "",
    cb1_state: "",
    cb1_zip: "",
    cb1_percentage: "",

    po_signature: "",
    po_name: "",
    po_date: "",
    po_ssn: "",
    po_dob: "",
    po_telephone: "",
    po_email: "",
    po_address: "",
    po_city: "",
    po_state: "",
    po_zip: "",

    disclaimer_check: "",
  });

  const handleFormChange = (e) => {
    // safety check for datepicker
    if (e.target && e.target.attributes["field"]) {
      const field = e.target.attributes["field"].value;
      const { value, checked, type } = e.target;

      setFormValues((prevFormValues) => {
        const nextFormValues = { ...prevFormValues };

        if (type === "checkbox") {
          nextFormValues[field] = checked;
        } else {
          nextFormValues[field] = value;
        }

        return nextFormValues;
      });
    }
  };

  const handleDateChange = (field, date) => {
    setFormValues((prevFormValues) => {
      const nextFormValues = { ...prevFormValues };

      if (date) {
        nextFormValues[field] = date.format("MM/DD/YYYY");
      } else {
        nextFormValues[field] = null;
      }

      return {
        ...nextFormValues,
      };
    });
  };

  const handlePhoneChange = (e) => {
    e.target.value = usPhone(e.target.value);
  };

  const handleSignature = (field, signature) => {
    setFormValues((prevFormValues) => {
      const nextFormValues = { ...prevFormValues };
      nextFormValues[field] = signature;

      return nextFormValues;
    });
  };

  const handleFilter = (key) => {
    const newCheckboxValues = { ...filters };
    Object.keys(newCheckboxValues).forEach((checkboxKey) => {
      newCheckboxValues[checkboxKey] = false;
    });

    setListFilters({ ...newCheckboxValues, [key]: true });
    setSelectedFilterCheckbox(key);
  };

  const validateForm = (form, errors) => {
    let errorMsg = "Please enter a valid value";
    let focusEl = null;

    Object.keys(form).forEach((index) => {
      let el = form[index];

      if (el.type == "radio" && el.required && !el.checked) {
        let field = el.attributes["name"].value;
        errors = {
          ...errors,
          [field]: "Please choose an option",
        };

        if (!focusEl) focusEl = el;
      } else if (el.required && !el.value) {
        let field = el.attributes["name"].value;
        errors = {
          ...errors,
          [field]: errorMsg,
        };

        if (!focusEl) focusEl = el;
      }
    });

    Object.keys(form).forEach((index) => {
      let el = form[index];
      if (el.type == "radio" && el.checked) {
        let field = el.attributes["name"].value;
        delete errors[field];
      }
    });

    if (focusEl) focusEl.focus();

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      !(
        formValues.primary_beneficiary ||
        formValues.contingent_beneficiary ||
        formValues.both
      )
    ) {
      return toast.error("Beneficiary Category is a required field.");
    }

    let err = validateForm(e.target, {});
    if (err && !_.isEmpty(err)) {
      setErrors(err);
      return toast.error("Please fix the validation errors.");
    } else {
      setErrors({});
    }

    if (!formValues.po_signature) {
      return toast.error("Policy Owner Signature is a required field.");
    }

    let all_primary_beneficiary_1_fields_filled =
      formValues.pb_first_name &&
      formValues.pb_last_name &&
      formValues.pb_ssn &&
      formValues.pb_dob &&
      formValues.pb_telephone &&
      formValues.pb_address &&
      formValues.pb_city &&
      formValues.pb_state &&
      formValues.pb_zip &&
      formValues.pb_percentage;
    let all_primary_beneficiary_2_fields_filled =
      formValues.pb1_first_name &&
      formValues.pb1_last_name &&
      formValues.pb1_ssn &&
      formValues.pb1_dob &&
      formValues.pb1_telephone &&
      formValues.pb1_address &&
      formValues.pb1_city &&
      formValues.pb1_state &&
      formValues.pb1_zip &&
      formValues.pb1_percentage;
    let any_primary_beneficiary_2_fields_filled =
      formValues.pb1_first_name ||
      formValues.pb1_middle_initial ||
      formValues.pb1_last_name ||
      formValues.pb1_ssn ||
      formValues.pb1_dob ||
      formValues.pb1_telephone ||
      formValues.pb1_address ||
      formValues.pb1_city ||
      formValues.pb1_state ||
      formValues.pb1_zip ||
      formValues.pb1_percentage;
    let all_primary_beneficiary_3_fields_filled =
      formValues.pb2_first_name &&
      formValues.pb2_last_name &&
      formValues.pb2_ssn &&
      formValues.pb2_dob &&
      formValues.pb2_telephone &&
      formValues.pb2_address &&
      formValues.pb2_city &&
      formValues.pb2_state &&
      formValues.pb2_zip &&
      formValues.pb2_percentage;
    let any_primary_beneficiary_3_fields_filled =
      formValues.pb2_first_name ||
      formValues.pb2_middle_initial ||
      formValues.pb2_last_name ||
      formValues.pb2_ssn ||
      formValues.pb2_dob ||
      formValues.pb2_telephone ||
      formValues.pb2_address ||
      formValues.pb2_city ||
      formValues.pb2_state ||
      formValues.pb2_zip ||
      formValues.pb2_percentage;

    if (formValues.primary_beneficiary || formValues.both) {
      if (!all_primary_beneficiary_1_fields_filled) {
        return toast.error(
          "Please fill all required fields of Primary Beneficiary 1.",
        );
      }

      if (
        any_primary_beneficiary_2_fields_filled &&
        !all_primary_beneficiary_2_fields_filled
      ) {
        return toast.error(
          "Please fill all the fields of Primary Beneficiary 2.",
        );
      }

      if (
        any_primary_beneficiary_3_fields_filled &&
        !all_primary_beneficiary_3_fields_filled
      ) {
        return toast.error(
          "Please fill all the fields of Primary Beneficiary 3.",
        );
      }

      if (
        +formValues.pb_percentage +
          +formValues.pb1_percentage +
          +formValues.pb2_percentage !=
        100
      ) {
        return toast.error(
          "The total percentage for all Primary Beneficiaries must equal 100%.",
        );
      }
    }

    let all_contingent_beneficiary_1_fields_filled =
      formValues.cb_first_name &&
      formValues.cb_last_name &&
      formValues.cb_ssn &&
      formValues.cb_dob &&
      formValues.cb_telephone &&
      formValues.cb_address &&
      formValues.cb_city &&
      formValues.cb_state &&
      formValues.cb_zip &&
      formValues.cb_percentage;
    let all_contingent_beneficiary_2_fields_filled =
      formValues.cb1_first_name &&
      formValues.cb1_last_name &&
      formValues.cb1_ssn &&
      formValues.cb1_dob &&
      formValues.cb1_telephone &&
      formValues.cb1_address &&
      formValues.cb1_city &&
      formValues.cb1_state &&
      formValues.cb1_zip &&
      formValues.cb1_percentage;
    let any_contingent_beneficiary_2_fields_filled =
      formValues.cb1_first_name ||
      formValues.cb1_middle_initial ||
      formValues.cb1_last_name ||
      formValues.cb1_ssn ||
      formValues.cb1_dob ||
      formValues.cb1_telephone ||
      formValues.cb1_address ||
      formValues.cb1_city ||
      formValues.cb1_state ||
      formValues.cb1_zip ||
      formValues.cb1_percentage;

    if (formValues.contingent_beneficiary || formValues.both) {
      if (!all_contingent_beneficiary_1_fields_filled) {
        return toast.error(
          "Please fill all required fields of Contingent Beneficiary 1.",
        );
      }

      if (
        any_contingent_beneficiary_2_fields_filled &&
        !all_contingent_beneficiary_2_fields_filled
      ) {
        return toast.error(
          "Please fill all required fields of Contingent Beneficiary 2.",
        );
      }

      if (+formValues.cb_percentage + +formValues.cb1_percentage != 100) {
        return toast.error(
          "The total percentage for all Contingent Beneficiaries must equal 100%.",
        );
      }
    }

    let data = {
      id: doc?.id,
      doc_type: doc?.docs_type_id,
      form: formValues,
      single_doc: isSingleDoc ? true : false,
      rehire: rehire ? true : false,
    };

    dispatch(docsActions.saveDoc(data)).then((res) => {
      if (res.success) {
        if (mobileMode && !lastPage) {
          setScreen("sidebar");
          navigate("next", rehire, props?.giss, {
            props: { docs, adminMode, resetForm, ...props },
          });
        } else if (lastPage) {
          if (adminMode) {
            props.history.push("/docs");
          } else {
            props.history.push("/dashboard");
          }
        } else {
          navigate("next", rehire, props?.giss, {
            props: { docs, adminMode, resetForm, ...props },
          });
        }
      } else {
        toast.error(res.message);
      }
    });
  };

  useEffect(() => {
    if (doc) {
      dispatch(docsActions.getForm(doc?.id))
        .then((res) => {
          if (res.success) {
            let formValuesData = res?.data?.form;
            let formOwner = res?.data?.form_owner;
            let userData = res?.data?.user;
            setFormValues(formValuesData);
            setFormOwner(formOwner);
            setFormValues((prevState) => ({
              ...prevState,
              first_name: userData?.first_name,
              last_name: userData?.last_name,
              middle_initial: userData?.middle_name,
              ssn: userData?.ssn,
              telephone: userData?.phone,
              policy_number: "E6064059",
              dob: userData?.dob,
              email: userData?.email,
              address: userData?.physical_address?.address,
              city: userData?.physical_address?.city,
              state: userData?.physical_address?.state,
              zip: userData?.physical_address?.zip,
              po_name:
                (userData?.first_name || "") +
                " " +
                (userData?.middle_name || "") +
                " " +
                (userData?.last_name || ""),
              po_ssn: userData?.ssn,
              po_dob: userData?.dob,
              po_telephone: userData?.phone,
              po_email: userData?.email,
              po_address: userData?.physical_address?.address,
              po_city: userData?.physical_address?.city,
              po_state: userData?.physical_address?.state,
              po_zip: userData?.physical_address?.zip,
              po_date: moment().format("MM/DD/YYYY"),
            }));
          }
        })
        .catch((err) => {
          toast.error(err?.message ?? "Error on loading data!", {
            position: "top-right",
            autoClose: 5000,
          });
        });
    }
  }, []);

  useEffect(() => {
    if (selectedFilterCheckbox == "Primary Beneficiary") {
      setFormValues((prevState) => ({
        ...prevState,
        primary_beneficiary: "1",
        contingent_beneficiary: "",
        both: "",
      }));
    }

    if (selectedFilterCheckbox == "Contingent Beneficiary") {
      setFormValues((prevState) => ({
        ...prevState,
        primary_beneficiary: "",
        contingent_beneficiary: "1",
        both: "",
      }));
    }

    if (selectedFilterCheckbox == "Both") {
      setFormValues((prevState) => ({
        ...prevState,
        primary_beneficiary: "",
        contingent_beneficiary: "",
        both: "1",
      }));
    }
  }, [selectedFilterCheckbox]);

  useEffect(() => {
    if (formValues.primary_beneficiary == "1") {
      setSelectedFilterCheckbox("Primary Beneficiary");
    }

    if (formValues.contingent_beneficiary == "1") {
      setSelectedFilterCheckbox("Contingent Beneficiary");
    }

    if (formValues.both == "1") {
      setSelectedFilterCheckbox("Both");
    }
  }, [formValues]);

  useEffect(() => {
    const shouldLockDocs = checkIfDocsShouldBeLocked(
      formOwner,
      formValues,
      adminMode,
    );
    setDocumentLocked(shouldLockDocs);
  }, [formOwner, formValues, adminMode]);

  return (
    <div className="doc_info-rh">
      {/* Title */}
      <div className="doc-title">
        <img
          src={`${config.assetUrl}icons/doc-title-img.svg`}
          className="doc-title-img"
        />
        <span
          className="close-button"
          onClick={() => {
            // props.resetForm()
            setScreen("sidebar");
          }}
        >
          <img src={`${config.assetUrl}icons/back-btn.svg`} />
        </span>
        <h3>Change of Beneficiary Form Page</h3>
      </div>

      {/* Form */}
      <div className="doc_info-form">
        <form
          className="info-form"
          onChange={handleFormChange}
          onSubmit={handleSubmit}
          name="beneficiary_form"
          noValidate
        >
          <fieldset disabled={isDocumentLocked}>
            {/* owner */}
            <div className="col">
              <div className="input-col w-50">
                <h3>I am changing the following</h3>
                {Object.keys(listFilters).map((key) => (
                  <div
                    className="current_qualification_item current_qualification_item_alignment"
                    key={key}
                  >
                    <div className="current_qualification_item_alignment_left verification">
                      <input
                        type="checkbox"
                        id={key}
                        name={key}
                        checked={selectedFilterCheckbox === key}
                        onChange={() => handleFilter(key)}
                      />
                      <label htmlFor={key}>
                        <span />
                      </label>
                      <span
                        className="wpcf7-list-item-label mr-1"
                        style={{ wordBreak: "break-all" }}
                      >
                        {key}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
              {/* <div className="input-col w-50">
                <h5>From</h5>
                <div className="inputs-items">
                  <input
                    type="text"
                    className={`${errors["formValues.from"] ? "error" : ""}`}
                    name="from"
                    id="from"
                    value={formValues.from}
                    field="from"
                    placeholder="Enter Here"
                    required
                  />
                  {errors["formValues.from"] && (
                    <label htmlFor="from" className="error_label">
                      {errors["formValues.from"]}
                    </label>
                  )}
                </div>
                <h5>Number of Pages</h5>
                <div className="inputs-items">
                  <input
                    type="text"
                    className={`${
                      errors["formValues.number_of_pages"] ? "error" : ""
                    }`}
                    name="number_of_pages"
                    id="number_of_pages"
                    value={formValues.number_of_pages}
                    field="number_of_pages"
                    placeholder="Enter Here"
                    required
                  />
                  {errors["formValues.number_of_pages"] && (
                    <label htmlFor="number_of_pages" className="error_label">
                      {errors["formValues.number_of_pages"]}
                    </label>
                  )}
                </div>
              </div> */}
            </div>
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Insured's Full Name
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items group">
                    <div className="grouped">
                      <input
                        className={`${
                          errors["formValues.first_name"] ? "error" : ""
                        }`}
                        type="text"
                        name="formValues.first_name"
                        id="first_name"
                        value={formValues.first_name}
                        field="first_name"
                        placeholder="First Name"
                        required={true}
                      />
                      {errors["formValues.first_name"] && (
                        <label htmlFor="first_name" className="error_label">
                          {errors["formValues.first_name"]}
                        </label>
                      )}
                    </div>
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.middle_initial"] ? "error" : ""
                        }`}
                        name="formValues.middle_initial"
                        id="middle_initial"
                        value={formValues.middle_initial}
                        field="middle_initial"
                        placeholder="Middle Name (Optional)"
                      />
                      {errors["formValues.middle_initial"] && (
                        <label htmlFor="middle_initial" className="error_label">
                          {errors["formValues.middle_initial"]}
                        </label>
                      )}
                    </div>
                    <div className="grouped">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.last_name"] ? "error" : ""
                        }`}
                        name="formValues.last_name"
                        id="last_name"
                        value={formValues.last_name}
                        field="last_name"
                        placeholder="Last Name"
                        required={true}
                      />
                      {errors["formValues.last_name"] && (
                        <label htmlFor="last_name" className="error_label">
                          {errors["formValues.last_name"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    SSN<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items ">
                    <input
                      type="text"
                      className={`${errors["formValues.ssn"] ? "error" : ""}`}
                      name="formValues.ssn"
                      id="ssn"
                      value={formValues.ssn}
                      field="ssn"
                      placeholder="- Enter here -"
                      required={true}
                    />
                    {errors["formValues.ssn"] && (
                      <label htmlFor="ssn" className="error_label">
                        {errors["formValues.ssn"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    DOB<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${
                        errors["formValues.dob"] ? "error" : ""
                      } w-100`}
                      name="formValues.dob"
                      id="dob"
                      required={true}
                      selected={formValues.dob ? moment(formValues.dob) : ""}
                      field="dob"
                      placeholder="mm/dd/yyyy"
                      onChange={(date) => handleDateChange("dob", date)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    />
                    {errors["formValues.dob"] && (
                      <label htmlFor="dob" className="error_label">
                        {errors["formValues.dob"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Cell Phone
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <div className="grouped">
                      <input
                        type="tel"
                        className={`${
                          errors["formValues.telephone"] ? "error" : ""
                        }`}
                        name="formValues.telephone"
                        id="telephone"
                        value={formValues.telephone}
                        field="telephone"
                        placeholder=""
                        onChange={(e) => handlePhoneChange(e)}
                        required={true}
                      />
                      {errors["formValues.telephone"] && (
                        <label htmlFor="telephone" className="error_label">
                          {errors["formValues.telephone"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Email Address<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <div className="grouped">
                      <input
                        type="email"
                        className={`${
                          errors["formValues.email"] ? "error" : ""
                        }`}
                        name="formValues.email"
                        id="email"
                        value={formValues.email}
                        field="email"
                        placeholder="Enter here"
                        required={true}
                      />
                      {errors["formValues.email"] && (
                        <label htmlFor="email" className="error_label">
                          {errors["formValues.email"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="inputs-col">
                <div className="inputs-head">
                  Address<span className="required-star">&#x2A;</span>
                </div>
                <div className="inputs-items">
                  <input
                    type="text"
                    className={`${errors["formValues.address"] ? "error" : ""}`}
                    name="formValues.address"
                    id="address"
                    value={formValues.address}
                    field="address"
                    placeholder="Street or Box Number"
                    required={true}
                  />
                  {errors["formValues.address"] && (
                    <label htmlFor="address" className="error_label">
                      {errors["formValues.address"]}
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="col mailing-address">
              <div className="inputs-col">
                <div className="inputs-items group">
                  <div className="grouped">
                    <input
                      type="text"
                      className={`${errors["formValues.city"] ? "error" : ""}`}
                      name="formValues.city"
                      id="city"
                      value={formValues.city}
                      field="city"
                      placeholder="City"
                      required={true}
                    />
                    {errors["formValues.city"] && (
                      <label htmlFor="city" className="error_label">
                        {errors["formValues.city"]}
                      </label>
                    )}
                  </div>
                  <div className="grouped">
                    <input
                      type="text"
                      className={`${errors["formValues.state"] ? "error" : ""}`}
                      name="formValues.state"
                      id="state"
                      value={formValues.state}
                      field="state"
                      placeholder="State"
                      required={true}
                    />
                    {errors["formValues.state"] && (
                      <label htmlFor="state" className="error_label">
                        {errors["formValues.state"]}
                      </label>
                    )}
                  </div>
                  <div className="grouped">
                    <input
                      type="text"
                      className={`${errors["formValues.zip"] ? "error" : ""}`}
                      name="formValues.zip"
                      id="zip"
                      value={formValues.zip}
                      field="zip"
                      placeholder="Zip"
                      required={true}
                    />
                    {errors["formValues.zip"] && (
                      <label htmlFor="zip" className="error_label">
                        {errors["formValues.zip"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="inputs-col">
                <div className="inputs-head">
                  Policy Numbers<span className="required-star">&#x2A;</span>
                </div>
                <div className="inputs-items">
                  <input
                    type="text"
                    className={`${
                      errors["formValues.policy_number"] ? "error" : ""
                    }`}
                    name="formValues.policyNumbers"
                    id="policyNumbers"
                    value={formValues.policy_number}
                    field="policy_number"
                    placeholder="Policy Numbers"
                    disabled
                  />
                  {errors["formValues.policy_number"] && (
                    <label htmlFor="policy_number" className="error_label">
                      {errors["formValues.policy_number"]}
                    </label>
                  )}
                </div>
              </div>
            </div>
            {/* </div> */}
            <div className="col">
              <div className="col-md-12" style={{ paddingLeft: 0 }}>
                <h3 className="mt-0">General Instructions</h3>
                <p>
                  <strong>Naming a Minor as a Beneficiary: </strong>
                  In some instances, Colonial Life may not be able to pay life
                  insurance proceeds to a minor beneficiary unless a court
                  appointed adult guardian, conservator or custodian has been
                  properly designated for the minor’s property in advance
                  planning documents. When Colonial Life is unable to disperse
                  benefits in such situations, Colonial Life will hold the
                  proceeds (with interest earned on the funds) until the minor
                  reaches the age of majority. If you have questions about the
                  consequences of naming a minor as a beneficiary, feel free to
                  discuss with a legal or estate planning professional.
                </p>
                <p>
                  <strong>Naming a Trust: </strong>Provide the name of the
                  trust, the date the trust was established, and the address of
                  where the trust is held.
                </p>
                <p>
                  <strong>Naming a Funeral Home: </strong> Provide the name,
                  full address, and the owner or authorized personnel of the
                  funeral home. Write “As Interest May Appear” and designate
                  another primary beneficiary to receive any remaining benefits
                  available after the funeral home’s expenses have been paid.
                </p>
              </div>
            </div>

            {(selectedFilterCheckbox == "Primary Beneficiary" ||
              selectedFilterCheckbox == "Both") && (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="mt-0">Primary Beneficiaries</h3>
                    <p>
                      <strong>
                        All fields must be completed for each beneficiary:
                      </strong>
                      &nbsp;Unless otherwise specified, proceeds will be paid in
                      equal shares to surviving beneficiaries. If selecting more
                      than one Primary Beneficiary, the percentages must equal
                      100%. Attach additional pieces of paper if more space is
                      needed.
                    </p>
                  </div>
                </div>
                {/* PB */}

                <div className="row form-row">
                  <div className="col">
                    <div className="inputs-col">
                      <div className="inputs-head">
                        Insured's Full Name
                        <span className="required-star">&#x2A;</span>
                      </div>
                      <div className="inputs-items group">
                        <div className="grouped">
                          <input
                            className={`${
                              errors["formValues.pb_first_name"] ? "error" : ""
                            }`}
                            type="text"
                            name="formValues.pb_first_name"
                            id="first_name"
                            value={formValues.pb_first_name}
                            field="pb_first_name"
                            placeholder="First Name"
                            required={true}
                          />
                          {errors["formValues.pb_first_name"] && (
                            <label htmlFor="first_name" className="error_label">
                              {errors["formValues.pb_first_name"]}
                            </label>
                          )}
                        </div>
                        <div className="grouped">
                          <input
                            type="text"
                            className={`${
                              errors["formValues.pb_middle_initial"]
                                ? "error"
                                : ""
                            }`}
                            name="formValues.pb_middle_initial"
                            id="middle_initial"
                            value={formValues.pb_middle_initial}
                            field="pb_middle_initial"
                            placeholder="Middle Name (Optional)"
                          />
                          {errors["formValues.pb_middle_initial"] && (
                            <label
                              htmlFor="middle_initial"
                              className="error_label"
                            >
                              {errors["formValues.pb_middle_initial"]}
                            </label>
                          )}
                        </div>
                        <div className="grouped">
                          <input
                            type="text"
                            className={`${
                              errors["formValues.pb_last_name"] ? "error" : ""
                            }`}
                            name="formValues.pb_last_name"
                            id="last_name"
                            value={formValues.pb_last_name}
                            field="pb_last_name"
                            placeholder="Last Name"
                            required={true}
                          />
                          {errors["formValues.pb_last_name"] && (
                            <label htmlFor="last_name" className="error_label">
                              {errors["formValues.pb_last_name"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="inputs-col">
                      <div className="inputs-head">
                        SSN<span className="required-star">&#x2A;</span>
                      </div>
                      <div className="inputs-items ">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.pb_ssn"] ? "error" : ""
                          }`}
                          name="formValues.pb_ssn"
                          id="ssn"
                          value={formValues.pb_ssn}
                          field="pb_ssn"
                          placeholder="- Enter here -"
                          required={true}
                        />
                        {errors["formValues.pb_ssn"] && (
                          <label htmlFor="ssn" className="error_label">
                            {errors["formValues.pb_ssn"]}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="inputs-col">
                      <div className="inputs-head">
                        DOB<span className="required-star">&#x2A;</span>
                      </div>
                      <div className="inputs-items">
                        <DatePicker
                          className={`${
                            errors["formValues.pb_dob"] ? "error" : ""
                          } w-100`}
                          name="formValues.pb_dob"
                          id="pb_dob"
                          required={true}
                          selected={
                            formValues.pb_dob ? moment(formValues.pb_dob) : ""
                          }
                          field="pb_dob"
                          placeholder="mm/dd/yyyy"
                          onChange={(date) => handleDateChange("pb_dob", date)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                        />
                        {errors["formValues.pb_dob"] && (
                          <label htmlFor="pb_dob" className="error_label">
                            {errors["formValues.pb_dob"]}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="inputs-col">
                      <div className="inputs-head">
                        Cell Phone
                        <span className="required-star">&#x2A;</span>
                      </div>
                      <div className="inputs-items">
                        <div className="grouped">
                          <input
                            type="tel"
                            name="formValues.pb_telephone"
                            className={`${
                              errors["formValues.pb_telephone"] ? "error" : ""
                            }`}
                            id="home_phone"
                            value={formValues.pb_telephone}
                            field="pb_telephone"
                            placeholder="Cell Phone"
                            onChange={(e) => handlePhoneChange(e)}
                            required={true}
                          />
                          {errors["formValues.pb_telephone"] && (
                            <label htmlFor="home_phone" className="error_label">
                              {errors["formValues.pb_telephone"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">
                      Address<span className="required-star">&#x2A;</span>
                    </div>
                    <div className="inputs-items">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.pb_address"] ? "error" : ""
                        }`}
                        name="formValues.pb_address"
                        id="address"
                        value={formValues.pb_address}
                        field="pb_address"
                        placeholder="Street or Box Number"
                        required={true}
                      />
                      {errors["formValues.pb_address"] && (
                        <label htmlFor="address" className="error_label">
                          {errors["formValues.pb_address"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col mailing-address">
                  <div className="inputs-col">
                    <div className="inputs-items group">
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.pb_city"] ? "error" : ""
                          }`}
                          name="formValues.pb_city"
                          id="city"
                          value={formValues.pb_city}
                          field="pb_city"
                          placeholder="City"
                          required={true}
                        />
                        {errors["formValues.pb_city"] && (
                          <label htmlFor="pb_city" className="error_label">
                            {errors["formValues.pb_city"]}
                          </label>
                        )}
                      </div>
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.pb_state"] ? "error" : ""
                          }`}
                          name="formValues.pb_state"
                          id="pb_state"
                          value={formValues.pb_state}
                          field="pb_state"
                          placeholder="State"
                          required={true}
                        />
                        {errors["formValues.pb_state"] && (
                          <label htmlFor="pb_state" className="error_label">
                            {errors["formValues.pb_state"]}
                          </label>
                        )}
                      </div>
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.pb_zip"] ? "error" : ""
                          }`}
                          name="formValues.pb_zip"
                          id="pb_zip"
                          value={formValues.pb_zip}
                          field="pb_zip"
                          placeholder="Zip"
                          required={true}
                        />
                        {errors["formValues.pb_zip"] && (
                          <label htmlFor="pb_zip" className="error_label">
                            {errors["formValues.pb_zip"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">
                      Percentage<span className="required-star">&#x2A;</span>
                    </div>
                    <div className="inputs-items">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.pb_percentage"] ? "error" : ""
                        }`}
                        name="formValues.pb_percentage"
                        id="policyNumbers"
                        value={formValues.pb_percentage}
                        field="pb_percentage"
                        placeholder="Percentage"
                        required={true}
                      />
                      {errors["formValues.pb_percentage"] && (
                        <label htmlFor="pb_percentage" className="error_label">
                          {errors["formValues.pb_percentage"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>

                {/* PB 1 */}
                <div className="row form-row">
                  <div className="col">
                    <div className="inputs-col">
                      <div className="inputs-head">Insured's Full Name</div>
                      <div className="inputs-items group">
                        <div className="grouped">
                          <input
                            className={`${
                              errors["formValues.pb1_first_name"] ? "error" : ""
                            }`}
                            type="text"
                            name="formValues.pb1_first_name"
                            id="first_name"
                            value={formValues.pb1_first_name}
                            field="pb1_first_name"
                            placeholder="First Name"
                          />
                          {errors["formValues.pb1_first_name"] && (
                            <label
                              htmlFor="pb1_first_name"
                              className="error_label"
                            >
                              {errors["formValues.pb1_first_name"]}
                            </label>
                          )}
                        </div>
                        <div className="grouped">
                          <input
                            type="text"
                            className={`${
                              errors["formValues.pb1_middle_initial"]
                                ? "error"
                                : ""
                            }`}
                            name="formValues.pb1_middle_initial"
                            id="pb1_middle_initial"
                            value={formValues.pb1_middle_initial}
                            field="pb1_middle_initial"
                            placeholder="Middle Name (Optional)"
                          />
                          {errors["formValues.pb1_middle_initial"] && (
                            <label
                              htmlFor="pb1_middle_initial"
                              className="error_label"
                            >
                              {errors["formValues.pb1_middle_initial"]}
                            </label>
                          )}
                        </div>
                        <div className="grouped">
                          <input
                            type="text"
                            className={`${
                              errors["formValues.pb1_last_name"] ? "error" : ""
                            }`}
                            name="formValues.pb1_last_name"
                            id="last_name"
                            value={formValues.pb1_last_name}
                            field="pb1_last_name"
                            placeholder="Last Name"
                          />
                          {errors["formValues.pb1_last_name"] && (
                            <label
                              htmlFor="pb1_last_name"
                              className="error_label"
                            >
                              {errors["formValues.pb1_last_name"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="inputs-col">
                      <div className="inputs-head">SSN</div>
                      <div className="inputs-items ">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.pb1_ssn"] ? "error" : ""
                          }`}
                          name="formValues.pb1_ssn"
                          id="pb1_ssn"
                          value={formValues.pb1_ssn}
                          field="pb1_ssn"
                          placeholder="- Enter here -"
                        />
                        {errors["formValues.pb1_ssn"] && (
                          <label htmlFor="pb1_ssn" className="error_label">
                            {errors["formValues.pb1_ssn"]}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="inputs-col">
                      <div className="inputs-head">DOB</div>
                      <div className="inputs-items">
                        <DatePicker
                          className={`${
                            errors["formValues.pb1_dob"] ? "error" : ""
                          } w-100`}
                          name="formValues.pb1_dob"
                          id="pb1_dob"
                          selected={
                            formValues.pb1_dob ? moment(formValues.pb1_dob) : ""
                          }
                          field="pb1_dob"
                          placeholder="mm/dd/yyyy"
                          onChange={(date) => handleDateChange("pb1_dob", date)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                        />
                        {errors["formValues.pb1_dob"] && (
                          <label htmlFor="pb1_dob" className="error_label">
                            {errors["formValues.pb1_dob"]}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="inputs-col">
                      <div className="inputs-head">Cell Phone</div>
                      <div className="inputs-items">
                        <div className="grouped">
                          <input
                            type="tel"
                            className={`${
                              errors["formValues.pb1_telephone"] ? "error" : ""
                            } w-100`}
                            name="formValues.pb1_telephone"
                            id="home_phone"
                            value={formValues.pb1_telephone}
                            field="pb1_telephone"
                            placeholder="Cell Phone"
                            onChange={(e) => handlePhoneChange(e)}
                          />
                          {errors["formValues.pb1_telephone"] && (
                            <label
                              htmlFor="pb1_telephone"
                              className="error_label"
                            >
                              {errors["formValues.pb1_telephone"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">Address</div>
                    <div className="inputs-items">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.pb1_address"] ? "error" : ""
                        }`}
                        name="formValues.pb1_address"
                        id="pb1_address"
                        value={formValues.pb1_address}
                        field="pb1_address"
                        placeholder="Street or Box Number"
                      />
                      {errors["formValues.pb1_address"] && (
                        <label htmlFor="pb1_address" className="error_label">
                          {errors["formValues.pb1_address"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col mailing-address">
                  <div className="inputs-col">
                    <div className="inputs-items group">
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.pb1_city"] ? "error" : ""
                          }`}
                          name="formValues.pb1_city"
                          id="pb1_city"
                          value={formValues.pb1_city}
                          field="pb1_city"
                          placeholder="City"
                        />
                        {errors["formValues.pb1_city"] && (
                          <label htmlFor="pb1_city" className="error_label">
                            {errors["formValues.pb1_city"]}
                          </label>
                        )}
                      </div>
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.pb1_state"] ? "error" : ""
                          }`}
                          name="formValues.pb1_state"
                          id="pb1_state"
                          value={formValues.pb1_state}
                          field="pb1_state"
                          placeholder="State"
                        />
                        {errors["formValues.pb1_state"] && (
                          <label htmlFor="pb1_state" className="error_label">
                            {errors["formValues.pb1_state"]}
                          </label>
                        )}
                      </div>
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.pb1_zip"] ? "error" : ""
                          }`}
                          name="formValues.pb1_zip"
                          id="pb1_zip"
                          value={formValues.pb1_zip}
                          field="pb1_zip"
                          placeholder="Zip"
                        />
                        {errors["formValues.pb1_zip"] && (
                          <label htmlFor="pb1_zip" className="error_label">
                            {errors["formValues.pb1_zip"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">Percentage</div>
                    <div className="inputs-items">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.pb1_percentage"] ? "error" : ""
                        }`}
                        name="formValues.pb1_percentage"
                        id="pb1_percentage"
                        value={formValues.pb1_percentage}
                        field="pb1_percentage"
                        placeholder="Percentage"
                      />
                      {errors["formValues.pb1_percentage"] && (
                        <label htmlFor="pb1_percentage" className="error_label">
                          {errors["formValues.pb1_percentage"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                {/* PB 2 */}

                <div className="row form-row">
                  <div className="col">
                    <div className="inputs-col">
                      <div className="inputs-head">Insured's Full Name</div>
                      <div className="inputs-items group">
                        <div className="grouped">
                          <input
                            className={`${
                              errors["formValues.pb2_first_name"] ? "error" : ""
                            }`}
                            type="text"
                            name="formValues.pb2_first_name"
                            id="pb2_first_name"
                            value={formValues.pb2_first_name}
                            field="pb2_first_name"
                            placeholder="First Name"
                          />
                          {errors["formValues.pb2_first_name"] && (
                            <label
                              htmlFor="pb2_first_name"
                              className="error_label"
                            >
                              {errors["formValues.pb2_first_name"]}
                            </label>
                          )}
                        </div>
                        <div className="grouped">
                          <input
                            type="text"
                            className={`${
                              errors["formValues.pb2_middle_initial"]
                                ? "error"
                                : ""
                            }`}
                            name="formValues.pb2_middle_initial"
                            id="pb2_middle_initial"
                            value={formValues.pb2_middle_initial}
                            field="pb2_middle_initial"
                            placeholder="Middle Name (Optional)"
                          />
                          {errors["formValues.pb2_middle_initial"] && (
                            <label
                              htmlFor="pb2_middle_initial"
                              className="error_label"
                            >
                              {errors["formValues.pb2_middle_initial"]}
                            </label>
                          )}
                        </div>
                        <div className="grouped">
                          <input
                            type="text"
                            className={`${
                              errors["formValues.pb2_last_name"] ? "error" : ""
                            }`}
                            name="formValues.pb2_last_name"
                            id="pb2_last_name"
                            value={formValues.pb2_last_name}
                            field="pb2_last_name"
                            placeholder="Last Name"
                          />
                          {errors["formValues.pb2_last_name"] && (
                            <label
                              htmlFor="pb2_last_name"
                              className="error_label"
                            >
                              {errors["formValues.pb2_last_name"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="inputs-col">
                      <div className="inputs-head">SSN</div>
                      <div className="inputs-items ">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.pb2_ssn"] ? "error" : ""
                          }`}
                          name="formValues.pb2_ssn"
                          id="ssn"
                          value={formValues.pb2_ssn}
                          field="pb2_ssn"
                          placeholder="- Enter here -"
                        />
                        {errors["formValues.pb2_ssn"] && (
                          <label htmlFor="pb2_ssn" className="error_label">
                            {errors["formValues.pb2_ssn"]}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="inputs-col">
                      <div className="inputs-head">DOB</div>
                      <div className="inputs-items">
                        <DatePicker
                          className={`${
                            errors["formValues.pb2_dob"] ? "error" : ""
                          } w-100`}
                          name="formValues.pb2_dob"
                          id="pb2_dob"
                          selected={
                            formValues.pb2_dob ? moment(formValues.pb2_dob) : ""
                          }
                          field="pb2_dob"
                          placeholder="mm/dd/yyyy"
                          onChange={(date) => handleDateChange("pb2_dob", date)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                        />
                        {errors["formValues.pb2_dob"] && (
                          <label htmlFor="pb2_dob" className="error_label">
                            {errors["formValues.pb2_dob"]}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="inputs-col">
                      <div className="inputs-head">Cell Phone</div>
                      <div className="inputs-items">
                        <div className="grouped">
                          <input
                            type="tel"
                            name="formValues.pb2_telephone"
                            className={`${
                              errors["formValues.pb2_telephone"] ? "error" : ""
                            }`}
                            id="pb2_telephone"
                            value={formValues.pb2_telephone}
                            field="pb2_telephone"
                            placeholder="Cell Phone"
                            onChange={(e) => handlePhoneChange(e)}
                          />
                          {errors["formValues.pb2_telephone"] && (
                            <label
                              htmlFor="pb2_telephone"
                              className="error_label"
                            >
                              {errors["formValues.pb2_telephone"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">Address</div>
                    <div className="inputs-items">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.pb2_address"] ? "error" : ""
                        }`}
                        name="formValues.pb2_address"
                        id="pb2_address"
                        value={formValues.pb2_address}
                        field="pb2_address"
                        placeholder="Street or Box Number"
                      />
                      {errors["formValues.pb2_address"] && (
                        <label htmlFor="pb2_address" className="error_label">
                          {errors["formValues.pb2_address"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col mailing-address">
                  <div className="inputs-col">
                    <div className="inputs-items group">
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.pb2_city"] ? "error" : ""
                          }`}
                          name="formValues.pb2_city"
                          id="pb2_city"
                          value={formValues.pb2_city}
                          field="pb2_city"
                          placeholder="City"
                        />
                        {errors["formValues.pb2_city"] && (
                          <label htmlFor="pb2_city" className="error_label">
                            {errors["formValues.pb2_city"]}
                          </label>
                        )}
                      </div>
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.pb2_state"] ? "error" : ""
                          }`}
                          name="formValues.pb2_state"
                          id="pb2_state"
                          value={formValues.pb2_state}
                          field="pb2_state"
                          placeholder="State"
                        />
                        {errors["formValues.pb2_state"] && (
                          <label htmlFor="pb2_state" className="error_label">
                            {errors["formValues.pb2_state"]}
                          </label>
                        )}
                      </div>
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.pb2_zip"] ? "error" : ""
                          }`}
                          name="formValues.pb2_zip"
                          id="pb2_zip"
                          value={formValues.pb2_zip}
                          field="pb2_zip"
                          placeholder="Zip"
                        />
                        {errors["formValues.pb2_zip"] && (
                          <label htmlFor="pb2_zip" className="error_label">
                            {errors["formValues.pb2_zip"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">Percentage</div>
                    <div className="inputs-items">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.pb_2_percentage"] ? "error" : ""
                        }`}
                        name="formValues.pb_2_percentage"
                        id="pb2_percentage"
                        value={formValues.pb2_percentage}
                        field="pb2_percentage"
                        placeholder="Policy Numbers"
                      />
                      {errors["formValues.pb_2_percentage"] && (
                        <label
                          htmlFor="pb_2_percentage"
                          className="error_label"
                        >
                          {errors["formValues.pb_2_percentage"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {(selectedFilterCheckbox == "Contingent Beneficiary" ||
              selectedFilterCheckbox == "Both") && (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <h3 className="mt-0">Contingent beneficiary(ies)</h3>
                    <p>
                      If at the time of the insured’s death and all primary
                      beneficiaries are disqualified or die before the insured,
                      proceeds will be paid to the contingent beneficiaries
                      listed in equal shares. If selecting more than one
                      contingent beneficiary, the percentage must equal 100%.
                      Attach additional pieces of paper if more space is needed
                    </p>
                  </div>
                </div>

                {/* CB */}
                <div className="row form-row">
                  <div className="col">
                    <div className="inputs-col">
                      <div className="inputs-head">
                        Insured's Full Name
                        <span className="required-star">&#x2A;</span>
                      </div>
                      <div className="inputs-items group">
                        <div className="grouped">
                          <input
                            className={`${
                              errors["formValues.cb_first_name"] ? "error" : ""
                            }`}
                            type="text"
                            name="formValues.cb_first_name"
                            id="cb_first_name"
                            value={formValues.cb_first_name}
                            field="cb_first_name"
                            placeholder="First Name"
                            required={true}
                          />
                          {errors["formValues.cb_first_name"] && (
                            <label
                              htmlFor="cb_first_name"
                              className="error_label"
                            >
                              {errors["formValues.cb_first_name"]}
                            </label>
                          )}
                        </div>
                        <div className="grouped">
                          <input
                            type="text"
                            className={`${
                              errors["formValues.cb_middle_initial"]
                                ? "error"
                                : ""
                            }`}
                            name="formValues.cb_middle_initial"
                            id="cb_middle_initial"
                            value={formValues.cb_middle_initial}
                            field="cb_middle_initial"
                            placeholder="Middle Name (Optional)"
                          />
                          {errors["formValues.cb_middle_initial"] && (
                            <label
                              htmlFor="cb_middle_initial"
                              className="error_label"
                            >
                              {errors["formValues.cb_middle_initial"]}
                            </label>
                          )}
                        </div>
                        <div className="grouped">
                          <input
                            type="text"
                            className={`${
                              errors["formValues.cb_last_name"] ? "error" : ""
                            }`}
                            name="formValues.cb_last_name"
                            id="cb_last_name"
                            value={formValues.cb_last_name}
                            field="cb_last_name"
                            placeholder="Last Name"
                            required={true}
                          />
                          {errors["formValues.cb_last_name"] && (
                            <label
                              htmlFor="cb_last_name"
                              className="error_label"
                            >
                              {errors["formValues.cb_last_name"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="inputs-col">
                      <div className="inputs-head">
                        SSN<span className="required-star">&#x2A;</span>
                      </div>
                      <div className="inputs-items ">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.cb_ssn"] ? "error" : ""
                          }`}
                          name="formValues.cb_ssn"
                          id="cb_ssn"
                          value={formValues.cb_ssn}
                          field="cb_ssn"
                          placeholder="- Enter here -"
                          required={true}
                        />
                        {errors["formValues.cb_ssn"] && (
                          <label htmlFor="cb_ssn" className="error_label">
                            {errors["formValues.cb_ssn"]}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="inputs-col">
                      <div className="inputs-head">
                        DOB<span className="required-star">&#x2A;</span>
                      </div>
                      <div className="inputs-items">
                        <DatePicker
                          className={`${
                            errors["formValues.cb_dob"] ? "error" : ""
                          } w-100`}
                          name="formValues.cb_dob"
                          id="cb_dob"
                          required={true}
                          selected={
                            formValues.cb_dob ? moment(formValues.cb_dob) : ""
                          }
                          field="cb_dob"
                          placeholder="mm/dd/yyyy"
                          onChange={(date) => handleDateChange("cb_dob", date)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                        />
                        {errors["formValues.cb_dob"] && (
                          <label htmlFor="cb_dob" className="error_label">
                            {errors["formValues.cb_dob"]}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="inputs-col">
                      <div className="inputs-head">
                        Cell Phone
                        <span className="required-star">&#x2A;</span>
                      </div>
                      <div className="inputs-items">
                        <div className="grouped">
                          <input
                            type="tel"
                            name="formValues.cb_telephone"
                            className={`${
                              errors["formValues.cb_telephone"] ? "error" : ""
                            }`}
                            id="cb_telephone"
                            value={formValues.cb_telephone}
                            field="cb_telephone"
                            placeholder="Cell Phone"
                            onChange={(e) => handlePhoneChange(e)}
                          />
                          {errors["formValues.cb_telephone"] && (
                            <label
                              htmlFor="cb_telephone"
                              className="error_label"
                            >
                              {errors["formValues.cb_telephone"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">
                      Address<span className="required-star">&#x2A;</span>
                    </div>
                    <div className="inputs-items">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.cb_address"] ? "error" : ""
                        }`}
                        name="formValues.cb_address"
                        id="cb_address"
                        value={formValues.cb_address}
                        field="cb_address"
                        placeholder="Street or Box Number"
                        required={true}
                      />
                      {errors["formValues.cb_address"] && (
                        <label htmlFor="cb_address" className="error_label">
                          {errors["formValues.cb_address"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col mailing-address">
                  <div className="inputs-col">
                    <div className="inputs-items group">
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.cb_city"] ? "error" : ""
                          }`}
                          name="formValues.cb_city"
                          id="cb_city"
                          value={formValues.cb_city}
                          field="cb_city"
                          placeholder="City"
                          required={true}
                        />
                        {errors["formValues.cb_city"] && (
                          <label htmlFor="cb_city" className="error_label">
                            {errors["formValues.cb_city"]}
                          </label>
                        )}
                      </div>
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.cb_state"] ? "error" : ""
                          }`}
                          name="formValues.cb_state"
                          id="cb_state"
                          value={formValues.cb_state}
                          field="cb_state"
                          placeholder="State"
                          required={true}
                        />
                        {errors["formValues.cb_state"] && (
                          <label htmlFor="cb_state" className="error_label">
                            {errors["formValues.cb_state"]}
                          </label>
                        )}
                      </div>
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.cb_zip"] ? "error" : ""
                          }`}
                          name="formValues.cb_zip"
                          id="cb_zip"
                          value={formValues.cb_zip}
                          field="cb_zip"
                          placeholder="Zip"
                          required={true}
                        />
                        {errors["formValues.cb_zip"] && (
                          <label htmlFor="cb_zip" className="error_label">
                            {errors["formValues.cb_zip"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">
                      Percentage<span className="required-star">&#x2A;</span>
                    </div>
                    <div className="inputs-items">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.cb_percentage"] ? "error" : ""
                        }`}
                        name="formValues.cb_percentage"
                        id="cb_percentage"
                        value={formValues.cb_percentage}
                        field="cb_percentage"
                        placeholder="Percentage"
                        required={true}
                      />
                      {errors["formValues.cb_percentage"] && (
                        <label htmlFor="cb_percentage" className="error_label">
                          {errors["formValues.cb_percentage"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>

                {/* CB 1 */}

                <div className="row form-row">
                  <div className="col">
                    <div className="inputs-col">
                      <div className="inputs-head">Insured's Full Name</div>
                      <div className="inputs-items group">
                        <div className="grouped">
                          <input
                            className={`${
                              errors["formValues.cb1_first_name"] ? "error" : ""
                            }`}
                            type="text"
                            name="formValues.cb1_first_name"
                            id="cb1_first_name"
                            value={formValues.cb1_first_name}
                            field="cb1_first_name"
                            placeholder="First Name"
                          />
                          {errors["formValues.cb1_first_name"] && (
                            <label
                              htmlFor="cb1_first_name"
                              className="error_label"
                            >
                              {errors["formValues.cb1_first_name"]}
                            </label>
                          )}
                        </div>
                        <div className="grouped">
                          <input
                            type="text"
                            className={`${
                              errors["formValues.cb1_middle_initial"]
                                ? "error"
                                : ""
                            }`}
                            name="formValues.cb1_middle_initial"
                            id="cb1_middle_initial"
                            value={formValues.cb1_middle_initial}
                            field="cb1_middle_initial"
                            placeholder="Middle Name (Optional)"
                          />
                          {errors["formValues.cb1_middle_initial"] && (
                            <label
                              htmlFor="middle_initial"
                              className="error_label"
                            >
                              {errors["formValues.cb1_middle_initial"]}
                            </label>
                          )}
                        </div>
                        <div className="grouped">
                          <input
                            type="text"
                            className={`${
                              errors["formValues.cb1_last_name"] ? "error" : ""
                            }`}
                            name="formValues.cb1_last_name"
                            id="cb1_last_name"
                            value={formValues.cb1_last_name}
                            field="cb1_last_name"
                            placeholder="Last Name"
                          />
                          {errors["formValues.cb1_last_name"] && (
                            <label
                              htmlFor="cb1_last_name"
                              className="error_label"
                            >
                              {errors["formValues.cb1_last_name"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="inputs-col">
                      <div className="inputs-head">SSN</div>
                      <div className="inputs-items ">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.cb1_ssn"] ? "error" : ""
                          }`}
                          name="formValues.cb1_ssn"
                          id="cb1_ssn"
                          value={formValues.cb1_ssn}
                          field="cb1_ssn"
                          placeholder="- Enter here -"
                        />
                        {errors["formValues.cb1_ssn"] && (
                          <label htmlFor="cb1_ssn" className="error_label">
                            {errors["formValues.cb1_ssn"]}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="inputs-col">
                      <div className="inputs-head">DOB</div>
                      <div className="inputs-items">
                        <DatePicker
                          className={`${
                            errors["formValues.cb1_dob"] ? "error" : ""
                          } w-100`}
                          name="formValues.cb1_dob"
                          id="cb1_dob"
                          selected={
                            formValues.cb1_dob ? moment(formValues.cb1_dob) : ""
                          }
                          field="cb1_dob"
                          placeholder="mm/dd/yyyy"
                          onChange={(date) => handleDateChange("cb1_dob", date)}
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          autoComplete="off"
                        />
                        {errors["formValues.cb1_dob"] && (
                          <label htmlFor="cb1_dob" className="error_label">
                            {errors["formValues.cb1_dob"]}
                          </label>
                        )}
                      </div>
                    </div>
                    <div className="inputs-col">
                      <div className="inputs-head">Cell Phone</div>
                      <div className="inputs-items">
                        <div className="grouped">
                          <input
                            type="tel"
                            name="formValues.cb1_telephone"
                            className={`${
                              errors["formValues.cb1_telephone"] ? "error" : ""
                            }`}
                            id="cb1_telephone"
                            value={formValues.cb1_telephone}
                            field="cb1_telephone"
                            placeholder="Cell Phone"
                            onChange={(e) => handlePhoneChange(e)}
                          />
                          {errors["formValues.cb1_telephone"] && (
                            <label
                              htmlFor="cb1_telephone"
                              className="error_label"
                            >
                              {errors["formValues.cb1_telephone"]}
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">Address</div>
                    <div className="inputs-items">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.cb1_address"] ? "error" : ""
                        }`}
                        name="formValues.cb1_address"
                        id="cb1_address"
                        value={formValues.cb1_address}
                        field="cb1_address"
                        placeholder="Street or Box Number"
                      />
                      {errors["formValues.cb1_address"] && (
                        <label htmlFor="cb1_address" className="error_label">
                          {errors["formValues.cb1_address"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col mailing-address">
                  <div className="inputs-col">
                    <div className="inputs-items group">
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.cb1_city"] ? "error" : ""
                          }`}
                          name="formValues.cb1_city"
                          id="city"
                          value={formValues.cb1_city}
                          field="cb1_city"
                          placeholder="City"
                        />
                        {errors["formValues.cb1_city"] && (
                          <label htmlFor="cb1_city" className="error_label">
                            {errors["formValues.cb1_city"]}
                          </label>
                        )}
                      </div>
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.cb1_state"] ? "error" : ""
                          }`}
                          name="formValues.cb1_state"
                          id="cb1_state"
                          value={formValues.cb1_state}
                          field="cb1_state"
                          placeholder="State"
                        />
                        {errors["formValues.cb1_state"] && (
                          <label htmlFor="cb1_state" className="error_label">
                            {errors["formValues.cb1_state"]}
                          </label>
                        )}
                      </div>
                      <div className="grouped">
                        <input
                          type="text"
                          className={`${
                            errors["formValues.cb1_zip"] ? "error" : ""
                          }`}
                          name="formValues.cb1_zip"
                          id="cb1_zip"
                          value={formValues.cb1_zip}
                          field="cb1_zip"
                          placeholder="Zip"
                        />
                        {errors["formValues.cb1_zip"] && (
                          <label htmlFor="cb1_zip" className="error_label">
                            {errors["formValues.cb1_zip"]}
                          </label>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="inputs-col">
                    <div className="inputs-head">Percentage</div>
                    <div className="inputs-items">
                      <input
                        type="text"
                        className={`${
                          errors["formValues.cb_1_percentage"] ? "error" : ""
                        }`}
                        name="formValues.cb1_percentage"
                        id="cb1_percentage"
                        value={formValues.cb1_percentage}
                        field="cb1_percentage"
                        placeholder="Percentage"
                      />
                      {errors["formValues.cb1_percentage"] && (
                        <label htmlFor="cb1_percentage" className="error_label">
                          {errors["formValues.cb1_percentage"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {/* SIGN */}
            <div className="col">
              <div className="inputs-col w-50 sign-input">
                <div className="inputs-head">
                  Policy Owner Signature
                  <span className="required-star">&#x2A;</span>
                </div>
                <div className="inputs-items">
                  <div className="sign_here">
                    {/* {this.state.sign && ( */}
                    <Fragment>
                      <SignPad
                        id="wageEmpSignPad"
                        height={211}
                        width={411}
                        handleSignature={(po_signature) =>
                          handleSignature("po_signature", po_signature)
                        }
                        signature={formValues.po_signature}
                      />
                      <input
                        type="hidden"
                        name="formValues.po_signature"
                        field="po_signature"
                      ></input>
                    </Fragment>
                    {errors["formValues.po_signature"] && (
                      <label htmlFor="po_signature" className="error_label">
                        {errors["formValues.po_signature"]}
                      </label>
                    )}
                    {/* )} */}
                  </div>
                </div>
              </div>
              <div className="inputs-col w-50">
                <div className="inputs-head">
                  Date<span className="required-star">&#x2A;</span>
                </div>
                <div className="inputs-items">
                  <DatePicker
                    className={`${
                      errors["formValues.po_date"] ? "error" : ""
                    } w-100`}
                    name="formValues.po_date"
                    id="po_date"
                    required={true}
                    selected={
                      formValues.po_date ? moment(formValues.po_date) : ""
                    }
                    field="po_date"
                    placeholder="mm/dd/yyyy"
                    onChange={(date) => handleDateChange("po_date", date)}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    autoComplete="off"
                    disabled={true}
                  />
                  {errors["formValues.po_date"] && (
                    <label htmlFor="po_date" className="error_label">
                      {errors["formValues.po_date"]}
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="row form-row">
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    Policy Owner's Full Name
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <input
                      className={`${
                        errors["formValues.po_name"] ? "error" : ""
                      }`}
                      type="text"
                      name="formValues.po_name"
                      id="po_name"
                      value={formValues.po_name}
                      field="po_name"
                      placeholder="Full Name"
                      required={true}
                    />
                    {errors["formValues.po_name"] && (
                      <label htmlFor="po_name" className="error_label">
                        Please sign here
                      </label>
                    )}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="inputs-col">
                  <div className="inputs-head">
                    SSN<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items ">
                    <input
                      type="text"
                      className={`${
                        errors["formValues.po_ssn"] ? "error" : ""
                      }`}
                      name="formValues.po_ssn"
                      id="po_ssn"
                      value={formValues.po_ssn}
                      field="po_ssn"
                      placeholder="- Enter here -"
                      required={true}
                    />
                    {errors["formValues.po_ssn"] && (
                      <label htmlFor="po_ssn" className="error_label">
                        {errors["formValues.po_ssn"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    DOB<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <DatePicker
                      className={`${
                        errors["formValues.po_dob"] ? "error" : ""
                      } w-100`}
                      name="formValues.po_dob"
                      id="po_dob"
                      required={true}
                      selected={
                        formValues.po_dob ? moment(formValues.po_dob) : ""
                      }
                      field="po_dob"
                      placeholder="mm/dd/yyyy"
                      onChange={(date) => handleDateChange("po_dob", date)}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      autoComplete="off"
                    />
                    {errors["formValues.po_dob"] && (
                      <label htmlFor="po_dob" className="error_label">
                        {errors["formValues.po_dob"]}
                      </label>
                    )}
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Cell Phone
                    <span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <div className="grouped">
                      <input
                        type="tel"
                        className={`${
                          errors["formValues.po_telephone"] ? "error" : ""
                        }`}
                        name="formValues.po_telephone"
                        id="po_telephone"
                        value={formValues.po_telephone}
                        field="po_telephone"
                        placeholder="Cell Phone"
                        onChange={(e) => handlePhoneChange(e)}
                        required={true}
                      />
                      {errors["formValues.po_telephone"] && (
                        <label htmlFor="po_telephone" className="error_label">
                          {errors["formValues.po_telephone"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
                <div className="inputs-col">
                  <div className="inputs-head">
                    Email Address<span className="required-star">&#x2A;</span>
                  </div>
                  <div className="inputs-items">
                    <div className="grouped">
                      <input
                        type="po_email"
                        className={`${
                          errors["formValues.po_email"] ? "error" : ""
                        }`}
                        name="formValues.po_email"
                        id="po_email"
                        value={formValues.po_email}
                        field="po_email"
                        placeholder="Enter here"
                        required={true}
                      />
                      {errors["formValues.po_email"] && (
                        <label htmlFor="po_email" className="error_label">
                          {errors["formValues.po_email"]}
                        </label>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="inputs-col">
                <div className="inputs-head">
                  Address<span className="required-star">&#x2A;</span>
                </div>
                <div className="inputs-items">
                  <input
                    type="text"
                    className={`${
                      errors["formValues.po_address"] ? "error" : ""
                    }`}
                    name="formValues.po_address"
                    id="po_address"
                    value={formValues.po_address}
                    field="po_address"
                    placeholder="Street or Box Number"
                    required={true}
                  />
                  {errors["formValues.po_address"] && (
                    <label htmlFor="po_address" className="error_label">
                      {errors["formValues.po_address"]}
                    </label>
                  )}
                </div>
              </div>
            </div>
            <div className="col mailing-address">
              <div className="inputs-col">
                <div className="inputs-items group">
                  <div className="grouped">
                    <input
                      type="text"
                      className={`${
                        errors["formValues.po_city"] ? "error" : ""
                      }`}
                      name="formValues.po_city"
                      id="po_city"
                      value={formValues.po_city}
                      field="po_city"
                      placeholder="City"
                      required={true}
                    />
                    {errors["formValues.po_city"] && (
                      <label htmlFor="po_city" className="error_label">
                        {errors["formValues.po_city"]}
                      </label>
                    )}
                  </div>
                  <div className="grouped">
                    <input
                      type="text"
                      className={`${
                        errors["formValues.po_state"] ? "error" : ""
                      }`}
                      name="formValues.po_state"
                      id="state"
                      value={formValues.po_state}
                      field="po_state"
                      placeholder="State"
                      required={true}
                    />
                    {errors["formValues.po_state"] && (
                      <label htmlFor="po_state" className="error_label">
                        {errors["formValues.po_state"]}
                      </label>
                    )}
                  </div>
                  <div className="grouped">
                    <input
                      type="text"
                      className={`${
                        errors["formValues.po_zip"] ? "error" : ""
                      }`}
                      name="formValues.po_zip"
                      id="po_zip"
                      value={formValues.po_zip}
                      field="po_zip"
                      placeholder="Zip"
                      required={true}
                    />
                    {errors["formValues.po_zip"] && (
                      <label htmlFor="po_zip" className="error_label">
                        {errors["formValues.po_zip"]}
                      </label>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p>
                  <strong>
                    Special Notice for Residents of a Community Property State:
                  </strong>
                  &nbsp;A spouse or former spouse may have an interest in life
                  insurance proceeds or any accumulated cash value if the policy
                  premiums were paid with community funds. It is your
                  responsibility to consult your legal advisor to 1) ensure that
                  any required consent from a spouse or former spouse has been
                  received and 2) ensure that your spouse or former spouse will
                  not be able to make a claim against any policy values and/or
                  proceeds in the event any policy benefits become payable.
                </p>
              </div>
            </div>

            <div className="row form-row last">
              <div className="col">
                <div className="inputs-col">
                  <div className="check_radio_group check_disclaimer">
                    <div className="check_radio_item">
                      <input
                        type="checkbox"
                        name="disclaimer_check"
                        id="disclaimer_check"
                        field="disclaimer_check"
                        checked={formValues.disclaimer_check}
                        required={true}
                      />
                      <span className="checkbox_checkbox"></span>

                      <label htmlFor="disclaimer_check">
                        I have confirmed the above details are true and valid.
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormNav
              adminMode={adminMode}
              navigate={(to) => handleNavigate(to, rehire, docs, doc, props)}
              disclaimer_check={formValues?.disclaimer_check}
              mobileMode={mobileMode}
              lastPage={lastPage}
            />
          </fieldset>
        </form>
      </div>
    </div>
  );
};

export default withRouter(BeneficiaryForm);
