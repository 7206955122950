export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  GETALL_REQUEST: "USERS_GETALL_REQUEST",
  GETALL_SUCCESS: "USERS_GETALL_SUCCESS",
  GETALL_FAILURE: "USERS_GETALL_FAILURE",

  GETPROFILE_REQUEST: "USERS_GETPROFILE_REQUEST",
  GETPROFILE_SUCCESS: "USERS_GETPROFILE_SUCCESS",
  GETPROFILE_FAILURE: "USERS_GETPROFILE_FAILURE",

  LOCK_PROFILE_REQUEST: "LOCK_PROFILE_REQUEST",
  LOCK_PROFILE_SUCCESS: "LOCK_PROFILE_SUCCESS",
  LOCK_PROFILE_FAILURE: "LOCK_PROFILE_FAILURE",

  CHANGE_STATUS_REQUEST: "CHANGE_STATUS_REQUEST",
  CHANGE_STATUS_SUCCESS: "CHANGE_STATUS_SUCCESS",
  CHANGE_STATUS_FAILURE: "CHANGE_STATUS_FAILURE",

  EMPSELFSTATUS_REQUEST: "USERS_EMPSELFSTATUS_REQUEST",
  EMPSELFSTATUS_SUCCESS: "USERS_EMPSELFSTATUS_SUCCESS",
  EMPSELFSTATUS_FAILURE: "USERS_EMPSELFSTATUS_FAILURE",

  DELETE_REQUEST: "USERS_DELETE_REQUEST",
  DELETE_SUCCESS: "USERS_DELETE_SUCCESS",
  DELETE_FAILURE: "USERS_DELETE_FAILURE",

  EDITPROFILE_REQUEST: "USERS_EDITPROFILE_REQUEST",
  EDITPROFILE_SUCCESS: "USERS_EDITPROFILE_SUCCESS",
  EDITPROFILE_FAILURE: "USERS_EDITPROFILE_FAILURE",

  RESET_REQUEST: "USERS_RESET_REQUEST",
  RESET_SUCCESS: "USERS_RESET_SUCCESS",
  RESET_FAILURE: "USERS_RESET_FAILURE",

  PASSWORD_REQUEST: "USERS_PASSWORD_REQUEST",
  PASSWORD_SUCCESS: "USERS_PASSWORD_SUCCESS",
  PASSWORD_FAILURE: "USERS_PASSWORD_FAILURE",

  ASSIGNSTAFF_REQUEST: "ASSIGNSTAFF_REQUEST",
  ASSIGNSTAFF_SUCCESS: "ASSIGNSTAFF_SUCCESS",
  ASSIGNSTAFF_FAILURE: "ASSIGNSTAFF_FAILURE",

  REMOVEPROFILE_REQUEST: "USERS_REMOVEPROFILE_REQUEST",
  REMOVEPROFILE_SUCCESS: "USERS_REMOVEPROFILE_SUCCESS",
  REMOVEPROFILE_FAILURE: "USERS_REMOVEPROFILE_FAILURE",

  GETSTATS_REQUEST: "USERS_GETSTATS_REQUEST",
  GETSTATS_SUCCESS: "USERS_GETSTATS_SUCCESS",
  GETSTATS_FAILURE: "USERS_GETSTATS_FAILURE",

  LOGDETAILS_REQUEST: "USERS_LOGDETAILS_REQUEST",
  LOGDETAILS_SUCCESS: "USERS_LOGDETAILS_SUCCESS",
  LOGDETAILS_FAILURE: "USERS_LOGDETAILS_FAILURE",

  NOTES_REQUEST: "USERS_NOTES_REQUEST",
  NOTES_SUCCESS: "USERS_NOTES_SUCCESS",
  NOTES_FAILURE: "USERS_NOTES_FAILURE",

  GETBULLETIN_REQUEST: "USERS_GETBULLETIN_REQUEST",
  GETBULLETIN_SUCCESS: "USERS_GETBULLETIN_SUCCESS",
  GETBULLETIN_FAILURE: "USERS_GETBULLETIN_FAILURE",

  DELETEFILE_REQUEST: "USERS_DELETEFILE_REQUEST",
  DELETEFILE_SUCCESS: "USERS_DELETEFILE_SUCCESS",
  DELETEFILE_FAILURE: "USERS_DELETEFILE_FAILURE",

  WIDGET_REQUEST: "USER_WIDGETS_REQUEST",
  WIDGET_SUCCESS: "USER_WIDGETS_SUCCESS",
  WIDGET_FAILURE: "USER_WIDGETS_FAILURE",

  WIDGET_UPDATE_REQUEST: "USER_WIDGETS_UPDATE_REQUEST",
  WIDGET_UPDATE_SUCCESS: "USER_WIDGETS_UPDATE_SUCCESS",
  WIDGET_UPDATE_FAILURE: "USER_WIDGETS_UPDATE_FAILURE",

  UPDATE_DOCS_STATUS: "UPDATE_DOCS_STATUS",

  GET_USER_ROLES_REQUEST: "GET_USER_ROLES_REQUEST",
  GET_USER_ROLES_SUCCESS: "GET_USER_ROLES_SUCCESS",
  GET_USER_ROLES_ERROR: "GET_USER_ROLES_ERROR",

  IMPORT_EMPLOYEES_REQUEST: "IMPORT_EMPLOYEES_REQUEST",
  IMPORT_EMPLOYEES_SUCCESS: "IMPORT_EMPLOYEES_SUCCESS",
  IMPORT_EMPLOYEES_ERROR: "IMPORT_EMPLOYEES_ERROR",

  ADD_EMPLOYEE_REQUEST: "ADD_EMPLOYEE_REQUEST",
  ADD_EMPLOYEE_SUCCESS: "ADD_EMPLOYEE_SUCCESS",
  ADD_EMPLOYEE_ERROR: "ADD_EMPLOYEE_ERROR",

  ADD_QUALIFICATION_REQUEST: "ADD_QUALIFICATION_REQUEST",
  ADD_QUALIFICATION_SUCCESS: "ADD_QUALIFICATION_SUCCESS",
  ADD_QUALIFICATION_ERROR: "ADD_QUALIFICATION_ERROR",

  EDIT_QUALIFICATION_REQUEST: "EDIT_QUALIFICATION_REQUEST",
  EDIT_QUALIFICATION_SUCCESS: "EDIT_QUALIFICATION_SUCCESS",
  EDIT_QUALIFICATION_ERROR: "EDIT_QUALIFICATION_ERROR",

  UPDATE_QUALIFICATION_REQUEST: "UPDATE_QUALIFICATION_REQUEST",
  UPDATE_QUALIFICATION_SUCCESS: "UPDATE_QUALIFICATION_SUCCESS",
  UPDATE_QUALIFICATION_ERROR: "UPDATE_QUALIFICATION_ERROR",

  REMOVE_QUALIFICATION_REQUEST: "REMOVE_QUALIFICATION_REQUEST",
  REMOVE_QUALIFICATION_SUCCESS: "REMOVE_QUALIFICATION_SUCCESS",
  REMOVE_QUALIFICATION_ERROR: "REMOVE_QUALIFICATION_ERROR",
};

export const userAccountStatus = {
  Blocked: 0,
  Active: 1,
  Locked: 2,
  Guest: 3,
};

export const userStatus = {
  Unavailable: 0,
  Available: 1,
  Archived: 2,
  Guest: 3,
};

export const USER_ROLES = {
  Admin: 1,
  SuperAdmin: 2,
};

export const dolApplicationStatus = {
  1: "Application Sent",
  2: "Approved",
  3: "Denied",
};

export const insuranceCheckStatus = {
  1: "Sent To Insurance",
  2: "Approved",
  3: "Denied",
};
