import React from "react";
import { connect } from "react-redux";
import { userActions } from "../_actions";
import {
  resourceActions,
  notificationActions,
  permissionActions,
} from "../_actions";
import "../assets/styles/assignStaff.css";
import { authHeader, config } from "../_helpers";
import { omit } from "lodash";
import { toast } from "react-toastify";
import "../Manifest/DragDropTouch.js";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { unAvailableReason } from "../_utils/utils";
import ReactModal from "react-modal";
ReactModal.setAppElement("#app");

function formatPhoneNumber(s) {
  var s2 = ("" + s).replace(/\D/g, "");
  var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !m ? null : m[1] + "-" + m[2] + "-" + m[3];
}

const grid = 4;

const getItemStyle = (isDragging, draggableStyle) => {
  // some basic styles to make the items look a bit nicer
  return {
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: "#E7EAE6",

    // styles we need to apply on draggables
    ...draggableStyle,
  };
};

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? "rgb(245, 245, 245)" : "#E7EAE6",
  padding: grid,
});

class Assign extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      all: {},
      resourceList: [],
      ffList: [],
      asList: [],
      dragList: {},
      resourceIDList: {},
      assigned: ["Reserve Crew"],
      users: [],
      buttons: [],
      statusOpen: false,
      profile: [],
      touch_id: "",
      touch_last: "",
      generalQualificationList: [],
      resource_type_list: [],
      resource_images: [],
      init: true,
      threshold: 0,
      currentDY: 0,
      width: window.innerWidth,
      ffMobileDiv: [],
      asMobileDiv: [],
      step: "step1",
      resourcedata: [],
      user_hash: "",
      user_id: "",
      user_crew: "",
      crew_list: [],
      resource_name: "",
      crew_detail: [],
      crew_head: [],
      user_type: "",
      permissions: [],
      assignStaffs: {},
      drag: false,
      unavailableReason: 0,
      otherReason: "",
      savingNote: false,
      loading: false,
    };
    this.updated = {};
    this.onDragStart = this.onDragStart.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.remove = this.remove.bind(this);
    this.statusOpenModal = this.statusOpenModal.bind(this);
    this.statusCloseModal = this.statusCloseModal.bind(this);
    this.updateNotes = this.updateNotes.bind(this);
    this.onImageError = this.onImageError.bind(this);
    this._scroller = React.createRef();
    this.handleWindowSizeChange = this.handleWindowSizeChange.bind(this);
    this.onStepChange = this.onStepChange.bind(this);
    this.crewClick = this.crewClick.bind(this);
    this.choosePosition = this.choosePosition.bind(this);
    this.submitPostion = this.submitPostion.bind(this);
    this.postionConfirm = this.postionConfirm.bind(this);
    this.backToStep = this.backToStep.bind(this);
    // this.backToStep2 = this.backToStep2.bind(this);
    // this.backToStep3 = this.backToStep3.bind(this);
    this.cancelStep = this.cancelStep.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  updateNotes(hash) {
    let data = new FormData();
    data.append("hash", hash);
    data.append("note", this.state.notes);
    if (this.state.notes !== null) {
      this.setState({ savingNote: true });
      this.props
        .dispatch(userActions.updateEmployeeNote(data, "?hash=" + hash))
        .then(
          (res) => {
            toast.success("Notes Saved !");
            this.setState({ savingNote: false });
          },
          (err) => {
            toast.error("Notes Cannot be saved");
            this.setState({ savingNote: false });
          },
        );
    }
  }

  handleChange(event) {
    if (event.target.name === "notes") {
      if (
        this.state.permList &&
        this.state.permList.includes("Employee Notes")
      ) {
        this.setState({ [event.target.name]: event.target.value });
      }
      return;
    }
    this.setState({ [event.target.name]: event.target.value });
  }

  createId(key, index) {
    return key + "." + index;
  }

  // update(obj, id) {
  //   let data = new FormData();
  //   data.append("resource_id", id);
  //   data.append("user_hash", obj["hash"]);
  //   data.append("user_type", obj["user_type_id"]);

  //   fetch(config.apiUrl + "/api/employee/assignstaff", {
  //     headers: authHeader(),
  //     method: "POST",
  //     body: data,
  //   })
  //     .then((res) => {
  //       if (!res.ok) {
  //         throw new Error(res.message);
  //       }
  //       return res.json();
  //     })
  //     .then((data) => {
  //       this.props.dispatch(notificationActions.getCount());
  //     })
  //     .catch((error) => {
  //       toast.error(`An error occurred. ${error}`);
  //     });
  // }

  onDragEnd = (result) => {
    const { source, destination } = result;
    if (!source || !destination) return;

    const s = source["droppableId"].split(".");
    const e = destination["droppableId"].split(".");
    const start = "crew_" + s[0];
    const end = "crew_" + e[0];
    const startindex = parseInt(source.index);
    const endindex = parseInt(destination.index);
    const startkey = s[1];
    const endkey = e[1];
    const issame =
      start === end && startindex === endindex && startkey === endkey;
    if (issame) return;

    // Save original state for reverting if needed
    const originalState = JSON.parse(JSON.stringify(this.state.assignStaffs));
    const obj = { ...this.state.assignStaffs };

    try {
      let slice;

      if (!issame) {
        if (obj[start]?.__users?.[startkey]) {
          if (isNaN(startindex)) return;

          slice = obj[start]["__users"][startkey].splice(startindex, 1);

          if (obj[start]["__users"][startkey].length === 0) {
            obj[start]["__users"][startkey].push({
              user_type: slice[0]["user_type"],
              user_type_id: slice[0]["user_type_id"],
            });
          }
        }

        if (slice?.[0] && obj[end]?.__users?.[endkey]) {
          if (isNaN(endindex)) return;

          const newobj = { ...slice[0] };
          const target = obj[end]["__users"][endkey];
          const lastAssign = obj[start]["resource_name"] || "";
          const newAssign = obj[end]["resource_name"] || "";

          if (target.length) {
            newobj["user_type"] = target[0]["user_type"];
            newobj["user_type_id"] = target[0]["user_type_id"];
          }

          if (newAssign !== lastAssign) {
            newobj["last_assign"] = lastAssign;
            newobj["crew_name"] = newAssign;
          }

          obj[end]["__users"][endkey].splice(endindex, 0, slice[0]);

          // Update state with new assignment, but rollback on error
          this.setState({ assignStaffs: obj }, () => {
            this.update(newobj, e[0], originalState);
          });

          return;
        }
      } else {
        if (obj[start]?.__users?.[startkey]) {
          const arr = obj[start]["__users"][startkey];
          const sorted = this.swapArrayElements(arr, startindex, endindex);
          obj[start]["__users"][startkey] = sorted;

          this.setState({ assignStaffs: obj }, () => {
            this.update(obj[end]["__users"][endkey], e[0], originalState);
          });

          return;
        }
      }
    } catch (err) {
      console.warn(err);
    }
  };

  update(obj, id, originalState) {
    let data = new FormData();
    data.append("resource_id", id);
    data.append("user_hash", obj["hash"]);
    data.append("user_type", obj["user_type_id"]);

    fetch(config.apiUrl + "/api/employee/assignstaff", {
      headers: authHeader(),
      method: "POST",
      body: data,
    })
      .then((res) => {
        if (!res.ok) {
          return res.json().then((errorData) => {
            throw new Error(errorData.message || "Failed to assign staff.");
          });
        }
        return res.json();
      })
      .then((data) => {
        this.props.dispatch(notificationActions.getCount());
      })
      .catch((error) => {
        toast.error(`An error occurred: ${error.message}`);

        // Revert state if there's an error
        this.setState({ assignStaffs: originalState });
      })
      .finally(() => {
        // Ensure drag overlay is hidden and interaction is reset
        this.hideDragOverlay();
      });
  }

  hideDragOverlay() {
    setTimeout(() => {
      this.setState({
        drag: false,
      });
    }, 150);
  }
  statusOpenModal(user, hash) {
    this.setState({ profile: null, loading: true }, () => {
      this.props.dispatch(userActions.getProfile("?hash=" + hash));
      this.setState({ statusOpen: true, popupHash: hash });
    });
  }

  statusCloseModal() {
    this.setState({ statusOpen: false, profile: null });
  }

  onDragStart(ev, id, res) {
    this.setState({ threshold: Math.max(120, window.innerHeight / 4) });
    ev.dataTransfer.setData("id", id);
    ev.dataTransfer.setData("last", res);
  }

  onTouchStart(ev, id, res) {
    this.setState({ touch_id: id, touch_last: res });
  }

  componentWillMount() {
    window.addEventListener("resize", this.handleWindowSizeChange);
  }

  onStepChange(step, hash, id, crew) {
    this.setState({
      step,
      user_hash: hash,
      user_id: id,
      user_crew: crew,
    });
  }

  crewClick(step, crew, id) {
    this.setState({
      step,
      resource_name: crew,
      resource_id: id,
    });
    var crew_detail = [],
      crew_head = [];
    Object.keys(this.state.resourcedata).forEach((key) => {
      if (key && key != "" && Array.isArray(this.state.resourcedata[key])) {
        this.state.resourcedata[key].map((item, index) => {
          if (item.id === id) {
            crew_head.push(
              <div className="crew-div">
                <div className="crew-img">
                  <img
                    src={item.resource_photo}
                    alt={item.resource_name}
                    onError={this.onImageError.bind(this)}
                  />
                </div>
                <div className="crew-details">
                  <h3 title={item.resource_name}>{item.resource_name}</h3>
                  {item.users && Array.isArray(item.users) && (
                    <span>{item.users.length} members</span>
                  )}
                </div>
              </div>,
            );
            item.users.map((elements, index) => {
              crew_detail.push(
                <li className="">
                  <img
                    src={elements.profile_photo}
                    alt={elements.first_name + " " + elements.last_name}
                    onError={this.onImageError.bind(this)}
                  />
                  <div className="mem-det">
                    <h3 title={elements.first_name + " " + elements.last_name}>
                      {elements.first_name + " " + elements.last_name}
                    </h3>
                    <p className="qualy">
                      {Array.isArray(elements.qualification_key) &&
                      elements.qualification_key.length > 0
                        ? elements.qualification_key.join(", ")
                        : ""}
                    </p>
                  </div>
                </li>,
              );
            });
          }
        });
      }
    });

    this.setState({ crew_detail, crew_head });
  }

  choosePosition(e) {
    this.setState({ user_type: e.target.value });
    e.target.setAttribute("errors", "");
  }

  postionConfirm(step) {
    if (this.state.user_type !== "") {
      this.setState({
        step,
      });
    } else {
      this.setState({
        typeError: "error",
      });
    }
  }
  backToStep(step) {
    this.setState({
      step,
    });
  }
  cancelStep() {
    this.setState({
      step: "step1",
      resource_id: "",
      user_hash: "",
      user_type: "",
    });
  }

  submitPostion() {
    let data = new FormData();
    data.append("resource_id", this.state.resource_id);
    data.append("user_hash", this.state.user_hash);
    data.append("user_type", this.state.user_type);
    // this.props.dispatch(resourceActions.assignStaff(data));
    var self = this;
    fetch(config.apiUrl + "/api/employee/assignstaff", {
      headers: authHeader(),
      method: "POST",
      body: data,
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        if (data.success) {
          self.setState({
            step: "step1",
          });
          self.props.dispatch(resourceActions.assignStaffList());
        }
      });
  }

  handleWindowSizeChange() {
    this.setState({ width: window.innerWidth });
  }
  // make sure to remove the listener
  // when the component is not mounted anymore
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowSizeChange);
  }

  scrollToPosition(element, to, duration) {
    if (duration <= 0) {
      return;
    }
    var difference = to - window.scrollY;

    var perTick = (difference / duration) * 10;

    window.scrollTo(0, window.scrollY + perTick);
    this.scrollToPosition(element, to, duration - 10);
  }

  switchCase(type) {
    switch (type) {
      case "Firefighters":
        return "7";
        break;
      case "Superint. / Eng. Captain":
        return "4";
        break;
      case "Captains":
        return "6";
        break;
      case "Staff Managers":
        return "8";
        break;
      case "Staffs":
        return "9";
        break;
      case "GISS Technician":
        return "11";
        break;
      default:
        return "9";
        break;
    }
  }

  setUnAvailableReason = (reason, other) => {
    return unAvailableReason(reason, other);
  };

  render() {
    const { employee, resource, user } = this.props;
    const { permissions } = this.state;
    const { width } = this.state;
    const isMobile = width <= 600;
    var selectedUser = [];
    if (
      this.state.step === "step2" ||
      this.state.step === "step3" ||
      this.state.step === "step4"
    ) {
      Object.keys(this.state.resourcedata).forEach((key) => {
        if (key && key != "") {
          this.state.resourcedata[key].map((item, index) => {
            if (item.resource_name === this.state.user_crew) {
              item.users.map((ele, index) => {
                if (ele.hash === this.state.user_hash) {
                  selectedUser.push(
                    <div className="res-selected">
                      <div className="crew-div">
                        <div className="crew-img">
                          <img
                            src={ele.profile_photo}
                            onError={this.onImageError.bind(this)}
                            alt={ele.first_name + " " + ele.last_name}
                          />
                        </div>
                        <div className="crew-details">
                          <span className="selected">Selected: </span>
                          <h3 title={ele.first_name + " " + ele.last_name}>
                            {ele.first_name + " " + ele.last_name}
                          </h3>

                          {key == "FireFighter" && (
                            <p>
                              {Array.isArray(ele.qualification_key) &&
                              ele.qualification_key.length > 0
                                ? ele.qualification_key.join(", ")
                                : ""}
                            </p>
                          )}

                          {key == "AdminStaff" && <p>FFT2,EFR</p>}

                          <p className="prev">
                            {" "}
                            Last Assign: {ele.last_assign}
                          </p>
                          {this.state.step === "step3" && (
                            <p className="assinTo">
                              {" "}
                              Assign to: {this.state.resource_name}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>,
                  );
                }
              });
            }
          });
        }
      });
    }
    //
    // if(permissions.length>0){
    // if(this.state.permissions.includes("Assign Staff")){
    // if(resource.items) {
    return (
      permissions.length > 0 &&
      (this.state.permissions.includes("Assign Staff") ? (
        resource.items ? (
          isMobile ? (
            <div className="applicants_container assignStaff_mobile_container">
              <div className="page_title float">
                <h1>Assign Staff</h1>
              </div>
              {this.state.step === "step1" && (
                <div className="step1">
                  {/*<div className="inner_head">
                                            <span className="step">Step 1: &nbsp;</span>
                                            <span className="info">Select User to Assign</span>
                                        </div>*/}
                  <div className="content_div custom-css">
                    <div id="amWrapperMob">
                      <div className="note">
                        <span className="step">Step 1:</span>
                        <span className="info">Select User to Assign</span>
                      </div>
                      <div className="">
                        {this.state.ffMobileDiv}
                        {this.state.asMobileDiv}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.step === "step2" && (
                <div className="">
                  {/*<div className="inner_head">
                                            <span className="step">Step 2: &nbsp;</span>
                                            <span className="info">Select User to Assign</span>
                                        </div>*/}
                  <div className="content_div">
                    <div id="amWrapperMob">
                      <div className="step2">
                        <div className="top">
                          <div
                            className="left goBack back"
                            onClick={this.backToStep.bind(this, "step1")}
                          >
                            Back
                          </div>
                          <div
                            className="right cancel"
                            onClick={this.cancelStep.bind(this)}
                          >
                            Cancel
                          </div>
                        </div>
                        {selectedUser}
                        <div className="note">
                          <span className="step">Step 2:</span>
                          <span className="info">Move To</span>
                        </div>
                        <div className="crew-list">
                          {this.state.crew_list}
                          {this.state.ascrew_list}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.step === "step3" && (
                <div className="">
                  {/*<div className="inner_head">
                                            <span className="step">Step 3: &nbsp;</span>
                                            <span className="info">Select User to Assign</span>
                                        </div>*/}
                  <div className="content_div">
                    <div id="amWrapperMob">
                      <div className="step3">
                        <div className="top">
                          <div
                            className="left goBack back"
                            onClick={this.backToStep.bind(this, "step2")}
                          >
                            Back
                          </div>
                          <div
                            className="right cancel"
                            onClick={this.cancelStep.bind(this)}
                          >
                            Cancel
                          </div>
                        </div>
                        {selectedUser}
                        <div className="note">
                          <span className="step">Step 3:</span>
                          <span className="info">Confirm Postion</span>
                        </div>
                        <div className="position-confirm-div">
                          <select
                            id="choosePositionType"
                            className=""
                            defaultValue={this.state.user_type}
                            errors={this.state.typeError}
                            onChange={this.choosePosition.bind(this)}
                          >
                            <option id="rm_choose_position" value="">
                              Choose Position*
                            </option>
                            <option className="position-option" value="4">
                              Superintendent / Engine Captain
                            </option>
                            {/*<option className="position-option" value="Engine Captain">Engine Captain</option>*/}
                            <option className="position-option" value="6">
                              Captains
                            </option>
                            <option className="position-option" value="7">
                              Firefighters
                            </option>
                            <option className="position-option" value="8">
                              Staff Managers
                            </option>
                            <option className="position-option" value="9">
                              Staffs
                            </option>
                            <option className="position-option" value="12">
                              DCR Operations Manager
                            </option>
                            <option className="position-option" value="11">
                              GISS Technician
                            </option>
                          </select>
                          <input
                            id="confirmPosition"
                            type="button"
                            className="button btn-confirm-position"
                            value="Confirm Position"
                            onClick={this.postionConfirm.bind(this, "step4")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {this.state.step === "step4" && (
                <div className="">
                  {/*<div className="inner_head">
                                            <span className="step">Step 4: &nbsp;</span>
                                           <span className="info">Select User to Assign</span>
                                        </div>*/}
                  <div className="content_div">
                    <div id="amWrapperMob">
                      <div className="step4">
                        <div className="top">
                          <div
                            className="left goBack back"
                            onClick={this.backToStep.bind(this, "step3")}
                          >
                            Back
                          </div>
                          <div
                            className="right cancel"
                            onClick={this.cancelStep.bind(this)}
                          >
                            Cancel
                          </div>
                        </div>
                        {selectedUser}
                        <div className="placement-confirm-div">
                          <input
                            id="confirmPlacement"
                            type="button"
                            className="button btn-confirm-placement"
                            value="Confirm Placement"
                            onClick={this.submitPostion.bind(this)}
                          />
                        </div>
                        <div className="clear-div" />
                        <div className="resourcebox">
                          {this.state.crew_head}
                          <div className="member-list">
                            <ul>{this.state.crew_detail}</ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="applicants_container assign_staff_container">
              <div className="page_title float">
                <h1>Assign Staff</h1>
              </div>
              <div className="manifest_type">
                {/*<div className="inner_head">
                                </div>*/}
                <div className="content_div custom-css">
                  <div className="assign_staff_content">
                    <div className="drag_sort_wrap am-wrap-left relative">
                      {this.state.drag && <div className="loader-drag" />}
                      <div className="inner_box scroller" id="content-box">
                        <DragDropContext onDragEnd={this.onDragEnd}>
                          {Object.keys(this.state.assignStaffs).map(
                            (ele, index) => {
                              var group = this.state.assignStaffs[ele],
                                userslist;
                              var userslist = group["__users"];
                              var blockid = group["id"];
                              return (
                                <div
                                  key={index + "sortable"}
                                  className="dr resource-crew-array"
                                >
                                  <div>
                                    {group["id"] !== 1 && (
                                      <button
                                        className="remove_assign"
                                        onClick={this.remove.bind(this, ele)}
                                      />
                                    )}
                                    <h3>{group["resource_name"]}</h3>
                                    <img
                                      src={group["resource_photo"]}
                                      onError={this.onImageError.bind(this)}
                                    />
                                  </div>

                                  {Object.keys(userslist).map((key, uid) => {
                                    var data = userslist[key];

                                    return (
                                      <Droppable
                                        key={uid + "dropable"}
                                        droppableId={this.createId(
                                          blockid,
                                          key,
                                        )}
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            className="assign_item"
                                            ref={provided.innerRef}
                                            style={getListStyle(
                                              snapshot.isDraggingOver,
                                            )}
                                          >
                                            <h3 className="is-wrap task-header">
                                              {data[0]["user_type"]}
                                            </h3>
                                            {data &&
                                              data.map((item, index) => {
                                                return (
                                                  item.crew_identifier_id && (
                                                    <Draggable
                                                      key={index + "draggable"}
                                                      draggableId={this.createId(
                                                        blockid,
                                                        item.id,
                                                      )}
                                                      index={index}
                                                    >
                                                      {(provided, snapshot) => (
                                                        <div
                                                          className="staff_div"
                                                          ref={
                                                            provided.innerRef
                                                          }
                                                          {...provided.draggableProps}
                                                          {...provided.dragHandleProps}
                                                          style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided
                                                              .draggableProps
                                                              .style,
                                                          )}
                                                          onClick={this.statusOpenModal.bind(
                                                            this,
                                                            item,
                                                            item.hash,
                                                          )}
                                                        >
                                                          <img
                                                            className={
                                                              item.active
                                                            }
                                                            src={
                                                              config.apiUrl +
                                                              "/" +
                                                              item.profile_photo
                                                            }
                                                            onError={this.onImageError.bind(
                                                              this,
                                                            )}
                                                          />
                                                          <p className="name">
                                                            <span
                                                              className={
                                                                "color_code " +
                                                                item.colorcode
                                                              }
                                                            />
                                                          </p>
                                                          <p>
                                                            {item.first_name +
                                                              " " +
                                                              item.last_name}
                                                          </p>
                                                          <p>
                                                            {Array.isArray(
                                                              item.qualification_key,
                                                            ) &&
                                                            item
                                                              .qualification_key
                                                              .length > 0
                                                              ? item.qualification_key.join(
                                                                  ", ",
                                                                )
                                                              : ""}
                                                          </p>
                                                          <p>
                                                            Last Assign:{" "}
                                                            {item.last_assign}
                                                          </p>
                                                          {item.primary_crew_name ? (
                                                            <p>
                                                              Primary Crew:{" "}
                                                              {
                                                                item.primary_crew_name
                                                              }
                                                            </p>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      )}
                                                    </Draggable>
                                                  )
                                                );
                                              })}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    );
                                  })}
                                </div>
                              );
                            },
                          )}
                        </DragDropContext>
                      </div>
                    </div>
                    <div className="drag_sort_wrap am-wrap-right">
                      {/*this.state.resourceList*/}
                      <div>
                        <div className="ffList">{this.state.ffList}</div>
                        <div className="asList">{this.state.asList}</div>
                      </div>
                    </div>
                    <div className="btn_list">
                      {/* <button className="button grey" onClick={this.goToType.bind(this,this.props.type)}>Previous</button>
                                            <button className="button" onClick={this.toVehicle.bind(this)}>Next</button> */}
                    </div>
                  </div>
                </div>
              </div>

              {!this.state.loading &&
              this.state.profile &&
              this.state.statusOpen ? (
                <>
                  <ReactModal
                    key={this.state.profile.hash}
                    isOpen={this.state.statusOpen}
                    onClose={this.statusCloseModal.bind(this)}
                    contentLabel="Minimal Modal Example"
                    overlayClassName="AssignStaffPopup fl-AssignStaffPopup"
                  >
                    <div className="modal-head">
                      <h4 className="modal-title">
                        {this.state.profile.first_name +
                          " " +
                          this.state.profile.last_name}
                      </h4>
                      <img
                        className="closeModal"
                        onClick={this.statusCloseModal.bind(this)}
                        src={
                          require("../assets/images/close_green.png").default
                        }
                        onError={this.onImageError.bind(this)}
                      />
                    </div>

                    <div className="modal-body">
                      <div className="content_div">
                        <div className="profile1 col-sm-3">
                          <div className="profile_div">
                            <img
                              src={
                                this.state.profile.profile_photo
                                  ? config.apiUrl +
                                    "/" +
                                    this.state.profile.profile_photo
                                  : config.apiUrl + "/" + config.altImg
                              }
                              className={
                                this.state.profile.active +
                                " profilePicture availability"
                              }
                              onError={this.onImageError.bind(this)}
                            />
                          </div>

                          <p className="name">
                            <span
                              className={
                                "color_code " + this.state.profile.colorcode
                              }
                            />
                            {this.state.profile.first_name +
                              " " +
                              this.state.profile.last_name}
                          </p>

                          <div className="crew-profile-detail">
                            <p>{this.state.profile.user_type}</p>
                            {this.state.profile.crew_name && (
                              <p>{this.state.profile.crew_name}</p>
                            )}
                            <p>{this.state.profile.fire_id}</p>
                            <p>{this.state.profile.ica_number}</p>
                            {this.state.profile.primary_crew_name && (
                              <p>
                                Primary Crew:{" "}
                                {this.state.profile.primary_crew_name}
                              </p>
                            )}
                            {this.state.profile.active == "Unavailable" && (
                              <p>
                                Unavailable:{" "}
                                {this.setUnAvailableReason(
                                  this.state.unavailableReason,
                                  this.state.otherReason,
                                )}
                              </p>
                            )}
                          </div>
                        </div>
                        <div className="profile2 col-sm-9">
                          <div className="cp-qual profile-sep">
                            <h4 className="cp-title">Qualifications</h4>
                            {this.state.generalQualificationList}
                          </div>
                          <div className="cp-cellno profile-sep">
                            <h4 className="cp-title">Cell Phone</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile &&
                              this.state.profile.phone ? (
                                <a
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  className="phone_email_link"
                                  href={`tel:${this.state.profile.phone}`}
                                  target="_top"
                                >
                                  {formatPhoneNumber(this.state.profile.phone)}
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                          <div className="cp-sec profile-sep">
                            <h4 className="cp-title">Secondary Phone</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile &&
                              this.state.profile.second_phone ? (
                                <a
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  className="phone_email_link"
                                  href={`tel:${this.state.profile.second_phone}`}
                                  target="_top"
                                >
                                  {formatPhoneNumber(
                                    this.state.profile.second_phone,
                                  )}
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                          <div className="cp-email profile-sep">
                            <h4 className="cp-title">Email</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile &&
                              this.state.profile.email ? (
                                <a
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  className="phone_email_link"
                                  href={`mailto:${this.state.profile.email}`}
                                  target="_top"
                                >
                                  {this.state.profile.email}
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                          </div>
                          <div className="cp-madr profile-sep">
                            <h4 className="cp-title">Mailing Address</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile.mailing_address
                                ? this.state.profile.mailing_address.address
                                : ""}
                            </p>
                            <p className="crew-profile-detail txtformat-capitalize">
                              {this.state.profile.mailing_address
                                ? this.state.profile.mailing_address.city
                                : ""}
                            </p>
                            <p className="crew-profile-detail txtformat-capitalize">
                              {this.state.profile.mailing_address
                                ? this.state.profile.mailing_address.state
                                : ""}
                            </p>
                            <p className="crew-profile-detail">
                              {this.state.profile.mailing_address
                                ? this.state.profile.mailing_address.zip
                                : ""}
                            </p>
                          </div>
                          <div className="cp-pa profile-sep">
                            <h4 className="cp-title">Physical Address</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile.physical_address
                                ? this.state.profile.physical_address.address
                                : ""}
                            </p>
                            <p className="crew-profile-detail txtformat-capitalize">
                              {this.state.profile.physical_address
                                ? this.state.profile.physical_address.city
                                : ""}
                            </p>
                            <p className="crew-profile-detail txtformat-capitalize">
                              {this.state.profile.physical_address
                                ? this.state.profile.physical_address.state
                                : ""}
                            </p>
                            <p className="crew-profile-detail">
                              {this.state.profile.physical_address
                                ? this.state.profile.physical_address.zip
                                : ""}
                            </p>
                          </div>
                          <div className="cp-sex profile-sep">
                            <h4 className="cp-title">Sex</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile.gender}
                            </p>
                          </div>
                          <div className="cp-contact1 profile-sep">
                            <h4 className="cp-title">Emergency Contact #1</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile.emgcontact1
                                ? this.state.profile.emgcontact1.fname +
                                  " " +
                                  this.state.profile.emgcontact1.lname
                                : ""}
                            </p>
                            <p className="crew-profile-detail">
                              {this.state.profile.emgcontact1 ? (
                                <a
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  className="phone_email_link"
                                  href={`tel:${this.state.profile.emgcontact1.phone}`}
                                  target="_top"
                                >
                                  {formatPhoneNumber(
                                    this.state.profile.emgcontact1.phone,
                                  )}
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                            <p className="crew-profile-detail">
                              {this.state.profile.emgcontact1
                                ? this.state.profile.emgcontact1.relation
                                : ""}
                            </p>
                          </div>
                          <div className="cp-contact2 profile-sep">
                            <h4 className="cp-title">Emergency Contact #2</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile.emgcontact2
                                ? this.state.profile.emgcontact2.fname +
                                  " " +
                                  this.state.profile.emgcontact2.lname
                                : ""}
                            </p>
                            <p className="crew-profile-detail">
                              {this.state.profile.emgcontact2 ? (
                                <a
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  className="phone_email_link"
                                  href={`tel:${this.state.profile.emgcontact2.phone}`}
                                  target="_top"
                                >
                                  {formatPhoneNumber(
                                    this.state.profile.emgcontact2.phone,
                                  )}
                                </a>
                              ) : (
                                ""
                              )}
                            </p>
                            <p className="crew-profile-detail">
                              {this.state.profile.emgcontact2
                                ? this.state.profile.emgcontact2.relation
                                : ""}
                            </p>
                          </div>
                          <div className="cp-drivers-license profile-sep">
                            <h4 className="cp-title">Drivers License</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile.drivers_license}
                            </p>
                          </div>
                          <div className="cp-weight profile-sep">
                            <h4 className="cp-title">Weight (lbs)</h4>
                            <p className="crew-profile-detail">
                              {this.state.profile.weight}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ReactModal>
                </>
              ) : (
                <></>
              )}
            </div>
          )
        ) : (
          <div className="applicants_container assign_staff_container">
            <div className="page_title float">
              <h1>Assign Staff</h1>
            </div>
            <div className="manifest_type">
              <div className="content_div">
                <center
                  id="feedLoader"
                  className={this.state.loader ? this.state.loader : ""}
                >
                  <img
                    alt="Loader"
                    src={require("../assets/images/loader.gif").default}
                  />
                </center>
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="content_div">
          <div className="no_permission">No permission to this page</div>
        </div>
      ))
    );
  }

  componentDidMount() {
    this.props.dispatch(resourceActions.assignStaffList());
    this.props.dispatch(resourceActions.employeeList());
    this.props.dispatch(permissionActions.getPermission());
    var self = this;
    fetch(config.apiUrl + "/api/permission/getuserpermissions", {
      headers: authHeader(),
      method: "GET",
    })
      .then(function (res) {
        return res.json();
      })
      .then(function (data) {
        self.setState({ permissions: data.data });
      });
  }

  remove(key) {
    var obj = this.state.assignStaffs[key] || null;
    if (key && obj) {
      this.updated[key] = obj;
    }
    this.setState(
      { assignStaffs: omit(this.state.assignStaffs, key) },
      function () {
        var index = this.state.assigned.indexOf(key);
        if (index > -1) {
          this.state.assigned.splice(index, 1);
        }
        var ele = document.getElementById(key);
        if (key) ele.classList.remove("disabled");
      },
    );
  }

  onImageError(e) {
    e.target.classList.add("errorImage");
    e.target.setAttribute("src", config.apiUrl + "/" + config.altImg);
  }
  __createResourceList(event, item, res, admin = false) {
    event.target.classList.add("disabled");
    return this.createSection(item, admin);
  }

  swapArrayElements(arr, start, end) {
    var sliced = arr.slice(start, start + 1)[0];
    arr.splice(end, 0, sliced);
    arr.splice(start, 1);
    return arr;
  }

  formattedData(item) {
    var users = item.users || [],
      res = {},
      added = [],
      defaults = {
        4: "Superintendent",
        6: "Crew Captain",
        7: "Firefighter",
        8: "Staff Manager",
        9: "Office Staff",
        12: "DCR Operations Manager",
        11: "GISS Technician",
      };
    users.map(function (ele) {
      if (!res.hasOwnProperty(ele["user_type_id"])) {
        // var isadmin = "admin" === (ele["user_type"] || "").trim().toLowerCase();
        // if (isadmin) return;
        res[ele["user_type_id"]] = [];
        added.push(ele["user_type_id"].toString());
      }
      res[ele["user_type_id"]].push(ele);
    });
    for (var key in defaults) {
      if (added.indexOf(key.toString()) === -1) {
        res[key] = [
          {
            user_type: defaults[key],
            user_type_id: key,
          },
        ];
      }
    }
    item["__users"] = res;
    return item;
  }

  createSection(item, index) {
    var id = item.id,
      prefix = "crew_",
      key = prefix + id;

    if (!this.state.assignStaffs.hasOwnProperty(key)) {
      var obj = Object.assign({}, this.state.assignStaffs);
      if (this.updated.hasOwnProperty(key)) {
        obj[key] = this.updated[key];
        delete this.updated[key];
      } else {
        obj[key] = this.formattedData(item);
      }
      this.setState({ assignStaffs: obj }, function () {
        setTimeout(() => {
          var ele = document.getElementById("content-box");
          if (!ele) return;
          var current = ele.scrollLeft,
            width = ele.clientWidth,
            sw = ele.scrollWidth;
          if (sw < width) return;
          var dist = sw - width;
          ele.scrollLeft = dist;
        }, 100);
      });
    }
  }

  createResourceList(item, res, admin = false) {
    if (this.state.buttons[item.resource_name] === "show") {
      if (res !== undefined && res !== "undefined") {
        this.setState({
          assigned: [...this.state.assigned, res],
        });
      }
      var v = [];
      v[item.resource_name] = "hide";
      this.setState((state) => ({
        buttons: { ...state.buttons, ...v },
      }));

      if (item.users && item.users.length < 1) {
        var organizeStaff = [];
        organizeStaff[item.resource_name] = [];
        if (!admin) {
          organizeStaff[item.resource_name]["Superint. / Eng. Captain"] = [];
          organizeStaff[item.resource_name]["Captains"] = [];
          organizeStaff[item.resource_name]["Firefighters"] = [];
        }
        organizeStaff[item.resource_name]["Staff Managers"] = [];
        organizeStaff[item.resource_name]["Staffs"] = [];
        organizeStaff[item.resource_name]["GISS Technician"] = [];

        if (Object.keys(this.state.dragList).length) {
          this.setState((state) => ({
            dragList: { ...state.dragList, ...organizeStaff },
          }));
        } else {
          this.setState({
            ...this.state,
            dragList: organizeStaff,
          });
        }
      } else {
        var organizeStaff = [];
        var options = [];

        this.setState({
          users: [...this.state.users, ...item.users],
        });

        item.users.map((user, index) => {
          // if(user.user_type_nickname !== 'Super Admin') {
          if (user.crew_name in organizeStaff) {
            if (user.user_type_nickname in organizeStaff[user.crew_name]) {
              options = organizeStaff[user.crew_name][user.user_type_nickname];
              options.push(user);
              organizeStaff[user.crew_name][user.user_type_nickname] = options;
            }
          } else {
            options = [];
            organizeStaff[user.crew_name] = [];
            if (!admin) {
              organizeStaff[user.crew_name]["Superint. / Eng. Captain"] = [];
              organizeStaff[user.crew_name]["Captains"] = [];
              organizeStaff[user.crew_name]["Firefighters"] = [];
            }
            organizeStaff[user.crew_name]["Staff Managers"] = [];
            organizeStaff[user.crew_name]["Staffs"] = [];
            organizeStaff[user.crew_name]["GISS Technician"] = [];
            options.push(user);
            organizeStaff[user.crew_name][user.user_type_nickname] = options;
          }

          // }
        });
        if (Object.keys(this.state.dragList).length) {
          this.setState((state) => ({
            dragList: { ...state.dragList, ...organizeStaff },
          }));
        } else {
          this.setState({
            ...this.state,
            dragList: organizeStaff,
          });
        }
      }
    } else {
      // console.log('already loaded')
    }
  }

  componentWillReceiveProps(nextProps) {
    const { employee, resource, user, permission } = nextProps;

    var permList = [];

    if (permission.permissions && this.props.permission != permission) {
      permList = permission.permissions.data;

      this.setState({
        permList,
      });
    }

    if (resource.items && this.props.resource != resource) {
      const { data } = resource.items;
      this.setState({ resourcedata: data });
      var ffOptions = [];
      var asOptions = [];
      var ids = [];
      var v = [];
      var rtl = [];
      var imgs = [],
        ffMobileDiv = [],
        asMobileDiv = [],
        crew_list = [],
        ascrew_list = [],
        reserve_crew = [];

      if (data) {
        Object.keys(data).forEach((key) => {
          if (key && key != "" && Array.isArray(data[key])) {
            data[key].map((item, index) => {
              if (item.id === 1) {
                reserve_crew = { crew_1: item };

                var id = item.id,
                  prefix = "crew_",
                  key = prefix + id;
                if (!this.state.hasOwnProperty(key)) {
                  var obj = Object.assign({}, this.state.assignStaffs);
                  obj[key] = this.formattedData(item);
                  this.setState({ assignStaffs: obj, assignUserType: index });
                }
              }

              v[item.resource_name] = "show";
              this.setState({ ...this.state.buttons, buttons: v }, function () {
                // console.log('changing button....')

                ids[item.resource_name] = item.id;
                rtl[item.resource_name] = item.resource_type;
                this.setState((state) => ({
                  resource_type_list: { ...state.resource_type_list, ...rtl },
                }));
                imgs[item.resource_name] = item.resource_photo;
                this.setState((state) => ({
                  resource_images: { ...state.resource_images, ...imgs },
                }));
                // this.setState({ resource_type_list: })
                if (item.users && item.id === 1 && this.state.init) {
                  this.createResourceList(item, item.resource_name, false);
                  this.setState({ users: item.users, init: false });
                }
                if (item.id !== 1) {
                  ffOptions.push(
                    <div
                      key={item.resource_name + index}
                      className="resourcebox"
                    >
                      <h3>
                        {item.resource_name.length > 18
                          ? item.resource_name.slice(0, 18) + "..."
                          : item.resource_name}
                      </h3>
                      <div className="image-wrapper flex-image">
                        <div className="inner-image-wrapper">
                          <img
                            className="Unavailable"
                            src={item.resource_photo}
                            onError={this.onImageError.bind(this)}
                          />
                        </div>
                      </div>
                      <button
                        className={
                          "btn-res-assign button " +
                          this.state.buttons[item.resource_name]
                        }
                        id={"crew_" + item.id}
                        onClick={(e) => {
                          this.__createResourceList(
                            e,
                            item,
                            item.resource_name,
                            true,
                          );
                        }}
                      >
                        Assign
                      </button>
                    </div>,
                  );
                }
              });

              crew_list.push(
                <div>
                  <div
                    className="crew-div"
                    onClick={this.crewClick.bind(
                      this,
                      "step3",
                      item.resource_name,
                      item.id,
                    )}
                  >
                    <div className="crew-img">
                      <img
                        src={item.resource_photo}
                        alt={item.resource_name}
                        onError={this.onImageError.bind(this)}
                      />
                    </div>
                    <div className="crew-details">
                      <h3 title={item.resource_name}>{item.resource_name}</h3>
                      {item.users && Array.isArray(item.users) && (
                        <span>{item.users.length} members</span>
                      )}
                    </div>
                  </div>
                  <div className="clear-div" />
                </div>,
              );
              var usersDiv = [];
              if (item.users) {
                item.users.map((elements, index) => {
                  usersDiv.push(
                    <li
                      key={index}
                      className="crew_mem_clk"
                      onClick={this.onStepChange.bind(
                        this,
                        "step2",
                        elements.hash,
                        elements.id,
                        elements.crew_name,
                      )}
                    >
                      <img
                        src={elements.profile_photo}
                        alt={elements.first_name + " " + elements.last_name}
                        onError={this.onImageError.bind(this)}
                      />
                      <h3
                        title={elements.first_name + " " + elements.last_name}
                      >
                        {elements.first_name + " " + elements.last_name}
                      </h3>
                      <p className="qualy">
                        {Array.isArray(elements.qualification_key) &&
                        elements.qualification_key.length > 0
                          ? elements.qualification_key.join(", ")
                          : ""}
                      </p>
                      <p className="prev-crew">
                        Last Assign: {elements.last_assign}
                      </p>
                    </li>,
                  );
                });
              }
              ffMobileDiv.push(
                <div>
                  <div className="resourcebox">
                    <div className="crew-div">
                      <div className="crew-img">
                        <img
                          src={item.resource_photo}
                          alt={item.resource_name}
                          onError={this.onImageError.bind(this)}
                        />
                      </div>
                      <div className="crew-details">
                        <h3 title={item.resource_name}>{item.resource_name}</h3>
                        {item.users && Array.isArray(item.users) && (
                          <span>{item.users.length} members</span>
                        )}
                      </div>
                    </div>
                    <div className="crew-member-list">
                      <ul>{usersDiv}</ul>
                    </div>
                  </div>
                  <div className="clear-div" />
                </div>,
              );
            });
          }
        });
      }
      this.setState({
        resourceList: ffOptions,
        ffList: ffOptions,
        // asList: asOptions,
        resourceIDList: ids,
        ffMobileDiv,
        crew_list,
      });
    }

    if (user) {
      if (user.items) {
        var generalQualificationList = [],
          data = user.items.data;
        if (data.qualification) {
          data.qualification.map((item, index) => {
            generalQualificationList.push(
              <p className="crew-profile-detail" key={index}>
                {item}
              </p>,
            );
          });
        }
        this.setState({
          profile: user.items.data,
          loading: false,
          generalQualificationList,
          notes: this?.state?.notes
            ? this?.state?.notes
            : user.items.data.notes,
          unavailableReason: user.items.data.unavailable_reason,
          otherReason: user.items.data.other_reason,
        });
      }
    }
  }

  updateResourceList() {
    var staffs = this.state.all;

    staffs = staffs
      .map(JSON.stringify)
      .reverse() // convert to JSON string the array content, then reverse it (to check from end to begining)
      .filter(function (item, index, staffs) {
        return staffs.indexOf(item, index + 1) === -1;
      }) // check if there is any occurence of the item in whole array
      .reverse()
      .map(JSON.parse);
    var organizeStaff = [];
    var options = [];
    var filterStaff = [];
    this.state.assigned.forEach((res, index) => {
      filterStaff[res] = [];
      staffs.filter((user) => {
        if (user.crew_name === res) {
          filterStaff[res].push(user);
        }
      });
    });

    Object.keys(filterStaff).forEach((index) => {
      var item = filterStaff[index];
      if (filterStaff[index].length < 1) {
        var organizeStaff = [];
        organizeStaff[index] = [];
        if (this.state.resource_type_list[index] === "FireFighter") {
          organizeStaff[index]["Superint. / Eng. Captain"] = [];
          organizeStaff[index]["Captains"] = [];
          organizeStaff[index]["Firefighters"] = [];
        }
        organizeStaff[index]["Staff Managers"] = [];
        organizeStaff[index]["Staffs"] = [];
        organizeStaff[index]["GISS Technician"] = [];

        if (Object.keys(this.state.dragList).length) {
          this.setState((state) => ({
            dragList: { ...state.dragList, ...organizeStaff },
          }));
        } else {
          this.setState({
            ...this.state,
            dragList: organizeStaff,
          });
        }
      } else {
        var organizeStaff = [];
        var options = [];

        filterStaff[index].map((user, index) => {
          // if(user.user_type_nickname !== 'Super Admin') {
          if (user.crew_name in organizeStaff) {
            if (user.user_type_nickname in organizeStaff[user.crew_name]) {
              options = organizeStaff[user.crew_name][user.user_type_nickname];
              options.push(user);
              organizeStaff[user.crew_name][user.user_type_nickname] = options;
            }
          } else {
            options = [];
            organizeStaff[user.crew_name] = [];
            if (
              this.state.resource_type_list[user.crew_name] === "FireFighter"
            ) {
              organizeStaff[user.crew_name]["Superint. / Eng. Captain"] = [];
              organizeStaff[user.crew_name]["Captains"] = [];
              organizeStaff[user.crew_name]["Firefighters"] = [];
            }
            organizeStaff[user.crew_name]["Staff Managers"] = [];
            organizeStaff[user.crew_name]["Staffs"] = [];
            organizeStaff[user.crew_name]["GISS Technician"] = [];
            options.push(user);
            organizeStaff[user.crew_name][user.user_type_nickname] = options;
          }
          // }
        });
        if (Object.keys(this.state.dragList).length) {
          this.setState((state) => ({
            dragList: { ...state.dragList, ...organizeStaff },
          }));
        } else {
          this.setState({
            ...this.state,
            dragList: organizeStaff,
          });
        }
      }
    });
  }
}

function mapStateToProps(state) {
  const { resource, employee, user, permission } = state;
  return {
    resource,
    employee,
    user,
    permission,
  };
}

const connectedAssignStaffPage = connect(mapStateToProps)(Assign);
export { connectedAssignStaffPage as Assign };
