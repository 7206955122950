import { useMutation } from "react-query";
import React, { useState, useEffect } from "react";
import Modal from "_components/utils/Modal/Modal";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { userStatus } from "_constants";
import { profileService } from "_services/profile.service";
import { ReactComponent as EditIcon } from "assets/icons/edit-icon-grey.svg";

export const useUpdateProfile = (
  refetchUser,
  refetchHistory,
  onSuccessCallback,
  setDisableSave,
) => {
  return useMutation(
    ({ userId, formData }) => profileService.updateProfile(userId, formData),
    {
      onSuccess: (data, variables) => {
        refetchUser();
        const { formData } = variables; // Access formData here

        if (formData?.employment_history_id) {
          refetchHistory();
        }
        toast.success("Profile updated successfully!");
        onSuccessCallback();
        setDisableSave(false);
      },
      onError: (error) => {
        const errorMessage = error?.response?.data?.message;

        let firstError = "";
        if (typeof errorMessage === "string") {
          firstError = errorMessage;
        } else if (typeof errorMessage === "object" && errorMessage !== null) {
          const firstKey = Object.keys(errorMessage)[0]; // Get the first key (e.g., "email")
          if (
            Array.isArray(errorMessage[firstKey]) &&
            errorMessage[firstKey].length > 0
          ) {
            firstError = errorMessage[firstKey][0]; // Get the first error message
          }
        }
        setDisableSave(false);
        toast.error("Failed to update profile. " + firstError);
      },
    },
  );
};

export const EditProfile = ({
  label,
  profileData,
  refetchUser,
  refetchHistory,
  authUser,
  hasPermissionRequiredToEditOwn,
  hasPermissionToEditOthers,
  editClicked,
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [lastSavedProfile, setLastSavedProfile] = useState(profileData);

  const {
    register,
    setValue,
    getValues,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    setValue("ssn", lastSavedProfile?.ssn || "");
    setValue("phone", lastSavedProfile?.phone || "");
    setValue("second_phone", lastSavedProfile?.second_phone || "");
    setValue(
      "emg_contact_1_phone",
      lastSavedProfile?.emg_contact_1_phone || "",
    );
    setValue(
      "emg_contact_2_phone",
      lastSavedProfile?.emg_contact_2_phone || "",
    );
  }, [lastSavedProfile, setValue]);

  const ownProfile = profileData?.id == authUser?.id;

  const EditForm = ({
    children,
    register,
    errors,
    setValue,
    control,
    handleSubmit,
    handleSave,
    handleCancel,
  }) => (
    <form onSubmit={handleSubmit(handleSave)}>
      <div>{children(register, errors, setValue, control)}</div>
      <div className="button-holder button-holder--right">
        <button
          disabled={disableSave}
          className="button grey"
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button disabled={disableSave} className="button" type="submit">
          Save
        </button>
      </div>
    </form>
  );

  const mutation = useUpdateProfile(
    refetchUser,
    refetchHistory,
    () => {
      setIsModalOpen(false); // Close the modal on success
    },
    setDisableSave,
  );

  // Compare current form data with original profile data and send only changed fields
  const handleSave = (formData) => {
    setDisableSave(true);
    const updatedFields = {};
    // Compare each field in the formData with the profileData
    Object.keys(formData).forEach((key) => {
      if (
        !(
          (
            formData[key] == lastSavedProfile[key] || // Values are strictly equal
            ([null, "", undefined].includes(formData[key]) &&
              [null, "", undefined].includes(lastSavedProfile[key]))
          ) // Both are null, "", or undefined
        ) ||
        (formData[key] == "" && lastSavedProfile[key] == 0) ||
        [
          "employment_history_id",
          "employment_start_date",
          "employment_end_date",
          "last_day_worked_date",
          "last_day_worked_reason",
        ].includes(key)
      ) {
        updatedFields[key] = formData[key];
      }
    });

    mutation.mutate(
      { userId: profileData.id, formData: updatedFields },
      {
        onSuccess: () => {
          setLastSavedProfile((prev) => ({ ...prev, ...updatedFields }));
        },
      },
    ); // Send only changed fields
  };

  // Function to open modal and set default form values
  const handleEdit = (fieldKey) => {
    setIsModalOpen(true);
    if (editClicked) {
      editClicked();
    }
  };

  const handleCancel = () => {
    reset(profileData);
    setIsModalOpen(false); // Close modal after saving
  };

  // 1. Active user 2. Own profile and permission not required to edit field 3. Permission to edit Others field
  const canEdit =
    profileData?.status != userStatus.Unavailable &&
    ((ownProfile &&
      hasPermissionRequiredToEditOwn &&
      profileData?.status == userStatus.Available) ||
      hasPermissionToEditOthers);

  return (
    <>
      {canEdit && (
        <>
          <button
            type="button"
            className="profile-edit-button"
            onClick={handleEdit}
          >
            edit button
            <EditIcon />
          </button>
          {isModalOpen && (
            <Modal
              isOpen={isModalOpen}
              className="update-applicant-status-modal"
            >
              <div className="modal-head">
                <h4>Edit {label}</h4>
              </div>
              <div className="modal-body applicant-status-modal-body">
                <EditForm
                  children={children}
                  register={register}
                  errors={errors}
                  setValue={setValue}
                  control={control}
                  handleSubmit={handleSubmit}
                  handleSave={handleSave}
                  handleCancel={handleCancel}
                />
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  );
};
