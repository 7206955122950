import React from "react";

import { EditProfile } from "./EditProfile";

const MerchandiseInformationComponent = ({
  profileData,
  dropdownData,
  refetchUser,
  isOpen,
  toggleAccordion,
  permList,
  authUser,
}) => {
  return (
    <div
      id="merchandise-information"
      className={`profile-data__drops profile-data_comp ${isOpen ? "is-active" : ""}`}
    >
      <header onClick={() => toggleAccordion("#merchandise-information")}>
        <button type="button">
          <h3>Merchandise Information</h3>
        </button>
      </header>
      <div className="profile-data__drops-cnt">
        <ul role="list">
          <li role="listitem">
            <span>
              <b>Shirt Size</b>
            </span>
            <span>{profileData.shirt ? profileData.shirt : "-"}</span>
            <EditProfile
              label="Shirt Size"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors) => (
                <>
                  <label>Shirt Size</label>
                  <select
                    {...register("shirt_id")}
                    defaultValue={profileData.shirt_size}
                    className={errors.shirt_id && "profile-form-field-error"}
                  >
                    <option value="">Select Shirt Size</option>
                    {dropdownData.ShirtSize.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                  {errors.shirt_id && (
                    <p className="error-message">{errors.shirt_id.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Pant Size</b>
            </span>
            <span>{profileData.pant ? profileData.pant : "-"}</span>
            <EditProfile
              label="Pant Size"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors) => (
                <>
                  <label>Pant Size</label>
                  <select
                    {...register("pant_id")}
                    defaultValue={profileData.pant_size}
                    className={errors.pant_id && "profile-form-field-error"}
                  >
                    <option value="">Select Pant Size</option>
                    {dropdownData.PantSize.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                  {errors.pant_id && (
                    <p className="error-message">{errors.pant_id.message}</p>
                  )}
                </>
              )}
            </EditProfile>
          </li>

          <li role="listitem">
            <span>
              <b>Inseam Pant Size</b>
            </span>
            <span>
              {profileData.inseam_pant ? profileData.inseam_pant : "-"}
            </span>
            <EditProfile
              label="Inseam Pant Size"
              profileData={profileData}
              refetchUser={refetchUser}
              authUser={authUser}
              hasPermissionRequiredToEditOwn={true}
              hasPermissionToEditOthers={permList.includes(
                "Manage Employee Profile Fields",
              )}
            >
              {(register, errors) => (
                <>
                  <label>Inseam Pant Size</label>
                  <select
                    {...register("inseam_pant_id")}
                    defaultValue={profileData.inseam_pant_size}
                    className={
                      errors.inseam_pant_id && "profile-form-field-error"
                    }
                  >
                    <option value="">Select Inseam Pant Size</option>
                    {dropdownData.ListInseamPantSize.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.value}
                      </option>
                    ))}
                  </select>
                  {errors.inseam_pant_id && (
                    <p className="error-message">
                      {errors.inseam_pant_id.message}
                    </p>
                  )}
                </>
              )}
            </EditProfile>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MerchandiseInformationComponent;
